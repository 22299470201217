import type { Theme } from '@mui/material/styles';

export const dayOfWeekButtonStyles = (theme: Theme, isSelected: boolean) => ({
  border: `1px solid ${!isSelected ? theme.palette.secondary.main : theme.palette.secondary.dark}`,
  backgroundColor: !isSelected ? theme.palette.white.main : theme.palette.secondary.main,
  color: !isSelected ? theme.palette.dark.main : theme.palette.white.main,
  padding: '8px 4px',
  borderRadius: '4px',
  textAlign: 'center',
  minWidth: '32px',
  minHeight: '44px',
  '&:hover': {
    backgroundColor: theme.palette.baseLight.base24,
  },
});

export const daysOfWeekButtonContainerStyle = (theme: Theme) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '8px',
});

export const disabledOverlayShadow = (theme: Theme) => ({
  position: 'absolute',
  height: '100%',
  width: `100%`,
  top: 0,
  backgroundColor: theme.palette.baseLight.base0,
  opacity: 0.6,
  zIndex: 9999,
});
