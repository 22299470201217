import { EnumTabHelper } from '../../utils/EnumTabHelper';

export enum CustomerInformationTab {
  CustomerDetails = 'CUSTOMER_DETAILS',
  TripHistory = 'TRIP_HISTORY',
  Budget = 'BUDGET',
  DriverLicence = 'DRIVER_LICENCE',
}

const customerInformationTranslations = {
  CustomerDetails: 'customerInformationModal.tab.customerDetails',
  TripHistory: 'customerInformationModal.tab.tripHistory',
  Budget: 'common.budget',
  DriverLicence: 'customerInformationModal.tab.driversLicence',
};

export const CustomerInformationTabHelper = new EnumTabHelper(CustomerInformationTab, customerInformationTranslations);
