import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Form, Formik } from 'formik';

import { NumberInput } from '../../wmv-components';

import { TripBudgetRefundValidationSchema } from './validation-schemas/TripBudgetRefundValidationSchema';

const tripBudgetRefundFormInitialValues: TripBudgetRefundFormFields = {
  maxAllowedBudgetRefundAmt: 12,
  budgetRefundAmt: '',
};

interface TripBudgetRefundFormFields {
  maxAllowedBudgetRefundAmt: number;
  budgetRefundAmt: number | '';
}

export function TripBudgedRefundForm() {
  return (
    <Formik
      initialValues={tripBudgetRefundFormInitialValues}
      onSubmit={(values) => console.log(values)}
      validationSchema={TripBudgetRefundValidationSchema}
    >
      {({ touched, errors, handleSubmit, values, setFieldValue }) => (
        <Form onSubmit={handleSubmit}>
          <Box display="flex" flexDirection="row" gap={3}>
            <Box display="flex" flexDirection="row" gap={3} flex="min-content">
              <TextField
                name="maxAllowedBudgetRefundAmt"
                value={`${values.maxAllowedBudgetRefundAmt} Hardcoded`}
                variant="outlined"
                label={'budgetRefund.maxAllowed'.i18nText()}
                sx={{
                  '.MuiInputBase-root  input': {
                    textAlign: 'center',
                  },
                }}
                title={'budgetRefund.maxAllowed'.i18nText()}
                disabled
              />
              <NumberInput
                name="budgetRefundAmt"
                value={values.budgetRefundAmt}
                onChange={async ({ name, value }) => {
                  await setFieldValue(name, value);
                }}
                label={'budgetRefund.budgetRefundAmt'.i18nText()}
                dataTestId="budgetRefundAmt"
                fullWidth={false}
                error={touched['budgetRefundAmt'] && !!errors['budgetRefundAmt']}
                helperText={touched['budgetRefundAmt'] && !!errors['budgetRefundAmt'] ? errors['budgetRefundAmt'] : null}
                sx={{ width: '200px' }}
                inputExtraProps={{ title: 'budgetRefund.budgetRefundAmt'.i18nText() }}
              />
            </Box>

            <Box display="flex" flexDirection="row" gap={2} flex="min-content">
              <Button type="reset" size="medium" variant="contained">
                {'maas.customerDatabase.reset'.i18nText()}
              </Button>
              <Button type="submit" size="medium" variant="secondaryLight">
                {'common.budgetRefund'.i18nText()}
              </Button>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
}
