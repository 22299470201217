import type { Theme } from '@mui/material/styles';

export const headerSectionContainerStyles = (theme: Theme) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(5)} ${theme.spacing(3)}`,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const contentHeadingStyles = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  marginBottom: theme.spacing(5),
});

export const inputContainerStyles = {
  display: 'flex',
  gap: '16px',
};

export const inputTitleAndDescriptionContainerStyles = {
  width: '350px',
  maxWidth: '350px',
};

export const vehicleTypesCheckboxGroupStyles = (theme: Theme) => ({
  width: '400px',
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(160px, 1fr))',
  justifyItems: 'stretch',
  rowGap: theme.spacing(2),
  flex: 1,
  marginTop: theme.spacing(4),
});

export const budgetPlanFormInputStyles = (theme: Theme) => ({
  width: '300px',
});

export const actionButtonStyles = (theme: Theme) => ({
  marginLeft: 1,
  width: '120px',
});

// Table Cell
export const statusIndicatorCircleStyles = (theme: Theme, color: string) => ({
  height: '14px',
  width: '14px',
  borderRadius: '50%',
  backgroundColor: color,
  marginRight: theme.spacing(1),
});

export const statusContainerStyles = (theme: Theme) => ({
  display: 'flex',
  alignItems: 'center',
});
