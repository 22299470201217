import {
  AggregatedVehicle,
  BudgetPlanDetailsResponse,
  BudgetPlanMembershipDetailsResponse,
  BudgetPlanMembershipSearchResponse,
  BudgetPlanMembershipsListResponse,
  BudgetPlansListResponse,
  ChangeRequestResponse,
  ChangeStatusRequest,
  CreateBudgetPlanMembershipRequest,
  CreateBudgetPlanMembershipResponse,
  CreateBudgetPlanRequest,
  CreateBudgetPlanResponse,
  CustomerDetails,
  DashboardShortcut,
  ForceStopTripDetailsResponse,
  GetTripsQueryParams,
  LawEnforcementTripSummaryResponse,
  RetryFailedMembershipTransitionResponse,
  SearchUsersQueryParams,
  UnlinkMembershipResponse,
  UpdateBudgetPlanRequest,
  UpdateBudgetPlanResponse,
  UpdateBudgetPlanStatusRequest,
  UpdateBudgetPlanStatusResponse,
  TripDetailsResponse,
  CustomerTripsListResponse,
  ZoneRequest,
  ZoneResponse,
  ZoneStatus,
  VehicleTripsListResponse,
} from '../models';
import {
  UpdateVehiclePricingRequest,
  UpdateVehiclePricingResponse,
  VehicleFeePricingListResponse,
  VehicleFeePricingResponse,
} from '../models/vehicle-fee-pricing';
import axiosInstance from '../services/AxiosClient';
import UserService from '../services/UserService';

export const searchUsers = ({ firstName, lastName, birthDate }: SearchUsersQueryParams): Promise<CustomerDetails[]> => {
  return axiosInstance.get(`/mobility/users/?firstName=${firstName}&lastName=${lastName}&birthDate=${birthDate}`);
};

export const putCustomerDetails = (
  payload: Partial<CustomerDetails>,
  subjectClaim: string,
): Promise<CustomerDetails | undefined | null> => {
  return axiosInstance.put(`/mobility/users/${subjectClaim}/updateBySupport`, payload);
};

export const getVehicleTripsList = ({
  vehicleId,
  fromInclusiveEpochMillis,
  toInclusiveEpochMillis,
}: GetTripsQueryParams): Promise<VehicleTripsListResponse> => {
  return axiosInstance.get(
    `/mobility/vehicles/${vehicleId}/trips?fromInclusiveEpochMillis=${fromInclusiveEpochMillis}&toInclusiveEpochMillis=${toInclusiveEpochMillis}`,
  );
};

export const getMobilityCustomerTripsList = (customerSubjectClaim: string): Promise<CustomerTripsListResponse> => {
  return axiosInstance.get(`/mobility/users/${customerSubjectClaim}/trips`);
};

export const getCustomerDetails = (subjectClaim: string): Promise<CustomerDetails> => {
  return axiosInstance.get(`/mobility/users/${subjectClaim}`);
};

export const getTripDetails = (tripId: number): Promise<TripDetailsResponse> => {
  return axiosInstance.get(`/mobility/users/${UserService.subjectClaim()}/trips/${tripId}`);
};

export const getDashboardWidgetsData = (): Promise<DashboardShortcut[]> => {
  return axiosInstance.get(`/mobility/mobility-manager/dashboard-shortcuts`);
};

export const getAllDashboardWidgetsData = (): Promise<DashboardShortcut[]> => {
  return axiosInstance.get(`/mobility/mobility-manager/metadata/dashboard-shortcut-categories`);
};

export const fetchZonesByStatus = (status: ZoneStatus): Promise<ZoneResponse[]> => {
  return axiosInstance.get(`/mobility-manager/zones?status=${status}`);
};

export const fetchAllZones = (): Promise<ZoneResponse[]> => {
  return axiosInstance.get(`/mobility-manager/zones`);
};

export const createZone = (payload: ZoneRequest): Promise<ZoneResponse> => {
  return axiosInstance.post(`/mobility-manager/zones`, payload);
};

export const updateZone = (zoneId: number, payload: ZoneRequest): Promise<ZoneResponse> => {
  return axiosInstance.put(`/mobility-manager/zones/${zoneId}`, payload);
};

export const deleteZone = (zoneId: number): Promise<ZoneResponse> => {
  return axiosInstance.delete(`/mobility-manager/zones/${zoneId}`);
};

export const forceStopTrip = (tripId: number, payload: any): Promise<ForceStopTripDetailsResponse> => {
  return axiosInstance.post(`/mobility/users/${UserService.subjectClaim()}/trips/${tripId}/force-stop`, payload);
};

export const fetchTripSummariesForLawEnforcement = (
  fromInclusiveEpochMillis: number,
  toInclusiveEpochMillis: number,
): Promise<LawEnforcementTripSummaryResponse[]> => {
  return axiosInstance.get(
    `/mobility/lookup/trips-for-law-enforcement?fromInclusiveEpochMillis=${fromInclusiveEpochMillis}&toInclusiveEpochMillis=${toInclusiveEpochMillis}`,
  );
};

export const listBudgetPlans = (): Promise<BudgetPlansListResponse> => {
  return axiosInstance.get(`/mobility-manager/mobility-service-providers/${UserService.subjectClaim()}/budget-plans`);
};

export const addBudgetPlan = (payload: CreateBudgetPlanRequest): Promise<CreateBudgetPlanResponse> => {
  return axiosInstance.post(`/mobility-manager/mobility-service-providers/${UserService.subjectClaim()}/budget-plans`, payload);
};

export const updateBudgetPlan = (payload: UpdateBudgetPlanRequest, planId: number): Promise<UpdateBudgetPlanResponse> => {
  return axiosInstance.put(`/mobility-manager/mobility-service-providers/${UserService.subjectClaim()}/budget-plans/${planId}`, payload);
};

export const updateBudgetPlanStatus = (payload: UpdateBudgetPlanStatusRequest, planId: number): Promise<UpdateBudgetPlanStatusResponse> => {
  return axiosInstance.put(`/mobility-manager/mobility-service-providers/${UserService.subjectClaim()}/budget-plans/${planId}`, payload);
};

export const fetchBudgetPlanByIdApi = (id: number): Promise<BudgetPlanDetailsResponse> => {
  return axiosInstance.get(`/mobility-manager/mobility-service-providers/${UserService.subjectClaim()}/budget-plans/${id}`);
};

export const fetchBudgetPlanMembershipSummaries = (budgetPlanId: number): Promise<BudgetPlanMembershipsListResponse> => {
  return axiosInstance.get(
    `/mobility-manager/mobility-service-providers/${UserService.subjectClaim()}/budget-plans/${budgetPlanId}/memberships`,
  );
};

export const fetchBudgetPlanMembershipDetails = (
  budgetPlanId: number,
  budgetPlanMembershipId: number,
): Promise<BudgetPlanMembershipDetailsResponse> => {
  return axiosInstance.get(
    `/mobility-manager/mobility-service-providers/${UserService.subjectClaim()}/budget-plans/${budgetPlanId}/memberships/${budgetPlanMembershipId}`,
  );
};

export const searchMembership = (email: string): Promise<BudgetPlanMembershipSearchResponse> => {
  return axiosInstance.get('/mobility-manager/mobility-users', {
    params: {
      email,
    },
    headers: {
      accept: 'application/vnd.budget-plan.membership-linkage+json',
    },
  });
};

export const createMembership = (
  planId: number,
  payload: CreateBudgetPlanMembershipRequest,
): Promise<CreateBudgetPlanMembershipResponse> => {
  return axiosInstance.post(
    `/mobility-manager/mobility-service-providers/${UserService.subjectClaim()}/budget-plans/${planId}/memberships`,
    payload,
  );
};

export const deleteMembership = (planId: number, membershipId: number): Promise<UnlinkMembershipResponse> => {
  return axiosInstance.delete(
    `/mobility-manager/mobility-service-providers/${UserService.subjectClaim()}/budget-plans/${planId}/memberships/${membershipId}`,
  );
};

export const retryFailedMembershipStateTransition = (
  planId: number,
  membershipId: number,
): Promise<RetryFailedMembershipTransitionResponse> => {
  return axiosInstance.post(
    `/mobility-manager/mobility-service-providers/${UserService.subjectClaim()}/budget-plans/${planId}/memberships/${membershipId}/retry-failed-attempt`,
  );
};

export const cancelLastFailedAttempt = (planId: number, membershipId: number): Promise<''> => {
  return axiosInstance.post(
    `/mobility-manager/mobility-service-providers/${UserService.subjectClaim()}/budget-plans/${planId}/memberships/${membershipId}/mark-last-failed-attempt-acknowledged`,
  );
};

export const fetchAggregatedVehicles = (): Promise<AggregatedVehicle[]> => {
  return axiosInstance.get(`/mobility-manager/mobility-service-providers/${UserService.subjectClaim()}/vehicles`);
};

export const changeVehicleStatus = (payload: ChangeStatusRequest, vehicleId: number): Promise<ChangeRequestResponse> => {
  return axiosInstance.patch(
    `mobility-manager/mobility-service-providers/${UserService.subjectClaim()}/vehicles/${vehicleId}/status`,
    payload,
  );
};

export const fetchVehicleFeeList = (): Promise<VehicleFeePricingListResponse> => {
  return axiosInstance.get('/mobility-manager/mobility-service-providers/aggregated/vehicle-models/aggregated/fee-schedules');
};

export const fetchVehicleFeePricing = (mspSubjectClaim: string, vehicleModelId: number): Promise<VehicleFeePricingResponse> => {
  return axiosInstance.get(`/mobility-manager/mobility-service-providers/${mspSubjectClaim}/vehicle-models/${vehicleModelId}/fee-schedule`);
};

export const updateVehicleFeePricing = (
  request: UpdateVehiclePricingRequest,
  mspSubjectClaim: string,
  vehicleModelId: number,
): Promise<UpdateVehiclePricingResponse> => {
  return axiosInstance.put(
    `/mobility-manager/mobility-service-providers/${mspSubjectClaim}/vehicle-models/${vehicleModelId}/fee-schedule`,
    request,
  );
};

type NO_CONTENT = '';
export const discardFailedVehiclePricingStatusTransition = (mspSubjectClaim: string, vehicleModelId: number): Promise<NO_CONTENT> => {
  return axiosInstance.post(
    `/mobility-manager/mock/mobility-service-providers/${mspSubjectClaim}/vehicle-models/${vehicleModelId}/fee-schedule/mark-last-failed-attempt-acknowledged`,
  );
};
