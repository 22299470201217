import React, { useRef, useState } from 'react';

import { ButtonGroup } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { ReactComponent as FilterIcon } from '../../../assets/icons/outline/filter.svg';
import { ReactComponent as InfoIcon } from '../../../assets/icons/outline/mapInfo.svg';
import CustomPopover from '../../CustomPopover';
import CustomZoneControlButton from '../MapModes/CustomZoneControlButton';

import InfoZoneLayer from './InfoZone';
import MapEntitiesFilters, { MapFilters } from './MapFilters';

function MapFiltersAndZoneTypeInfoControls({ filters, isAnyFilterApplied, onFilterChange }: MapFiltersAndZoneTypeInfoControlsProps) {
  const [showFilterLayer, setShowFilterLayer] = useState(false);
  const [showInfoLayer, setShowInfoLayer] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [isSelected, setIsSelected] = useState<string | null>(null);
  const popupDisplayRef = useRef<HTMLButtonElement | null>(null);

  const handleZoneFilter = () => {
    setShowFilterLayer((prevState) => !prevState);
    setAnchorEl(null);
    setIsSelected(isSelected === 'filter' ? null : 'filter');
  };

  const handleZoneInfo = (event?: React.MouseEvent<HTMLElement> | MouseEvent) => {
    setShowInfoLayer((prevState) => !prevState);
    setAnchorEl(null);
    setIsSelected(isSelected === 'info' ? null : 'info');
  };

  const theme = useTheme();

  return (
    <>
      <div>
        <ButtonGroup
          orientation="vertical"
          variant="outlined"
          aria-label="outlined primary button group"
          sx={{ boxShadow: theme.shadowOptions.belowMedium, zIndex: '1' }}
        >
          <CustomZoneControlButton
            icon={<FilterIcon />}
            label="filter"
            onClick={handleZoneFilter}
            isSelected={isSelected === 'filter' || isAnyFilterApplied}
            ref={popupDisplayRef}
          />
          <CustomZoneControlButton icon={<InfoIcon />} label="info" onClick={handleZoneInfo} isSelected={isSelected === 'info'} />
        </ButtonGroup>
      </div>
      {showFilterLayer && (
        <CustomPopover open={showFilterLayer} anchorEl={popupDisplayRef.current} handleClose={handleZoneFilter}>
          <MapEntitiesFilters handleClose={handleZoneFilter} onFilterChange={onFilterChange} filters={filters!} />
        </CustomPopover>
      )}
      {showInfoLayer && (
        <CustomPopover
          open={showInfoLayer}
          anchorEl={popupDisplayRef.current}
          handleClose={handleZoneInfo}
          style={{ maxWidth: theme.spacing(65), padding: theme.spacing(2) }}
        >
          <InfoZoneLayer />
        </CustomPopover>
      )}
    </>
  );
}

interface MapFiltersAndZoneTypeInfoControlsProps {
  filters: MapFilters;
  isAnyFilterApplied: boolean;
  onFilterChange: (values: MapFilters) => void;
}

export default MapFiltersAndZoneTypeInfoControls;
