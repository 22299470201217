import { Box, Button, Modal, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useIntl } from 'react-intl';

import { statusChangeModalStyle } from './StatusChangeModal';

type ModalType = 'change' | 'cancel';

function StatusChangeModal({ isOpen, vehicleQrCode, handleStatusChangeSuccessModal, modalType }: StatusChangeModalProps) {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const customModalStyles = modalType === 'cancel' ? { ...statusChangeModalStyle(theme), width: '400px' } : statusChangeModalStyle(theme);

  // TODO: Use the Modal from wmv-components
  return (
    <Modal open={isOpen}>
      <Box sx={customModalStyles}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(0.5) }}>
          <Typography variant="bodyMediumBold" color={theme.palette.dark.main} sx={{ textAlign: 'center' }}>
            {formatMessage({ id: `operator.statusChange.success.${modalType}.caption` })}
            <br />
            {formatMessage({ id: 'operator.statusChange.vehicle' })} {vehicleQrCode}
          </Typography>
          <Typography variant="bodySmall" color={theme.palette.dark.shade76} sx={{ textAlign: 'center' }}>
            {formatMessage({ id: `operator.statusChange.success.${modalType}.text` })}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            sx={{
              width: theme.spacing(23),
              height: theme.spacing(4),
            }}
            onClick={handleStatusChangeSuccessModal}
          >
            <Typography variant="bodySmallBold" color={theme.palette.white.main}>
              {formatMessage({ id: 'common.dialog.confirm' })}
            </Typography>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

interface StatusChangeModalProps {
  isOpen: boolean;
  vehicleQrCode: string;
  handleStatusChangeSuccessModal: () => void;
  modalType: ModalType;
}

export default StatusChangeModal;
