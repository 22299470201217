import { useMemo } from 'react';

import { Table, TableBody, TableRow, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useIntl } from 'react-intl';

import { themeOptions as theme } from '../../../init-setup/ThemeOptions';
import { TripPaymentDetails } from '../../../models';
import { TripPricingBreakdownService } from '../../../services/trip/TripPricingBreakdownService';

import { StyledTableCell } from './StyledTableCell';

interface TripPricingBreakdownProps {
  isTripCompleted: boolean;
  payment: TripPaymentDetails;
  totalChargedMins: string | null;
}

export function TripPricingBreakdown({ isTripCompleted, payment, totalChargedMins }: TripPricingBreakdownProps) {
  const { formatMessage } = useIntl();

  const {
    perUnitCharges: { perMinRideCharges, perMinPausedCharges, perTripActivationCharges },
    totalChargesBreakdown: { rideTripCharges, pausedTripCharges, totalCharges, totalChargesWithoutVat, vatPercentage, vatCharges },
    budgetDeductionInfo: { planName, linkedAccountOwnerName, amountInMins },
  } = useMemo(() => new TripPricingBreakdownService(payment), [payment]);

  return (
    <Box my={1}>
      <Typography variant="bodyLargeBold" flexGrow={2} mb={2}>
        {'common.paymentBreakDown'.i18nText()}
      </Typography>

      <Table>
        <TableBody>
          {planName && linkedAccountOwnerName && (
            <TableRow>
              <StyledTableCell>
                {'trip.budgetDeduction'.i18nText()} <br />
                <Typography component="div" variant="bodyMedium" color={theme.palette.baseLight.base76} mt={0.5}>
                  {planName}
                </Typography>{' '}
                <Typography component="div" variant="bodyMedium" color={theme.palette.baseLight.base76} mt={0.5}>
                  {'common.owner'.i18nText()}: {linkedAccountOwnerName}
                </Typography>
              </StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell>-{amountInMins}</StyledTableCell>
            </TableRow>
          )}

          {isTripCompleted && (
            <TableRow sx={{ borderBottom: `1px dashed ${theme.palette.baseLight.base24}` }}>
              <StyledTableCell sx={{ py: 3 }}>{'common.minutesCharged'.i18nText()}</StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell>{totalChargedMins}</StyledTableCell>
            </TableRow>
          )}

          <TableRow>
            <StyledTableCell sx={{ pt: 3, mb: 2 }}>
              {formatMessage({ id: 'userTripDetails.rideTripCost' })}
              <Typography variant="bodyMedium" color={theme.palette.baseLight.base60}>
                {' '}
                ({perMinRideCharges || 'common.dash'.i18nText()}, {'common.inclVAT'.i18nText()})
              </Typography>
            </StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell sx={{ pt: 3 }}>{rideTripCharges || 'common.dash'.i18nText()}</StyledTableCell>
          </TableRow>

          <TableRow>
            <StyledTableCell sx={{ mb: 2 }}>
              {formatMessage({ id: 'userTripDetails.pausedTripCost' })}{' '}
              <Typography variant="bodyMedium" color={theme.palette.baseLight.base60}>
                {' '}
                ({perMinPausedCharges || 'common.dash'.i18nText()}, {'common.inclVAT'.i18nText()})
              </Typography>
            </StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell>{pausedTripCharges || 'common.dash'.i18nText()}</StyledTableCell>
          </TableRow>

          <TableRow sx={{ mb: 2, borderBottom: `1px dashed ${theme.palette.baseLight.base24}` }}>
            <StyledTableCell sx={{ pb: 2 }}>
              {'common.activationFee'.i18nText()}
              <Typography variant="bodyMedium" color={theme.palette.baseLight.base60}>
                {' '}
                ({'common.inclVAT'.i18nText()})
              </Typography>
            </StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell> {perTripActivationCharges || 'common.dash'.i18nText()}</StyledTableCell>
          </TableRow>

          <TableRow>
            <StyledTableCell variant="bodyMedium" color={theme.palette.baseLight.base60} sx={{ py: 2 }}>
              {'common.net'.i18nText()}
            </StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell variant="bodyMedium" color={theme.palette.baseLight.base60}>
              {totalChargesWithoutVat || 'common.dash'.i18nText()}
            </StyledTableCell>
          </TableRow>

          <TableRow sx={{ borderBottom: `1px solid ${theme.palette.baseLight.base24}` }}>
            <StyledTableCell variant="bodyMedium" color={theme.palette.baseLight.base60} sx={{ pb: 2, pt: 0 }}>
              {'common.totalVat'.i18nText()} {vatPercentage || 'common.dash'.i18nText()}{' '}
            </StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell variant="bodyMedium" color={theme.palette.baseLight.base60}>
              {vatCharges || 'common.dash'.i18nText()}
            </StyledTableCell>
          </TableRow>

          <TableRow>
            <StyledTableCell sx={{ pt: 2, pb: 3 }}>{'common.totalCharged'.i18nText()}</StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell>{totalCharges || 'common.dash'.i18nText()}</StyledTableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
}
