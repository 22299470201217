import { useRef } from 'react';

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { trimEnd } from 'lodash';

import { themeOptions } from '../../init-setup/ThemeOptions';
import { TripDetailsResponse, TripPaymentDetails, VehicleTypeHelper } from '../../models';
import { TripPricingBreakdownService } from '../../services/trip/TripPricingBreakdownService';
import { intl } from '../../utils/intl';

interface TripAndVehicleSummaryProps {
  tripDetails: TripDetailsResponse;
}

export function TripOverview({ tripDetails }: TripAndVehicleSummaryProps) {
  const prevAmountBreakdownRef = useRef<TripPaymentDetails | null>(null);
  const tripCostsRef = useRef<string | null>(null);

  const {
    vehicle: { provider, type: vehicleType, model },
    payment,
  } = tripDetails;

  if (payment && prevAmountBreakdownRef.current !== payment) {
    prevAmountBreakdownRef.current = payment;
    tripCostsRef.current = new TripPricingBreakdownService(payment).totalChargesBreakdown.totalCharges || '';
  }

  return (
    <Stack gap={4}>
      <Typography variant="bodyLargeBold">{'common.overview'.i18nText()}</Typography>
      <Stack columnGap="1">
        <Stack direction="row" spacing={4}>
          <Stack spacing={1}>
            {renderOverviewDetail(trimEnd('maas.customer.trip.provider'.i18nText(), ':'), provider.name)}
            {renderOverviewDetail(trimEnd('userTripDetails.tripCosts'.i18nText(), ':'), tripCostsRef?.current)}
          </Stack>
          <Stack spacing={1}>
            {renderOverviewDetail(
              trimEnd('maas.customer.trip.vehicleType'.i18nText(), ':'),
              VehicleTypeHelper.metadata(vehicleType).displayText,
            )}
            {renderOverviewDetail(trimEnd('common.vehicleModel'.i18nText(), ':'), model.name)}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

function renderOverviewDetail(label: string, value?: number | string | null) {
  return (
    <Box display="flex" gap="0.5rem" minWidth="200px">
      <Typography variant="bodyMedium" color={themeOptions.palette.baseLight.base76} minWidth="120px">
        {label}:{' '}
      </Typography>
      <Typography variant="bodyMediumBold">{value || intl.formatMessage({ id: 'common.dash' })}</Typography>
    </Box>
  );
}
