import { startTransition, useCallback, useEffect, useRef, useState } from 'react';

import type { IntlShape } from 'react-intl';

import type { PreviewPredicates, TimeWindow, DateRangeAndDaysOfWeek } from '../../../models';
import { SliderGranularity, SliderPointFormatter } from '../../../services/zone';
import { Mode } from '../../../services/zone';

type UseFormattedSliderDisplayText = (
  currentSliderPointIndex: number,
  granularity: SliderGranularity,
  sliderPointsAsEpochMillis: number[],
  intl: IntlShape,
) => {
  formattedTextWithoutNewLine: string;
  formattedTextWithNewLine: string;
};

export const useFormattedSliderDisplayText: UseFormattedSliderDisplayText = (
  currentSliderPointIndex,
  granularity,
  sliderPointsAsEpochMillis,
  intl,
) => {
  const sliderContentProviderRef = useRef<SliderPointFormatter | null>(null);
  const [formattedTextWithoutNewLine, setFormattedTextWithoutNewLine] = useState('');
  const [formattedTextWithNewLine, setFormattedTextWithNewLine] = useState('');

  const { formatMessage } = intl;

  useEffect(() => {
    sliderContentProviderRef.current = new SliderPointFormatter(granularity, intl);
  }, [granularity, intl]);

  useEffect(() => {
    let currentSliderPointEpochMillis = sliderPointsAsEpochMillis[currentSliderPointIndex];

    if (currentSliderPointEpochMillis) {
      setFormattedTextWithoutNewLine(sliderContentProviderRef.current!.formatTimestamp(currentSliderPointEpochMillis, false));
      setFormattedTextWithNewLine(sliderContentProviderRef.current!.formatTimestamp(currentSliderPointEpochMillis, true));
    } else {
      setFormattedTextWithoutNewLine(formatMessage({ id: 'planningPreview.noApplicableDate' }));
      setFormattedTextWithNewLine(formatMessage({ id: 'planningPreview.noApplicableDate' }));
    }
  }, [granularity, currentSliderPointIndex, sliderPointsAsEpochMillis, formatMessage, sliderContentProviderRef]);

  return {
    formattedTextWithoutNewLine,
    formattedTextWithNewLine,
  };
};

type UseInputChangeHandlers = (
  previewPredicates: PreviewPredicates,
  onChange: (input: PreviewPredicates) => void,
) => {
  handleTimeChange: (timeRange: TimeWindow) => void;
  handleModeChange: (mode: Mode) => void;
  handleDateRangeAndDayOfWeekPickerChange: (dateRangeAndDaysOfWeek: DateRangeAndDaysOfWeek) => void;
};

export const useInputChangeHandlers: UseInputChangeHandlers = (previewPredicates, onChange) => {
  const handleModeChange = useCallback(
    (mode: Mode) => startTransition(() => onChange({ ...previewPredicates, modeType: mode.modeType })),
    [onChange, previewPredicates],
  );

  const handleTimeChange = useCallback(
    (timeRange: TimeWindow) => onChange({ ...previewPredicates, timeRange: timeRange }),
    [onChange, previewPredicates],
  );

  const handleDateRangeAndDayOfWeekPickerChange = useCallback(
    (dateRangeAndDaysOfWeek: DateRangeAndDaysOfWeek) => onChange({ ...previewPredicates, dateRangeAndDaysOfWeek: dateRangeAndDaysOfWeek }),
    [onChange, previewPredicates],
  );

  return {
    handleModeChange,
    handleTimeChange,
    handleDateRangeAndDayOfWeekPickerChange,
  };
};
