import { addMethod, string } from 'yup';

import { regexPatterns } from '../../utils/regex-patterns';

addMethod(string, 'email', function validateEmail(message) {
  return this.matches(regexPatterns.email, {
    message,
    name: 'email',
    excludeEmptyString: true,
  });
});
