import React, { useState } from 'react';

import { Box, Button, Popover, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { AggregatedVehicle, AggregatedVehicleUsageStatus, AggregatedVehicleUsageStatusHelper } from '../../../../models';

import StatusChangeModal from './StatusChangeModal';

function StatusChangePopover({
  vehicle,
  anchorElement,
  handleCloseCellAnchor,
  handleStatusChangeConfirm,
  futureStatus,
  onCurrentStatusPopoverClick,
}: StatusChangePopoverProps) {
  const theme = useTheme();

  const handleClose = () => {
    handleCloseCellAnchor();
  };
  const open = Boolean(anchorElement);
  const id = open ? 'simple-popover' : undefined;

  const [openChangeModal, setOpenChangeModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const handleOpenChangeModal = (futureStatusProp: AggregatedVehicleUsageStatus) => {
    onCurrentStatusPopoverClick(futureStatusProp);
    setOpenChangeModal(true);
  };

  const handleCloseChangeModal = () => {
    setOpenChangeModal(false);
  };

  const popoverContent = (options: ReturnType<typeof AggregatedVehicleUsageStatusHelper.metaDataList>) => {
    const isSameStatusAsCurrent = (status: AggregatedVehicleUsageStatus) =>
      vehicle.operationalStatusDetails!.lastKnownStatusDetails.status === status;

    return options?.map(
      (option, index) =>
        isSameStatusAsCurrent(option.status) && (
          <Button
            key={index}
            size="small"
            fullWidth
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: theme.spacing(2),
              '&:hover': {
                backgroundColor: theme.palette.dark.shade8,
                width: '100%',
              },
              padding: theme.spacing(1),
            }}
            onClick={() => handleOpenChangeModal(option.futureStatus)}
          >
            <Box
              sx={{
                width: '16px',
                height: '16px',
                borderRadius: '16px',
                backgroundColor: option.color,
                marginLeft: theme.spacing(2),
              }}
            />
            <Typography variant="bodyMedium" color={theme.palette.dark.main}>
              {option.usageStatusTranslation}
            </Typography>
          </Button>
        ),
    );
  };

  const showStatusTransitionPopOver = open && !openChangeModal && !openSuccessModal;
  return (
    <>
      <Popover
        id={id}
        open={showStatusTransitionPopOver}
        anchorEl={anchorElement}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        slotProps={{
          paper: {
            style: {
              marginTop: theme.spacing(1.5),
              marginLeft: theme.spacing(1.5),
              overflow: 'auto',
              minWidth: '210px',
            },
          },
        }}
        disableScrollLock
      >
        <Box sx={{ my: theme.spacing(1) }}>{popoverContent(AggregatedVehicleUsageStatusHelper.metaDataList())}</Box>
      </Popover>
      <StatusChangeModal
        isOpen={openChangeModal}
        vehicleId={vehicle.id}
        vehicleVersion={vehicle.version}
        vehicleQrCode={vehicle.qrCode}
        currentStatus={vehicle.operationalStatusDetails!.lastKnownStatusDetails.status}
        futureStatus={futureStatus}
        onClose={handleCloseChangeModal}
        handleStatusChangeConfirm={handleStatusChangeConfirm}
        openSuccessModal={openSuccessModal}
        setOpenSuccessModal={setOpenSuccessModal}
      />
    </>
  );
}

interface StatusChangePopoverProps {
  vehicle: AggregatedVehicle;
  anchorElement: HTMLButtonElement | null;
  handleCloseCellAnchor: () => void;
  parentWidth: number;
  handleStatusChangeConfirm: () => void;
  futureStatus: AggregatedVehicleUsageStatus;
  onCurrentStatusPopoverClick: (futureStatus: AggregatedVehicleUsageStatus) => void;
}

export default StatusChangePopover;
