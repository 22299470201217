import { memo, useCallback, useMemo } from 'react';

import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import type { ColumnDef, Row } from '@tanstack/react-table';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';

import NewDataTable from '../../../../../design-system/NewDataTable';
import { BudgetPlanMembershipDto } from '../../../../../services/budget-plan-memberships';
import { intl } from '../../../../../utils/intl';
import { useBudgetPlanMembership } from '../../atoms/hooks';
import { MembershipLastStatusUpdate } from '../common/MembershipLastStatusUpdate';

interface BudgetPlanMembershipTableProps {
  budgetPlanMemberships: BudgetPlanMembershipDto[];
}

export const BudgetPlanMembershipTable = memo(({ budgetPlanMemberships }: BudgetPlanMembershipTableProps) => {
  const { bmModalSearchString, setBMSelectedMembershipId } = useBudgetPlanMembership();

  const searchStringToLowercase = bmModalSearchString.toLowerCase();

  /*
   * Used to filter the memberships list based on the search string
   */
  const filteredMemberships = useMemo(() => {
    const trimmedSearchString = trim(searchStringToLowercase);
    if (searchStringToLowercase.length < 2 || isEmpty(trimmedSearchString)) {
      return budgetPlanMemberships;
    }

    return budgetPlanMemberships.filter(
      ({ firstName, lastName, email }) =>
        firstName.toLowerCase().includes(searchStringToLowercase) ||
        lastName.toLowerCase().includes(searchStringToLowercase) ||
        email.toLowerCase().includes(searchStringToLowercase),
    );
  }, [budgetPlanMemberships, searchStringToLowercase]);

  /*
   * Used to handle the click on a membership row
   */
  const handleMembershipRowClick = useCallback(
    (row: Row<BudgetPlanMembershipDto>) => {
      setBMSelectedMembershipId(row.original.id);
    },
    [setBMSelectedMembershipId],
  );

  const MemoizedBudgetPlanMembershipsTableColumnDefs = BudgetPlanMembershipsTableColumnDefs();
  return (
    <Box py={3} height="100%" sx={{ overflowY: 'hidden' }}>
      <Typography variant="bodyMediumBold" mb={1} component="h3">
        {intl.formatMessage({ id: 'budgetPlanMemberships.table.title' })}
      </Typography>
      <NewDataTable<BudgetPlanMembershipDto, 'default'>
        columns={MemoizedBudgetPlanMembershipsTableColumnDefs}
        data={filteredMemberships}
        onRowClickHandler={handleMembershipRowClick}
      />
    </Box>
  );
});
BudgetPlanMembershipTable.displayName = 'BudgetPlanMembershipTable';

export function BudgetPlanMembershipsTableColumnDefs() {
  const theme = useTheme();
  return useMemo(
    (): ColumnDef<BudgetPlanMembershipDto>[] => [
      {
        header: intl.formatMessage({ id: `common.firstName` }),
        accessorKey: 'firstName',
      },
      {
        header: intl.formatMessage({ id: `common.lastName` }),
        accessorKey: 'lastName',
      },
      {
        header: intl.formatMessage({ id: `common.email` }),
        accessorKey: 'email',
      },
      {
        header: intl.formatMessage({ id: `common.activationDate` }),
        accessorKey: 'activeState.subscribedAtEpochMillis',
        cell: ({ row: { original: planMembership } }: CellProps) => (
          <Typography variant="bodyMedium" px={1} color={theme.palette.baseLight.base76}>
            {planMembership.activeState.isSubscribed && planMembership.activationDate
              ? planMembership.activationDate.formatAsDateString()
              : intl.formatMessage({ id: 'common.na' })}
          </Typography>
        ),
      },
      {
        header: intl.formatMessage({ id: `common.lastStatusUpdate` }),
        accessorKey: 'lastAttemptedStateTransition.status',
        cell: ({ row: { original: planMembership } }: CellProps) => {
          return (
            <Box display="flex" alignItems="center" px={1}>
              <MembershipLastStatusUpdate membership={planMembership} sx={{ pr: 0.5 }} />
              {planMembership.isLastAttemptedStateTransitionFailed() && (
                <Typography variant="bodyMedium" color={theme.palette.alert.main} component="span">
                  - {intl.formatMessage({ id: 'common.clickToRetry' })}
                </Typography>
              )}
            </Box>
          );
        },
      },
    ],
    [theme],
  );
}

interface BudgetPlanMembershipTableProps {
  budgetPlanMemberships: BudgetPlanMembershipDto[];
}

interface CellProps {
  row: Row<BudgetPlanMembershipDto>;
}
