import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';

import { ZoneDateRangeResponse } from '../../models';

import { VisibilityStatusPointProvider, VisibilityStatusPointProviderConstants } from './VisibilityStatusPointProvider';

export class ZoneDateRange implements VisibilityStatusPointProvider {
  private readonly _start: Dayjs;
  private readonly _startDateAtBeginningOfDay: Dayjs;
  private readonly _openEnded: boolean;
  private readonly _end?: Dayjs = VisibilityStatusPointProviderConstants.endOfUniverse.toEndOfDay();
  private readonly _endDateAtBeginningOfDay: Dayjs = VisibilityStatusPointProviderConstants.endOfUniverse.toBeginningOfDay();
  private readonly _endDateAtEndOfDay: Dayjs = VisibilityStatusPointProviderConstants.endOfUniverse.toEndOfDay();

  constructor(dateRange: ZoneDateRangeResponse) {
    const { startInclusive, endInclusive, openEnded } = dateRange;

    this._start = startInclusive.asDayjsDate();
    this._startDateAtBeginningOfDay = this._start.toBeginningOfDay();

    this._openEnded = openEnded;
    if (!openEnded) {
      this._end = endInclusive!.asDayjsDate();
      this._endDateAtBeginningOfDay = this._end!.toBeginningOfDay();
      this._endDateAtEndOfDay = this._end!.toEndOfDay();
    }
  }

  isVisible(evaluationPoint: Dayjs): boolean {
    const evaluationPointToBeggingOfDay: Dayjs = evaluationPoint.toBeginningOfDay();

    if (this._openEnded) {
      return evaluationPointToBeggingOfDay.isSameOrAfter(this._startDateAtBeginningOfDay);
    } else {
      return (
        evaluationPointToBeggingOfDay.isSameOrAfter(this._startDateAtBeginningOfDay) &&
        evaluationPointToBeggingOfDay.isSameOrBefore(this._endDateAtBeginningOfDay)
      );
    }
  }

  calculateNextVisiblePoint(evaluationPoint: Dayjs): Dayjs {
    if (evaluationPoint.isSameOrBefore(this._startDateAtBeginningOfDay)) {
      return this._startDateAtBeginningOfDay;
    } else {
      if (this._openEnded) {
        return evaluationPoint;
      } else {
        if (evaluationPoint.isAfter(this._endDateAtEndOfDay)) {
          return VisibilityStatusPointProviderConstants.endOfUniverse;
        } else {
          return evaluationPoint;
        }
      }
    }
  }

  calculateNextInvisiblePoint(evaluationPoint: dayjs.Dayjs): Dayjs {
    if (this._openEnded) {
      return VisibilityStatusPointProviderConstants.endOfUniverse;
    } else {
      return this._endDateAtEndOfDay;
    }
  }

  overlapsWith(predicateStartDateInclusive: Dayjs, predicateEndDateInclusive: Dayjs): boolean {
    return (
      this._endDateAtEndOfDay!.isSameOrAfter(predicateStartDateInclusive) &&
      this._startDateAtBeginningOfDay.isSameOrBefore(predicateEndDateInclusive)
    );
  }
}
