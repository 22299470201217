import React from 'react';

import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import type { Theme } from '@mui/material/styles';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { AvailableLanguage, changingLanguageSelector } from '../../state';
import {
  WMV_SUPPORT_EMAIL,
  WMV_USER_HANDBOOK_LINK_EN,
  WMV_USER_HANDBOOK_LINK_DE,
  DEFAULT_MAIN_CONTENT_HEIGHT,
} from '../../utils/constants';
import { WmvCard } from '../../wmv-components/WmvCard/WmvCard';

import { Header } from './Header';

function Support() {
  const { palette } = useTheme();
  const { formatMessage: fm } = useIntl();

  const language = useSelector(changingLanguageSelector);
  let WMV_USER_HANDBOOK_LINK = WMV_USER_HANDBOOK_LINK_EN;

  if (language === AvailableLanguage.DE) {
    WMV_USER_HANDBOOK_LINK = WMV_USER_HANDBOOK_LINK_DE;
  }

  return (
    <Box sx={supportPageContainerSx} component="main">
      <Box sx={supportPageGridLayoutSx}>
        <Header />
        <Box sx={{ gridArea: 'main', height: '562px' }}>
          <WmvCard<'info'>
            type="info"
            size="large"
            title={fm({ id: 'support.card.main.title' })}
            description={fm({ id: 'support.card.main.description' })}
            icon="Globe"
            headerAlignment="horizontal"
            height="562px"
            backgroundColor={palette.baseLight.base0}
          />
        </Box>
        <Box sx={{ gridArea: 'emailUs' }}>
          <WmvCard<'email'>
            type="email"
            title={fm({ id: 'support.card.emailUs.title' })}
            description={fm({ id: 'support.card.emailUs.description' })}
            icon="SupportRequestIcon"
            email={WMV_SUPPORT_EMAIL}
            btnLabel={fm({ id: 'support.card.emailUs.btn' })}
            emailSubject={fm({ id: 'support.card.emailUs.emailSubject' })}
            backgroundColor={palette.baseLight.base24}
          />
        </Box>
        <Box sx={{ gridArea: 'getInTouch' }}>
          <WmvCard<'info'>
            type="info"
            title={fm({ id: 'support.card.getInTouch.title' })}
            description={fm({ id: 'support.card.getInTouch.description' })}
            icon="MailIcon"
            backgroundColor={palette.baseLight.base24}
          />
        </Box>
        <Box sx={{ gridArea: 'learn' }}>
          <WmvCard<'link'>
            type="link"
            title={fm({ id: 'support.card.learn.title' })}
            description={fm({ id: 'support.card.learn.description' })}
            headerAlignment="horizontal"
            icon="BookIcon"
            redirectUrl={WMV_USER_HANDBOOK_LINK}
            btnLabel={fm({ id: 'support.card.learn.btn' })}
            backgroundColor={palette.primary.dark}
            color={palette.baseLight.base0}
          />
        </Box>
      </Box>
    </Box>
  );
}

const supportPageContainerSx = (theme: Theme) => ({
  height: DEFAULT_MAIN_CONTENT_HEIGHT,
  backgroundColor: theme.palette.baseLight.base4,
});

export const supportPageGridLayoutSx = (theme: Theme) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: 2,
  gridTemplateRows: 'auto',
  gridTemplateAreas: `
    "header header header"
     "main emailUs getInTouch"
     "main learn learn"
  `,
  padding: '30px 220px',
});

export default Support;
