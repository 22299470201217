import { useCallback } from 'react';

import { VehiclePricingDto } from '../../../../services/vehicle-fee-pricing';
import { useVehiclePricingDispatch, VehiclePricingActionType } from '../contexts';
import { VehiclePricingDetailsActionMode } from '../helpers';

export function useUpdatePricingModalActionMode() {
  const vehiclePricingDispatch = useVehiclePricingDispatch()!;

  return useCallback(
    (vehicleFeePricing: VehiclePricingDto) => {
      let actionMode;

      if (vehicleFeePricing.isStateChangeInProgress()) {
        actionMode = VehiclePricingDetailsActionMode.ViewInProgressPricing;
      } else if (vehicleFeePricing.isStateChangeFailed()) {
        actionMode = VehiclePricingDetailsActionMode.ViewFailedPricing;
      } else if (vehicleFeePricing.isStateChangeSuccessful()) {
        actionMode = VehiclePricingDetailsActionMode.ViewActivePricing;
      }

      if (actionMode) {
        vehiclePricingDispatch({
          type: VehiclePricingActionType.UpdateActionMode,
          payload: actionMode,
        });
      }
    },
    [vehiclePricingDispatch],
  );
}
