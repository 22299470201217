import React, { useMemo, useState } from 'react';

import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { type CellContext, type ColumnDef, type Row } from '@tanstack/react-table';
import camelCase from 'lodash/camelCase';
import { useIntl } from 'react-intl';

import { generateUniqueID } from '../../../components/MapTableView/ZonesPopupDataTable';
import { AggregatedVehicle, AggregatedVehicleUsageStatus, LastStatusTransitionAttemptStatus, VehicleTypeHelper } from '../../../models';
import { FormattedLiveVehicle } from '../../../state/common/vehicle.model';
import CustomIcon from '../../../utils/CustomIcon';
import { changeVehicleStatus } from '../../../utils/queries';

import StatusChangeFailModal from './StatusChange/StatusChangeFailModal';
import StatusChangePopover from './StatusChange/StatusChangePopover';

export function FleetManagerVehiclesListColumnDefs({ onVehicleLocationClick, getAggregatedVehicles }: OperatorColumnsProps) {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  return useMemo((): ColumnDef<AggregatedVehicle>[] => {
    return [
      {
        header: formatMessage({ id: 'operator.fleets.table.heading.qrCodeId' }),
        accessorKey: 'qrCode',
      },
      {
        header: formatMessage({ id: 'operator.fleets.table.heading.vehicleId' }),
        accessorKey: 'id',
      },
      {
        header: formatMessage({ id: 'operator.fleets.table.heading.vehicleType' }),
        cell: ({ row }: { row: Row<AggregatedVehicle> }) => {
          const vehicleTypeIcons = {
            electric_bike: CustomIcon('ebikeTooltipIcon', '', '22px'),
            electric_moped: CustomIcon('mopedTooltipIcon', '', '22px'),
            car: CustomIcon('carTooltipIcon', '', '22px'),
            cargo_bicycle: CustomIcon('cargobikeTooltipIcon', '', '22px'),
            electric_scooter: CustomIcon('scooterTooltipIcon', '', '22px'),
          };
          const IconComponent =
            vehicleTypeIcons[row.original.model.vehicleType.toLocaleLowerCase() as keyof typeof vehicleTypeIcons] || null;
          return (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: '4px',
              }}
            >
              {IconComponent}
              <Typography variant="bodySmall" px={1}>
                {formatMessage({ id: `mspVehicleType.${row.original.model.vehicleType.toLowerCase()}` })}
              </Typography>
            </Box>
          );
        },
      },
      {
        header: formatMessage({ id: 'operator.fleets.table.heading.lastEventType' }),
        accessorKey: 'operationalStatusDetails?.lastKnownStatusDetails.status',
        cell: ({ row }: { row: Row<AggregatedVehicle> }) =>
          formatMessage({
            id: row.original.operationalStatusDetails?.lastKnownStatusDetails.status
              ? `operator.statusChange.vehicleStatus.${camelCase(row.original.operationalStatusDetails.lastKnownStatusDetails.status)}`
              : 'common.all',
          }),
      },
      {
        header: formatMessage({ id: 'operator.fleets.table.heading.position' }),
        cell: ({ row: { original: vehicle } }: { row: Row<AggregatedVehicle> }) => {
          const liveVehicle = {
            vehicleTypeId: VehicleTypeHelper.toGraphqlVehicleType(vehicle.model.vehicleType),
            vehicleId: vehicle.qrCode,
            qrCodeId: vehicle.qrCode,
          };
          return !vehicle.operationalStatusDetails?.location?.lat ||
            !vehicle.operationalStatusDetails.location?.lng ||
            vehicle.operationalStatusDetails.lastKnownStatusDetails.status === AggregatedVehicleUsageStatus.OnTrip ? (
            <IconButton disabled sx={{ p: 0 }}>
              {CustomIcon('CurrentLocationIcon', theme.palette.dark.shade60, theme.spacing(4))}
            </IconButton>
          ) : (
            <IconButton
              disabled
              onClick={() => {
                onVehicleLocationClick(generateUniqueID(liveVehicle as unknown as FormattedLiveVehicle));
              }}
              sx={{ p: 0 }}
            >
              {CustomIcon('CurrentLocationIcon', theme.palette.dark.main, theme.spacing(4))}
            </IconButton>
          );
        },
      },
      {
        header: formatMessage({ id: 'operator.fleets.table.heading.currentStatus' }),
        accessorKey: 'operationalStatusDetails?.lastKnownStatusDetails.status',
        cell: ({ row }: { row: Row<AggregatedVehicle> }) => {
          const statusColorMap = {
            [AggregatedVehicleUsageStatus.Available]: theme.palette.success.main,
            [AggregatedVehicleUsageStatus.NonOperational]: theme.palette.warning.main,
            [AggregatedVehicleUsageStatus.OnTrip]: theme.palette.blue.tint,
          };
          const currentStatusBgColor =
            row.original.operationalStatusDetails?.lastKnownStatusDetails.status != null
              ? statusColorMap[row.original.operationalStatusDetails.lastKnownStatusDetails.status]
              : null;

          return (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: theme.spacing(1),
              }}
            >
              <Box
                sx={{
                  width: theme.spacing(2),
                  height: theme.spacing(2),
                  borderRadius: theme.spacing(2),
                  backgroundColor: currentStatusBgColor,
                }}
              />
              {formatMessage({
                id: row.original.operationalStatusDetails?.lastKnownStatusDetails.status
                  ? `operator.statusChange.vehicleStatus.${camelCase(row.original.operationalStatusDetails.lastKnownStatusDetails.status)}`
                  : 'common.all',
              })}
            </Box>
          );
        },
      },
      {
        header: formatMessage({ id: 'operator.fleets.table.heading.changeStatus' }),
        meta: {
          getCellContext: (context: CellContext<AggregatedVehicle, unknown>) => {
            console.log('context', context);
            const failedStatusTransition =
              context.row.original.operationalStatusDetails?.lastStatusTransitionAttemptDetails.attemptStatus ===
              LastStatusTransitionAttemptStatus.Failed;
            const processingStatusTransition =
              context.row.original.operationalStatusDetails?.lastStatusTransitionAttemptDetails.attemptStatus ===
              LastStatusTransitionAttemptStatus.InProgress;

            let bgColor = 'initial';
            let hoverBgColor = theme.palette.secondary.main;
            if (failedStatusTransition) {
              bgColor = theme.palette.alert.tint;
              hoverBgColor = theme.palette.alert.main;
            }
            if (processingStatusTransition) {
              bgColor = theme.palette.secondary.tint;
              hoverBgColor = theme.palette.secondary.main;
            }

            return {
              styles: {
                backgroundColor: bgColor,
                ':hover': {
                  backgroundColor: hoverBgColor,
                  '& .MuiTypography-root': {
                    color: theme.palette.baseLight.base0,
                  },
                  '& svg': {
                    fill: theme.palette.baseLight.base0,
                  },
                },
              },
            };
          },
        },
        cell: ({ row }: { row: Row<AggregatedVehicle> }) => {
          const failedStatusTransition =
            row.original.operationalStatusDetails?.lastStatusTransitionAttemptDetails.attemptStatus ===
            LastStatusTransitionAttemptStatus.Failed;
          const processingStatusTransition =
            row.original.operationalStatusDetails?.lastStatusTransitionAttemptDetails.attemptStatus ===
            LastStatusTransitionAttemptStatus.InProgress;

          const vehicleIsAvailableOrNonOperational =
            row.original.operationalStatusDetails?.lastKnownStatusDetails.status === AggregatedVehicleUsageStatus.Available ||
            row.original.operationalStatusDetails?.lastKnownStatusDetails.status === AggregatedVehicleUsageStatus.NonOperational;

          // eslint-disable-next-line react-hooks/rules-of-hooks
          const [parentWidth, setParentWidth] = React.useState<number>(0);
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const [futureStatus, setFutureStatus] = useState<AggregatedVehicleUsageStatus>(AggregatedVehicleUsageStatus.Available);
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const [failModalOpen, setFailModalOpen] = useState(false);

          const handleFailModalOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
            event.currentTarget.parentElement?.closest('tr')?.classList.add('keepHoverState');
            setAnchorEl(event.currentTarget);
            setFailModalOpen(true);
          };

          const handleFailModalClose = () => {
            setFailModalOpen(false);
            handleCloseCell();
          };

          const handleClickCell = (event: React.MouseEvent<HTMLButtonElement>) => {
            const parentCell = event.currentTarget.parentElement?.closest('td');
            const parentWidth = parentCell ? parentCell.offsetWidth : 0;
            event.currentTarget.parentElement?.closest('tr')?.classList.add('keepHoverState');
            setAnchorEl(event.currentTarget);
            setParentWidth(parentWidth);
          };

          const handleCloseCell = () => {
            anchorEl?.parentElement?.closest('tr')?.classList.remove('keepHoverState');
            setAnchorEl(null);
          };

          const handleStatusChangeConfirm = () => {
            getAggregatedVehicles();
            handleCloseCell();
          };

          //Will be called when the request to change the status fails from the vehicle side.
          const handleStatusChangeFail = () => {
            getAggregatedVehicles();
            handleCloseCell();
          };

          const handleStatusChangeCancel = () => {
            // API call to cancel request will go here - will be part of upcoming fleet management tickets.
            handleCloseCell();
          };

          const handleStatusChangeRetry = async () => {
            try {
              await changeVehicleStatus({ version: row.original.version, status: futureStatus }, row.original.id);
              getAggregatedVehicles();
            } catch (error) {
              handleStatusChangeFail();
              return false;
            }
          };

          let lastStatusUpdateTitle = 'operator.statusChange'.i18nText();
          if (failedStatusTransition) lastStatusUpdateTitle = 'operator.statusChange.request.failed'.i18nText();
          if (processingStatusTransition) lastStatusUpdateTitle = 'operator.statusChange.processing'.i18nText();

          return (
            <>
              <Tooltip
                title={
                  processingStatusTransition && (
                    <Typography variant="bodyMedium">{formatMessage({ id: 'operator.statusChange.tooltip.cancel' })}</Typography>
                  )
                }
                arrow
                componentsProps={{
                  tooltip: {
                    sx: { borderRadius: theme.spacing(1), padding: `${theme.spacing(1)} ${theme.spacing(3)}` },
                  },
                }}
              >
                <Box>
                  <Button
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '100%',
                      padding: 0,
                      height: '100%',
                      textTransform: 'none',
                      '&:hover *': {
                        fontWeight: theme.typography.fontWeightBold,
                      },
                    }}
                    onClick={(event) => {
                      if (failedStatusTransition && !processingStatusTransition) handleFailModalOpen(event);
                      if (vehicleIsAvailableOrNonOperational && !processingStatusTransition) handleClickCell(event);
                      if (vehicleIsAvailableOrNonOperational && processingStatusTransition) console.log('Open Cancel Modal'); //Will be implemented by #1479
                      if (!vehicleIsAvailableOrNonOperational) handleFailModalOpen(event);
                    }}
                  >
                    <Typography variant="bodySmall">{lastStatusUpdateTitle}</Typography>
                    {CustomIcon('ChevronDownIcon', theme.palette.dark.shade76, '24px')}
                  </Button>
                </Box>
              </Tooltip>

              {failedStatusTransition ? (
                <StatusChangeFailModal
                  isOpen={failModalOpen}
                  vehicleId={row.original.id}
                  onClose={handleFailModalClose}
                  modalType={'retry'}
                  handleStatusChangeCancel={handleStatusChangeCancel}
                  handleStatusChangeRetry={handleStatusChangeRetry}
                />
              ) : vehicleIsAvailableOrNonOperational && !processingStatusTransition ? (
                <StatusChangePopover
                  vehicle={row.original}
                  anchorElement={anchorEl}
                  handleCloseCellAnchor={handleCloseCell}
                  parentWidth={parentWidth}
                  handleStatusChangeConfirm={handleStatusChangeConfirm}
                  futureStatus={futureStatus}
                  onCurrentStatusPopoverClick={(futureStatusProp: AggregatedVehicleUsageStatus) => {
                    setFutureStatus(futureStatusProp);
                  }}
                />
              ) : null}
              {!vehicleIsAvailableOrNonOperational ? (
                <StatusChangeFailModal
                  isOpen={failModalOpen}
                  vehicleId={row.original.id}
                  onClose={handleFailModalClose}
                  modalType={'fail'}
                  handleStatusChangeCancel={handleStatusChangeCancel}
                />
              ) : null}
            </>
          );
        },
      },
    ];
  }, [getAggregatedVehicles, onVehicleLocationClick, formatMessage, theme]);
}

interface OperatorColumnsProps {
  onVehicleLocationClick: (latlng: string) => void;
  getAggregatedVehicles: () => Promise<void>;
}
