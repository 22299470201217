import { VehicleFeePricingActiveState, VehicleFeePricingBreakdown } from '../../models/vehicle-fee-pricing';

import { VehicleFeeAmount } from './VehicleFeeAmount';

export class VehicleFeePricingActiveStateDto extends VehicleFeeAmount {
  breakdown: VehicleFeePricingBreakdown[];

  constructor(activeState: VehicleFeePricingActiveState) {
    super(activeState.details.breakdown);
    this.breakdown = activeState.details.breakdown;
  }
}
