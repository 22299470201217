import { Form, useFormikContext, withFormik } from 'formik';

import { BP_ACTION_MODE } from '../atoms/budget-plan-atoms';
import { useBudgetPlan } from '../atoms/hooks/useBudgetPlan';
import { BudgetPlanModalCloseHandler } from '../BudgetPlanModal';
import { budgetPlanDetailsForm } from '../validation-schemas';

import { BudgetPlanDetailsFormValues, BudgetPlanFormSubmitHandler } from './BudgetPlanDetailsView';
import { BudgetPlanFormHeadingAndActionButtons } from './BudgetPlanFormHeadingAndActionButtons';
import { BudgetPlanFormInputs } from './BudgetPlanFormInputs';

interface BudgetPlanDetailsFormProps {
  initialValues: BudgetPlanDetailsFormValues;
  onBudgetPlanModalClose: BudgetPlanModalCloseHandler;
  onBudgetPlanFormSubmit: BudgetPlanFormSubmitHandler;
  onCancelEdit: () => void;
}

const BudgetPlanDetailsForm = ({ onBudgetPlanModalClose, onCancelEdit }: BudgetPlanDetailsFormProps) => {
  const { handleSubmit, initialValues } = useFormikContext<BudgetPlanDetailsFormValues>();
  const { budgetPlanActionMode } = useBudgetPlan();

  return (
    <Form onSubmit={handleSubmit}>
      <BudgetPlanFormHeadingAndActionButtons
        onCancelEdit={onCancelEdit}
        onCancelClick={onBudgetPlanModalClose}
        currentBudgetPlanFormValues={initialValues}
      />
      <BudgetPlanFormInputs isDisabled={budgetPlanActionMode === BP_ACTION_MODE.VIEW} />
    </Form>
  );
};

export const BudgetPlanDetailsFormWithFormik = withFormik<BudgetPlanDetailsFormProps, BudgetPlanDetailsFormValues>({
  validateOnBlur: true,
  validateOnChange: true,
  enableReinitialize: true,
  validateOnMount: true,
  mapPropsToValues: ({ initialValues }) => initialValues,
  handleSubmit: async (values, { props: { onBudgetPlanFormSubmit }, setSubmitting }) => {
    setSubmitting(true);
    try {
      await onBudgetPlanFormSubmit(values);
    } finally {
      setSubmitting(false);
    }
  },
  validationSchema: budgetPlanDetailsForm,
})(BudgetPlanDetailsForm);
