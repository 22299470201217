import { useAtom } from 'jotai';

import { REQUEST_STATUS, bpDetailsErrorMsgAtom, bpDetailsSubmitStatusAtom } from '../budget-plan-atoms';

export function useBudgetPlanDetails() {
  const [bpDetailsSubmitStatus, setBpDetailsSubmitStatus] = useAtom(bpDetailsSubmitStatusAtom);
  const [bpDetailsErrorMsg, setBpDetailsErrorMsg] = useAtom(bpDetailsErrorMsgAtom);

  const resetBudgetPlanDetailsState = () => {
    setBpDetailsSubmitStatus(REQUEST_STATUS.IDLE);
    setBpDetailsErrorMsg('');
  };

  return {
    bpDetailsSubmitStatus,
    setBpDetailsSubmitStatus,
    resetBudgetPlanDetailsState,
    bpDetailsErrorMsg,
    setBpDetailsErrorMsg,
  };
}
