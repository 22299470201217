import type { ReactNode } from 'react';

import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import type { ButtonOwnProps } from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useIntl } from 'react-intl';

import { ErrorMessage } from '../../components/FormFields/ErrorMessage';
import CustomIcon from '../../utils/CustomIcon';
import LoadingAnimation from '../LoadingAnimation/LoadingAnimation';

import { containerStyles, contentStyles, actionButtonStyles } from './ConfirmationModal.styles';

export type ConfirmationModalCloseReason = 'backdropClick' | 'escapeKeyDown' | 'cancelClick';
export interface ConfirmationModalProps {
  open: boolean;
  title: string;
  handleClose: (reason: ConfirmationModalCloseReason) => void;
  handleConfirm: () => void;
  loading?: boolean;
  confirmBtnColor?: ButtonOwnProps['color'];
  cancelBtnColor?: ButtonOwnProps['color'];
  confirmBtnText?: string;
  cancelBtnText?: string;
  children?: ReactNode;
  error?: string | null | undefined;
}

export const ConfirmationModal = ({
  open,
  title,
  handleClose,
  handleConfirm,
  loading = false,
  confirmBtnText,
  cancelBtnText,
  confirmBtnColor = 'primary',
  cancelBtnColor = 'primary',
  children,
  error,
}: ConfirmationModalProps) => {
  const { formatMessage } = useIntl();
  const { palette, spacing } = useTheme();

  if (!confirmBtnText) {
    confirmBtnText = formatMessage({ id: 'common.confirm' });
  }
  if (!cancelBtnText) {
    cancelBtnText = formatMessage({ id: 'actions.cancel' });
  }

  return (
    <Modal open={open} onClose={(e, reason) => handleClose(reason)}>
      <Box sx={containerStyles}>
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          width="100%"
          padding={spacing(0.5, 1, 0.5, 1)}
          sx={{ backgroundColor: palette.warning.light, color: palette.baseLight.base0, borderRadius: spacing(1, 1, 0, 0) }}
        >
          <Typography variant="bodyLarge" component="h2" sx={{ display: 'flex', alignItems: 'center' }}>
            {CustomIcon('Info', palette.baseLight.base0, spacing(3))}
            <Box component="span" ml={1}>
              {title}
            </Box>
          </Typography>
        </Box>

        <Box padding={2}>
          <Box sx={contentStyles}>{children}</Box>

          <Box display="flex" gap={1} mt={2}>
            <Button
              variant="outlined"
              size="small"
              color={cancelBtnColor}
              sx={actionButtonStyles}
              onClick={() => handleClose('cancelClick')}
              disabled={loading}
            >
              {cancelBtnText}
            </Button>
            <Button onClick={handleConfirm} variant="contained" size="small" sx={actionButtonStyles} color={confirmBtnColor}>
              {loading ? <LoadingAnimation sizeMultiplier={0.25} /> : confirmBtnText}
            </Button>
          </Box>

          <ErrorMessage error={error} />
        </Box>
      </Box>
    </Modal>
  );
};
