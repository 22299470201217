import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import type { SxProps, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import isEmpty from 'lodash/isEmpty';

import CustomIcon from '../utils/CustomIcon';

const HelperText = ({ value, type = 'info', showAttentionIcon = true, showCheckIcon = false, sx }: HelperTextProps) => {
  const theme = useTheme();
  let helperColor = theme.palette.alert.main;
  if (type === 'info') helperColor = theme.palette.baseLight.base60;
  if (type === 'success') helperColor = theme.palette.success.main;

  if (isEmpty(value)) {
    return null;
  }

  return (
    <Box display="flex" alignItems="center" paddingTop={0.5} paddingBottom={0.5} sx={sx}>
      {showAttentionIcon && CustomIcon('AttentionIcon', helperColor, theme.typography.caption.lineHeight)}
      {showCheckIcon && <CheckIcon color="success" />}
      <Typography variant="caption" color={helperColor} marginLeft={0.5}>
        {value}
      </Typography>
    </Box>
  );
};

export type HelperTextType = 'error' | 'info' | 'success';
export interface HelperTextProps {
  value: string;
  type?: HelperTextType;
  showAttentionIcon?: boolean;
  showCheckIcon?: boolean;
  sx?: SxProps<Theme>;
}

export default HelperText;
