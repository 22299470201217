import dayjs from 'dayjs';
import type { IntlShape } from 'react-intl';
import { date, ref, object } from 'yup';
import type { ObjectSchema } from 'yup';

export const getValidationSchema = (
  intl: IntlShape,
): ObjectSchema<{
  startDateTime: Date;
  endDateTime: Date;
}> => {
  const { formatMessage } = intl;

  const validationSchema = object().shape({
    startDateTime: date()
      .typeError(formatMessage({ id: 'form.validation.invalidValue' }))
      .required(formatMessage({ id: 'form.validation.errorFieldRequired' }))
      .max(dayjs(), formatMessage({ id: 'form.validation.errorNoFutureDateTime' })),
    endDateTime: date()
      .typeError(formatMessage({ id: 'form.validation.invalidValue' }))
      .required(formatMessage({ id: 'form.validation.errorFieldRequired' }))
      .max(dayjs().toEndOfDay().toDate(), formatMessage({ id: 'form.validation.errorNoFutureDateTime' }))
      .min(ref('startDateTime'), formatMessage({ id: 'form.validation.errorEndDateBeforeStartDate' })),
  });

  return validationSchema;
};
