import { useCallback, useEffect } from 'react';

import { enqueuedSnackbar } from '../../../../components/EnqueuedSnackbar';
import { SnackbarAlertType } from '../../../../components/SnackbarAlert';
import { VehicleTypeHelper } from '../../../../models';
import { VehiclePricingDto } from '../../../../services/vehicle-fee-pricing';
import { intl } from '../../../../utils/intl';
import { fetchVehicleFeePricing } from '../../../../utils/queries';
import { pollActionCreator } from '../../BudgetPlan/BudgetPlanMemberships/usePolling';
import { useVehiclePricingDispatch, useVehiclePricingState, VehiclePricingActionType } from '../contexts';

let polledActions: { start: any; stop: any; intervalId: ReturnType<typeof setTimeout> }[] = [];

export function usePollInProgressVehiclePricingTransitions() {
  const vehiclePricingState = useVehiclePricingState();
  const vehiclePricingDispatch = useVehiclePricingDispatch();

  const updateInProgressVehiclePricingTransitions = useCallback(
    (mspSubjectClaim: string, vehicleModelId: number) => async () => {
      try {
        const updatedVehiclePricing = await fetchVehicleFeePricing(mspSubjectClaim, vehicleModelId);
        const updatedVehiclePricingDto = new VehiclePricingDto(updatedVehiclePricing);
        if (!updatedVehiclePricingDto.isStateChangeInProgress()) {
          const newVehiclePricingsList: VehiclePricingDto[] = vehiclePricingState.list!.map((vehiclePricing) => {
            if (updatedVehiclePricing.version > vehiclePricing.version && vehiclePricing.id === updatedVehiclePricingDto.id)
              return updatedVehiclePricingDto;
            return vehiclePricing;
          });
          vehiclePricingDispatch!({
            type: VehiclePricingActionType.UpdateVehiclePricingList,
            payload: newVehiclePricingsList,
          });
          snackBarOnInProgressVehicleTransition(updatedVehiclePricingDto);
        }
      } catch (err) {
        console.log(`Failed to fetch vehicle pricing with id ${vehicleModelId}`, err);
      }
    },
    [vehiclePricingState.list, vehiclePricingDispatch],
  );

  const pollInProgressVehiclePricing = useCallback(() => {
    for (let i = 0; i < vehiclePricingState.list.length; i++) {
      const vehiclePricing = vehiclePricingState.list[i];
      if (vehiclePricing.isStateChangeInProgress()) {
        const pollingAction = pollActionCreator(5000);
        const polledActionId = pollingAction.start(
          updateInProgressVehiclePricingTransitions(vehiclePricing.msp.subjectClaim, vehiclePricing.modelId),
        );
        polledActions.push({ ...pollingAction, intervalId: polledActionId });
      }
    }
  }, [updateInProgressVehiclePricingTransitions, vehiclePricingState.list]);

  useEffect(() => {
    if (vehiclePricingState.list.length) {
      pollInProgressVehiclePricing();
    }

    return () => {
      polledActions.forEach((polledAction) => polledAction.stop(polledAction.intervalId));
      polledActions = [];
    };
  }, [pollInProgressVehiclePricing, vehiclePricingState.list]);
}

function snackBarOnInProgressVehicleTransition(vehiclePricing: VehiclePricingDto) {
  const options = {
    vehicleType: VehicleTypeHelper.metadata(vehiclePricing.vehicleType).displayText,
    vehicleModel: vehiclePricing.modelName,
  };

  let messageId;
  let messageType: SnackbarAlertType;

  if (vehiclePricing.isStateChangeSuccessful()) {
    messageId = 'vehicleFeePricing.successfullyUpdated';
    messageType = 'success';
  } else if (vehiclePricing.isStateChangeFailed()) {
    messageId = 'vehicleFeePricing.updateFailed';
    messageType = 'error';
  }

  if (messageId) {
    enqueuedSnackbar(intl.formatMessage({ id: messageId }, options), messageType!);
  }
}
