import type { Theme } from '@mui/material/styles';

export const previewTableContainerStyles = (theme: Theme) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  padding: theme.spacing(1),
  borderBottom: `1px solid ${theme.palette.baseLight.base24}`,
});

export const previewTableTitleBoxStyles = (theme: Theme) => ({
  display: 'flex',
  gap: 2,
  justifyContent: 'center',
  alignItems: 'center',
});

export const previewTableTitleTextStyles = (theme: Theme) => ({
  background: theme.palette.baseLight.base4,
  borderRadius: 1,
  padding: `${theme.spacing(0.25)} ${theme.spacing(1.25)}`,
});
