import { ReactElement } from 'react';

import { IconButton, Modal as MuiModal } from '@mui/material';
import type { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import CustomIcon from '../../utils/CustomIcon';

import { closeIconButtonStyles, modalContentStyles } from './styles';

export const Modal = ({ open, onClose, children, bgColor, disableClose = false, sx }: ModalProps) => {
  const theme = useTheme();
  const { palette } = theme;
  return (
    <MuiModal open={open}>
      <Box sx={[modalContentStyles(theme, bgColor), ...(Array.isArray(sx) ? sx : [sx])]}>
        {children}
        <Box sx={closeIconButtonStyles}>
          <IconButton onClick={onClose} disabled={disableClose}>
            {CustomIcon('CloseIcon', disableClose ? palette.baseLight.base32 : palette.white.main, '32px')}
          </IconButton>
        </Box>
      </Box>
    </MuiModal>
  );
};

export interface ModalProps {
  open: boolean;
  onClose: onCloseHandler;
  children: ReactElement;
  disableClose?: boolean;
  bgColor?: string;
  sx?: SxProps<Theme>;
}

export type onCloseHandler = () => void;
