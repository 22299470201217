import { useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';

import { ReactComponent as OverlayFilledIcon } from '../../assets/icons/fill/overlayFilled.svg';
import { ReactComponent as OverlayOutlinedIcon } from '../../assets/icons/outline/overlay.svg';
import { Zone } from '../../services/zone';
import { FormattedLiveVehicle } from '../../state/common/vehicle.model';
import { GroupMenuControl } from '../Map/controls/GroupMenuControl';
import GeoJsonZones from '../Map/GeoJsonZones';
import SetView from '../Map/MapModes/PlanningMode/LayersView/SetView';
import VehicleStateLegendAndResetFilterBottomBar from '../Map/VehicleStateLegendAndResetFilterBottomBar';
import WikimoveMap from '../Map/WikimoveMap';
import { MapFilters } from '../Map/ZoneControls/MapFilters';

import { MapVehiclesTableColumnDefs } from './MapVehiclesTableColumnDefs';
import ZonesPopupDataTable from './ZonesPopupDataTable';

export const MapTableView = ({
  zones,
  vehicles,
  loadingVehicles,
  loadingVehiclesError,
  filters,
  isAnyFilterApplied,
  onFiltersReset,
  defaultMarkerId,
}: MapTableViewProps) => {
  const [popupTableData, setPopupTableData] = useState<FormattedLiveVehicle[]>([]);

  const [open, setOpen] = useState(false);
  const [openMarkerId, setOpenMarkerId] = useState<string | null | undefined>(null);

  const height = useMemo(() => {
    return `calc(100% - ${open ? '255px' : '0px'})`;
  }, [open]);

  useEffect(() => {
    if (!open && popupTableData.length > 0) {
      setOpen(true);
    }
    if (open && popupTableData.length < 1) {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupTableData]);

  const MemoizedMapVehiclesTableColumnDefs = MapVehiclesTableColumnDefs();
  return (
    <>
      <Box sx={{ height }}>
        <WikimoveMap openMarkerId={openMarkerId || defaultMarkerId} setPopupTableData={setPopupTableData} vehicles={vehicles}>
          <GeoJsonZones zones={zones} />
          <GroupMenuControl
            buttons={[
              {
                icon: <OverlayOutlinedIcon />,
                activeIcon: <OverlayFilledIcon />,
                content: <SetView />,
              },
            ]}
          />
        </WikimoveMap>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          borderRadius: 2,
        }}
      >
        {open && (
          <ZonesPopupDataTable
            loading={loadingVehicles}
            data={popupTableData}
            columns={MemoizedMapVehiclesTableColumnDefs}
            onClose={() => {
              setOpen(false);
              setOpenMarkerId(null);
            }}
            onRowClickHandler={setOpenMarkerId}
          />
        )}
        <VehicleStateLegendAndResetFilterBottomBar
          filters={filters}
          isAnyFilterApplied={isAnyFilterApplied}
          onFiltersReset={onFiltersReset}
          loadingVehicles={loadingVehicles}
          loadingVehiclesError={loadingVehiclesError}
        />
      </Box>
    </>
  );
};

interface MapTableViewProps {
  zones: Zone[];
  loadingVehicles: boolean;
  loadingVehiclesError: boolean | string;
  vehicles: FormattedLiveVehicle[];
  filters: MapFilters;
  isAnyFilterApplied: boolean;
  onFiltersReset: () => void;
  defaultMarkerId?: string | null;
}
