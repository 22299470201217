import {
  VehicleFeePricingBreakdown,
  VehicleFeePricingBreakDownSubtype,
  VehicleFeePricingBreakDownType,
} from '../../models/vehicle-fee-pricing';

export class VehicleFeeAmount {
  breakdown: VehicleFeePricingBreakdown[];

  constructor(breakdown: VehicleFeePricingBreakdown[]) {
    this.breakdown = breakdown;
  }

  activationFeeAmount() {
    return this.breakdown.find(
      (breakdown) =>
        breakdown.type === VehicleFeePricingBreakDownType.UsageCharges &&
        breakdown.subType === VehicleFeePricingBreakDownSubtype.ActivationFee,
    );
  }

  activeDurationFeeAmount() {
    return this.breakdown.find(
      (breakdown) =>
        breakdown.type === VehicleFeePricingBreakDownType.UsageCharges &&
        breakdown.subType === VehicleFeePricingBreakDownSubtype.ActiveDuration,
    );
  }

  pausedDurationFeeAmount() {
    return this.breakdown.find(
      (breakdown) =>
        breakdown.type === VehicleFeePricingBreakDownType.UsageCharges &&
        breakdown.subType === VehicleFeePricingBreakDownSubtype.PausedDuration,
    );
  }
}
