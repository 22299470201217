import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import { useLeafletContext } from '@react-leaflet/core';
import { Polygon } from 'leaflet';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';

import {
  DeleteZoneConfirmationModal,
  DeleteZoneConfirmationModalCloseReason,
} from '../../../components/Map/ZoneManagement/ZoneRuleSettingsPopover/DeleteZoneConfirmationModal';
import { zoneRuleInitialValues } from '../../../components/Map/ZoneManagement/ZoneRuleSettingsPopover/helpers';
import {
  ZoneManagementActionState,
  ZoneRuleSettingsPopover,
} from '../../../components/Map/ZoneManagement/ZoneRuleSettingsPopover/ZoneRuleSettingsPopover';
import { ZoneRuleFormServerError, ZoneRulesFormValues } from '../../../components/Map/ZoneManagement/ZoneRuleSettingsPopover/ZoneRulesForm';
import { ZoneType, ZoneTypeHelper } from '../../../models';
import { Layer, Map } from '../../../services/layer-management';
import { Zone, ZoneLayerProperties } from '../../../services/zone';
import { useAppDispatch } from '../../../state';
import { showAlertAction } from '../../../state/app';
import {
  createZoneAsyncThunkAction,
  createZoneErrorSelector,
  createZoneServerViolationsFieldToErrorSelector,
  resetErrorAction,
  saveZoneErrorSelector,
  updateZoneAsyncThunkAction,
  updateZoneErrorSelector,
  updateZoneServerViolationsFieldToErrorSelector,
} from '../../../state/zones-management';

import {
  useDisableActiveLayerManagementTool,
  useDragEndEvent,
  useDrawDisabledEvent,
  useDrawFinishedEvent,
  useDrawStartEvent,
  useGeoJsonZones,
  useLayerCutEvent,
  useZoneAreaManipulationBottomActionButtons,
} from './hooks';
import { LayerManagementTool, LayerManagementToolbar } from './LayerManagementToolbar';
import { ZoneAreaManipulationBottomActionButtons } from './ZoneAreaManipulationBottomActionButtons';

export type ZoneGeoJsonFeature = GeoJSON.Feature<GeoJSON.Geometry, ZoneLayerProperties>;
interface PlanningModeControlsProps {
  zones: Zone[];
}
export const ACTIVE_ZONE_LAYER_STROKE_WIDTH = 6;

export function ZoneManagement({ zones }: PlanningModeControlsProps) {
  const context = useLeafletContext();
  const map = context.map;
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const [activeLayerManagementTool, setActiveLayerManagementTool] = useState<LayerManagementTool | null>(null);
  const [activeZoneGeoJsonFeature, setActiveZoneGeoJsonFeature] = useState<ZoneGeoJsonFeature | null>(null);
  const [deleteZoneModalVisible, setDeleteZoneModalVisible] = useState(false);
  const [zoneRuleFormVisible, setZoneRuleFormVisible] = useState(false);
  const [zoneManagementActionState, setZoneManagementActionState] = useState<ZoneManagementActionState>(
    ZoneManagementActionState.ZonesPreview,
  );

  const createZoneError = useSelector(createZoneErrorSelector);
  const updateZoneError = useSelector(updateZoneErrorSelector);
  const savedZoneError = useSelector(saveZoneErrorSelector);
  const updateZoneServerViolationsFieldToError = useSelector(updateZoneServerViolationsFieldToErrorSelector);
  const createZoneServerViolationsFieldToError = useSelector(createZoneServerViolationsFieldToErrorSelector);

  const anchorElementRef = useRef<HTMLButtonElement | null>(null);
  const workingLayerRef = useRef<Layer | null>(null);
  const mapRef = useRef<Map | null>(null);

  if (workingLayerRef.current) {
    mapRef.current?.enableCutOnLayer(workingLayerRef.current.layer);
  }

  const disableActiveLayerManagementTool = useDisableActiveLayerManagementTool(map, workingLayerRef.current);
  const {
    zoneAreaManipulationBottomActionButtons,
    hideZoneAreaManipulationBottomActionButtons,
    showZoneAreaManipulationBottomActionButtonsAsEnabled,
    showOnlyCancelButtonAsEnabled,
    showZoneAreaManipulationBottomActionButtonsAsDisabled,
  } = useZoneAreaManipulationBottomActionButtons();

  const handleLayerClick = useCallback(
    (selectedLayer: Polygon) => {
      if (zoneManagementActionState !== ZoneManagementActionState.ZonesPreview) {
        return;
      }
      const selectedZoneGeoJsonFeature = selectedLayer.toGeoJSON();
      setZoneManagementActionState(ZoneManagementActionState.ViewZoneInformationScreen);
      setActiveZoneGeoJsonFeature(selectedZoneGeoJsonFeature);
      setZoneRuleFormVisible(true);
      setActiveLayerManagementTool(null);
      const layer = new Layer(selectedLayer!);
      layer.strokeWeight(ACTIVE_ZONE_LAYER_STROKE_WIDTH);
      workingLayerRef.current = layer;
    },
    [zoneManagementActionState],
  );

  const handleDragEnd = useCallback((layer: Polygon) => {
    workingLayerRef.current = new Layer(layer);
  }, []);

  const handleDrawFinish = useCallback(
    (layer: Polygon) => {
      workingLayerRef.current = new Layer(layer);
      setZoneManagementActionState(ZoneManagementActionState.NewZoneAreaManipulation);
      showZoneAreaManipulationBottomActionButtonsAsEnabled();
    },
    [showZoneAreaManipulationBottomActionButtonsAsEnabled],
  );

  const handleDrawDisabled = useCallback(() => {
    if (activeLayerManagementTool === LayerManagementTool.Cut) {
      setActiveLayerManagementTool(null);
    }
  }, [activeLayerManagementTool]);

  const handleLayerCut = useCallback((layer: Polygon, originalLayer: Polygon) => {
    // Cut returns a new layer, so we need to attach properties from original layer to newly cut layer
    layer.feature = originalLayer.toGeoJSON();
    layer.removeEventListener('click');
    workingLayerRef.current = new Layer(layer!);
    setActiveLayerManagementTool(null);
  }, []);

  const handleDrawStart = useCallback((workingLayer: Polygon, shape: string) => {
    workingLayerRef.current = new Layer(workingLayer);
    setZoneManagementActionState(ZoneManagementActionState.DrawingNewZone);
  }, []);

  const handleVertexAdd = useCallback(
    (vertexCount: number) => {
      if (vertexCount === 2) {
        showOnlyCancelButtonAsEnabled();
      }
    },
    [showOnlyCancelButtonAsEnabled],
  );

  useEffect(() => {
    mapRef.current = new Map(map);
  }, [map]);

  useGeoJsonZones(zones, handleLayerClick, workingLayerRef.current?.layer);
  useDragEndEvent(map, handleDragEnd);
  useDrawFinishedEvent(map, handleDrawFinish);
  useDrawDisabledEvent(map, handleDrawDisabled);
  useLayerCutEvent(map, handleLayerCut);
  useDrawStartEvent(map, handleDrawStart, handleVertexAdd);

  useEffect(() => {
    setZoneManagementActionState(ZoneManagementActionState.ZonesPreview);
    hideZoneAreaManipulationBottomActionButtons();
    setZoneRuleFormVisible(false);
  }, [dispatch, hideZoneAreaManipulationBottomActionButtons]);

  const handleZoneRuleFormClose = () => {
    setZoneRuleFormVisible(false);

    if (zoneManagementActionState === ZoneManagementActionState.ViewZoneInformationScreen) {
      setZoneManagementActionState(ZoneManagementActionState.ZonesPreview);
      setActiveZoneGeoJsonFeature(null);
      workingLayerRef.current = null;
    } else if (zoneManagementActionState === ZoneManagementActionState.EditZoneInformationScreen) {
      setZoneManagementActionState(ZoneManagementActionState.EditZoneAreaManipulation);
      showZoneAreaManipulationBottomActionButtonsAsEnabled();
    } else if (zoneManagementActionState === ZoneManagementActionState.NewZoneInformationScreen) {
      setZoneManagementActionState(ZoneManagementActionState.NewZoneAreaManipulation);
      disableActiveLayerManagementTool();
      setActiveLayerManagementTool(null);
      showZoneAreaManipulationBottomActionButtonsAsEnabled();
    }

    if (createZoneError) dispatch(resetErrorAction('createZone'));
    if (updateZoneError) dispatch(resetErrorAction('updateZone'));
  };

  const handleDrawClick = () => {
    if (activeLayerManagementTool === LayerManagementTool.Add) {
      setZoneManagementActionState(ZoneManagementActionState.ZonesPreview);
      hideZoneAreaManipulationBottomActionButtons();
      workingLayerRef.current = null;
    } else {
      showZoneAreaManipulationBottomActionButtonsAsDisabled();
    }
  };

  const handleLayerDrawContinueClick = useCallback(() => {
    if (zoneManagementActionState === ZoneManagementActionState.NewZoneAreaManipulation) {
      setZoneManagementActionState(ZoneManagementActionState.NewZoneInformationScreen);
      setActiveLayerManagementTool(LayerManagementTool.Add);
    } else if (zoneManagementActionState === ZoneManagementActionState.EditZoneAreaManipulation) {
      setZoneManagementActionState(ZoneManagementActionState.EditZoneInformationScreen);
      setActiveLayerManagementTool(null);
    }
    disableActiveLayerManagementTool();
    setZoneRuleFormVisible(true);
    hideZoneAreaManipulationBottomActionButtons();
  }, [disableActiveLayerManagementTool, hideZoneAreaManipulationBottomActionButtons, zoneManagementActionState]);

  const handleLayerDrawCancelClick = useCallback(() => {
    map.removeLayer(workingLayerRef.current!.layer);
    setZoneManagementActionState(ZoneManagementActionState.ZonesPreview);
    disableActiveLayerManagementTool();
    setActiveLayerManagementTool(null);
    setActiveZoneGeoJsonFeature(null);
    hideZoneAreaManipulationBottomActionButtons();
    workingLayerRef.current = null;
  }, [map, disableActiveLayerManagementTool, hideZoneAreaManipulationBottomActionButtons]);

  const handleFormDeleteClick = () => {
    setDeleteZoneModalVisible(true);
    hideZoneAreaManipulationBottomActionButtons();
    setActiveLayerManagementTool(null);
  };

  const handleZoneRuleSubmitButtonClick = async (values: ZoneRulesFormValues) => {
    try {
      const geoJson = workingLayerRef.current!.layer.toGeoJSON();
      if (zoneManagementActionState === ZoneManagementActionState.NewZoneInformationScreen) {
        await dispatch(createZoneAsyncThunkAction({ config: values, geoJson })).unwrap();
        dispatch(showAlertAction({ message: intl.formatMessage({ id: 'alert.zones.add.success' }) }));
      } else {
        await dispatch(updateZoneAsyncThunkAction({ zoneId: geoJson.id as number, config: values, geoJson })).unwrap();
        dispatch(showAlertAction({ message: intl.formatMessage({ id: 'alert.zones.edit.success' }) }));
      }
      setZoneRuleFormVisible(false);
      setZoneManagementActionState(ZoneManagementActionState.ZonesPreview);
      setActiveLayerManagementTool(null);
      setActiveZoneGeoJsonFeature(null);
      workingLayerRef.current = null;
    } catch (error) {
      console.log('Failed to save the zone', error);
    }
  };

  const handleZoneTypeChange = useCallback(
    (zoneType: ZoneType) => {
      if (workingLayerRef.current) {
        const zoneColor = ZoneTypeHelper.metadata(zoneType).color;
        workingLayerRef.current.layerColor(zoneColor);
        workingLayerRef.current.layerFillColor(zoneColor);
      }
    },
    [workingLayerRef],
  );

  const handleDeleteZoneConfirmationModalClose = (reason: DeleteZoneConfirmationModalCloseReason) => {
    setDeleteZoneModalVisible(false);
    if (reason === 'deleteZoneSuccess') {
      setZoneRuleFormVisible(false);
      setActiveZoneGeoJsonFeature(null);
      setZoneManagementActionState(ZoneManagementActionState.ZonesPreview);
      workingLayerRef.current = null;
    }
  };

  const handleEditZoneClick = () => {
    setZoneManagementActionState(ZoneManagementActionState.EditZoneAreaManipulation);
    setZoneRuleFormVisible(false);
    showZoneAreaManipulationBottomActionButtonsAsEnabled();
  };

  const serverError: ZoneRuleFormServerError = useMemo(
    () => ({
      createError: createZoneServerViolationsFieldToError,
      updateError: updateZoneServerViolationsFieldToError,
      saveZoneError: savedZoneError,
    }),
    [createZoneServerViolationsFieldToError, updateZoneServerViolationsFieldToError, savedZoneError],
  );

  return (
    <Fragment>
      <Box display="flex" mr={3} justifyContent="flex-end">
        <LayerManagementToolbar
          activeTool={activeLayerManagementTool}
          onDrawClick={handleDrawClick}
          addButtonRef={anchorElementRef}
          workingLayer={workingLayerRef.current}
          setActiveLayerManagementTool={setActiveLayerManagementTool}
          enableAddControl={
            zoneManagementActionState === ZoneManagementActionState.ZonesPreview ||
            zoneManagementActionState === ZoneManagementActionState.DrawingNewZone
          }
          enableEditControls={
            zoneManagementActionState === ZoneManagementActionState.EditZoneAreaManipulation ||
            zoneManagementActionState === ZoneManagementActionState.NewZoneAreaManipulation
          }
        />
      </Box>
      {zoneAreaManipulationBottomActionButtons.visible && (
        <ZoneAreaManipulationBottomActionButtons
          cancelButtonEnabled={zoneAreaManipulationBottomActionButtons.cancelButtonEnabled}
          continueButtonEnabled={zoneAreaManipulationBottomActionButtons.continueButtonEnabled}
          onContinueClick={handleLayerDrawContinueClick}
          onCancelClick={handleLayerDrawCancelClick}
        />
      )}
      <ZoneRuleSettingsPopover
        zoneSettings={activeZoneGeoJsonFeature?.properties || zoneRuleInitialValues}
        isOpen={zoneRuleFormVisible}
        popOverAnchor={anchorElementRef.current}
        onZoneRuleFormClose={handleZoneRuleFormClose}
        zoneManagementActionState={zoneManagementActionState}
        onEditZoneClick={handleEditZoneClick}
        onZoneRuleSubmitButtonClick={handleZoneRuleSubmitButtonClick}
        onZoneTypeChange={handleZoneTypeChange}
        onZoneDeleteClick={handleFormDeleteClick}
        popOverTransformOrigin={{ horizontal: 'right', vertical: 0 }}
        popOverAnchorOrigin={{ horizontal: -20, vertical: 0 }}
        serverError={serverError}
      />
      {deleteZoneModalVisible && (
        <DeleteZoneConfirmationModal
          open={deleteZoneModalVisible}
          handleClose={handleDeleteZoneConfirmationModalClose}
          name={activeZoneGeoJsonFeature!.properties.name}
          startDate={activeZoneGeoJsonFeature!.properties.startDate!}
          endDate={activeZoneGeoJsonFeature!.properties.endDate}
          zoneId={activeZoneGeoJsonFeature!.properties.id}
        />
      )}
    </Fragment>
  );
}
