import NewDataTable from '../../../design-system/NewDataTable';
import { TripActivityLogDetails, TripDetailsResponse } from '../../../models';
import { TripAuditLogTableColumnsDefs } from '../table-column-defs/TripAuditLogTableColumnDefs';

interface TripAuditLogProps {
  tripDetails: TripDetailsResponse;
}

const compareTimestamps = (a: TripActivityLogDetails, b: TripActivityLogDetails) => a.timestamp - b.timestamp;

export function TripAuditLog({ tripDetails }: TripAuditLogProps) {
  const chronologicalActivityLog = tripDetails?.activityLog.sort(compareTimestamps) || [];

  const MemoizedTripAuditLogTableColumnsDefs = TripAuditLogTableColumnsDefs();
  return (
    <NewDataTable<TripActivityLogDetails, 'default'>
      columns={MemoizedTripAuditLogTableColumnsDefs}
      data={chronologicalActivityLog}
      enableSorting={false}
      height="300px"
    />
  );
}
