import { Dayjs } from 'dayjs';

import { ZoneTimeWindowResponse } from '../../models';
import { END_OF_DAY_MINUTES_FROM_BEGINNING_OF_DAY } from '../../utils/constants';

import { VisibilityStatusPointProviderConstants, VisibilityStatusPointProvider } from './VisibilityStatusPointProvider';

export class ZoneTimeWindow implements VisibilityStatusPointProvider {
  private readonly _start: Dayjs;
  private readonly _startTimeInclusiveSecondsFromBeginningOfDay: number;
  private readonly _end: Dayjs;
  private readonly _endTimeInclusiveSecondsFromBeginningOfDay: number;
  private readonly _visibleThroughOutDay: boolean;
  private readonly _timeWindowSpansSingleDay: boolean;

  constructor(timeWindow: ZoneTimeWindowResponse) {
    this._start = timeWindow.startInclusive.asDayjsTime();
    this._startTimeInclusiveSecondsFromBeginningOfDay = this._start.secondsFromBeginningOfDay();

    this._end = timeWindow.endExclusive.asDayjsTime();
    this._endTimeInclusiveSecondsFromBeginningOfDay = this._end.toInclusive().secondsFromBeginningOfDay();

    this._visibleThroughOutDay = this.minutesDiffBetweenStartAndEndTime() === END_OF_DAY_MINUTES_FROM_BEGINNING_OF_DAY;
    this._timeWindowSpansSingleDay = this._start.isBeforeTime(this._end);
  }

  isVisible(evaluationPoint: Dayjs): boolean {
    if (this._visibleThroughOutDay) {
      return true;
    }

    const evaluationPointSameOrAfterStartTime: boolean = evaluationPoint.isSameOrAfterTime(this._start);
    const evaluationPointSameOrBeforeEndTime: boolean = evaluationPoint.isSameOrBeforeTime(this._end);
    if (this._timeWindowSpansSingleDay) {
      return evaluationPointSameOrAfterStartTime && evaluationPointSameOrBeforeEndTime;
    } else {
      return evaluationPointSameOrAfterStartTime || evaluationPointSameOrBeforeEndTime;
    }
  }

  calculateNextVisiblePoint(evaluationPoint: Dayjs): Dayjs {
    if (this._visibleThroughOutDay) {
      return evaluationPoint;
    }

    const evaluationPointSameOrBeforeZoneStartTime: boolean = evaluationPoint.isSameOrBeforeTime(this._start);
    if (evaluationPointSameOrBeforeZoneStartTime) {
      return evaluationPoint.setTime(this._start);
    } else {
      return evaluationPoint.plusDays(1).setTime(this._start);
    }
  }

  calculateNextInvisiblePoint(evaluationPoint: Dayjs): Dayjs {
    if (this._visibleThroughOutDay) {
      return VisibilityStatusPointProviderConstants.endOfUniverse;
    }

    let nextInvisiblePoint: Dayjs;
    const evaluationPointSameOrAfterStartTime: boolean = evaluationPoint.isSameOrAfterTime(this._start);
    const evaluationPointSameOrBeforeEndTime: boolean = evaluationPoint.isSameOrBeforeTime(this._end);
    const evaluationPointBetweenStartAndEndTime: boolean = evaluationPointSameOrAfterStartTime && evaluationPointSameOrBeforeEndTime;

    if (this._timeWindowSpansSingleDay) {
      if (evaluationPointBetweenStartAndEndTime) {
        nextInvisiblePoint = evaluationPoint.setTime(this._end);
      } else {
        nextInvisiblePoint = evaluationPoint;
      }
    } else {
      if (evaluationPointSameOrBeforeEndTime) {
        nextInvisiblePoint = evaluationPoint.setTime(this._end);
      } else if (evaluationPointSameOrAfterStartTime) {
        nextInvisiblePoint = evaluationPoint.plusDays(1).setTime(this._end);
      } else {
        nextInvisiblePoint = evaluationPoint;
      }
    }

    return nextInvisiblePoint.plusSeconds(1);
  }

  overlapsWith(predicateStartTimeSecondsFromBeginningOfDay: number, predicateEndTimeSecondsFromBeginningOfDay: number): boolean {
    return (
      this._endTimeInclusiveSecondsFromBeginningOfDay >= predicateStartTimeSecondsFromBeginningOfDay &&
      this._startTimeInclusiveSecondsFromBeginningOfDay <= predicateEndTimeSecondsFromBeginningOfDay
    );
  }

  private minutesDiffBetweenStartAndEndTime() {
    return this._end.minuteDiff(this._start);
  }
}
