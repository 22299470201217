import { useCallback, useEffect, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import isEmpty from 'lodash/isEmpty';
import { useIntl } from 'react-intl';

import { LoadingAndErrorWithRetryAndNoResults } from '../../../../../components/LoadingAndErrorWithRetryAndNoResults';
import { BudgetPlanMembershipDto } from '../../../../../services/budget-plan-memberships';
import { deleteMembership, fetchBudgetPlanMembershipDetails } from '../../../../../utils/queries';
import HelperText, { HelperTextType } from '../../../../../wmv-components/HelperText';
import { REQUEST_STATUS } from '../../atoms/budget-plan-atoms';
import { useBudgetPlan } from '../../atoms/hooks/useBudgetPlan';
import { useBudgetPlanMembership } from '../../atoms/hooks/useBudgetPlanMembership';

import { MembershipDetailsActionButtons } from './MembershipDetailsActionButtons';
import { MembershipInfo } from './MembershipInfo';
import { UnlinkMembershipConfirmationModal } from './UnlinkMembershipConfirmationModal';

interface MembershipDetailsModalContentProps {
  onUnlinkMembershipModalClose: () => void;
}

export const MembershipDetailsModalContent = ({ onUnlinkMembershipModalClose }: MembershipDetailsModalContentProps) => {
  const { palette } = useTheme();
  const { formatMessage: fm } = useIntl();
  const { selectedPlan } = useBudgetPlan();
  const {
    bmSelectedMembershipId,
    bmUnlinkModalVisible,
    setBMUnlinkModalVisible,
    retryStateTransitionApiStatus,
    discardFailedStateTransitionApiStatus,
  } = useBudgetPlanMembership();

  const [membership, setMembership] = useState<BudgetPlanMembershipDto | null>(null);
  const [membershipDetailsApiStatus, setMembershipDetailsApiStatus] = useState<REQUEST_STATUS>(REQUEST_STATUS.IDLE);
  const [unlinkingApiStatus, setUnlinkingApiStatus] = useState<REQUEST_STATUS>(REQUEST_STATUS.IDLE);

  const helperTextRef = useRef<string>('');
  const helperTextTypeRef = useRef<HelperTextType>('info');

  if (membership?.isUnlinkingFailed()) {
    helperTextRef.current = fm({ id: 'budgetPlanMembership.unlinkingFailed' });
    helperTextTypeRef.current = 'error';
  }
  if (membership?.isLinkingFailed()) {
    helperTextRef.current = fm({ id: 'budgetPlanMembership.linkingFailed' });
    helperTextTypeRef.current = 'error';
  }

  const getMembershipDetails = useCallback(async () => {
    if (selectedPlan?.id && bmSelectedMembershipId) {
      setMembershipDetailsApiStatus(REQUEST_STATUS.PENDING);
      try {
        const resp = await fetchBudgetPlanMembershipDetails(selectedPlan.id, bmSelectedMembershipId);
        setMembership(isEmpty(resp) ? null : new BudgetPlanMembershipDto(resp));
        setMembershipDetailsApiStatus(REQUEST_STATUS.SUCCESS);
      } catch (err) {
        console.error('Error fetching membership details', err);
        setMembership(null);
        setMembershipDetailsApiStatus(REQUEST_STATUS.FAILURE);
      }
    }
  }, [selectedPlan, bmSelectedMembershipId]);

  useEffect(() => {
    (async () => {
      if (!bmUnlinkModalVisible) {
        await getMembershipDetails();
      }
    })();
  }, [getMembershipDetails, bmUnlinkModalVisible]);

  if (!membership) {
    return (
      <LoadingAndErrorWithRetryAndNoResults
        error={membershipDetailsApiStatus === REQUEST_STATUS.FAILURE}
        loading={membershipDetailsApiStatus === REQUEST_STATUS.PENDING}
        noResults={isEmpty(membership) && membershipDetailsApiStatus === REQUEST_STATUS.SUCCESS}
        onRetry={getMembershipDetails}
        baseTranslationKey="membershipDetails"
        style={{ backgroundColor: palette.white.main, flex: 1 }}
      />
    );
  }

  return (
    <Box display="flex" flex="1" flexDirection="column">
      <MembershipInfo membership={membership} />
      <MembershipDetailsActionButtons
        membership={membership}
        selectedPlan={selectedPlan!}
        onUnlinkMembershipModalClose={onUnlinkMembershipModalClose}
      />
      <HelperText
        showAttentionIcon={helperTextTypeRef.current === 'error'}
        showCheckIcon={helperTextTypeRef.current === 'success'}
        type={
          retryStateTransitionApiStatus === REQUEST_STATUS.PENDING || discardFailedStateTransitionApiStatus === REQUEST_STATUS.PENDING
            ? 'info'
            : helperTextTypeRef.current
        }
        value={helperTextRef.current}
      />
      <UnlinkMembershipConfirmationModal
        membership={membership}
        selectedPlan={selectedPlan!}
        unlinkingApiStatus={unlinkingApiStatus}
        onUnlinkConfirmClick={handleUnlinkConfirmClick}
      />
    </Box>
  );

  async function handleUnlinkConfirmClick() {
    setUnlinkingApiStatus(REQUEST_STATUS.PENDING);
    try {
      const newMembership = await deleteMembership(selectedPlan!.id, membership!.id);
      const newMembershipDto = new BudgetPlanMembershipDto(newMembership);
      setMembership(newMembershipDto);
      updateHelperTextAndType(newMembershipDto);
      setUnlinkingApiStatus(REQUEST_STATUS.SUCCESS);
      setBMUnlinkModalVisible(false);
    } catch (err) {
      console.error('Error unlinking membership', err);
      setUnlinkingApiStatus(REQUEST_STATUS.FAILURE);
    }
  }

  function updateHelperTextAndType(membership: BudgetPlanMembershipDto) {
    if (membership.isInactive()) {
      helperTextRef.current = fm({ id: 'budgetPlanMembership.successfullyUnlinked' });
      helperTextTypeRef.current = 'success';
    }
    if (membership.isUnlinkingInProgress()) {
      helperTextRef.current = fm({ id: 'common.requestSuccessfullySubmitted' });
      helperTextTypeRef.current = 'success';
    }
    if (membership.isUnlinkingFailed()) {
      helperTextRef.current = fm({ id: 'budgetPlanMembership.unlinkingFailed' });
      helperTextTypeRef.current = 'error';
    }
    if (membership.isActive()) {
      helperTextRef.current = fm({ id: 'budgetPlanMembership.successfullyLinked' });
      helperTextTypeRef.current = 'success';
    }
    if (membership.isLinkingInProgress()) {
      helperTextRef.current = fm({ id: 'common.requestSuccessfullySubmitted' });
      helperTextTypeRef.current = 'success';
    }
    if (membership.isLinkingFailed()) {
      helperTextRef.current = fm({ id: 'budgetPlanMembership.linkingFailed' });
      helperTextTypeRef.current = 'error';
    }
  }
};
