import { useCallback, useState, useLayoutEffect } from 'react';

import { AxiosError } from 'axios';
import type { Dayjs } from 'dayjs';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { LoadingAndErrorWithRetryAndNoResults } from '../../../../components/LoadingAndErrorWithRetryAndNoResults';
import { BudgetPlanDetailsResponse, DepositFrequency, VehicleType } from '../../../../models';
import { BudgetPlanDto } from '../../../../services/budget-plan';
import { showAlertAction } from '../../../../state/app';
import { addBudgetPlan, fetchBudgetPlanByIdApi, updateBudgetPlan } from '../../../../utils/queries';
import { BP_ACTION_MODE, REQUEST_STATUS } from '../atoms/budget-plan-atoms';
import { useBudgetPlan, useBudgetPlanDetails } from '../atoms/hooks';
import { BudgetPlanModalCloseHandler } from '../BudgetPlanModal';
import { budgetPlanFormInitialValues, makeCreateBudgetPlanRequestPayload, makeUpdateBudgetPlanRequestPayload } from '../helpers';

import { BudgetPlanDetailsFormWithFormik } from './BudgetPlanDetailsForm';

interface BudgetPlanDetailsViewProps {
  onBudgetPlanModalClose: BudgetPlanModalCloseHandler;
}

export const BudgetPlanDetailsView = ({ onBudgetPlanModalClose }: BudgetPlanDetailsViewProps) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const [budgetPlanDetailsFetchState, setBudgetPlanDetailsFetchState] = useState<BudgetPlanDetailsFetchState>({
    data: null,
    hasError: false,
    loading: false,
  });
  const [budgetPlanFormValues, setBudgetPlanFormValues] = useState<BudgetPlanDetailsFormValues>(budgetPlanFormInitialValues);

  const { selectedPlan, setSelectedPlan, budgetPlanActionMode, setBudgetPlanActionMode } = useBudgetPlan();
  const { setBpDetailsSubmitStatus, setBpDetailsErrorMsg, resetBudgetPlanDetailsState } = useBudgetPlanDetails();

  const handleModalClose = () => {
    onBudgetPlanModalClose();
  };

  const onCancelEdit = () => {
    resetBudgetPlanDetailsState();
    fetchBudgetPlanDetailsData(selectedPlan!.id);
  };

  const fetchBudgetPlanDetailsData = useCallback(async (budgetPlanId: number) => {
    setBudgetPlanDetailsFetchState({ data: null, hasError: false, loading: true });
    try {
      const budgetPlanDetails = await fetchBudgetPlanByIdApi(budgetPlanId);
      const budgetPlanDetailsDto = new BudgetPlanDto(budgetPlanDetails);
      setBudgetPlanFormValues(budgetPlanDetailsDto.toFormInput(budgetPlanDetailsDto.activeState));
      setBudgetPlanDetailsFetchState({ data: budgetPlanDetails, hasError: false, loading: false });
    } catch (error) {
      console.error('Failed to fetch budget details:', error);
      setBudgetPlanDetailsFetchState({ data: null, hasError: true, loading: false });
    }
  }, []);

  useLayoutEffect(() => {
    if (selectedPlan) {
      fetchBudgetPlanDetailsData(selectedPlan.id);
    }
  }, [fetchBudgetPlanDetailsData, selectedPlan]);

  const handleBudgetPlanSubmit: BudgetPlanFormSubmitHandler = async (values: BudgetPlanDetailsFormValues) => {
    setBpDetailsSubmitStatus(REQUEST_STATUS.PENDING);
    try {
      let resp;
      if (budgetPlanActionMode === BP_ACTION_MODE.ADD) {
        const requestPayload = makeCreateBudgetPlanRequestPayload(values);
        resp = await addBudgetPlan(requestPayload);
      } else if (budgetPlanActionMode === BP_ACTION_MODE.EDIT && selectedPlan) {
        const requestPayload = makeUpdateBudgetPlanRequestPayload({ ...values, version: selectedPlan.version });
        resp = await updateBudgetPlan(requestPayload, selectedPlan.id);
      } else {
        throw new Error('Invalid action mode');
      }
      const messageId =
        budgetPlanActionMode === BP_ACTION_MODE.ADD ? 'budgetPlan.addSuccess.alertMessage' : 'budgetPlan.updateSuccess.alertMessage';
      dispatch(showAlertAction({ message: formatMessage({ id: messageId }) }));
      const updatedBudgetPlanDto = new BudgetPlanDto(resp);
      setSelectedPlan(updatedBudgetPlanDto);
      setBudgetPlanFormValues(updatedBudgetPlanDto.toFormInput(updatedBudgetPlanDto.activeState));
      setBpDetailsSubmitStatus(REQUEST_STATUS.SUCCESS);
      if (budgetPlanActionMode === BP_ACTION_MODE.EDIT) {
        handleModalClose();
      }
      setBudgetPlanActionMode(BP_ACTION_MODE.VIEW);
    } catch (err: unknown) {
      console.error('Budget plan operation failed:', err);
      setBpDetailsSubmitStatus(REQUEST_STATUS.FAILURE);
      const messageId =
        budgetPlanActionMode === BP_ACTION_MODE.ADD ? 'budgetPlan.addFailure.alertMessage' : 'budgetPlan.updateFailure.alertMessage';
      dispatch(
        showAlertAction({
          message: formatMessage({ id: messageId }),
          type: 'error',
        }),
      );
      if (err instanceof AxiosError) {
        setBpDetailsErrorMsg(err.response?.data?.title);
      } else {
        throw err;
      }
    }
  };

  if (budgetPlanDetailsFetchState.loading || budgetPlanDetailsFetchState.hasError) {
    return (
      <LoadingAndErrorWithRetryAndNoResults
        error={budgetPlanDetailsFetchState.hasError}
        loading={budgetPlanDetailsFetchState.loading}
        onRetry={() => fetchBudgetPlanDetailsData!(selectedPlan!.id)}
        baseTranslationKey="budgetPlanDetails"
        style={{ height: '100%' }}
      />
    );
  }

  return (
    <BudgetPlanDetailsFormWithFormik
      initialValues={budgetPlanFormValues}
      onBudgetPlanFormSubmit={handleBudgetPlanSubmit}
      onCancelEdit={onCancelEdit}
      onBudgetPlanModalClose={handleModalClose}
    />
  );
};

export interface BudgetPlanDetailsFetchState {
  data: BudgetPlanDetailsResponse | null;
  hasError: boolean;
  loading: boolean;
}

export type BudgetPlanFormSubmitHandler = (values: BudgetPlanDetailsFormValues) => Promise<void>;

export interface BudgetPlanDetailsFormValues {
  name: string;
  depositFrequency: DepositFrequency;
  depositDayOfMonth?: number;
  depositTime: Dayjs | null;
  depositAmount: number | null;
  amountPerTripSpendConstraintExists: boolean | null;
  amountPerTripSpendConstraint?: number;
  vehicleTypesSpendConstraintExists: boolean | null;
  vehicleTypesSpendConstraint: VehicleType[];
}
