import * as React from 'react';
import { useState } from 'react';

import type { ColumnDef, Row } from '@tanstack/react-table';

import { ReactComponent as UnlinkIcon } from '../../../../assets/icons/outline/unlink.svg';
import NewDataTable from '../../../../design-system/NewDataTable';
import { intl } from '../../../../utils/intl';

import { SlidingConfirmationButton, SlidingConfirmationIconButtonClickPosition } from './SlidingConfirmationButton';

export function LinkedMembersList({ memberships }: { memberships: any[] }) {
  return <NewDataTable<any, 'default'> data={memberships} columns={CustomerBudgetMembersList} variant="no-border" />;
}

const CustomerBudgetMembersList: ColumnDef<any>[] = [
  {
    header: intl.formatMessage({ id: `common.name` }),
    accessorKey: 'name',
  },
  {
    header: intl.formatMessage({ id: `common.activationDate` }),
    accessorKey: 'activationDate',
  },
  {
    header: '',
    accessorKey: 'final',
    cell: ({ row: { original: budgetInfo } }: CellProps) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [toggleUnlinkingButton, setToggleUnlinkingButton] = useState<boolean>(false);

      const handleToggleUnlinkButton = (clickPosition: SlidingConfirmationIconButtonClickPosition) => {
        if (clickPosition === 'iconClick') setToggleUnlinkingButton((prev) => !prev);
        if (clickPosition === 'outsideClick') setToggleUnlinkingButton(false);
      };

      const handleUnlinkingConfirm = () => {
        console.log('Unlinking', budgetInfo.name);
        // Handle Unlinking here
      };
      return (
        <SlidingConfirmationButton
          toggleButton={toggleUnlinkingButton}
          onButtonClick={handleUnlinkingConfirm}
          onIconClick={handleToggleUnlinkButton}
          loading={false}
          confirmationButtonProps={{ variant: 'alert', sx: { mx: 1 } }}
          confirmationButtonText={'common.unlinkMember'.i18nText()}
          icon={<UnlinkIcon />}
          toolTipTitle={'common.removeFromSBA'.i18nText()}
        />
      );
    },
  },
];

interface CellProps {
  row: Row<any>;
}
