import { BudgetPlanMembershipLastAttemptedStateTransition, BudgetPlanMembershipStatus, LastChangeAttemptStatus } from '../../models';

export class BudgetPlanMembershipLastAttemptedStateTransitionDto {
  private readonly lastAttemptedStateTransition: BudgetPlanMembershipLastAttemptedStateTransition;
  status: LastChangeAttemptStatus;
  from: BudgetPlanMembershipStatus;
  to: BudgetPlanMembershipStatus;

  constructor(lastAttemptedStateTransition: BudgetPlanMembershipLastAttemptedStateTransition) {
    this.lastAttemptedStateTransition = lastAttemptedStateTransition;
    this.status = lastAttemptedStateTransition.status;
    this.from = lastAttemptedStateTransition.transition.from;
    this.to = lastAttemptedStateTransition.transition.to;
  }

  isInProgress(): boolean {
    return this.status === LastChangeAttemptStatus.InProgress;
  }

  isFailed(): boolean {
    return this.status === LastChangeAttemptStatus.Failed && !this.isFailedAttemptAcknowledged();
  }

  isSuccessful(): boolean {
    return this.status === LastChangeAttemptStatus.Success;
  }

  isFailedAttemptAcknowledged(): boolean {
    return this.status === LastChangeAttemptStatus.Failed && this.lastAttemptedStateTransition.acknowledged;
  }

  toSubscribed(): boolean {
    return this.to === BudgetPlanMembershipStatus.Subscribed;
  }

  toUnSubscribed(): boolean {
    return this.to === BudgetPlanMembershipStatus.Unsubscribed;
  }
}
