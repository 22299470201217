import Keycloak from 'keycloak-js';

import keycloakConfig from '../configs/keycloak-config';

const _kc = new Keycloak(keycloakConfig);

const initKeycloak = (onAuthenticatedCallback: () => void) => {
  _kc
    .init({
      onLoad: 'login-required',
      // silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
      // pkceMethod: 'S256',
    })
    .then((authenticated) => {
      // _kc.refreshToken = 'fake';
      if (!authenticated) {
        console.log('user is not authenticated..!');
      }
      onAuthenticatedCallback();
    })
    .catch(console.error);
};

console.log(_kc);

const invalidateToken = () => {
  _kc.token = 'Invalidated!';
  _kc.tokenParsed = undefined;
};

const clearToken = _kc.clearToken;

const doLogin = _kc.login;

const doLogout = _kc.logout;

const subjectClaim = () => _kc.subject;

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token && !_kc.isTokenExpired();

const updateToken = (refreshIfExpiresWithinInSeconds: number, successCallback?: () => void) =>
  _kc.updateToken(refreshIfExpiresWithinInSeconds).then(successCallback).catch(doLogin);

const refreshToken = (refreshIfExpiresWithinInSeconds: number) => _kc.updateToken(refreshIfExpiresWithinInSeconds);

const changePassword = () => {
  const loginURL = _kc.createLoginUrl();
  const url = `${loginURL}&kc_action=UPDATE_PASSWORD`;
  window.location.href = url;
};
const getUsername = () => _kc.tokenParsed?.preferred_username;

const getProfileData = () => {
  return {
    loginName: _kc.tokenParsed?.azp,
    email: _kc.tokenParsed?.email,
    familyName: _kc.tokenParsed?.family_name,
    givenName: _kc.tokenParsed?.given_name,
    phone: _kc.tokenParsed?.phone_number,
  };
};
const hasRole = (roles: string[]) => roles.some((role) => _kc.hasRealmRole(role));

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  subjectClaim,
  getToken,
  getProfileData,
  updateToken,
  refreshToken,
  getUsername,
  hasRole,
  invalidateToken,
  clearToken,
  changePassword,
};

export default UserService;
