// Add additional functions can be borrowed from here
// https://obaranovskyi.medium.com/top-7-functions-youll-ever-need-to-work-with-enums-in-typescript-d8a75a445f2b

export type EnumWithNumberValue = {
  [s: number]: string;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace EnumWithNumberValue {
  export function isEnumKey<T extends EnumWithNumberValue>(enumSrc: T, key: unknown): key is keyof T {
    return Number.isInteger(enumSrc[key as keyof T]);
  }

  export function enumToKeys<T extends EnumWithNumberValue>(enumSrc: T): (keyof T)[] {
    return Object.keys(enumSrc).filter((key: keyof T | any) => isEnumKey(enumSrc, key)) as (keyof T)[];
  }

  export function enumToValues<T extends EnumWithNumberValue>(enumSrc: T): T[keyof T][] {
    return enumToKeys(enumSrc).map((key: keyof T) => enumSrc[key]);
  }
}

export type EnumWithStringValue = {
  [s: string]: string;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace EnumWithStringValue {
  export function isEnumKey<T extends EnumWithStringValue>(enumSrc: T, key: unknown): key is keyof T {
    return typeof enumSrc[key as keyof T] === 'string';
  }

  export function enumToKeys<T extends EnumWithStringValue>(enumSrc: T): (keyof T)[] {
    return Object.keys(enumSrc).filter((key: keyof T | any) => isEnumKey(enumSrc, key)) as (keyof T)[];
  }

  export function enumToValues<T extends EnumWithStringValue>(enumSrc: T): T[keyof T][] {
    return enumToKeys(enumSrc).map((key: keyof T) => enumSrc[key]);
  }

  export function getEnumKeyByEnumValue<T extends { [index: string]: string }>(myEnum: T, enumValue: string): keyof T | null {
    let keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue);
    return keys.length > 0 ? keys[0] : null;
  }
}
