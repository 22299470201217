import {
  PaymentCardBrandHelper,
  PaymentMethodType,
  PaymentMethodTypeHelper,
  PaymentStatus,
  PaymentStatusHelper,
  Unit,
  UnitHelper,
  TripPaymentAmountBreakdownDetails,
  TripPaymentAmountSubType,
  TripPaymentDetails,
  TripPaymentAmountSchedule,
} from '../../models';

/**
 * Service class for handling and calculating trip pricing breakdown details.
 * This class provides methods to access various pricing and payment-related details
 * for a specific trip, such as total charges, budget deductions, and payment details.
 *
 * @class
 */
export class TripPricingBreakdownService {
  private tripPayment: TripPaymentDetails;
  private pricingBreakdown: TripPaymentAmountBreakdownDetails[];

  constructor(tripPayment: TripPaymentDetails) {
    this.tripPayment = tripPayment;
    this.pricingBreakdown = tripPayment.amount.breakdown;
  }

  /**
   * Indicates whether the trip is fully paid.
   *
   * @type {boolean}
   * @readonly
   */
  get isPaid(): boolean {
    return this.tripPayment.status === PaymentStatus.Paid;
  }

  /**
   * Provides information about budget deductions including the amount and its unit.
   *
   * @type {Object}
   * @property {number} amount - The amount of budget deduction.
   * @property {string} unit - The unit of the budget deduction (e.g., minutes).
   * @property {string} amountInMins - The formatted budget amount in minutes.
   * @readonly
   */
  get budgetDeductionInfo() {
    return {
      ...this.tripPayment.amount.budget,
      amountInMins:
        this.tripPayment.amount.budget?.amount + UnitHelper.metadata(this.tripPayment.amount.budget?.unit || Unit.Minutes).displayText,
    };
  }

  /**
   * Returns per-unit charges for various aspects of the trip, such as ride time, paused time, and activation fee.
   *
   * @type {Object}
   * @property {string} perMinRideCharges - The charges per minute for the active ride duration.
   * @property {string} perMinPausedCharges - The charges per minute for the paused duration.
   * @property {number} perTripActivationCharges - The one-time activation charges for the trip.
   * @readonly
   */
  get perUnitCharges() {
    const rideChargesSchedule = this.pricingPerUnitByUsageChargeSubType(TripPaymentAmountSubType.ActiveDuration);
    const pausedChargesSchedule = this.pricingPerUnitByUsageChargeSubType(TripPaymentAmountSubType.PausedDuration);
    const activationChargesSchedule = this.pricingPerUnitByUsageChargeSubType(TripPaymentAmountSubType.ActivationFee);
    return {
      perMinRideCharges: rideChargesSchedule?.value.toCents() + UnitHelper.metadata(rideChargesSchedule?.type!).displayText,
      perMinPausedCharges: pausedChargesSchedule?.value.toCents() + UnitHelper.metadata(pausedChargesSchedule?.type!).displayText,
      perTripActivationCharges: activationChargesSchedule?.value.toCents(),
    };
  }

  /**
   * Provides a detailed breakdown of total charges for the trip, including ride, paused, activation, and VAT details.
   *
   * @type {Object}
   * @property {number} rideTripCharges - Total charges for the active ride duration.
   * @property {number} pausedTripCharges - Total charges for the paused duration.
   * @property {number} activationTripCharges - Total activation charges for the trip.
   * @property {number} totalCharges - Total charges including VAT.
   * @property {number} totalChargesWithoutVat - Total charges excluding VAT.
   * @property {number} vatPercentage - VAT percentage applied to the charges.
   * @property {number} vatCharges - Total VAT charges.
   * @readonly
   */
  get totalChargesBreakdown() {
    return {
      rideTripCharges: this.totalRideDurationCharges.toCents(),
      pausedTripCharges: this.totalPausedDurationCharges.toCents(),
      activationTripCharges: this.perUnitCharges.perTripActivationCharges,
      totalCharges: this.tripPayment.amount.money?.amount.toCents(),
      totalChargesWithoutVat: this.tripPayment.amount.money?.amountBeforeVat.toCents(),
      vatPercentage: this.tripPayment.amount.money?.vatPercentage.toPercentage(),
      vatCharges: this.tripPayment.amount.money?.vatAmount.toCents(),
    };
  }

  /**
   * Provides a list of formatted payment details for the trip transaction.
   *
   * @type {FormattedTripPaymentDetails[]}
   * @readonly
   */
  get tripTransactionPaymentDetails(): FormattedTripPaymentDetails[] {
    let paymentDetails: FormattedTripPaymentDetails[] = [];
    const budgetPayment = this.tripPayment.amount.budget;
    const moneyPayment = this.tripPayment.amount.money;

    if (!this.isPaid) return [];

    if (moneyPayment) {
      const { paymentMethod } = moneyPayment;
      paymentDetails.push({
        methodName:
          PaymentCardBrandHelper.displayText(paymentMethod.brand) +
          ' ' +
          PaymentMethodTypeHelper.displayText(paymentMethod.type)! +
          ' ' +
          `**** ${paymentMethod.last4Digits}`,
        date: moneyPayment.paidAt.parseEpochMillis().formatAsDateTimeString(),
        amount: moneyPayment.amount.toCents(),
        lastStatusUpdate: PaymentStatusHelper.metadata(moneyPayment.status).displayText,
        isPaid: moneyPayment.status === PaymentStatus.Paid,
      });
    }

    if (budgetPayment) {
      paymentDetails.push({
        methodName: PaymentMethodTypeHelper.displayText(PaymentMethodType.Budget)! + budgetPayment.planName,
        date: budgetPayment.capturedAt.parseEpochMillis().formatAsDateTimeString(),
        amount: budgetPayment.amount + UnitHelper.metadata(budgetPayment.unit).displayText,
        lastStatusUpdate: PaymentStatusHelper.metadata(budgetPayment.status).displayText,
        isPaid: budgetPayment.status === PaymentStatus.Paid,
      });
    }
    return paymentDetails;
  }

  /**
   * Finds a trip breakdown by usage charge subtype.
   *
   * @private
   * @param {TripPaymentAmountSubType} breakdownSubType - The subtype of the trip payment amount breakdown.
   * @returns {TripPaymentAmountBreakdownDetails | undefined} The breakdown details for the specified subtype.
   */
  private tripByUsageChargeSubType(breakdownSubType: TripPaymentAmountSubType): TripPaymentAmountBreakdownDetails | undefined {
    return this.tripPayment?.amount.breakdown.find((breakdown) => breakdown.subType === breakdownSubType);
  }

  /**
   * Retrieves the pricing per unit for a specified usage charge subtype.
   *
   * @private
   * @param {TripPaymentAmountSubType} subType - The subtype of the trip payment amount breakdown.
   * @returns {TripPaymentAmountSchedule | undefined} The pricing schedule for the specified usage charge subtype.
   */
  private pricingPerUnitByUsageChargeSubType(subType: TripPaymentAmountSubType): TripPaymentAmountSchedule | undefined {
    return this.tripByUsageChargeSubType(subType)?.metadata.schedule;
  }

  /**
   * Calculates the total charges for the ride duration.
   *
   * @private
   * @type {number}
   * @readonly
   */
  private get totalRideDurationCharges(): number {
    return this.pricingBreakdown
      .filter((amount) => amount.subType === TripPaymentAmountSubType.ActiveDuration)
      .reduce((acc, curr) => acc + curr.amount, 0);
  }

  /**
   * Calculates the total charges for the paused duration.
   *
   * @private
   * @type {number}
   * @readonly
   */
  private get totalPausedDurationCharges(): number {
    return this.pricingBreakdown
      .filter((amount) => amount.subType === TripPaymentAmountSubType.PausedDuration)
      .reduce((acc, curr) => acc + curr.amount, 0);
  }
}

export interface FormattedTripPaymentDetails {
  methodName: string;
  date: string;
  amount: string;
  lastStatusUpdate: string;
  isPaid: boolean;
}
