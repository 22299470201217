import { MouseEventHandler, useState } from 'react';

import { Divider } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import { TripDetailsResponse, TripStatus } from '../../models';
import { forceStopTrip } from '../../utils/queries';
import { enqueuedSnackbar } from '../EnqueuedSnackbar';
import { LoadingAndErrorWithRetryAndNoResults } from '../LoadingAndErrorWithRetryAndNoResults';

import { TripForceStopFormToggle } from './TripForceStopFormToggle';
import { TripForceStopFormField } from './TripForceStopFormToggle/TripForceStopForm';
import { TripInfoTabs } from './TripInfoTabs';
import { TripOverview } from './TripOverview';
import { TripViewBottomActionButtons } from './TripViewBottomActionButtons';

interface TripViewProps {
  tripDetails: TripDetailsResponse;
  tripDetailsError: boolean;
  tripDetailsLoading: boolean;
  emptyTripDetails: boolean;
  includeForceStop?: boolean;
  onTripUpdate?: (trip: TripDetailsResponse) => void;
  fetchTripDetails: () => Promise<void>;
  onCloseTripBtnClick: MouseEventHandler<HTMLButtonElement>;
  onNextTripBtnClick: MouseEventHandler<HTMLButtonElement>;
  disableCloseBtn?: boolean;
  disableNextBtn?: boolean;
}

const TripView = ({
  tripDetailsLoading,
  tripDetailsError,
  tripDetails,
  fetchTripDetails,
  onTripUpdate,
  includeForceStop = true,
  onCloseTripBtnClick,
  onNextTripBtnClick,
  disableCloseBtn,
  disableNextBtn,
}: TripViewProps) => {
  const theme = useTheme();

  const [prevForceStopPayload, setPrevForceStopPayload] = useState<TripForceStopFormField | null>(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [forceStoppedTripDetails, setForceStoppedTripDetails] = useState<TripDetailsResponse | null>(null);

  if (tripDetailsLoading || tripDetailsError) {
    return (
      <LoadingAndErrorWithRetryAndNoResults
        style={{ height: '150px' }}
        error={tripDetailsError}
        loading={tripDetailsLoading}
        onRetry={determineRetryFunction(isEditMode)}
        baseTranslationKey="tripDetails"
      />
    );
  }

  const updatedTripDetails = forceStoppedTripDetails || tripDetails;
  const isTripFinished = updatedTripDetails.status === TripStatus.Stopped || updatedTripDetails.status === TripStatus.ForceStopped;
  return (
    <Box sx={{ boxShadow: theme.shadowOptions.belowSmall }}>
      <Box display="flex" flexDirection="column" gap="1rem" pl={4} py={5}>
        <TripOverview tripDetails={updatedTripDetails} />
        {includeForceStop && (
          <TripForceStopFormToggle
            tripDetails={updatedTripDetails}
            onForceStopBtnSubmit={handleForceStop}
            isTripFinished={isTripFinished}
          />
        )}
      </Box>
      <Divider />
      <Box pl={4} py={3}>
        <TripInfoTabs tripDetails={updatedTripDetails} />
        <Box mt={8}>
          <TripViewBottomActionButtons
            onCloseTripBtnClick={onCloseTripBtnClick}
            onNextTripBtnClick={onNextTripBtnClick}
            disableCloseBtn={disableCloseBtn}
            disableNextBtn={disableNextBtn}
          />
        </Box>
      </Box>
    </Box>
  );

  async function handleForceStop(values: TripForceStopFormField) {
    setPrevForceStopPayload(values);
    setIsEditMode(true);
    const tripForceStopEpochMillis = values.endDate?.setTime(values.endTime || '').toEpochMillis();

    try {
      const trip = await forceStopTrip(updatedTripDetails.id, { finishedAt: tripForceStopEpochMillis });
      setForceStoppedTripDetails(trip);
      enqueuedSnackbar('trip.forceStop.success'.i18nText({ tripId: updatedTripDetails.id.toString() }), 'success');
      if (onTripUpdate && trip) onTripUpdate(trip);
    } catch (err) {
      enqueuedSnackbar('trip.forceStop.failed'.i18nText({ tripId: updatedTripDetails.id.toString() }), 'error');
      console.error(err);
    }
  }

  function determineRetryFunction(editMode: boolean) {
    if (editMode && includeForceStop && prevForceStopPayload) {
      return () => handleForceStop(prevForceStopPayload);
    }
    return () => fetchTripDetails();
  }
};

export default TripView;
