import { number, object } from 'yup';

import { intl } from '../../../utils/intl';

/**
 * Yup validation schema for validating trip budget refund amounts.
 * This schema ensures that the budget refund amount is required, falls within a specified range, and meets minimum and maximum constraints.
 *
 * @constant
 * @type {object}
 *
 * @property {number} maxAllowedBudgetRefundAmt - The maximum allowed budget refund amount. This field is required.
 * @property {number} budgetRefundAmt - The budget refund amount to be validated. This field is required and must:
 * - Be less than or equal to `maxAllowedBudgetRefundAmt`.
 * - Be greater than or equal to 1.
 *
 * @returns {Yup.ObjectSchema} A Yup object schema for validating the trip budget refund form.
 */
export const TripBudgetRefundValidationSchema = object().shape({
  maxAllowedBudgetRefundAmt: number().required(),
  budgetRefundAmt: number()
    .required(intl.formatMessage({ id: 'form.validation.errorFieldRequired' }))
    .test({
      name: 'minAndMaxValueCheck',
      test(value, ctx) {
        const maxAllowedBudgetRefundAmt = this.parent['maxAllowedBudgetRefundAmt'];
        if (maxAllowedBudgetRefundAmt && Number(maxAllowedBudgetRefundAmt) < Number(value)) {
          return ctx.createError({
            message: intl.formatMessage({ id: 'form.validation.mustBeEqualOrLess' }, { label: 'Refundable budget amount (max.)' }),
          });
        }
        if (Number(value) < 1) {
          return ctx.createError({
            message: intl.formatMessage({ id: 'form.validation.mustBeGreaterThanOrEqualToOne' }),
          });
        }
        return true;
      },
    }),
});
