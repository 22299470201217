import L, { LatLng } from 'leaflet';

import { FormattedLiveVehicle } from '../../state/common/vehicle.model';

// TODO: w/1459: Delete this & use a library that does this.
export const getVehiclesInsideZone = (shape: any, allVehicleData: FormattedLiveVehicle[], boxZoomBounds: L.LatLngBounds[]) => {
  const containedVehicles: FormattedLiveVehicle[] = [];

  if (shape && shape?.geometry?.coordinates[0]) {
    //Unregular shaped polygons require a ray casting algorithm in order to determine which points are in the polygon - getBounds.contains just creates a rectangle
    let poly = L.polygon(shape.geometry.coordinates);
    allVehicleData.forEach((vehicle) => {
      var inside = false;
      var x = vehicle.lon,
        y = vehicle.lat;
      for (var ii = 0; ii < poly.getLatLngs().length; ii++) {
        var polyPoints = poly.getLatLngs()[ii];
        if (Array.isArray(polyPoints)) {
          for (var i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
            if (polyPoints[i] instanceof LatLng && polyPoints[j] instanceof LatLng) {
              var xi = (polyPoints[i] as LatLng).lat,
                yi = (polyPoints[i] as LatLng).lng;
              var xj = (polyPoints[j] as LatLng).lat,
                yj = (polyPoints[j] as LatLng).lng;
              // eslint-disable-next-line no-mixed-operators
              var intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
              if (intersect) inside = !inside;
            }
          }
        }
      }

      if (inside === true) {
        containedVehicles.push(vehicle);
      }
    });

    return containedVehicles;
  }
  if (boxZoomBounds) {
    const zoomBoxBounds = boxZoomBounds[0];
    if (zoomBoxBounds.isValid()) {
      allVehicleData.forEach((vehicle) => {
        if (
          zoomBoxBounds.contains({
            lat: vehicle.lat,
            lng: vehicle.lon,
          })
        ) {
          containedVehicles.push(vehicle);
        }
      });
      return containedVehicles;
    }
  }
};
