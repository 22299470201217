import type { MouseEvent } from 'react';

import ButtonGroup from '@mui/material/ButtonGroup';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { btnGroupSx, StyledButton } from './WmvButtonGroup.styles';

export const WmvButtonGroup = ({ config, activeBtn, onClick }: WmvButtonGroupProps) => {
  const { palette } = useTheme();

  return (
    <ButtonGroup variant="outlined" sx={btnGroupSx}>
      {config.map((config: ButtonGroupItemConfig) => {
        const isActive = activeBtn === config.btnId;

        return (
          <StyledButton key={config.btnId} onClick={(e) => onClick(e, config.btnId)} isActive={isActive} variant="contained">
            <Typography
              variant={isActive ? 'bodyMediumBold' : 'bodyMedium'}
              color={isActive ? palette.baseLight.main : palette.baseLight.base76}
            >
              {config.text}
            </Typography>
          </StyledButton>
        );
      })}
    </ButtonGroup>
  );
};

export interface WmvButtonGroupProps {
  config: ButtonGroupItemConfig[];
  activeBtn: string;
  onClick: (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>, switchModeButtonId: string) => void;
}

export interface ButtonGroupItemConfig {
  text: string;
  btnId: string;
}
