import { Box, Button, Grid, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';

import { CustomerInfoFormFields } from './index';

export function BottomActionButtons({ isInEditMode, onCancelEdit, onUpdateSubmit }: BottomActionButtonsProps) {
  const { formatMessage } = useIntl();
  const { values, isValid, isValidating } = useFormikContext<CustomerInfoFormFields>();

  return (
    <Grid item xs={12}>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          float: 'right',
          height: '32px',
          marginTop: '30px',
        }}
      >
        <Button variant="outlined" disabled={!isInEditMode} size="small" color="primary" onClick={onCancelEdit}>
          <Typography variant="bodySmallBold">{formatMessage({ id: 'maas.customer.details.cancel' })}</Typography>
        </Button>
        <Button
          type="submit"
          variant="contained"
          size="small"
          disabled={isValidating || !isValid || !isInEditMode}
          sx={{
            marginLeft: '24px',
          }}
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            onUpdateSubmit(values);
          }}
        >
          <Typography variant="bodySmallBold">{formatMessage({ id: 'maas.customer.details.update' })}</Typography>
        </Button>
      </Box>
    </Grid>
  );
}

interface BottomActionButtonsProps {
  isInEditMode: boolean;
  onCancelEdit: () => void;
  onUpdateSubmit: (values: CustomerInfoFormFields) => void;
}
