import { EnumWithStringValue } from '../../utils/EnumUtils';

export enum ModeType {
  WideInterval = 'WIDE_INTERVAL',
  DayWise = 'DAY_WISE',
  PointInTime = 'POINT_IN_TIME',
}

interface ModeTypeDerivedMetadata {
  labelTranslationKey: string;
  descriptionTranslationKey: string;
}

interface ModeTypeUnderivedMetadata {
  granularityTranslationKey: string;
}

export interface ModeTypeMetadata extends ModeTypeUnderivedMetadata, ModeTypeDerivedMetadata {}

export namespace ModeTypeHelper {
  const modeTypeToUnderivedMetadata = new Map<ModeType, ModeTypeUnderivedMetadata>([
    [ModeType.WideInterval, { granularityTranslationKey: 'planningPreview.granularity.aggregated' }],
    [ModeType.DayWise, { granularityTranslationKey: 'planningPreview.granularity.aggregated' }],
    [ModeType.PointInTime, { granularityTranslationKey: 'planningPreview.granularity.hourly' }],
  ]);

  export function metadata(modeType: ModeType): ModeTypeMetadata {
    const underivedMetadata = modeTypeToUnderivedMetadata.get(modeType);
    if (!underivedMetadata) {
      throw Error(`
            Specified Mode type: ${toString(modeType)} doesnt have corresponding underived/explicitly defined metadata.
            This usually (not always) means a bug or incomplete implementation.
        `);
    }
    const derivedMetadata: ModeTypeDerivedMetadata = {
      labelTranslationKey: `planningPreview.modeType.${toString(modeType)}.title`,
      descriptionTranslationKey: `planningPreview.modeType.${toString(modeType)}.description`,
    };

    return { ...underivedMetadata, ...derivedMetadata };
  }

  export function toString(modeType: ModeType): string | null {
    return EnumWithStringValue.getEnumKeyByEnumValue(ModeType, modeType);
  }

  export function allModeTypesArray(): ModeType[] {
    return EnumWithStringValue.enumToValues(ModeType);
  }
}
