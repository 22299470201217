import { useState } from 'react';

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useIntl } from 'react-intl';

import { ErrorCode } from '../../../../../models';
import { BudgetPlanMembershipDto } from '../../../../../services/budget-plan-memberships';
import { createMembership } from '../../../../../utils/queries';
import { ProgressAwareButton } from '../../../../../wmv-components';
import HelperText, { HelperTextType } from '../../../../../wmv-components/HelperText';
import { REQUEST_STATUS } from '../../atoms/budget-plan-atoms';
import { useBudgetPlan, useBudgetPlanMembership } from '../../atoms/hooks';
import { MembershipDetailsRow } from '../common/MembershipDetailsRow';

import { SearchedMembershipLinkingDetails } from './SearchedMembershipLinkingDetails';
import { SearchedMembershipUserDetails } from './SearchedMembershipUserDetails';

export const SearchedMembershipDetailsAndLinkNewUserButton = () => {
  const { formatMessage: fm } = useIntl();
  const { selectedPlan } = useBudgetPlan();
  const { bmUserToLink, bmLinkUserStatus, setBMLinkUserStatus } = useBudgetPlanMembership();

  const [membershipLinkStatusMessage, setMembershipLinkStatusMessage] = useState<{
    message: string;
    messageType: HelperTextType;
  }>({
    message: '',
    messageType: 'info',
  });

  const disableLinking = !bmUserToLink?.isEligibleToBeLinked() || membershipLinkStatusMessage?.messageType === 'success';

  return (
    <Box mt={2}>
      <SearchedMembershipUserDetails />
      {disableLinking ? (
        <Box>
          <MembershipDetailsRow
            title={'budgetPlanMembership.currentlyBeingLinked'.i18nText()}
            titleVariant="bodyMedium"
            labelTranslationKey="common.currentStatus"
          />
          <MembershipDetailsRow
            title={bmUserToLink?.linkedBudgetPlan?.name!}
            labelTranslationKey="common.linkedBudgetPlan"
            toolTipText={'budgetMembership.linkedToPlanTooltip'.i18nText()}
          />
        </Box>
      ) : (
        <SearchedMembershipLinkingDetails />
      )}
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt={3}>
        <ProgressAwareButton
          loading={bmLinkUserStatus === REQUEST_STATUS.PENDING}
          onClick={handleLinkNewMembershipClick}
          type="button"
          variant="contained"
          size="medium"
          loadingButtonProps={{
            sx: { width: 'fit-content', minWidth: '240px' },
          }}
          disabled={disableLinking}
        >
          <Typography variant="bodyMediumBold">{fm({ id: 'budgetPlanMembership.linkUser' })}</Typography>
        </ProgressAwareButton>
        <HelperText
          value={membershipLinkStatusMessage.message}
          type={membershipLinkStatusMessage.messageType}
          showCheckIcon={membershipLinkStatusMessage.messageType === 'success'}
          showAttentionIcon={membershipLinkStatusMessage.messageType === 'error'}
        />
      </Box>
    </Box>
  );

  async function handleLinkNewMembershipClick() {
    setBMLinkUserStatus(REQUEST_STATUS.PENDING);
    try {
      const resp = await await createMembership(selectedPlan?.id!, {
        subjectClaim: bmUserToLink?.details.subjectClaim!,
      });
      const newMembershipDto = new BudgetPlanMembershipDto(resp);
      setMembershipLinkStatusMessage({
        message: linkingApiSuccessMessage(newMembershipDto)!,
        messageType: newMembershipDto.isLinkingFailed() ? 'error' : 'success',
      });
      setBMLinkUserStatus(REQUEST_STATUS.SUCCESS);
    } catch (err: any) {
      console.error('Error searching for membership', err);
      setMembershipLinkStatusMessage({
        message: linkingApiFailureMessage(err.errorCode),
        messageType: 'error',
      });
      setBMLinkUserStatus(REQUEST_STATUS.FAILURE);
    }
  }

  function linkingApiSuccessMessage(membership: BudgetPlanMembershipDto) {
    if (membership.isLinkingInProgress()) return fm({ id: 'budgetPlanMemberships.linkingRequestSubmitted' });
    if (membership.isActive()) return fm({ id: 'common.linked' });
    if (membership.isLinkingFailed()) return fm({ id: 'budgetPlanMemberships.linkingFailed' });
  }

  function linkingApiFailureMessage(errorCode?: ErrorCode) {
    if (errorCode === ErrorCode.MobilityUserAlreadyLinkedToPlan) return fm({ id: 'budgetPlanMembership.alreadyLinkedToThisPlan' });
    else if (errorCode === ErrorCode.MobilityUserSharingOthersBudget) return fm({ id: 'budgetPlanMembership.linkedToOtherPlan' });
    else return fm({ id: 'budgetPlanMemberships.linkingFailed' });
  }
};
