import React, { useState, useRef, ReactNode } from 'react';

import { Box, Button, ButtonGroup, Popper, useTheme } from '@mui/material';

interface GroupMenuControlProps {
  buttons: {
    icon: ReactNode; // Icon when the menu is off
    activeIcon: ReactNode; // Icon when the menu is on
    content?: ReactNode; // Optional content to display in the Popper
    onClick?: (event: React.MouseEvent<HTMLElement>, index: number) => void; // Optional event handler
  }[];
}

/**
 * GroupMenuControl component provides a vertical button group where each button
 * can toggle a corresponding popper with optional content.
 *
 * @param {GroupMenuControlProps} props - The props for the GroupMenuControl component.
 * @returns {JSX.Element} The rendered GroupMenuControl component.
 */
export function GroupMenuControl({ buttons }: GroupMenuControlProps) {
  // Keep track of the open popper for each button
  const [openStates, setOpenStates] = useState<boolean[]>(new Array(buttons.length).fill(false));
  // Anchor element for Popper
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  // Refs for each button
  const refs = useRef<(HTMLButtonElement | null)[]>([]);

  const theme = useTheme();

  /**
   * Handles button click events. It toggles the popper visibility for the clicked button.
   * If an `onClick` handler is provided for the button, it is called before the popper is toggled.
   *
   * @param {number} index - The index of the clicked button.
   * @returns {(event: React.MouseEvent<HTMLElement>) => void} The click event handler function.
   */
  const handleClick = (index: number) => (event: React.MouseEvent<HTMLElement>) => {
    const button = buttons[index];
    // If the button has an onClick handler, call it
    if (button.onClick) {
      button.onClick(event, index);
    }

    setAnchorEl(refs.current[index]);
    setOpenStates((prevStates) => {
      return prevStates.map((state, i) => {
        if (i === index) {
          // Always toggle the clicked button's state
          return !state;
        } else if (buttons[i].content) {
          // Close other buttons with content
          return false;
        } else {
          // Maintain state for buttons without content
          return state;
        }
      });
    });
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          margin: theme.spacing(2, 3, 1.5, 3),
        }}
      >
        <ButtonGroup
          orientation="vertical"
          variant="outlined"
          aria-label="outlined primary button group"
          sx={{ boxShadow: theme.shadowOptions.belowMedium, zIndex: '1' }}
        >
          {buttons.map((button, index) => (
            <Button
              key={index}
              ref={(el) => (refs.current[index] = el)} // Store ref for each button
              onClick={handleClick(index)}
              disableRipple
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                background: theme.palette.baseLight.base0,
                border: 'none',
                '&:hover': {
                  backgroundColor: theme.palette.secondary.tint,
                  border: 'none',
                },
                width: '52px',
                height: '52px',
              }}
            >
              {openStates[index] ? button.activeIcon : button.icon}
            </Button>
          ))}
        </ButtonGroup>
      </Box>

      {buttons.map(
        (button, index) =>
          button.content && (
            <Popper
              key={index}
              open={openStates[index]}
              anchorEl={anchorEl}
              placement="left-start"
              modifiers={[
                {
                  name: 'offset',
                  options: {
                    offset: [0, 8], // Adjust margin between Popper and Button
                  },
                },
              ]}
              style={{ zIndex: theme.zIndex.modal }}
            >
              {button.content}
            </Popper>
          ),
      )}
    </>
  );
}
