import { useAtom } from 'jotai';

import { isDashboardPageVisibleAtom } from './atoms/feature-flags-atoms';

export const routes = {
  login: '/login', // this page doesn't exist anymore, but its still a logical redirect
  // and might also be used again in future
  dashboard: '/dashboard',
  communication: '/communication',
  zones: '/zones',
  incidents: '/incidents',
  analytics: '/analytics',
  operator: '/operator',
  user: '/user',
  support: '/support',
  maas: '/maas',

  customerDatabase: '/maas/customer-database',

  budgetPlanning: '/operator/budget-planning',
  fleetManagement: '/operator/fleet-management',
  vehicleFeePricing: '/operator/vehicle-fee-pricing',
};

export interface SubMenuConfig {
  name: string;
  route: string;
}

export interface MenuConfig {
  name: string;
  route: string;
  subMenus?: SubMenuConfig[];
}

export const useMenuRoutesConfig = (): MenuConfig[] => {
  const [isDashboardPageVisible] = useAtom(isDashboardPageVisibleAtom);

  const menus = [
    ...(isDashboardPageVisible
      ? [
          {
            name: 'menu.dashboard',
            route: routes.dashboard,
          },
        ]
      : []),
    { name: 'menu.zones', route: routes.zones },
    {
      name: 'menu.maas',
      route: routes.maas,
      subMenus: [{ name: 'menu.submenu.customerDatabase', route: routes.customerDatabase }],
    },
    { name: 'menu.analytics', route: routes.analytics },
    {
      name: 'menu.operator',
      route: routes.operator,
      subMenus: [
        { name: 'menu.submenu.operator.fleetManagement', route: routes.fleetManagement },
        { name: 'common.vehicleFeePricing', route: routes.vehicleFeePricing },
        { name: 'menu.submenu.operator.budgetPlanning', route: routes.budgetPlanning },
      ],
    },

    {
      name: 'menu.support',
      route: routes.support,
    },
  ];

  return menus;
};

export default routes;
