/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useCallback, useEffect, useState } from 'react';

import 'leaflet/dist/leaflet.css';

import { useAtom } from 'jotai';
import L, { LatLngBounds, LatLngExpression } from 'leaflet';
import { MapContainer, TileLayer, ZoomControl, useMap } from 'react-leaflet';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';

import { FormattedLiveVehicle } from '../../state/common/vehicle.model';
import { BAMBERG_COORDINATES } from '../../utils/constants';
import VehicleInformationModal from '../VehicleInformationModal';

import { baseMapStyleAtom } from './atoms/map-view-atoms';
import MapBoxLogoControl from './controls/MapboxLogoControl';
import { VehicleMarkerWithInfoPopover } from './VehicleMarkerWithInfoPopover';
import { getVehiclesInsideZone } from './ZoneMethods';

import '../../../src/assets/styles/components/_map.scss';

// Use environment variable for the Mapbox access token
const mapboxAccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const mapboxTileLayers: { [key: string]: string } = {
  Light: 'mapbox/light-v11',
  Dark: 'mapbox/dark-v11',
  Satellite: 'mapbox/satellite-v9',
  Hybrid: 'mapbox/satellite-streets-v11',
  Street: 'mapbox/streets-v11',
};

declare global {
  interface Window {
    mapInstance: L.Map; // Assuming L.Map is the type you're assigning
  }
}

function WikimoveMap({ zoomBtn = true, showLayers = true, children, openMarkerId, setPopupTableData, vehicles }: MapProps) {
  const center = BAMBERG_COORDINATES as LatLngExpression;

  const [selectedVehicles, setSelectedVehicles] = useState<FormattedLiveVehicle[]>([]);
  const [selectedVehicleForDetailedModal, setSelectedVehicleForDetailedModal] = useState<FormattedLiveVehicle | null>(null);
  const [detailedVehicleModalIsOpen, setDetailedVehicleModalIsOpen] = useState(false);

  const [zoombox, setZoombox] = useState<LatLngBounds[] | null>(null);
  const [baseMapStyle] = useAtom(baseMapStyleAtom);

  //Extend functionality of leaflet method boxzoom
  (L.Map as any).BoxPrinter = (L.Map as any).BoxZoom.extend({
    _onMouseUp: function (e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
      this._finish();
      let map = this._map,
        startPoint = map.containerPointToLatLng(this._startPoint),
        layerPoint = map.mouseEventToLatLng(e);

      if (this._startPoint.equals(layerPoint)) {
        return;
      }

      let bounds = new L.LatLngBounds(startPoint, layerPoint);

      setZoombox([bounds]);
    },
  });
  L.Map.mergeOptions({ boxPrinter: true });
  L.Map.mergeOptions({ boxZoom: false });
  L.Map.addInitHook('addHandler', 'boxPrinter', (L.Map as any).BoxPrinter);

  useEffect(() => {
    if (zoombox && zoombox.length > 0 && vehicles) {
      const vehiclesInsideZoombox = getVehiclesInsideZone(null, vehicles, zoombox);
      setPopupTableData && setPopupTableData(vehiclesInsideZoombox || []);
      setSelectedVehicles(vehiclesInsideZoombox || []);
    }
  }, [zoombox]);

  function MapInstanceForTesting() {
    const map = useMap();
    window.mapInstance = map;
    return null;
  }

  const handleCloseDetailedVehicleModal = useCallback(() => {
    setDetailedVehicleModalIsOpen(false);
    selectedVehicleForDetailedModal && setSelectedVehicleForDetailedModal(null);
  }, []);

  const handleOpenDetailedVehicleModal = useCallback((vehicle: FormattedLiveVehicle) => {
    setDetailedVehicleModalIsOpen(true);
    vehicle && setSelectedVehicleForDetailedModal(vehicle);
  }, []);

  return (
    <Fragment>
      <MapContainer
        id="map-container"
        style={{ height: '100%', width: '100%' }}
        center={center}
        zoom={13}
        zoomControl={false}
        attributionControl={true}
        doubleClickZoom={false}
      >
        {vehicles &&
          vehicles.map((vehicle, index) => (
            <VehicleMarkerWithInfoPopover
              key={vehicle.vehicleId || vehicle.stableVehicleId}
              vehicle={vehicle}
              openMarkerId={openMarkerId}
              selected={isSelectedVehicle(vehicle, selectedVehicles)}
              handleOpenDetailedVehicleModal={handleOpenDetailedVehicleModal}
            />
          ))}

        {zoomBtn && <ZoomControl position={'bottomright'} />}
        <MapBoxLogoControl />
        <TileLayer
          url={`https://api.mapbox.com/styles/v1/${mapboxTileLayers[baseMapStyle]}/tiles/{z}/{x}/{y}?access_token=${mapboxAccessToken}`}
          tileSize={512}
          zoomOffset={-1}
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> | <a href="https://labs.mapbox.com/contribute/#/-74@site/src/10/">Improve this map</a>'
        />
        <MapInstanceForTesting />
        {children}
      </MapContainer>
      {selectedVehicleForDetailedModal && (
        <VehicleInformationModal
          vehicle={selectedVehicleForDetailedModal}
          isOpen={detailedVehicleModalIsOpen}
          onClose={handleCloseDetailedVehicleModal}
        />
      )}
    </Fragment>
  );
}

export function generateUniqueID(vehicle: FormattedLiveVehicle) {
  return [vehicle.vehicleTypeId, vehicle.vehicleId, vehicle.qrCodeId].map((v) => String(v)).join('#');
}

function isSelectedVehicle(vehicle: FormattedLiveVehicle, selectedVehicles: FormattedLiveVehicle[]) {
  return selectedVehicles.some((v) => generateUniqueID(v) === generateUniqueID(vehicle));
}

interface MapProps {
  zoomBtn?: boolean;
  showLayers?: boolean;
  children?: React.ReactNode;
  openMarkerId?: string | null;
  setPopupTableData?: (vehicles: FormattedLiveVehicle[]) => void;
  vehicles?: FormattedLiveVehicle[]; // TODO: Fix this option type
}

export default WikimoveMap;
