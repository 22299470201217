import { useEffect } from 'react';

import { Map, Polygon } from 'leaflet';

export const useDragEndEvent = (map: Map, onDragEnd: (layer: Polygon) => void) => {
  useEffect(() => {
    // @ts-ignore
    const handleDragEndEvent = ({ layer }) => {
      onDragEnd(layer as Polygon);
    };

    map.on('pm:dragend', handleDragEndEvent);

    return () => {
      map.off('pm:dragend', handleDragEndEvent);
    };
  }, [map, onDragEnd]);
};
