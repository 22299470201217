import Box from '@mui/material/Box';

import { TranslatedText } from '../../../wmv-components';

import { pageHeadingStyles } from './styles';

export const PageHeading = () => {
  return (
    <Box sx={pageHeadingStyles}>
      <TranslatedText translationKey="common.vehicleFeePricing" variant="bodyLargeBold" />
      <TranslatedText
        translationKey="vehicleFeePricing.pageSubheading"
        variant="bodyMedium"
        typographyProps={{ sx: { maxWidth: '510px' } }}
      />
    </Box>
  );
};
