import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import { membershipInfoLabelStyles, membershipInfoRowStyles } from './styles';

export function MembershipInfo() {
  const { palette } = useTheme();
  const budgetAboutToExpire = true;

  return (
    <Box mt={5}>
      <Box sx={membershipInfoRowStyles}>
        <Typography variant="bodyMedium" component="div" sx={membershipInfoLabelStyles}>
          {'common.currentRole'.i18nText()}:
        </Typography>
        <Typography variant="bodyMedium" color={palette.baseLight.main}>
          Owner
        </Typography>
      </Box>
      <Box sx={membershipInfoRowStyles}>
        <Typography variant="bodyMedium" component="div" sx={membershipInfoLabelStyles}>
          {'common.activationDate'.i18nText()}:
        </Typography>
        <Typography variant="bodyMedium" color={palette.baseLight.main}>
          01.01.2024
        </Typography>
      </Box>
      <Box sx={membershipInfoRowStyles}>
        <Typography variant="bodyMedium" component="div" sx={membershipInfoLabelStyles}>
          {'common.nextDeposit'.i18nText()}:
        </Typography>
        <Typography variant="bodyMedium" color={palette.baseLight.main}>
          01.06.2024
        </Typography>
      </Box>
      <Box sx={membershipInfoRowStyles}>
        <Typography variant="bodyMedium" component="div" sx={membershipInfoLabelStyles}>
          {'common.renewalOrFrequency'.i18nText()}:
        </Typography>
        <Typography variant="bodyMedium" color={budgetAboutToExpire ? palette.alert.main : palette.baseLight.main}>
          Monthly, every 1st at 00:01
        </Typography>
      </Box>
    </Box>
  );
}
