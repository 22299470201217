import { createAction } from '@reduxjs/toolkit';

import { AvailableLanguage } from './common.model';
import { LiveVehicle } from './vehicle.model';

export const updateAllVehiclesAction = createAction<LiveVehicle[]>('common/allVehicles');
export const loadingVehiclesDataAction = createAction<boolean>('common/loadingVehiclesData');
export const loadingVehiclesErrorAction = createAction<string>('common/loadingVehiclesError');
export const changeLanguageAction = createAction<AvailableLanguage>('common/changeLanguage');

export const toggleAccessForbiddenAlertAction = createAction<boolean>('common/toggleAccessForbiddenAlert');
