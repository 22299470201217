import { Box } from '@mui/material';

const ZoneIcon = ({ image, zoneType }: { image: string | undefined; zoneType: string }) => {
  return (
    <Box
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        borderRadius: 8,
      }}
    >
      <Box component="img" src={image} alt={`${zoneType}-Zone Icon`} />
    </Box>
  );
};

export default ZoneIcon;
