import { MenuItem, TextField } from '@mui/material';

import { FormikFieldProps } from '../../models';

const FormikSelectField: React.FC<FormikFieldProps> = ({ field, form, ...props }) => {
  return (
    <TextField
      select
      onChange={(event) => {
        form.setFieldValue(field.name, event.target.value);
      }}
      size="small"
      fullWidth
      {...field}
      {...props}
    >
      {props.options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default FormikSelectField;
