import 'react-datepicker/dist/react-datepicker.css';

import { Box, Button, Typography, useTheme } from '@mui/material';
import { useAtom } from 'jotai';
import { useIntl } from 'react-intl';

import darkMap from '../../../../../assets/images/darkMap.png';
import hybridMap from '../../../../../assets/images/hybridMap.svg';
import lightMap from '../../../../../assets/images/lightMap.png';
import satelliteMap from '../../../../../assets/images/sateliteMap.svg';
import streetMap from '../../../../../assets/images/streetMap.svg';
import { themeOptions as theme } from '../../../../../init-setup/ThemeOptions';
import { baseMapStyleAtom } from '../../../atoms/map-view-atoms';

interface ViewButtonProps {
  handleOverlay: (event: React.MouseEvent<HTMLButtonElement>) => void;
  selected: boolean;
  input: string;
  backgroundImage: string;
}

const ViewButton = ({ handleOverlay, backgroundImage, selected, input }: ViewButtonProps) => {
  return (
    <Button
      key="Overlay"
      onClick={handleOverlay}
      disableRipple
      sx={{
        borderRadius: '8px',
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'column',
        backgroundImage: `url(${backgroundImage})`,
        opacity: selected ? '1' : '0.5',
        margin: '2.5px 0px',
        padding: '0px',
        border: '2px solid white',
        '&:hover': {
          border: `2px solid ${theme.palette.secondary.dark}`,
          opacity: '1',
        },
        height: '63px',
      }}
    >
      <div
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          borderRadius: '0px 0px 6px 6px',
          textAlign: 'left',
          position: 'absolute',
          bottom: '0',
          left: '0',
          width: '100%',
          display: 'flex',
          height: '20px',
        }}
      >
        <Typography
          sx={{
            padding: '2px 2px',
            fontWeight: selected ? 'bold' : 'normal',
          }}
          variant="caption"
          color={selected ? theme.palette.secondary.dark : theme.palette.hexBlack.shade32}
        >
          {input}
        </Typography>
      </div>
    </Button>
  );
};

const SetView = () => {
  const [baseMapStyle, setBaseMapStyle] = useAtom(baseMapStyleAtom);
  const { spacing, shadows } = useTheme();

  const handleButtonSelect = (selectedBaseMapStyle: string) => {
    setBaseMapStyle(selectedBaseMapStyle);
  };

  const { formatMessage } = useIntl();

  return (
    <>
      <Box
        style={{
          borderRadius: spacing(1),
          backgroundColor: 'white',
          boxShadow: shadows[3],
          display: 'flex',
          justifyContent: 'center',
          width: '72px',
          height: '300px',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <ViewButton
          input={formatMessage({ id: 'map.customLayers.light' })}
          backgroundImage={lightMap}
          selected={baseMapStyle === 'Light'}
          handleOverlay={() => handleButtonSelect('Light')}
        />
        <ViewButton
          input={formatMessage({ id: 'map.customLayers.dark' })}
          backgroundImage={darkMap}
          selected={baseMapStyle === 'Dark'}
          handleOverlay={() => handleButtonSelect('Dark')}
        />
        <ViewButton
          input={formatMessage({ id: 'map.customLayers.street' })}
          backgroundImage={streetMap}
          selected={baseMapStyle === 'Street'}
          handleOverlay={() => handleButtonSelect('Street')}
        />
        <ViewButton
          input={formatMessage({ id: 'map.customLayers.satellite' })}
          backgroundImage={satelliteMap}
          selected={baseMapStyle === 'Satellite'}
          handleOverlay={() => handleButtonSelect('Satellite')}
        />
        <ViewButton
          input={formatMessage({ id: 'map.customLayers.hybrid' })}
          backgroundImage={hybridMap}
          selected={baseMapStyle === 'Hybrid'}
          handleOverlay={() => handleButtonSelect('Hybrid')}
        />
      </Box>
    </>
  );
};
export default SetView;
