import { ReactNode } from 'react';

import type { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';

export const ModalContent = ({ children, sx }: ModalContentProps) => {
  return (
    <Box width="100%" height="100%" sx={sx}>
      {children}
    </Box>
  );
};

interface ModalContentProps {
  children: ReactNode;
  sx?: SxProps<Theme>;
}
