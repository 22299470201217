import React from 'react';

import isEmpty from 'lodash/isEmpty';
import type { NumericFormatProps } from 'react-number-format';

import { NumberFormatter } from './NumberFormatter';

export const FormatAsI18nNumber = React.forwardRef<NumericFormatProps, FormatAsI18nNumberProps>(function FormatAsI18nNumber(props, ref) {
  const { prefix = '', suffix = '', allowEmptyFormatting = true } = props;

  function numberFormatter(value: any) {
    if (allowEmptyFormatting && isEmpty(value)) return prefix + suffix;
    return Number(value).i18nNumber().trim();
  }

  return <NumberFormatter formatter={numberFormatter} {...props} />;
});

interface FormatAsI18nNumberProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  prefix: string;
  suffix: string;
  value: number;
  allowEmptyFormatting?: boolean;
}
