import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useIntl } from 'react-intl';

import { ReactComponent as MailIcon } from '../../../../../assets/icons/outline/mail.svg';
import { ReactComponent as UserIcon } from '../../../../../assets/icons/outline/user.svg';
import { useBudgetPlan, useBudgetPlanMembership } from '../../atoms/hooks';
import { MembershipDetailsRow } from '../common/MembershipDetailsRow';

export const SearchedMembershipLinkingDetails = () => {
  const { palette } = useTheme();
  const { formatMessage } = useIntl();

  const { selectedPlan } = useBudgetPlan();
  const { bmUserToLink } = useBudgetPlanMembership();

  const isAlreadyLinkedToCurrentBudgetPlan = bmUserToLink?.isLinkedTo(selectedPlan?.id!);

  if (bmUserToLink?.isEligibleToBeLinked())
    return (
      <MembershipDetailsRow
        title={formatMessage({ id: 'budgetPlanMembership.availableToBeLinked' })}
        titleVariant="bodyMedium"
        labelTranslationKey="common.currentStatus"
      />
    );

  if (isAlreadyLinkedToCurrentBudgetPlan)
    return (
      <MembershipDetailsRow
        title={formatMessage({ id: 'budgetPlanMembership.alreadyLinkedToThisPlan' })}
        titleVariant="bodyMedium"
        labelTranslationKey="common.currentStatus"
      />
    );

  if (bmUserToLink?.isLinkedToAnyBudgetPlan())
    return (
      <Box>
        <MembershipDetailsRow
          title={formatMessage({ id: 'budgetPlanMembership.linkedToOtherPlan' })}
          titleVariant="bodyMedium"
          extraMessage={formatMessage({ id: 'budgetPlanMembership.linkedToOtherPlan.extraInfo' })}
          labelTranslationKey="common.currentStatus"
        />
        <MembershipDetailsRow
          title={bmUserToLink!.linkedBudgetPlan!.name}
          labelTranslationKey="common.linkedBudgetPlan"
          toolTipText={formatMessage({ id: 'budgetMembership.linkedToPlanTooltip' })}
        />
      </Box>
    );

  if (bmUserToLink?.isDrawingFromOtherBudgetPlan())
    return (
      <Box>
        <MembershipDetailsRow
          title={formatMessage({ id: 'budgetPlanMembership.alreadyPartOfSBA' })}
          titleVariant="bodyMedium"
          extraMessage={formatMessage({ id: 'budgetPlanMembership.alreadyPartOfSBA.extraInfo' })}
          labelTranslationKey="common.currentStatus"
        />
        <Box mt={3}>
          <MembershipDetailsRow
            title={bmUserToLink!.drawingBudgetFrom!.budgetPlan.name}
            labelTranslationKey="common.SBA"
            toolTipText={formatMessage({ id: 'budgetMembership.sbaTooltip' })}
            extraMessage={
              <Box display="flex" flexDirection="column" mt={2}>
                <Box component="span" display="flex" alignItems="center" mb={1}>
                  <UserIcon height="18px" width="18px" />
                  <Typography variant="bodyMedium" ml={1} color={palette.baseLight.main}>
                    {bmUserToLink?.drawingBudgetFrom?.name}
                  </Typography>
                </Box>
                <Box component="span" display="flex" alignItems="center">
                  <MailIcon height="18px" width="18px" />
                  <Typography variant="bodyMedium" ml={1} color={palette.baseLight.main}>
                    {bmUserToLink?.drawingBudgetFrom?.email}
                  </Typography>
                </Box>
              </Box>
            }
          />
        </Box>
      </Box>
    );
};
