import { useCallback, useState } from 'react';

import { Box } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import { type Row } from '@tanstack/react-table';

import NewDataTable from '../../../design-system/NewDataTable';
import { themeOptions as theme } from '../../../init-setup/ThemeOptions';
import { CustomerTrip, TripDetailsResponse, TripStatus } from '../../../models';
import { TripActivityLogService } from '../../../services/trip/TripActivityLogService';
import { EnqueuedSnackbarProvider } from '../../EnqueuedSnackbar';
import { LoadingAndErrorWithRetryAndNoResults } from '../../LoadingAndErrorWithRetryAndNoResults';
import { TripDetailsAndManagement } from '../../TripDetailsAndManagement';
import { customerTripsTableColumDef } from '../table-column-defs/CustomerTripsListColumnDefs';

import useFetchCustomerTripsList from './useFetchCustomerTripsList';

interface CustomerTripsProps {
  customerSubjectClaim: string;
}

const CustomerTrips = ({ customerSubjectClaim }: CustomerTripsProps) => {
  const [expandedRowID, setExpandedRowID] = useState<string>('');
  const { allCustomerTrips, loading, error, fetchCustomerTripsList, setAllCustomerTrips } = useFetchCustomerTripsList(customerSubjectClaim);

  const handleRetry = async () => {
    fetchCustomerTripsList(customerSubjectClaim);
  };

  const updateTripsListTable = useCallback(
    (trip: TripDetailsResponse) => {
      const updatedTrips = allCustomerTrips.map((customerTrip) => {
        if (customerTrip.id === trip.id) {
          return {
            ...customerTrip,
            status: TripStatus.ForceStopped,
            distanceMeters: trip.distanceMeters,
            duration: {
              ...customerTrip.duration,
              finishedAt: new TripActivityLogService(trip.activityLog).terminalState?.timestamp,
            },
            payment: {
              ...customerTrip.payment!,
              status: trip.payment?.status!,
            },
          };
        }
        return customerTrip;
      });
      setAllCustomerTrips(updatedTrips);
    },
    [allCustomerTrips, setAllCustomerTrips],
  );

  /**
   * Toggles the expansion state of a row in a table. If the provided row's ID
   * matches the currently expanded row's ID, it collapses the row. Otherwise,
   * it sets the expanded row to the provided row's ID, expanding it.
   *
   * @param {Row<CustomerTrip>} row - The row object containing the ID to toggle.
   *   This is of type `Row<CustomerTrip>`, indicating it is a row related to customer trips.
   * @returns {void}
   */
  const toggleExpanderRow = useCallback(
    (row: Row<CustomerTrip>) => {
      if (row.id === expandedRowID) setExpandedRowID('');
      else setExpandedRowID(row.id);
    },
    [expandedRowID],
  );

  const expanderComponent = useCallback(
    (row: Row<CustomerTrip>, expanderIndex: number, rows: Row<CustomerTrip>[]) => (
      <TripDetailsAndManagement
        tripId={row.original.id}
        onTripUpdate={updateTripsListTable}
        disableNextBtn={expanderIndex + 1 === rows.length}
        onCloseTripBtnClick={() => setExpandedRowID('')}
        onNextTripBtnClick={() => setExpandedRowID(rows[expanderIndex + 1].id)}
      />
    ),
    [updateTripsListTable],
  );
  return (
    <EnqueuedSnackbarProvider>
      <Box sx={tripsViewContainerSx}>
        {!allCustomerTrips.length || error || loading ? (
          <LoadingAndErrorWithRetryAndNoResults
            style={{ height: '100%', backgroundColor: theme.palette.white.main }}
            error={error}
            loading={loading}
            onRetry={handleRetry}
            baseTranslationKey="tripHistory"
            noResults={!allCustomerTrips.length}
          />
        ) : (
          <NewDataTable<CustomerTrip, 'expander'>
            columns={customerTripsTableColumDef}
            data={allCustomerTrips}
            onRowClickHandler={toggleExpanderRow}
            expandedRowId={expandedRowID}
            expanderComponent={expanderComponent}
            category="expander"
          />
        )}
      </Box>
    </EnqueuedSnackbarProvider>
  );
};

export const tripsViewContainerSx = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  alignItems: 'stretch',
  height: '100%',
});

export default CustomerTrips;
