import Box from '@mui/material/Box';
import { useAtom } from 'jotai';
import { createBrowserRouter, RouterProvider, Outlet, Navigate } from 'react-router-dom';

import { isDashboardPageVisibleAtom } from './atoms/feature-flags-atoms';
import CustomerDatabase from './components/CustomerDatabase';
import ErrorBoundary from './components/ErrorBoundary';
import Header from './components/Header/Header';
import Placeholder from './components/Placeholder/Placeholder';
import Analytics from './pages/Analytics';
import Communication from './pages/Communication';
import Dashboard from './pages/Dashboard';
import Incidents from './pages/Incidents';
import MaaS from './pages/MaaS';
import BudgetPlan from './pages/Operator/BudgetPlan';
import Operator from './pages/Operator/FleetManagement';
import VehicleFeesPricingPage from './pages/Operator/VehicleFeePricing';
// import Page404 from './pages/Page404';
import Support from './pages/Support';
import Zones from './pages/Zones';
import routes, { useMenuRoutesConfig } from './routes';
import { HEADER_HEIGHT } from './utils/constants';

const Root = () => {
  return (
    <Box style={{ marginTop: HEADER_HEIGHT }}>
      <Header />
      <Outlet />
    </Box>
  );
};

const EmptyComponent = () => {
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'calc(100vh - 90px)',
        width: '100vw)',
      }}
    >
      <Placeholder />
    </Box>
  );
};

const BrowserRouterProvider = () => {
  const [isDashboardPageVisible] = useAtom(isDashboardPageVisibleAtom);
  const menus = useMenuRoutesConfig();

  const browserRoutes = [
    {
      path: '/',
      element: <Root />,
      errorElement: <ErrorBoundary></ErrorBoundary>,
      children: [
        {
          path: '',
          element: <Navigate to={menus[0].route} />, // Redirect to the first available menu item
        },
        // Conditionally add the Dashboard page route
        ...(isDashboardPageVisible
          ? [
              {
                path: routes.dashboard,
                element: <Dashboard />,
              },
            ]
          : []),
        {
          path: routes.communication,
          element: <Communication />,
        },
        {
          path: routes.zones,
          element: <Zones />,
        },
        {
          path: routes.incidents,
          element: <Incidents />,
        },
        {
          path: routes.maas,
          element: <MaaS />,
          children: [
            {
              path: '',
              element: <Navigate to={routes.customerDatabase} />,
            },
            {
              path: routes.customerDatabase,
              element: <CustomerDatabase />,
            },
          ],
        },
        {
          path: routes.analytics,
          element: <Analytics />,
        },
        {
          path: routes.operator,
          element: <Outlet />,
          children: [
            {
              path: '',
              element: <Navigate to={routes.fleetManagement} />,
            },
            {
              path: routes.fleetManagement,
              element: <Operator />,
            },
            {
              path: routes.vehicleFeePricing,
              element: <VehicleFeesPricingPage />,
            },
            {
              path: routes.budgetPlanning,
              element: <BudgetPlan />,
            },
          ],
        },
        {
          path: routes.support,
          element: <Support />,
        },
        {
          path: '/*',
          element: <EmptyComponent />,
        },
      ],
    },
  ];

  const router = createBrowserRouter(browserRoutes);
  return <RouterProvider router={router} />;
};

export default BrowserRouterProvider;
