import { Map, Polygon } from 'leaflet';

import { colorPalette } from '../../init-setup/ThemeOptions';

export class LeafletMap {
  map: Map;

  constructor(map: Map) {
    this.map = map;
  }

  enableGlobalCut() {
    this.map.pm.enableGlobalCutMode();
  }

  globalCutModeEnabled() {
    return this.map.pm.globalCutModeEnabled();
  }

  removeLayer(layer: Polygon) {
    this.map.removeLayer(layer);
  }

  enablePolygonDraw() {
    this.map.pm.enableDraw('Polygon', {
      templineStyle: { color: colorPalette.primary.main },
      hintlineStyle: { color: colorPalette.primary.main },
      pathOptions: { color: colorPalette.primary.main, fillColor: 'transparent' },
    });
  }

  enableCutOnLayer(layers: Polygon) {
    this.map.pm.setGlobalOptions({
      layersToCut: [layers],
    });
  }
}
