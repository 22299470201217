import React, { RefObject, FC } from 'react';

import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import type { Instance } from '@popperjs/core';

interface SliderDateLabelTooltipProps {
  children: React.ReactElement;
  text: string;
  positionRef?: RefObject<{ x: number; y: number }>;
  popperRef?: RefObject<Instance>;
  areaRef?: RefObject<HTMLDivElement>;
  withVirtualElement?: boolean;
}

const SliderDateLabelTooltip: FC<SliderDateLabelTooltipProps> = ({
  children,
  text,
  positionRef,
  popperRef,
  areaRef,
  withVirtualElement = false,
}) => {
  const theme = useTheme();

  return (
    <Tooltip
      enterTouchDelay={0}
      arrow
      placement="top"
      PopperProps={
        withVirtualElement
          ? {
              disablePortal: true,
              popperRef,
              anchorEl: {
                getBoundingClientRect: () => {
                  return new DOMRect(positionRef!.current!.x, areaRef!.current!.getBoundingClientRect().y, 0, 0);
                },
              },
            }
          : undefined
      }
      title={
        <React.Fragment>
          <Typography color={theme.palette.white.main} sx={{ whiteSpace: 'pre', textAlign: 'center' }}>
            {text}
          </Typography>
        </React.Fragment>
      }
    >
      {children}
    </Tooltip>
  );
};

export default SliderDateLabelTooltip;
