import { EnumWithStringValue } from '../../utils/EnumUtils';
import { intl } from '../../utils/intl';

import { FilterValueType, ListItemConfig } from './shared';
import { VehicleType } from './VehicleType';

export enum GraphqlVehicleType {
  ELECTRIC_MOPED = 'MOPED',
  ELECTRIC_BIKE = 'eBike',
  CAR = 'CAR',
  BICYCLE = 'BICYCLE',
  CARGO_BICYCLE = 'CARGO_BICYCLE',
  SCOOTER = 'SCOOTER',
  SCOOTER_STANDING = 'SCOOTER_STANDING',
  SCOOTER_SEATED = 'SCOOTER_SEATED',
  OTHER = 'OTHER',
}

export namespace GraphqlVehicleTypeHelper {
  export function toString(vehicleType: GraphqlVehicleType): string | null {
    return EnumWithStringValue.getEnumKeyByEnumValue(GraphqlVehicleType, vehicleType);
  }

  export function allTypes(): GraphqlVehicleType[] {
    return EnumWithStringValue.enumToValues(GraphqlVehicleType);
  }

  export const valuesWithLabelAndIconList: FilterValueType<GraphqlVehicleType>[] = [
    {
      value: GraphqlVehicleType.CARGO_BICYCLE,
      label: intl.formatMessage({ id: `vehicleType.cargoBicycle` }),
      icon: 'CargoBikeTooltipIcon',
    },
    {
      value: GraphqlVehicleType.ELECTRIC_BIKE,
      label: intl.formatMessage({ id: `vehicleType.electricBike` }),
      icon: 'BikeTooltipIcon',
    },
    { value: GraphqlVehicleType.BICYCLE, label: intl.formatMessage({ id: `vehicleType.bicycle` }), icon: 'BikeTooltipIcon' },
    { value: GraphqlVehicleType.CAR, label: intl.formatMessage({ id: `vehicleType.car` }), icon: 'CarTooltipIcon' },
    {
      value: GraphqlVehicleType.ELECTRIC_MOPED,
      label: intl.formatMessage({ id: `vehicleType.electricMoped` }),
      icon: 'MopedTooltipIcon',
    },
    { value: GraphqlVehicleType.SCOOTER, label: intl.formatMessage({ id: `vehicleType.scooter` }), icon: 'ScooterTooltipIcon' },
    {
      value: GraphqlVehicleType.SCOOTER_STANDING,
      label: intl.formatMessage({ id: `vehicleType.scooterStanding` }),
      icon: 'ScooterTooltipIcon',
    },
    {
      value: GraphqlVehicleType.SCOOTER_SEATED,
      label: intl.formatMessage({ id: `vehicleType.scooterSeated` }),
      icon: 'ScooterTooltipIcon',
    },
    { value: GraphqlVehicleType.OTHER, label: intl.formatMessage({ id: `vehicleType.other` }), icon: 'RefreshIcon' },
  ];

  export const valuesWithLabel: ListItemConfig<GraphqlVehicleType>[] = valuesWithLabelAndIconList.map(({ value, label }) => ({
    value: value as GraphqlVehicleType,
    label,
  }));

  export function toMMVehicleTypes() {
    return new Map<GraphqlVehicleType, VehicleType>([
      [GraphqlVehicleType.ELECTRIC_MOPED, VehicleType.ElectricMoped],
      [GraphqlVehicleType.ELECTRIC_BIKE, VehicleType.ElectricBike],
      [GraphqlVehicleType.CAR, VehicleType.Car],
      [GraphqlVehicleType.BICYCLE, VehicleType.Bicycle],
      [GraphqlVehicleType.CARGO_BICYCLE, VehicleType.CargoBicycle],
      [GraphqlVehicleType.SCOOTER, VehicleType.Scooter],
      [GraphqlVehicleType.SCOOTER_STANDING, VehicleType.ScooterStanding],
      [GraphqlVehicleType.SCOOTER_SEATED, VehicleType.ScooterSeated],
      [GraphqlVehicleType.OTHER, VehicleType.Other],
    ]);
  }

  export function toMMVehicleType(vehicleType: GraphqlVehicleType) {
    return toMMVehicleTypes().get(vehicleType);
  }
}
