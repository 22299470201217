import { Fragment, useState } from 'react';

import { CustomerBudgetInformationTab, CustomerBudgetInformationTabHelper } from '../../../models';
import { WmvTabs, WmvTabPanel } from '../../../wmv-components';

import { ActiveTabContent } from './ActiveTabContent';
import { BudgetHistoryTable } from './BudgetHistoryTabContent/BudgetHistoryTable';
import { LinkedProfilesTabContent } from './LinkedProfilesTabContent';
import { budgetTabPanelStyles } from './styles';

export function CustomerBudgetInformation() {
  const [activeTab, setActiveTab] = useState<CustomerBudgetInformationTab>(CustomerBudgetInformationTab.ActiveBudget);

  return (
    <Fragment>
      <WmvTabs<CustomerBudgetInformationTab>
        options={CustomerBudgetInformationTabHelper.allLabelAndValues()}
        activeTab={activeTab}
        onChange={handleTabChange}
      >
        <WmvTabPanel<CustomerBudgetInformationTab>
          activeTab={activeTab}
          currentTab={CustomerBudgetInformationTab.ActiveBudget}
          sx={budgetTabPanelStyles}
        >
          <ActiveTabContent />
        </WmvTabPanel>

        <WmvTabPanel<CustomerBudgetInformationTab>
          activeTab={activeTab}
          currentTab={CustomerBudgetInformationTab.LinkedProfiles}
          sx={budgetTabPanelStyles}
        >
          <LinkedProfilesTabContent />
        </WmvTabPanel>

        <WmvTabPanel<CustomerBudgetInformationTab>
          activeTab={activeTab}
          currentTab={CustomerBudgetInformationTab.BudgetHistory}
          sx={budgetTabPanelStyles}
        >
          <BudgetHistoryTable />
        </WmvTabPanel>
      </WmvTabs>
    </Fragment>
  );

  function handleTabChange(tab: CustomerBudgetInformationTab) {
    setActiveTab(tab);
  }
}
