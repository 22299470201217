import React from 'react';

import { Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useIntl } from 'react-intl';

interface CustomButtonProps {
  icon: React.ReactNode;
  label: string;
  isSelected: boolean;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

const CustomZoneControlButton = React.forwardRef<HTMLButtonElement, CustomButtonProps>(({ icon, label, isSelected, onClick }, ref) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  return (
    <Button
      onClick={onClick}
      disableRipple
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        background: 'white',
        border: 0,
        '&:hover': {
          backgroundColor: theme.palette.secondary.tint,
          border: 'none',
          '& .MuiTypography-root': {
            color: theme.palette.dark.main,
          },
        },
        width: '54px',
        height: '58px',
        '&  svg path': {
          fill: `${isSelected ? theme.palette.secondary.dark : theme.palette.dark.shade60} !important`,
        },
      }}
      ref={ref}
    >
      {icon}
      <Typography variant="subtitle2" color={isSelected ? theme.palette.secondary.dark : theme.palette.hexBlack.shade56}>
        {formatMessage({ id: `map.zoneControls.${label}` })}
      </Typography>
    </Button>
  );
});
CustomZoneControlButton.displayName = 'CustomZoneControlButton';
export default CustomZoneControlButton;
