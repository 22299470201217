import { useEffect, useState, useRef } from 'react';

import { Box, Button, Grid, Typography, Checkbox, Dialog } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Field, Form, Formik } from 'formik';
import { useIntl } from 'react-intl';
import type { IntlShape } from 'react-intl';

import { CustomerDetails, DrivingLicense } from '../../../models';
import { DEFAULT_DATE_FORMAT_DAYJS, countryCodesMenuConfig, licenseClasses } from '../../../utils/constants';
import { getCustomerDetails, putCustomerDetails } from '../../../utils/queries';
import CustomTextField from '../../FormFields/CustomTextField';
import FormikErrorMessage from '../../FormFields/ErrorMessage';
import FormikSelectField from '../../FormFields/FormikSelectField';
import { LoadingAndErrorWithRetryAndNoResults } from '../../LoadingAndErrorWithRetryAndNoResults';
import { CustomerLicenceInfoFormValidationSchema } from '../validation-schemas/CustomerLicenceInfoFormValidationSchema';

const placeholderData: DrivingLicense = {
  id: 'AB1234',
  issuedOn: '2000-01-01',
  expiresOn: '2099-12-31',
  countryCode: 'DE',
  placeOfIssuingAuthority: 'Bamberg',
  classes: [],
};

const enum TextFieldType {
  Date = 'date',
  Text = 'text',
  Dropdown = 'dropdown',
}

const getTextField = (
  placeholder: string | Date,
  name: string,
  label: string,
  type: TextFieldType,
  editMode: boolean = false,
  intl: IntlShape,
  options?: { label: string; value: string }[],
) => {
  switch (type) {
    case 'date':
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label htmlFor={name}>
            <Typography variant="bodySmallBold">{label}</Typography>
          </label>
          <Field
            disabled={!editMode}
            type="date"
            name={name}
            format={DEFAULT_DATE_FORMAT_DAYJS}
            as={CustomTextField}
            style={{ maxWidth: '250px' }}
          />
          <FormikErrorMessage name={name} />
        </div>
      );
    case 'text':
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label htmlFor={name}>
            <Typography variant="bodySmallBold">{label}</Typography>
          </label>
          <Field
            disabled={!editMode}
            placeholder={placeholder || 'Enter Value'}
            name={name}
            as={CustomTextField}
            style={{ maxWidth: '250px' }}
            inputProps={{ maxLength: 50 }}
          />
          <FormikErrorMessage name={name} />
        </div>
      );
    case 'dropdown':
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label htmlFor="countryCode">
            <Typography variant="bodySmallBold">{intl.formatMessage({ id: 'userDriverInfo.country' })}</Typography>
          </label>
          <Field name={'countryCode'} as={FormikSelectField} disabled={!editMode} options={options} size="medium" />
          <FormikErrorMessage name={name} />
        </div>
      );
    default:
      return null;
  }
};

interface ModelData {
  open: boolean;
  data: null | {
    title: string;
    message: string;
    buttons: {
      label: string;
      onClick: () => void;
      variant?: string;
      color?: string;
    }[];
  };
}
interface UserDriverInformationProps {
  subjectClaim: string;
}

const UserLicenceInformation = ({ subjectClaim }: UserDriverInformationProps) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(true);
  const latestValues = useRef<DrivingLicense | null>(null);
  const [editMode, setEditMode] = useState(false);
  const [initialValues, setInitialValues] = useState<CustomerDetails | null>(null);
  const [modelData, setModelData] = useState<ModelData>({
    open: false,
    data: null,
  });
  const intl = useIntl();
  const { formatMessage } = intl;

  const formikRef = useRef<any>(null);

  const successModelData = {
    title: formatMessage({ id: 'maas.customer.dl.dialog.successTitle' }),
    message: formatMessage({ id: 'maas.customer.dl.dialog.successText' }),
    buttons: [
      {
        label: formatMessage({ id: 'maas.customer.dl.dialog.ok' }),
        onClick: () => {
          handleModelClose();
        },
        variant: 'contained',
        color: 'primary',
      },
    ],
  };

  const discardModelData = {
    title: formatMessage({ id: 'maas.customer.dl.dialog.discardChangesTitle' }),
    message: formatMessage({ id: 'maas.customer.dl.dialog.discardChangesText' }),
    buttons: [
      {
        label: formatMessage({ id: 'maas.customer.dl.dialog.discardChangesConfirm' }),
        onClick: () => {
          setInitialValues(initialValues);
          setEditMode(false);
          handleModelClose();
          formikRef.current?.resetForm();
        },
        variant: 'contained',
        color: 'alert',
      },
      {
        label: formatMessage({ id: 'maas.customer.dl.dialog.discardChangesCancel' }),
        onClick: () => {
          handleModelClose();
        },
        variant: 'outlined',
        color: 'primary',
      },
    ],
  };

  const handleModelClose = () => {
    setModelData({
      ...modelData,
      open: false,
      data: null,
    });
  };

  const handleCancel = () => {
    setModelData({
      ...modelData,
      open: true,
      data: discardModelData,
    });
  };

  const handleSubmit = async (values: DrivingLicense) => {
    setLoading(true);
    setError(false);
    latestValues.current = values;

    if (initialValues) {
      // Remove unnecessary fields from initialValues
      const { createdAt, updatedAt, subjectClaim, acceptedWikimoveTerms, registrationToken, email, ...filteredInitialValues } =
        initialValues;

      putCustomerDetails({ ...filteredInitialValues, drivingLicense: values }, subjectClaim)
        .then((res: CustomerDetails | undefined | null) => {
          if (res) {
            setModelData({
              ...modelData,
              open: true,
              data: successModelData,
            });
            setInitialValues(res);
            setEditMode(false);
          } else {
            setError(true);
            console.log('Error updating driving license');
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const fetchCustomerData = async () => {
    setLoading(true);
    setError(false);
    try {
      const customerDetails: CustomerDetails = await getCustomerDetails(subjectClaim);
      if (customerDetails) {
        setInitialValues(customerDetails);
      } else {
        setInitialValues(null);
      }
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomerData();
    // eslint-disable-next-line
  }, [subjectClaim]);

  const retrySubmit = () => {
    if (latestValues.current) {
      handleSubmit(latestValues.current);
    }
  };

  return (
    <>
      {loading || error ? (
        <LoadingAndErrorWithRetryAndNoResults
          style={{
            height: '100%',
            backgroundColor: theme.palette.white.main,
          }}
          error={error}
          loading={loading}
          onRetry={editMode ? retrySubmit : fetchCustomerData}
          baseTranslationKey="license"
        />
      ) : (
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              height: '32px',
            }}
          >
            <Typography variant="bodyMediumBold">{formatMessage({ id: 'maas.customer.dl.driverInformation' })}</Typography>
            <Button
              disableElevation
              disabled={editMode}
              variant="outlined"
              size="small"
              onClick={(e) => setEditMode(!editMode)}
              style={{ minWidth: '90px' }}
            >
              <Typography variant="bodySmallBold">{formatMessage({ id: 'common.edit' })}</Typography>
            </Button>
          </Box>
          {initialValues && (
            <Formik
              innerRef={formikRef}
              initialValues={initialValues?.drivingLicense || placeholderData}
              onSubmit={handleSubmit}
              validationSchema={CustomerLicenceInfoFormValidationSchema}
              enableReinitialize
              validateOnMount
            >
              {({ isValid }) => (
                <Form>
                  <Grid container rowSpacing={4} columnSpacing={4}>
                    <Grid item xs={12}>
                      {getTextField('B1234567890', 'id', formatMessage({ id: 'maas.customer.dl.id' }), TextFieldType.Text, editMode, intl)}
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      {getTextField(
                        '01.01.2001',
                        'issuedOn',
                        formatMessage({ id: 'maas.customer.dl.issuedOn' }),
                        TextFieldType.Date,
                        editMode,
                        intl,
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      {getTextField(
                        '01.01.2001',
                        'expiresOn',
                        formatMessage({ id: 'maas.customer.dl.expiresOn' }),
                        TextFieldType.Date,
                        editMode,
                        intl,
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      {getTextField(
                        'DE',
                        'countryCode',
                        formatMessage({ id: 'maas.customer.dl.issuingCountry' }),
                        TextFieldType.Dropdown,
                        editMode,
                        intl,
                        countryCodesMenuConfig,
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      {getTextField(
                        'Bamberg',
                        'placeOfIssuingAuthority',
                        formatMessage({ id: 'maas.customer.dl.issuingAuthority' }),
                        TextFieldType.Text,
                        editMode,
                        intl,
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <Typography variant="bodySmallBold" component={'div'}>
                        {formatMessage({ id: 'maas.customer.dl.classes' })}
                      </Typography>
                      <Typography variant="bodySmall" pb={2}>
                        {formatMessage({ id: 'maas.customer.dl.classesText' })}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={9}>
                      <Grid container spacing={2}>
                        {licenseClasses.map(({ value, label }) => (
                          <Grid item xs={6} lg={4} key={value}>
                            <Box display="flex" alignItems="center">
                              <Field
                                disabled={!editMode}
                                type="checkbox"
                                name="classes"
                                value={value}
                                as={Checkbox}
                                disableRipple
                                color="secondary"
                                style={{ width: '20px', height: '20px' }}
                              />
                              <Typography variant="subtitle2" component="span" mx={1}>
                                {formatMessage(
                                  { id: 'maas.customer.dl.class' },
                                  {
                                    label: <b>{label}</b>,
                                  },
                                )}
                              </Typography>
                            </Box>
                          </Grid>
                        ))}
                        <FormikErrorMessage name="classes" />
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          float: 'right',
                          height: '32px',
                        }}
                      >
                        <Button
                          disableElevation
                          disabled={!editMode}
                          variant="contained"
                          size="small"
                          onClick={handleCancel}
                          sx={{
                            backgroundColor: theme.palette.white.main,
                            color: theme.palette.primary.main,
                          }}
                        >
                          <Typography variant="bodySmallBold">{formatMessage({ id: 'maas.customer.details.cancel' })}</Typography>
                        </Button>
                        <Button
                          disableElevation
                          disabled={!isValid || !editMode}
                          type="submit"
                          size="small"
                          variant="outlined"
                          sx={{
                            marginLeft: '24px',
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.white.main,
                          }}
                        >
                          <Typography variant="bodySmallBold">{formatMessage({ id: 'maas.customer.details.update' })}</Typography>
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          )}
          {modelData.open && modelData.data && (
            <Dialog open={modelData.open} onClose={handleModelClose}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '8px',
                  textAlign: 'center',
                }}
              >
                <Typography variant="bodyMediumBold" mb={2}>
                  {modelData.data.title}
                </Typography>
                <Typography variant="bodySmall" p={1}>
                  {modelData.data.message}
                </Typography>
                <Box display="flex" justifyContent="space-around" m="24px" width="90%">
                  {modelData.data.buttons.map((button, index) => (
                    <Button key={index} size="small" onClick={button.onClick} variant={button.variant as any} color={button.color as any}>
                      <Typography variant="bodySmallBold">{button.label}</Typography>
                    </Button>
                  ))}
                </Box>
              </Box>
            </Dialog>
          )}
        </Box>
      )}
    </>
  );
};

export default UserLicenceInformation;
