import { useEffect, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AppBar, Box, Fade, Paper, Popper, Stack, Toolbar, Typography } from '@mui/material';
import PopupState, { bindHover, bindPopper } from 'material-ui-popup-state';
import { useIntl } from 'react-intl';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import LogoPic from '../../assets/logo/Flow_Logo.svg';
import { themeOptions } from '../../init-setup/ThemeOptions';
import { MenuConfig, useMenuRoutesConfig } from '../../routes';

import {
  appBarStyles,
  navBarMenuContainerStyles,
  openedMenuStyles,
  popperRootSx,
  submenuContainerStyles,
  NavbarBoxButton,
} from './Header.styles';
import Profile from './Profile';

const NavBarMenus = ({ menus }: { menus: MenuConfig[] }) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState('');
  const [activeSubMenu, setActiveSubMenu] = useState('');

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get('highlightActiveRoute') === 'false') {
      return;
    }

    const activeLink = menus.find((menu) => location.pathname.includes(menu.route)) || {
      name: '',
      route: '',
      subMenus: [],
    };

    const subActiveLink = (activeLink.subMenus || []).find((menu) => location.pathname.includes(menu.route)) || {
      name: '',
    };

    setActiveMenu(activeLink.name);
    setActiveSubMenu(subActiveLink.name);
  }, [location, menus]);

  return (
    <>
      {menus.map((menu) => {
        return menu.subMenus ? (
          <PopupState variant="popover" popupId={menu.name} key={menu.name} disableAutoFocus>
            {(popupState) => (
              <>
                <Box
                  display={'flex'}
                  onClick={() => {
                    setActiveMenu(menu.name);
                    popupState.close();
                    if (menu.subMenus && menu.subMenus.length > 0) {
                      navigate(menu.subMenus[0].route);
                    }
                  }}
                >
                  <NavbarBoxButton
                    {...bindHover(popupState)}
                    key={menu.name}
                    className={activeMenu === menu.name ? 'active' : ''}
                    sx={popupState.isOpen ? openedMenuStyles : null}
                  >
                    {formatMessage({ id: menu.name })}
                    <ExpandMoreIcon />
                  </NavbarBoxButton>
                </Box>
                <Popper {...bindPopper(popupState)} placement="bottom-start" transition sx={popperRootSx}>
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                      <Paper
                        sx={{
                          boxShadow: themeOptions.shadowOptions.aboveMedium,
                          borderRadius: '4px',
                          background: themeOptions.palette.white.main,
                        }}
                      >
                        {(menu.subMenus || []).map((subMenu) => {
                          return (
                            <Box
                              sx={submenuContainerStyles}
                              className={activeSubMenu === subMenu.name ? 'active' : ''}
                              key={subMenu.name}
                              onClick={() => {
                                popupState.close();
                                setActiveSubMenu(subMenu.name);
                                navigate(subMenu.route);
                              }}
                            >
                              {formatMessage({ id: subMenu.name })}
                            </Box>
                          );
                        })}
                      </Paper>
                    </Fade>
                  )}
                </Popper>
              </>
            )}
          </PopupState>
        ) : (
          <NavbarBoxButton
            key={menu.name}
            className={activeMenu === menu.name ? 'active' : ''}
            title={formatMessage({ id: menu.name })}
            onClick={() => {
              navigate(menu.route);
              setActiveSubMenu('');
              setActiveMenu(menu.name);
            }}
          >
            {formatMessage({ id: menu.name })}
          </NavbarBoxButton>
        );
      })}
    </>
  );
};

const Header = () => {
  const { formatMessage } = useIntl();
  const menus = useMenuRoutesConfig();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <AppBar sx={appBarStyles} elevation={1}>
        <Toolbar sx={{ py: '0px', height: '100%' }}>
          <NavLink to={menus[0].route} style={{ display: 'flex', flexDirection: 'row' }}>
            <img src={LogoPic} alt="logo" style={{ width: '30px', marginRight: '0.8rem' }} />
            <Stack sx={{ mr: '3rem' }}>
              <Typography variant="bodyMediumBold">{formatMessage({ id: 'common.companyName' })}</Typography>
              <Typography variant="caption">{formatMessage({ id: 'common.productName' })}</Typography>
            </Stack>
          </NavLink>
          <Box sx={navBarMenuContainerStyles}>
            <NavBarMenus menus={menus} />
          </Box>
          <Box sx={{ flexGrow: 0, display: 'flex', flexDirection: 'row' }}>
            <Profile />
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
