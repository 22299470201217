import { useMemo } from 'react';

import { type ColumnDef } from '@tanstack/react-table';
import { useIntl } from 'react-intl';

import { TripActivityLogDetails } from '../../../models';

export const TripAuditLogTableColumnsDefs = () => {
  const { formatMessage } = useIntl();

  return useMemo(
    (): ColumnDef<TripActivityLogDetails>[] => [
      {
        header: formatMessage({ id: 'maas.customer.tripHistory.modifiedAt' }),
        accessorFn: (originalRow: TripActivityLogDetails) => originalRow.timestamp.parseEpochMillis().formatAsDateTimeString(),
      },
      {
        header: formatMessage({ id: 'maas.customer.tripHistory.modifiedBy' }),
        accessorFn: (originalRow: TripActivityLogDetails) => originalRow.updatedBy.name,
      },
      {
        header: formatMessage({ id: 'maas.customer.tripHistory.change' }),
        accessorFn: (originalRow: TripActivityLogDetails) =>
          formatMessage({ id: `account.tripHistory.activity.${originalRow.event.toLowerCase()}` }),
      },
    ],
    [formatMessage],
  );
};
