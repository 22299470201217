import type { Theme } from '@mui/material/styles';

export const controlButtonStyles = (theme: Theme, isSelected: boolean, disabled: boolean) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  textTransform: 'capitalize',
  border: 'none !important',

  // icon and text styles
  '&  svg path': {
    fill: `${
      isSelected ? theme.palette.secondary.main : disabled ? theme.palette.baseLight.base32 : theme.palette.hexBlack.shade0
    } !important`,
  },
  '& span': {
    color: isSelected ? theme.palette.secondary.main : disabled ? theme.palette.baseLight.base32 : theme.palette.baseLight.base60,
  },

  '&:hover': {
    backgroundColor: theme.palette.secondary.tint,
    border: 'none',
  },
  width: '60px',
  maxWidth: '100px',
  minWidth: '60px',
  height: '60px',
  ...theme.utils.ellipsisText,
});

export const titleStyles = (theme: Theme) => ({
  ...theme.utils.ellipsisText,
});
