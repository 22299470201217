import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import type { SxProps } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useIntl } from 'react-intl';

import { LastChangeAttemptStatus, LastChangeAttemptStatusHelper } from '../../../../../models';
import { BudgetPlanMembershipDto } from '../../../../../services/budget-plan-memberships';
import { dotIndicatorStyles } from '../styles';

export const MembershipLastStatusUpdate = ({ membership, sx }: MembershipStatusProps) => {
  const theme = useTheme();
  const { formatMessage: fm } = useIntl();
  const [statusDisplayText, setStatusDisplayText] = useState('');
  const [dotIndicatorColor, setDotIndicatorColor] = useState('');

  useEffect(() => {
    let metadata = LastChangeAttemptStatusHelper.metadata;
    if (membership.isActive()) {
      setStatusDisplayText(fm({ id: 'common.Active' }));
      setDotIndicatorColor(metadata(LastChangeAttemptStatus.Success).color);
    }
    if (membership.isInactive()) {
      setStatusDisplayText(fm({ id: 'common.status.unlinked' }));
      setDotIndicatorColor(metadata(LastChangeAttemptStatus.Success).color);
    }
    if (membership.isLinkingInProgress()) {
      setStatusDisplayText(fm({ id: 'common.linkingInProcess' }));
      setDotIndicatorColor(metadata(LastChangeAttemptStatus.InProgress).color);
    }
    if (membership.isUnlinkingInProgress()) {
      setStatusDisplayText(fm({ id: 'common.unlinkingInProcess' }));
      setDotIndicatorColor(metadata(LastChangeAttemptStatus.InProgress).color);
    }
    if (membership.isLinkingFailed()) {
      setStatusDisplayText(fm({ id: 'common.linkingFailed' }));
      setDotIndicatorColor(metadata(LastChangeAttemptStatus.Failed).color);
    }
    if (membership.isUnlinkingFailed()) {
      setStatusDisplayText(fm({ id: 'common.unlinkingFailed' }));
      setDotIndicatorColor(metadata(LastChangeAttemptStatus.Failed).color);
    }
  }, [membership, fm]);

  return (
    <Box display="flex" flexDirection="row" alignItems="center" sx={sx}>
      <Box sx={dotIndicatorStyles(theme, dotIndicatorColor)} />
      <Typography
        component="span"
        ml={1}
        color={
          membership.isLastAttemptedStateTransitionFailed()
            ? LastChangeAttemptStatusHelper.metadata(LastChangeAttemptStatus.Failed).color
            : 'inherit'
        }
      >
        {statusDisplayText}
      </Typography>
    </Box>
  );
};

interface MembershipStatusProps {
  membership: BudgetPlanMembershipDto;
  sx?: SxProps;
}
