import type { Theme } from '@mui/material/styles';

const CIRCLE_BASE_WIDTH = 100;
const DOT_BASE_WIDTH = 8;

export const circularLoaderStyles = (theme: Theme, sizeMultiplier: number) => ({
  position: 'relative',
  width: CIRCLE_BASE_WIDTH * sizeMultiplier,
  height: CIRCLE_BASE_WIDTH * sizeMultiplier,
});

export const dotStyles = (theme: Theme, sizeMultiplier: number) => ({
  position: 'absolute',
  width: DOT_BASE_WIDTH * sizeMultiplier,
  height: DOT_BASE_WIDTH * sizeMultiplier,
  backgroundColor: theme.palette.hexBlack.shade80,
  borderRadius: '50%',
  animation: `changeColor 1.6s infinite linear`,
  '@keyframes changeColor': {
    '0%': {
      backgroundColor: theme.palette.hexBlack.shade20,
    },
    '12.5%': {
      backgroundColor: theme.palette.hexBlack.shade80,
    },
    '100%': {
      backgroundColor: theme.palette.hexBlack.shade80,
    },
  },
});
