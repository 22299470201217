import { EnumWithStringValue } from '../../utils/EnumUtils';
import { intl } from '../../utils/intl';

export enum InfrastructureType {
  MobilityStation = 'MOBILITY_STATION',
  // ChargingStation = 'CHARGING_STATION', // Not getting used in App
}

export namespace InfrastructureTypeHelper {
  export function allTypes() {
    return EnumWithStringValue.enumToValues(InfrastructureType);
  }

  export const valuesWithLabel = Object.entries(InfrastructureType).map(([key, value]) => ({
    value: value,
    label: intl.formatMessage({ id: `filter.infrastructure.mobilityStation` }),
  }));
}
