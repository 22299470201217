import * as Yup from 'yup';

import { intl } from '../../../utils/intl';
import {
  validateAddressExtension,
  validateCity,
  validateDateOfBirthDate,
  validateHouseNumber,
  validateMobileNumber,
  validateName,
  validatePostalCode,
  validateStreetName,
} from '../../../utils/ValidationSchemas';

export const customerPersonalInfoFormValidationSchema = Yup.object().shape({
  firstName: validateName(intl),
  lastName: validateName(intl),
  birthDate: validateDateOfBirthDate(intl),
  phoneNumberWithoutCountryCode: validateMobileNumber(intl),
  street: validateStreetName(intl),
  houseNumber: validateHouseNumber(intl),
  extension: validateAddressExtension(intl),
  postalCode: validatePostalCode(intl),
  city: validateCity(intl),
});
