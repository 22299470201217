import { ListItemConfig } from '../models';

import { EnumWithStringValue } from './EnumUtils';
import { intl } from './intl';

interface TabMetadata {
  displayText: string;
}

// Constraint to ensure T is an enum
type EnumType = { [key: string]: string };

export class EnumTabHelper<T extends EnumType> {
  private metaData: Map<T[keyof T], TabMetadata>;
  private enumType: T;

  /**
   * Constructs an instance of EnumTabHelper.
   * @param enumType - The enum type to work with.
   * @param translations - An object mapping enum keys to translation IDs.
   */
  constructor(enumType: T, translations: { [key in keyof T]: string }) {
    this.enumType = enumType;
    this.metaData = new Map(
      Object.entries(translations).map(([key, value]) => [
        enumType[key as keyof T] as unknown as T[keyof T],
        { displayText: intl.formatMessage({ id: value }) },
      ]),
    );
  }

  /**
   * Gets the display text for a given enum value.
   * @param tab - The enum value.
   * @returns The display text or undefined if not found.
   */
  displayText(tab: T[keyof T]): string | undefined {
    return this.metaData.get(tab)?.displayText;
  }

  /**
   * Gets all enum values.
   * @returns An array of all enum values.
   */
  allValues(): T[keyof T][] {
    return EnumWithStringValue.enumToValues(this.enumType) as T[keyof T][];
  }

  /**
   * Gets an array of objects with value and label (display text) for each enum value.
   * @returns An array of ListItemConfig objects containing value and label.
   */
  allLabelAndValues(): ListItemConfig<T[keyof T]>[] {
    return this.allValues().map((tab) => ({
      value: tab,
      label: this.displayText(tab)!,
    }));
  }
}
