export enum OnboardingStatuses {
  AdapterRegistrationRequested = 'ADAPTER_REGISTRATION_REQUESTED',
  AdapterRegistrationSuccessful = 'ADAPTER_REGISTRATION_SUCCESSFUL',
  AdapterRegistrationFailed = 'ADAPTER_REGISTRATION_FAILED',
  OnboardingConfirmationRequested = 'ONBOARDING_CONFIRMATION_REQUESTED',
  AdapterReadyConfirmed = 'ADAPTER_READY_CONFIRMED',
  AdapterConfirmationFailed = 'ADAPTER_CONFIRMATION_FAILED',
  RideServiceReadyConfirmed = 'RIDE_SERVICE_READY_CONFIRMED',
  RideServiceConfirmationFailed = 'RIDE_SERVICE_CONFIRMATION_FAILED',
  FullyOnboarded = 'FULLY_ONBOARDED',
}

export enum LastStatusTransitionAttemptStatus {
  InProgress = 'IN_PROGRESS',
  Success = 'SUCCESS',
  Failed = 'FAILED',
  Cancelled = 'CANCELLED',
}

export enum LastStatusTransitionCancellationAttemptStatus {
  NotAttempted = 'NOT_ATTEMPTED',
  InProgress = 'IN_PROGRESS',
  Success = 'SUCCESS',
  Failed = 'FAILED',
}
