import { createSelector } from '@reduxjs/toolkit';
import type { Dictionary } from 'lodash';
import keyBy from 'lodash/keyBy';

import { Zone } from '../../services/zone';
import { RootState } from '../reducers';

import { ZoneServerBadRequestViolationFieldPath } from './models';

const zonesStateSelector = (state: RootState) => state.zones;

export const zonesApiRequestStatusSelector = (state: RootState) => state.zones.apiRequestStatus;

export const zoneEntitiesSelector = createSelector(zonesStateSelector, (zonesState) => zonesState.entities);
export const allZonesSelector = createSelector(zoneEntitiesSelector, (zoneEntities) => {
  if (!zoneEntities.allZones.length) {
    return [];
  }
  return zoneEntities.allZones.map((zone) => new Zone(zone));
});

export const publishedZonesSelector = createSelector(zoneEntitiesSelector, (zoneEntities) => {
  if (!zoneEntities.publishedZones.length) {
    return [];
  }
  return zoneEntities.publishedZones.map((zone) => new Zone(zone));
});

export const deleteZoneApiStatusSelector = (state: RootState) => state.zones.apiRequestStatus.deleteZone;

export const deleteZoneErrorSelector = (state: RootState) => state.zones.error.deleteZone;
export const createZoneErrorSelector = (state: RootState) => state.zones.error.createZone;
export const updateZoneErrorSelector = (state: RootState) => state.zones.error.updateZone;
export const saveZoneErrorSelector = (state: RootState) => createZoneErrorSelector(state) || updateZoneErrorSelector(state);

export const updateZoneServerViolationsFieldToErrorSelector = (
  state: RootState,
): Dictionary<{ field: ZoneServerBadRequestViolationFieldPath; message: string }> => {
  const violations = state.zones.error.updateZone?.violations;
  if (violations) {
    return keyBy(violations, 'field');
  }
  return {};
};

export const createZoneServerViolationsFieldToErrorSelector = (
  state: RootState,
): Dictionary<{ field: ZoneServerBadRequestViolationFieldPath; message: string }> => {
  const violations = state.zones.error.createZone?.violations;
  if (violations) {
    return keyBy(violations, 'field');
  }
  return {};
};
