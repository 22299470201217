import { useState } from 'react';

import { Grid, TableCell, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import PopupState, { bindHover, bindPopover } from 'material-ui-popup-state';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import { useIntl } from 'react-intl';

import { themeOptions as theme } from '../init-setup/ThemeOptions';
import { FilterProfileType, TableColumn, TableColumnType } from '../models';
import CustomIcon from '../utils/CustomIcon';

import { DefaultColumnFilter, MultipleChoiceFilter } from './TableFilters';

interface TableHeaderCellProps {
  column: TableColumn; //This verbose type will be removed with the implementation of the new table
  columns: TableColumnType[];
  index: number;
  getPreFilter: (columnId: string) => string;
  filterChangeDependency: FilterProfileType[];
}

const getFilterValue = (column: TableColumn) => {
  const filterValue = column.filterValue;
  if (!filterValue || filterValue.length < 1) {
    return 'NA';
  }
  if (typeof filterValue === 'string') {
    return filterValue;
  }
  return filterValue.join(',');
};

const TableHeaderCell = ({ columns, column, index, getPreFilter, filterChangeDependency }: TableHeaderCellProps) => {
  const [infoHover, setInfoHover] = useState(false);
  const { formatMessage } = useIntl();

  return (
    <TableCell
      key={index}
      sx={{
        padding: '4px',
        width: column.width || `calc(100vw / ${columns.length})`,
      }}
      onMouseEnter={() => setInfoHover(true)}
      onMouseLeave={() => setInfoHover(false)}
    >
      <Grid
        container
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        wrap={'nowrap'}
        sx={{ height: 'auto', minHeight: '44px', overflow: 'hidden', userSelect: 'none' }}
        {...column.getHeaderProps(column.getSortByToggleProps())}
      >
        <Grid container direction={'row'} justifyContent={'flex-start'}>
          <span style={{ marginRight: 8, marginLeft: 12, height: 20 }}>
            {column.isSortedDesc === undefined ? (
              <>{CustomIcon('DeselectedIcon', theme.palette.dark.shade32)}</>
            ) : column.isSortedDesc === true ? (
              <>{CustomIcon('DescendingIcon', theme.palette.dark.main)}</>
            ) : (
              <>{CustomIcon('AscendingIcon', theme.palette.dark.main)}</>
            )}
          </span>
          {/* Add Header text */}
          <Typography variant="subtitle2">{column.render('Header')}</Typography>
        </Grid>
        {infoHover && (
          <PopupState variant="popover" popupId="info-popover">
            {(popupState) => (
              <>
                <Box
                  component="span"
                  sx={{ display: 'flex' }}
                  aria-owns="mouse-over-popover"
                  aria-haspopup="true"
                  {...bindHover(popupState)}
                >
                  {CustomIcon('Info', theme.palette.dark.shade76, '20px')}
                </Box>
                <HoverPopover
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                  {...bindPopover(popupState)}
                >
                  <Grid container direction={'column'} sx={{ backgroundColor: theme.palette.primary.tint, padding: '8px', gap: '8px' }}>
                    <Typography variant="captionBold">{formatMessage({ id: 'tableHeaderCell.column.properties' })}</Typography>
                    <Grid container>
                      <Typography variant="caption" color={theme.palette.dark.shade76} sx={{ marginRight: '53px' }}>
                        {formatMessage({ id: 'tableHeaderCell.name' })}
                      </Typography>
                      <Typography variant="caption" color={theme.palette.dark.main}>
                        {column.render('Header')}
                      </Typography>
                    </Grid>
                    <Grid container>
                      <Typography variant="caption" color={theme.palette.dark.shade76} sx={{ marginRight: '30px' }}>
                        {formatMessage({ id: 'tableHeaderCell.condition' })}
                      </Typography>
                      <Typography variant="caption" color={theme.palette.dark.main}>
                        {`${formatMessage({ id: 'tableHeaderCell.condition' })} "${getFilterValue(column)}"`}
                      </Typography>
                    </Grid>
                  </Grid>
                </HoverPopover>
              </>
            )}
          </PopupState>
        )}
      </Grid>
      {/* Render the columns filter UI */}
      <div>
        {/* TODO: show filter options after loading finishes */}
        {column.canFilter ? (
          column.filter === 'select' ? (
            <MultipleChoiceFilter column={column} getPreFilter={getPreFilter} filterChangeDependency={filterChangeDependency} />
          ) : (
            <DefaultColumnFilter column={column} getPreFilter={getPreFilter} filterChangeDependency={filterChangeDependency} />
          )
        ) : null}
      </div>
    </TableCell>
  );
};

export default TableHeaderCell;
