import { LoadingStatus } from '../../models';

export class ApiStatusService {
  private static instance: ApiStatusService;
  public value: LoadingStatus;

  private constructor(status: LoadingStatus) {
    this.value = status;
  }

  public static getInstance(status: LoadingStatus): ApiStatusService {
    ApiStatusService.instance = new ApiStatusService(status);

    return ApiStatusService.instance;
  }

  public isIdle(): boolean {
    return this.value === LoadingStatus.Idle;
  }

  public isPending(): boolean {
    return this.value === LoadingStatus.Pending;
  }

  public isSucceeded(): boolean {
    return this.value === LoadingStatus.Succeeded;
  }

  public isFailed(): boolean {
    return this.value === LoadingStatus.Failed;
  }
}
