import type { JSX } from 'react';
import { MouseEvent } from 'react';

import { Box, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useIntl } from 'react-intl';

import { LastChangeAttemptStatusInfo } from '../../../../components/LastChangeAttemptStatusInfo';
import { VehicleTypeHelper, LastChangeAttemptStatus } from '../../../../models';
import { BudgetPlanDto } from '../../../../services/budget-plan';
import { CustomIconComponent } from '../../../../utils/CustomIcon';
import { statusContainerStyles } from '../styles';

export const tableHeadingTranslationKey = 'operator.budgetPlan.table.heading';

interface RowData {
  row: {
    original: BudgetPlanDto;
  };
}

interface BudgetPlansTableColumnMetadata {
  header: string;
  cell: (row: RowData) => JSX.Element | string;
}

type StatusChangeClickToTableColumnsFunction = (
  onStatusChangeCellClick: (budgetPlanId: BudgetPlanDto) => void,
  onAddUserButtonClick: (budgetPlanId: BudgetPlanDto) => void,
) => BudgetPlansTableColumnMetadata[];

export const useMakeBudgetPlanDetailsTableColumns: StatusChangeClickToTableColumnsFunction = (
  onStatusChangeCellClick,
  onAddUserButtonClick,
) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const handleFailedStatusCellClick = (e: MouseEvent<HTMLButtonElement>, budgetPlan: BudgetPlanDto) => {
    e.stopPropagation();
    onStatusChangeCellClick(budgetPlan);
  };

  return [
    {
      header: formatMessage({ id: `common.name` }),
      accessorKey: 'activeState.name',
      cell: ({ row: { original: budgetPlan } }: RowData) => (
        <Typography variant="bodyMedium" color={theme.palette.baseLight.base76} px={0.5}>
          {budgetPlan.activeState.name}
        </Typography>
      ),
    },
    {
      header: formatMessage({ id: `${tableHeadingTranslationKey}.description` }),
      accessorKey: 'activeState.depositAmount',
      cell: ({ row: { original: budgetPlan } }: RowData) => (
        <Typography variant="bodyMedium" title={budgetPlan.activeState.depositFrequencyShortAndLongFormDisplayText().longForm} px={0.5}>
          {budgetPlan.activeState.depositFrequencyShortAndLongFormDisplayText().shortFormNoAmount}
        </Typography>
      ),
    },
    {
      header: formatMessage({ id: `${tableHeadingTranslationKey}.depositAmount` }),
      accessorKey: 'activeState.depositAmount',
      cell: ({ row: { original: budgetPlan } }: RowData) => {
        const { depositAmount } = budgetPlan.activeState;
        return (
          <Typography variant="bodyMedium" color={theme.palette.baseLight.base76} px={0.5}>
            {depositAmount || `${formatMessage({ id: 'common.na' })}`}
          </Typography>
        );
      },
    },
    {
      header: formatMessage({ id: `${tableHeadingTranslationKey}.tripCap` }),
      accessorKey: 'activeState.amountPerTripConstraint',
      cell: ({ row: { original: budgetPlan } }: RowData) => {
        const { amountPerTripConstraint } = budgetPlan.activeState;
        return (
          <Typography variant="bodyMedium" color={theme.palette.baseLight.base76} px={0.5}>
            {amountPerTripConstraint || `${formatMessage({ id: 'common.na' })}`}
          </Typography>
        );
      },
    },
    {
      header: formatMessage({ id: `common.vehicleTypes` }),
      accessorKey: 'activeState.vehicleTypesConstraint.length',
      cell: ({ row: { original: budgetPlan } }: RowData) => {
        const { vehicleTypesConstraint } = budgetPlan.activeState;
        return (
          <Typography variant="bodyMedium" color={theme.palette.baseLight.base76} px={0.5}>
            {vehicleTypesConstraint
              ? vehicleTypesConstraint.map((vt) => VehicleTypeHelper.metadata(vt).displayText).join(', ')
              : formatMessage({ id: 'common.noRuleSet' })}
          </Typography>
        );
      },
    },
    {
      header: formatMessage({ id: `common.users` }),
      accessorKey: 'activeState.membershipCount.total',
      cell: ({ row: { original: budgetPlan } }: RowData) => {
        const { total } = budgetPlan.activeState.membershipCount;
        return (
          <Box display="flex" gap={2} alignItems="center" justifyContent="flex-end">
            <Typography variant="bodyMedium" color={theme.palette.baseLight.base76} px={0.5}>
              {total}
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={(e: MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                onAddUserButtonClick(budgetPlan);
              }}
              sx={{
                p: 1,
                minWidth: '120px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              <CustomIconComponent icon="AddUserIcon" size="small" />
              <Typography variant="bodySmallBold" color={theme.palette.baseLight.base76} px={0.5}>
                {formatMessage({ id: 'operator.budgetPlan.table.cell.addUser' })}
              </Typography>
            </Button>
          </Box>
        );
      },
    },
    {
      header: formatMessage({ id: `common.lastStatusUpdate` }),
      accessorKey: 'lastChangeAttempt.status',
      cell: ({ row: { original: budgetPlan } }: RowData) => {
        const {
          lastChangeAttempt: { status: lastChangeAttemptStatus },
        } = budgetPlan;
        const lastChangeAttemptStatusFailed = lastChangeAttemptStatus === LastChangeAttemptStatus.Failed;
        const content = <LastChangeAttemptStatusInfo status={lastChangeAttemptStatus} />;
        return (
          <Box px={0.5}>
            {lastChangeAttemptStatusFailed ? (
              <Button sx={{ ...statusContainerStyles(theme), p: 0 }} onClick={(e) => handleFailedStatusCellClick(e, budgetPlan)}>
                {content}
              </Button>
            ) : (
              <Box sx={{ ...statusContainerStyles(theme) }}>{content}</Box>
            )}
          </Box>
        );
      },
    },
  ];
};
