import type { Theme } from '@mui/material/styles';

export const menuItemStyles = (theme: Theme) => ({
  '&:hover': {
    backgroundColor: theme.palette.secondary.tint,
  },
});

export const modeTextStyles = (theme: Theme) => ({
  marginLeft: theme.spacing(1),
  color: theme.palette.baseLight.base60,
});
