import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { useTheme } from '@mui/material/styles';

import { budgetBarStyles, headingStyles, remainingBarHelperStyles, dotStyles } from './styles';

export function BudgetUsageLinearProgressbar() {
  const { palette } = useTheme();
  return (
    <Box mt={3}>
      <Box sx={headingStyles}>
        <Typography variant="bodyMediumBold">{'common.usage'.i18nText()}</Typography>
        <Typography variant="bodyMedium" color={palette.baseLight.base60}>
          {'common.usedMinutes'.i18nText({
            used: 400,
            total: 600,
          })}
        </Typography>
      </Box>
      <Box>
        <LinearProgress variant="determinate" value={50} sx={budgetBarStyles} />
        <Box sx={remainingBarHelperStyles}>
          <Box sx={dotStyles}></Box>
          <Typography variant="bodyMedium" color={palette.blue.main}>
            {'common.remainingMinutes'.i18nText({
              remainingMinutes: 200,
              // @ts-ignore
              bold: (str) => <b>{str}</b>,
            })}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
