import { DayOfWeekHelper } from '../../models';

import { SliderGranularity } from './models';

export class SliderPointFormatter {
  granularity: SliderGranularity;
  intl: any;

  constructor(granularity: SliderGranularity, intl: any) {
    this.granularity = granularity;
    this.intl = intl;
  }

  formatTimestamp(timestampInEpochMillis: number, includeNewLines: boolean): string {
    if (!timestampInEpochMillis) return '';

    const timestamp = timestampInEpochMillis.parseEpochMillis();
    const dayOfWeekAsStr = DayOfWeekHelper.metadata(timestamp.dayOfWeek()).displayText;
    const formattedTime = timestamp.formatAsTimeWithoutSeconds();
    const formattedDate = timestamp.formatAsDateString();

    switch (this.granularity) {
      case SliderGranularity.AGGREGATED:
        // TODO: w/1519 i18n
        return this.intl.formatMessage({ id: 'planningPreview.zonesDataTable.slider' });

      case SliderGranularity.DAY:
        return !includeNewLines ? `${dayOfWeekAsStr} ${formattedDate}` : `${dayOfWeekAsStr}\n${formattedDate}`;

      case SliderGranularity.HOUR:
        return !includeNewLines
          ? `${dayOfWeekAsStr}, ${formattedDate} ${formattedTime}`
          : `${dayOfWeekAsStr}\n${formattedDate}\n${formattedTime}`;

      default:
        return `${dayOfWeekAsStr},${formattedDate} ${formattedTime}`;
    }
  }
}
