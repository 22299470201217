import { MouseEventHandler } from 'react';

import isEmpty from 'lodash/isEmpty';

import { TripDetailsResponse } from '../../models';

import { useFetchTripDetails } from './hooks/useFetchTripDetails';
import TripView from './TripView';

interface TripDetailsAndManagementProps {
  tripId: number;
  onTripUpdate?: (trip: TripDetailsResponse) => void;
  includeForceStop?: boolean;
  disableCloseBtn?: boolean;
  disableNextBtn?: boolean;
  onCloseTripBtnClick: MouseEventHandler<HTMLButtonElement>;
  onNextTripBtnClick: MouseEventHandler<HTMLButtonElement>;
}

export function TripDetailsAndManagement({
  tripId,
  onTripUpdate,
  includeForceStop = true,
  disableCloseBtn,
  disableNextBtn,
  onCloseTripBtnClick,
  onNextTripBtnClick,
}: TripDetailsAndManagementProps) {
  const {
    tripDetailsData: tripDetails,
    loading: tripDetailsLoading,
    error: tripDetailsError,
    fetchTripDetails,
  } = useFetchTripDetails(tripId);

  return (
    <TripView
      tripDetails={tripDetails!}
      tripDetailsLoading={tripDetailsLoading}
      tripDetailsError={tripDetailsError}
      emptyTripDetails={isEmpty(tripDetails) && !tripDetailsLoading && !tripDetailsError}
      includeForceStop={includeForceStop}
      onTripUpdate={onTripUpdate}
      fetchTripDetails={fetchTripDetails}
      onCloseTripBtnClick={onCloseTripBtnClick}
      onNextTripBtnClick={onNextTripBtnClick}
      disableCloseBtn={disableCloseBtn}
      disableNextBtn={disableNextBtn}
    />
  );
}
