import React, { useCallback, useEffect, useRef, useState } from 'react';

import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { Box, Typography } from '@mui/material';
import Slider from '@mui/material/Slider';
import { useTheme } from '@mui/material/styles';
import type { Instance } from '@popperjs/core';
import { useIntl } from 'react-intl';

import { ModeType } from '../../../models';
import { PreviewPredicates } from '../../../models';
import { SliderGranularity, SliderPointsWithGranularity } from '../../../services/zone';
import { DateRangeAndDayOfWeekPicker, TimeWindowPicker } from '../../../wmv-components';

import ModeSelectionPopOver from './ModeSelectionPopover';
import { nonWideIntervalPreviewTooltipStyles, sliderRailStyles, sliderStyles } from './PalnningModePreviewPredicatesView.styles';
import { defaultModeTypes } from './PlanningModePreviewPredicatesView.helpers';
import { useFormattedSliderDisplayText, useInputChangeHandlers } from './PlanningModePreviewPredicatesView.hooks';
import SliderDateLabelTooltipWideInterval from './SliderDateLabelTooltip';

interface PlanningModePreviewPredicatesProps {
  previewPredicates: PreviewPredicates;
  onChange: (value: PreviewPredicates) => void;
  onSliderChange: (newSliderIndex: number) => void;
  sliderPointsWithGranularity: SliderPointsWithGranularity;
  activeSliderPointIndex: number;
}

export const PlanningModePreviewPredicatesView: React.FC<PlanningModePreviewPredicatesProps> = ({
  previewPredicates,
  onChange,
  sliderPointsWithGranularity,
  onSliderChange,
  activeSliderPointIndex,
}) => {
  const intl = useIntl();
  const { granularity, pointsAsEpochMillis: sliderPointsAsEpochMillis } = sliderPointsWithGranularity;
  const wideIntervalMode = previewPredicates.modeType === ModeType.WideInterval;
  const noSliderPoints = sliderPointsAsEpochMillis.length === 0;

  const theme = useTheme();
  const [showNonWideIntervalTooltip, setShowNonWideIntervalTooltip] = useState(false);

  const cursorPositionRef = useRef<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const popperRef = useRef<Instance | null>(null);
  const sliderAreaRef = useRef<HTMLDivElement | null>(null);

  const handleMouseMove = useCallback((event: Event) => {
    const e = event as MouseEvent;
    cursorPositionRef.current = { x: e.clientX, y: e.clientY };
    if (popperRef.current !== null) {
      popperRef.current.update();
    }
  }, []);

  useEffect(() => {
    const track = document.querySelector('.MuiSlider-root');
    if (granularity === SliderGranularity.AGGREGATED) {
      track!.addEventListener('mousemove', handleMouseMove);
    }
    return () => {
      track!.removeEventListener('mousemove', handleMouseMove);
    };
  }, [granularity, handleMouseMove]);

  const { formattedTextWithoutNewLine, formattedTextWithNewLine } = useFormattedSliderDisplayText(
    activeSliderPointIndex,
    granularity,
    sliderPointsAsEpochMillis,
    intl,
  );
  const { handleModeChange, handleTimeChange, handleDateRangeAndDayOfWeekPickerChange } = useInputChangeHandlers(
    previewPredicates,
    onChange,
  );

  const handleSliderChange = (event: Event, sliderActiveIndex: number | number[]) => {
    onSliderChange(sliderActiveIndex as number);
  };

  const nonWideIntervalPreviewTooltip = () => {
    return (
      <Typography color={theme.palette.white.main} sx={nonWideIntervalPreviewTooltipStyles}>
        {formattedTextWithNewLine}
      </Typography>
    );
  };

  const rulePreviewSlider = () => {
    const valueLabelVisibility = !wideIntervalMode ? (showNonWideIntervalTooltip ? 'on' : 'off') : 'off';
    const valueLabelFormat = formattedTextWithNewLine ? nonWideIntervalPreviewTooltip : '';
    const slotProps = {
      rail: { style: sliderRailStyles(theme) },
      thumb: {
        onMouseOver: () => setShowNonWideIntervalTooltip(true),
      },
    };

    return (
      <Slider
        ref={sliderAreaRef}
        value={activeSliderPointIndex}
        valueLabelDisplay={valueLabelVisibility}
        max={sliderPointsAsEpochMillis.length - 1}
        slotProps={slotProps}
        valueLabelFormat={valueLabelFormat}
        onChange={handleSliderChange}
        track={false}
        disabled={wideIntervalMode}
        sx={sliderStyles}
      />
    );
  };

  return (
    <Box display="flex" gap={2} alignItems="center" px={2} pt={1}>
      <ModeSelectionPopOver options={defaultModeTypes} onModeChange={handleModeChange} mode={previewPredicates.modeType} />
      <TimeWindowPicker onChange={handleTimeChange} initialValue={previewPredicates.timeRange} />
      <DateRangeAndDayOfWeekPicker
        onChange={handleDateRangeAndDayOfWeekPickerChange}
        initialValue={previewPredicates.dateRangeAndDaysOfWeek}
      />
      {/* Move this to its owm component  */}
      <Box display="flex" alignItems="center" gap={4} flex={1}>
        {wideIntervalMode ? (
          <SliderDateLabelTooltipWideInterval
            text={formattedTextWithNewLine}
            popperRef={popperRef}
            areaRef={sliderAreaRef}
            positionRef={cursorPositionRef}
            withVirtualElement
          >
            {rulePreviewSlider()}
          </SliderDateLabelTooltipWideInterval>
        ) : (
          <ClickAwayListener onClickAway={() => setShowNonWideIntervalTooltip(false)}>{rulePreviewSlider()}</ClickAwayListener>
        )}

        <Box alignSelf="baseline" width={noSliderPoints ? 'auto' : '250px'}>
          {!wideIntervalMode && (
            <Typography variant="bodySmall" flex={1} sx={{ ...theme.utils.ellipsisText, wordBreak: 'break-word' }}>
              {formattedTextWithoutNewLine}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};
