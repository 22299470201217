import { useMemo } from 'react';

import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';

import NewDataTable from '../../../design-system/NewDataTable';
import { TripPaymentDetails } from '../../../models';
import { FormattedTripPaymentDetails, TripPricingBreakdownService } from '../../../services/trip/TripPricingBreakdownService';
import { PaymentTransactionTableColumnDefs } from '../table-column-defs/PaymentTransactionTableColumnDefs';

interface TripPaymentTransactionsProps {
  payment: TripPaymentDetails;
}

export function TripPaymentTransactions({ payment }: TripPaymentTransactionsProps) {
  const { tripTransactionPaymentDetails: tripPaymentTransactions } = useMemo(() => new TripPricingBreakdownService(payment!), [payment]);

  const MemoizedPaymentTransactionTableColumns = PaymentTransactionTableColumnDefs();
  return (
    <Stack gap={2}>
      <Typography variant="bodyLargeBold">{'common.transactions'.i18nText()}</Typography>
      <NewDataTable<FormattedTripPaymentDetails, 'default'>
        columns={MemoizedPaymentTransactionTableColumns}
        data={tripPaymentTransactions}
        enableSorting={false}
        tableContainerSx={{ zIndex: 1 }}
      />
    </Stack>
  );
}
