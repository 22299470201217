import { themeOptions } from '../../init-setup/ThemeOptions';
import { EnumWithStringValue } from '../../utils/EnumUtils';
import { intl } from '../../utils/intl';

export enum UsageStatus {
  OnTrip = 'OnTrip',
  Available = 'Available',
  NonOperational = 'NonOperational',
}

interface UsageStatusUnderivedData {
  color: string;
  displayText: string;
}

interface UsageStatusMetaData extends UsageStatusUnderivedData {}

export namespace UsageStatusHelper {
  const usageStatusToUnderivedData = new Map<UsageStatus, UsageStatusUnderivedData>([
    [
      UsageStatus.OnTrip,
      {
        color: themeOptions.palette.alert.main,
        displayText: intl.formatMessage({ id: 'map.vehicleInfo.status.onTrip' }),
      },
    ],
    [
      UsageStatus.Available,
      { color: themeOptions.palette.success.main, displayText: intl.formatMessage({ id: `map.vehicleInfo.status.available` }) },
    ],
    [
      UsageStatus.NonOperational,
      { color: themeOptions.palette.warning.main, displayText: intl.formatMessage({ id: `map.vehicleInfo.status.non_operational` }) },
    ],
  ]);

  export function metadata(usageStatus: UsageStatus): UsageStatusMetaData {
    const underivedMetadata = usageStatusToUnderivedData.get(usageStatus);
    if (!underivedMetadata) {
      throw Error(`
            Specified UsageStatus type: ${toString(usageStatus)} doesnt have corresponding underived/explicitly defined metadata.
            This usually (not always) means a bug or incomplete implementation.
        `);
    }

    return { ...underivedMetadata };
  }

  export function toString(modeType: UsageStatus): string | null {
    return EnumWithStringValue.getEnumKeyByEnumValue(UsageStatus, modeType);
  }
}
