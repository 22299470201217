import { EnumWithStringValue } from '../../utils/EnumUtils';
import { intl } from '../../utils/intl';
import { ListItemConfig } from '../common';

export enum LookupDurationWindow {
  SixHours = 'SIX_HOURS',
  TwelveHours = 'TWELVE_HOURS',
  TwentyFourHours = 'TWENTY_FOUR_HOURS',
  Custom = 'CUSTOM',
}

interface LookupDurationWindowUnderivedMetadata {
  displayText: string;
  duration: number | null;
}

export namespace LookupDurationWindowHelper {
  const { formatMessage } = intl;
  const tabToUnderivedMetadata = new Map<LookupDurationWindow, LookupDurationWindowUnderivedMetadata>([
    [LookupDurationWindow.SixHours, { displayText: formatMessage({ id: `account.tripHistory.6h` }), duration: 6 * 60 * 60 * 1000 }],
    [LookupDurationWindow.TwelveHours, { displayText: formatMessage({ id: `account.tripHistory.12h` }), duration: 12 * 60 * 60 * 1000 }],
    [
      LookupDurationWindow.TwentyFourHours,
      { displayText: formatMessage({ id: `account.tripHistory.24h` }), duration: 24 * 60 * 60 * 1000 },
    ],
    [LookupDurationWindow.Custom, { displayText: formatMessage({ id: `account.tripHistory.custom` }), duration: null }],
  ]);

  export function metadata(lookupDurationWindow: LookupDurationWindow): LookupDurationWindowUnderivedMetadata {
    const underivedMetadata = tabToUnderivedMetadata.get(lookupDurationWindow);
    if (!underivedMetadata) {
      throw Error(`
              Specified LookupDurationWindow  Type: ${toString(lookupDurationWindow)} doesnt have corresponding 
              underived/explicitly defined metadata.This usually (not always) means a bug or incomplete implementation.
          `);
    }

    return { ...underivedMetadata };
  }

  export function duration(lookupDurationWindow: LookupDurationWindow): number | null {
    return metadata(lookupDurationWindow).duration;
  }

  export function toString(lookupDurationWindow: LookupDurationWindow): string {
    return EnumWithStringValue.getEnumKeyByEnumValue(LookupDurationWindow, lookupDurationWindow)!;
  }

  export function allValues(): LookupDurationWindow[] {
    return EnumWithStringValue.enumToValues(LookupDurationWindow);
  }

  export function allTabLabelAndValues(): ListItemConfig<LookupDurationWindow>[] {
    return allValues().map((lookupDurationWindow) => {
      return {
        value: lookupDurationWindow,
        label: metadata(lookupDurationWindow).displayText,
      };
    });
  }
}
