import {
  Currency,
  UpdateVehiclePricingRequest,
  VehicleFeePricingBreakdownAmountType,
  VehicleFeePricingBreakDownSubtype,
  VehicleFeePricingBreakDownType,
} from '../../../models/vehicle-fee-pricing';

import { VehiclePricingFormValues } from './VehiclePricingDetailsForm';

export const vehiclePricingFormFieldName = {
  activeActivationFee: 'activeActivationFee',
  activeActiveMinutesFee: 'activeActiveMinutesFee',
  activePausedMinutesFee: 'activePausedMinutesFee',
  newActivationFee: 'newActivationFee',
  newActiveMinutesFee: 'newActiveMinutesFee',
  newPausedMinutesFee: 'newPausedMinutesFee',
} as const;

export enum VehiclePricingDetailsActionMode {
  ViewInProgressPricing = 'VIEW_IN_PROGRESS_PRICING',
  ViewActivePricing = 'VIEW_ACTIVE_PRICING',
  EditActivePricing = 'EDIT_ACTIVE_PRICING',
  ViewFailedPricing = 'VIEW_FAILED_PRICING',
}

export function makeUpdateVehiclePricingRequestPayload(values: VehiclePricingFormValues, version: number): UpdateVehiclePricingRequest {
  return {
    version,
    currency: Currency.Euro,
    breakdown: [
      {
        type: VehicleFeePricingBreakDownType.UsageCharges,
        subType: VehicleFeePricingBreakDownSubtype.ActivationFee,
        amount: {
          type: VehicleFeePricingBreakdownAmountType.Fixed,
          value: values.newActivationFee!,
        },
      },
      {
        type: VehicleFeePricingBreakDownType.UsageCharges,
        subType: VehicleFeePricingBreakDownSubtype.ActiveDuration,
        amount: {
          type: VehicleFeePricingBreakdownAmountType.PerMinuteRate,
          value: values.newActiveMinutesFee!,
        },
      },
      {
        type: VehicleFeePricingBreakDownType.UsageCharges,
        subType: VehicleFeePricingBreakDownSubtype.PausedDuration,
        amount: {
          type: VehicleFeePricingBreakdownAmountType.PerMinuteRate,
          value: values.newPausedMinutesFee!,
        },
      },
    ],
  };
}
