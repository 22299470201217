import { useEffect, useRef } from 'react';

import { Box, Divider, Typography, useTheme } from '@mui/material';

import { Modal, WmvTabPanel, WmvTabs } from '../../wmv-components';

import {
  dividerStyles,
  modalContainerSx,
  modalContentSx,
  sidebarContentStyles,
  sidebarHeaderStyles,
  tabPanelContainerStyles,
} from './styles';

interface SidebarInfoModalProps<T> {
  isOpen: boolean;
  onClose: () => void;
  activeTab: T;
  setActiveTab: (tab: T) => void;
  tabOptions: { label: string; value: T }[];
  children: React.ReactNode[];
  title?: string;
  sidebarHeaderComponent?: React.ReactNode;
  disabledTabs?: T[];
}

const SidebarInfoModal = <T extends {}>({
  isOpen,
  onClose,
  activeTab,
  setActiveTab,
  tabOptions,
  children,
  title = '',
  sidebarHeaderComponent,
  disabledTabs = [],
}: SidebarInfoModalProps<T>) => {
  const theme = useTheme();
  const hasOpenedRef = useRef(false);

  useEffect(() => {
    if (isOpen && !hasOpenedRef.current) {
      const firstNonDisabledTab = tabOptions.find((option) => !disabledTabs.includes(option.value));
      if (firstNonDisabledTab) {
        setActiveTab(firstNonDisabledTab.value);
      }
      hasOpenedRef.current = true;
    }
  }, [isOpen, setActiveTab, tabOptions, disabledTabs]);

  return (
    <Modal open={isOpen} onClose={onClose} sx={modalContainerSx}>
      <Modal.Content sx={modalContentSx}>
        <Box sx={sidebarContentStyles}>
          <Box sx={sidebarHeaderStyles}>
            {sidebarHeaderComponent ? (
              sidebarHeaderComponent
            ) : (
              <Typography variant="bodyLargeBold" color={theme.palette.dark.main}>
                {title}
              </Typography>
            )}
          </Box>
          <Divider orientation="horizontal" flexItem sx={dividerStyles}></Divider>
          <WmvTabs options={tabOptions} activeTab={activeTab} onChange={setActiveTab} orientation="vertical" disabledTabs={disabledTabs} />
        </Box>

        <Box sx={tabPanelContainerStyles}>
          {children.map((child, index) => (
            <WmvTabPanel key={index} activeTab={activeTab} currentTab={tabOptions[index].value} sx={{ height: '100%', overflow: 'auto' }}>
              {child}
            </WmvTabPanel>
          ))}
        </Box>
      </Modal.Content>
    </Modal>
  );
};

export default SidebarInfoModal;
