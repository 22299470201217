import React, { memo } from 'react';

import { Box, IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useIntl } from 'react-intl';

import NewDataTable from '../../../design-system/NewDataTable';
import { PreviewPredicates } from '../../../models';
import { Zone } from '../../../services/zone';
import CustomIcon from '../../../utils/CustomIcon';
import { TranslatedText } from '../../../wmv-components';
import { PlanningModePreviewTableColumnDefs } from '../table-column-defs/PlanningModePreviewTableColumnDefs';

import { previewTableContainerStyles, previewTableTitleBoxStyles, previewTableTitleTextStyles } from './PlanningModePreviewTable.styles';

interface PlanningModePreviewTableProps {
  zones: Zone[];
  collapsed: boolean;
  onCollapseToggle: (collapsed: boolean) => void;
  previewPredicates: PreviewPredicates;
}

export const PlanningModePreviewTable: React.FC<PlanningModePreviewTableProps> = ({ zones, collapsed, onCollapseToggle }) => {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const MemoizedPlanningModePreviewTableColumnDefs = PlanningModePreviewTableColumnDefs();

  return (
    <>
      <Box sx={previewTableContainerStyles}>
        <Box sx={previewTableTitleBoxStyles}>
          <Box sx={previewTableTitleTextStyles}>
            <TranslatedText variant="subtitle1" translationKey="planningPreview.zonesDataTable.title" />
          </Box>
          <Box>
            <Typography variant="subtitle1">
              {formatMessage(
                { id: 'planningPreview.zonesDataTable.zonesCount' },
                {
                  zonesCount: zones?.length,
                },
              )}
            </Typography>
          </Box>
        </Box>

        <IconButton
          size="small"
          disableRipple
          onClick={(event) => {
            event.stopPropagation();
            onCollapseToggle(!collapsed);
          }}
        >
          {CustomIcon(collapsed ? 'DoubleChevronUp' : 'DoubleChevronDown', theme.palette.primary.main)}
        </IconButton>
      </Box>
      {!collapsed && <NewDataTable<Zone, 'default'> data={zones} columns={MemoizedPlanningModePreviewTableColumnDefs} height="200px" />}
    </>
  );
};

export default memo(PlanningModePreviewTable);
