import { atom } from 'jotai';

export const isHexagonLayerVisibleAtom = atom<boolean>(true);

export const colorScaleAtom = atom<string[]>(['#F5E231', '#F4E031', '#EEC22C', '#E68A25', '#E26321', '#DF3B1F', '#C62E16']);

export const selectedColorScaleIndexAtom = atom<number>(0);

export const reverseColorsAtom = atom<boolean>(false);

export const totalColorsAtom = atom<number>(7);

export const rangesAtom = atom<number[]>([1, 5, 10, 15, 20, 25, 30]);

export const hexagonResolutionAtom = atom<number>(9);

export const poiDataAtom = atom<string>('analytics.heatmap.tripsBooked');
