import { ReactNode } from 'react';

import type { TypographyProps } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';

import { InfoIconWithTooltip, TranslatedText } from '../../../../../wmv-components';

interface MembershipDetailsRowProps {
  labelTranslationKey: string;
  extraMessage?: ReactNode;
  title: ReactNode;
  titleVariant?: TypographyProps['variant'];
  applyLabelStyles?: boolean;
  toolTipText?: string;
  useGrid?: boolean;
}

export const MembershipDetailsRow = ({
  labelTranslationKey,
  title,
  titleVariant = 'bodyMediumBold',
  extraMessage = null,
  applyLabelStyles = true,
  toolTipText = '',
  useGrid = false,
}: MembershipDetailsRowProps) => {
  const theme = useTheme();

  const labelContent = (
    <Box display="flex" alignItems="center" gap={0.5}>
      <TranslatedText
        translationKey={labelTranslationKey}
        variant="bodyMedium"
        color={theme.palette.baseLight.base76}
        typographyProps={{ component: 'p' }}
      />
      :
      {toolTipText && (
        <InfoIconWithTooltip
          title={toolTipText}
          placement="bottom-start"
          tooltipProps={{
            componentsProps: {
              tooltip: { sx: { maxWidth: '420px' } },
            },
          }}
          arrow
        />
      )}
    </Box>
  );

  const valueContent = (
    <Box>
      {typeof title === 'string' ? <Typography variant={titleVariant}>{title}</Typography> : title}
      {isString(extraMessage) && !isEmpty(extraMessage) ? (
        <Typography color={theme.palette.alert.main}>{extraMessage}</Typography>
      ) : (
        extraMessage
      )}
    </Box>
  );

  if (useGrid) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          {labelContent}
        </Grid>
        <Grid item xs={6}>
          {valueContent}
        </Grid>
      </Grid>
    );
  }

  return (
    <Box display="flex" flexDirection="row" alignItems="baseline" mt={1}>
      <Box display="flex" alignItems="center" gap={0.5} sx={applyLabelStyles ? { minWidth: '220px', mr: 3 } : { mr: 1 }}>
        {labelContent}
      </Box>
      {valueContent}
    </Box>
  );
};
