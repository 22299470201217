import type { Theme } from '@mui/material/styles';

export const anchorButtonStyles = (theme: Theme) => ({
  height: '40px',
  minWidth: '220px',
  padding: theme.spacing(1),
  backgroundColor: theme.palette.baseLight.base8,
  color: theme.palette.dark.main,
  '&:hover': {
    border: `2px solid ${theme.palette.dark.main}`,
  },
  '&:focus': {
    border: `2px solid ${theme.palette.dark.main}`,
  },
  '&.active': {
    border: `2px solid ${theme.palette.dark.main}`,
  },
});

export const anchorButtonTextStyles = (theme: Theme) => ({
  color: theme.palette.hexBlack.shade0,
  ...theme.utils.ellipsisText,
});
