import { VehicleType, LastChangeAttemptStatus } from '../../models';
import { VehicleFeePricing, VehicleFeePricingMSP } from '../../models/vehicle-fee-pricing';

import { VehicleFeePricingActiveStateDto } from './VehicleFeePricingActiveState';
import { VehicleFeePricingLastChangeAttemptDto } from './VehicleFeePricingLastChangeAttemptDto';

export class VehiclePricingDto {
  id: number;
  version: number;
  modelId: number;
  modelName: string;
  vehicleType: VehicleType;
  lastAttemptedStateChange: VehicleFeePricingLastChangeAttemptDto;
  attemptedStatusChangeStatus: LastChangeAttemptStatus;
  activeState?: VehicleFeePricingActiveStateDto;
  msp: VehicleFeePricingMSP;

  constructor(vehicleFeePricing: VehicleFeePricing) {
    this.id = vehicleFeePricing.id;
    this.version = vehicleFeePricing.version;
    this.modelId = vehicleFeePricing.vehicleModel.id;
    this.modelName = vehicleFeePricing.vehicleModel.name;
    this.vehicleType = vehicleFeePricing.vehicleModel.vehicleType;
    this.attemptedStatusChangeStatus = vehicleFeePricing.lastAttemptedStateChange.status;
    this.lastAttemptedStateChange = new VehicleFeePricingLastChangeAttemptDto(vehicleFeePricing.lastAttemptedStateChange);
    this.msp = vehicleFeePricing.mobilityServiceProvider;

    if (vehicleFeePricing.activeState) {
      this.activeState = new VehicleFeePricingActiveStateDto(vehicleFeePricing.activeState);
    }
  }

  isStateChangeInProgress(): boolean {
    return this.lastAttemptedStateChange.status === LastChangeAttemptStatus.InProgress;
  }

  isStateChangeSuccessful(): boolean {
    return this.lastAttemptedStateChange.status === LastChangeAttemptStatus.Success;
  }

  isStateChangeFailed(): boolean {
    return this.lastAttemptedStateChange.status === LastChangeAttemptStatus.Failed;
  }
}
