/* eslint-disable react/no-unescaped-entities */
import { Box, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

import construction from '../../assets/images/construction.svg';

const Placeholder = () => {
  const { formatMessage } = useIntl();
  return (
    <Box p="3px" width="675px" justifyContent="center" alignItems="center" display="flex" flexDirection="column">
      <img style={{ height: '100%', width: '100%' }} src={construction} alt="" />
      <Typography variant="h2">{formatMessage({ id: 'placeholder.title' })}</Typography>
      <Typography
        variant="bodyMedium"
        style={{
          textAlign: 'center',
        }}
      >
        {formatMessage({ id: 'placeholder.description' })}
      </Typography>
    </Box>
  );
};
export default Placeholder;
