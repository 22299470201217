import Box from '@mui/material/Box';

import { DateTimeRangeSearchForm, StartAndEndDateTimeRangeValues } from '../../../wmv-components';
import { FieldsContainer, StyledFormContainer } from '../SearchForm';

export interface LawEnforcementTripSearchFormProps {
  onSearch: (formValues: StartAndEndDateTimeRangeValues) => void;
  loading: boolean;
}

export const LawEnforcementTripSearchForm = (props: LawEnforcementTripSearchFormProps) => {
  const { onSearch, loading } = props;

  return (
    <StyledFormContainer>
      <Box>
        <FieldsContainer>
          <DateTimeRangeSearchForm onSearch={onSearch} loading={loading} startDateStyles={{ mb: 2 }} endDateStyles={{ mb: 2 }} />
        </FieldsContainer>
      </Box>
    </StyledFormContainer>
  );
};
