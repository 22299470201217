import { Currency, PaymentCardBrand, PaymentMethodType, PaymentStatus, Unit, UsageStatus, VehicleType } from '../common';

export interface TripVehicle {
  qrCode: string;
  type: VehicleType;
  provider: TripVehicleProvider;
  model: TripVehicleModel;
}

export interface TripVehicleProvider {
  id: number;
  name: string;
}

export interface TripVehicleModel {
  id: number;
  name: string;
}

export interface TripInvoice {
  status: UsageStatus;
  url: string;
}

export interface TripActivityLogDetails {
  event: TripActivityLogEventType;
  terminalState: boolean;
  timestamp: number;
  updatedBy: UpdatedBy;
}

export interface TripDurationDetails {
  actualActiveSeconds: number;
  actualPausedSeconds: number;
  billableActiveSeconds: number;
  billablePausedSeconds: number;
  breakdown: TripDurationBreakdownDetails[];
}

export interface TripPaymentDetails {
  id: number;
  status: PaymentStatus;
  amount: TripPaymentAmountDetails;
}

export interface TripPaymentAmountDetails {
  budget?: TripPaymentAmountBudgetDetails;
  money?: TripPaymentAmountMoneyDetails;
  breakdown: TripPaymentAmountBreakdownDetails[];
}

export interface TripPaymentAmountBudgetDetails {
  status: PaymentStatus;
  amount: number;
  unit: Unit;
  capturedAt: number;
  planName: string;
  linkedAccountSubjectClaim: string;
  linkedAccountOwnerName: string;
}

export interface TripPaymentAmountMoneyDetails {
  status: PaymentStatus;
  amount: number;
  amountBeforeVat: number;
  vatAmount: number;
  vatPercentage: number;
  currency: Currency;
  paidAt: number;
  paymentMethod: PaymentMethod;
}

export interface TripPaymentAmountBreakdownDetails {
  type: TripPaymentAmountType;
  subType?: TripPaymentAmountSubType;
  amount: number;
  metadata: TripPaymentAmountBreakdownMetadata;
}

export interface TripPaymentAmountBreakdownMetadata {
  schedule?: TripPaymentAmountSchedule;
  discountCode?: string;
}

export interface TripDurationBreakdownDetails {
  start: number;
  actualDurationSeconds: number;
  billableDurationSeconds: number;
  type: TripDurationBreakdownType;
}

export interface UpdatedBy {
  id: string;
  name: string;
}

export interface PaymentMethod {
  type: PaymentMethodType;
  brand: PaymentCardBrand;
  expiryMonth: number;
  expiryYear: number;
  last4Digits: number;
}

export interface TripPaymentAmountSchedule {
  type: Unit;
  value: number;
}

export enum TripStatus {
  Init = 'INIT',
  StartRequested = 'START_REQUESTED',
  Started = 'STARTED',
  PauseRequested = 'PAUSE_REQUESTED',
  Paused = 'PAUSED',
  ResumeRequested = 'PAUSE_REQUESTED',
  Resumed = 'RESUMED',
  StopRequested = 'STOP_REQUESTED',
  Stopped = 'STOPPED',
  ForceStopRequested = 'FORCE_STOP_REQUESTED',
  ForceStopped = 'FORCE_STOPPED',
}

export enum TripPaymentAmountType {
  UsageCharges = 'USAGE_CHARGES',
  Discount = 'DISCOUNT',
  Tax = 'TAX',
}

export enum TripPaymentAmountSubType {
  ActivationFee = 'ACTIVATION_FEE',
  ActiveDuration = 'ACTIVE_DURATION',
  PausedDuration = 'PAUSED_DURATION',
  Vat = 'VAT',
}

export enum TripDurationBreakdownType {
  Active = 'ACTIVE',
  Paused = 'PAUSED',
}

export enum TripActivityLogEventType {
  StartRequested = 'START_REQUESTED',
  Started = 'STARTED',
  PauseRequested = 'PAUSE_REQUESTED',
  Paused = 'PAUSED',
  ResumeRequested = 'PAUSE_REQUESTED',
  Resumed = 'RESUMED',
  StopRequested = 'STOP_REQUESTED',
  Stopped = 'STOPPED',
  ForceStopRequested = 'FORCE_STOP_REQUESTED',
  ForceStopped = 'FORCE_STOPPED',
  PaymentRequired = 'PAYMENT_REQUIRED',
  PaymentReceived = 'PAYMENT_RECEIVED',
}
