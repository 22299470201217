import { ModeType, ModeTypeHelper } from '../../../models';

export interface ModeSelectionPopOverOption {
  value: ModeType;
  labelTranslationKey: string;
  descriptionTranslationKey: string;
  granularityTranslationKey: string;
}

export const defaultModeTypes: ModeSelectionPopOverOption[] = ModeTypeHelper.allModeTypesArray().map((modeType) => ({
  value: modeType,
  labelTranslationKey: ModeTypeHelper.metadata(modeType).labelTranslationKey,
  descriptionTranslationKey: ModeTypeHelper.metadata(modeType).descriptionTranslationKey,
  granularityTranslationKey: ModeTypeHelper.metadata(modeType).granularityTranslationKey,
}));
