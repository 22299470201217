import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

const StyledCard = styled(Box)`
  background:
    radial-gradient(circle at 100% 100%, #ffffff 0, #ffffff 6px, transparent 6px) 0% 0%/8px 8px no-repeat,
    radial-gradient(circle at 0 100%, #ffffff 0, #ffffff 6px, transparent 6px) 100% 0%/8px 8px no-repeat,
    radial-gradient(circle at 100% 0, #ffffff 0, #ffffff 6px, transparent 6px) 0% 100%/8px 8px no-repeat,
    radial-gradient(circle at 0 0, #ffffff 0, #ffffff 6px, transparent 6px) 100% 100%/8px 8px no-repeat,
    linear-gradient(#ffffff, #ffffff) 50% 50% / calc(100% - 4px) calc(100% - 16px) no-repeat,
    linear-gradient(#ffffff, #ffffff) 50% 50% / calc(100% - 16px) calc(100% - 4px) no-repeat,
    linear-gradient(50deg, #ffffff 0%, #009881 60%);

  border-radius: 8px;
  box-sizing: content-box;
  padding: 5px;
  box-shadow: 0px 6px 12px 0px #04092114;
`;

const StyledTyprography = styled(Typography)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap; /* let the text wrap preserving spaces */
  user-select: none;
`;

const StyledButton = styled(Button)(({ theme }) => ({
  border: '2px solid',
  borderColor: theme.palette.dark.shade8,
  backgroundColor: theme.palette.white.main,
  color: theme.palette.hexBlack.shade0,
  '&:hover': {
    border: `2px solid ${theme.palette.hexBlack.shade0}`,
    backgroundColor: theme.palette.hexBlack.shade0,
    color: theme.palette.white.main,
  },
  '&.Mui-disabled': {
    backgroundColor: theme.palette.dark.shade8,
    border: 'none',
  },
}));

interface WidgetProps {
  title: string;
  description: string;
  url: string;
}

const Widget = ({ title, description, url }: WidgetProps) => {
  const { formatMessage } = useIntl();

  return (
    <StyledCard minWidth="250px" width="250px" minHeight="150px">
      <Box m={2} display="flex" justifyContent="space-between" flexDirection="column" height={150}>
        <Box>
          <Typography variant="bodyMediumBold" color="#2F2E41">
            {title}
          </Typography>
        </Box>
        <Box>
          <StyledTyprography variant="bodyMedium" title={description} my={1}>
            {description}
          </StyledTyprography>
        </Box>
        <Box>
          <Link to={url}>
            <StyledButton variant="outlined" color="primary" endIcon={<OpenInNewOutlinedIcon />}>
              <Typography variant="bodySmallBold">{formatMessage({ id: 'pages.home.widget.open' })}</Typography>
            </StyledButton>
          </Link>
        </Box>
      </Box>
    </StyledCard>
  );
};

export default Widget;
