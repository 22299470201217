import { useEffect, useState, memo } from 'react';

import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Box, Popover, Button, Divider } from '@mui/material';
import type { PopoverOrigin } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import PopupState, { bindPopover, bindToggle } from 'material-ui-popup-state';
import { PopupState as PopupStateProps } from 'material-ui-popup-state/hooks';
import type { DateRange } from 'mui-daterange-picker';
import { DateRangePicker as MuiDateRangePicker } from 'mui-daterange-picker';
import { useIntl } from 'react-intl';

import { DateRangeAndDaysOfWeek, DayOfWeek } from '../../models';
import { PredicatePopoverAnchorButton } from '../../pages/Zones/ZonePlanningMode';
import { MIN_DEFAULT_DATE, MAX_DEFAULT_DATE } from '../../utils/constants';
import { DaysOfWeekPicker } from '../DaysOfWeekPicker';

import { popOverStyles } from './styles';

const transformOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
};

const anchorOrigin: PopoverOrigin = {
  vertical: -10,
  horizontal: 'left',
};

export interface DateRangePickerProps {
  placeholderTranslationKey?: string;
  initialValue: DateRangeAndDaysOfWeek;
  onChange: (value: DateRangeAndDaysOfWeek) => void;
}

export const DateRangeAndDayOfWeekPicker = memo(
  ({ placeholderTranslationKey = 'input.dateRange.placeholder', initialValue, onChange }: DateRangePickerProps) => {
    const [componentState, setComponentState] = useState(initialValue);
    const { formatMessage } = useIntl();
    const theme = useTheme();

    useEffect(() => {
      setComponentState(initialValue);
    }, [initialValue]);

    const handleDayOfWeekToggle = (selectedDaysOfWeek: Set<DayOfWeek>) => {
      setComponentState((prevComponentState) => ({
        ...prevComponentState,
        daysOfWeek: selectedDaysOfWeek,
      }));
    };

    const handleDateChange = (range: DateRange) => {
      setComponentState((prevDateRangeAndDaysOfWeekState) => ({
        ...prevDateRangeAndDaysOfWeekState,
        startDate: dayjs(range.startDate),
        endDate: dayjs(range.endDate),
      }));
    };

    const handleOutsideClick = (popupState: PopupStateProps) => {
      const daysOfWeekChanged = ![...initialValue.daysOfWeek].every((dayOfWeek) => componentState.daysOfWeek.has(dayOfWeek));
      const startDateChanged = !componentState.startDate.isSame(initialValue.startDate);
      const endDateChanged = !componentState.endDate.isSame(initialValue.endDate);
      if (daysOfWeekChanged || startDateChanged || endDateChanged) {
        popupState.setOpen(true);
      } else {
        popupState.setOpen(false);
      }
    };

    const showPlaceholder = !initialValue?.startDate && !initialValue?.endDate;
    let popupToggleButtonLabel: string;
    if (showPlaceholder) {
      popupToggleButtonLabel = formatMessage({ id: placeholderTranslationKey });
    } else {
      const startDateFormatted = initialValue!.startDate.formatAsDateString();
      const endDateFormatted = initialValue!.endDate.formatAsDateString();
      popupToggleButtonLabel = `${startDateFormatted} – ${endDateFormatted}`;
    }

    return (
      <Box display="flex" gap={1} alignItems="center">
        <PopupState variant="popover">
          {(popupState) => (
            <>
              <PredicatePopoverAnchorButton
                endIcon={popupState.isOpen ? <CalendarTodayIcon color="secondary" /> : <CalendarTodayIcon color="action" />}
                label={popupToggleButtonLabel}
                labelFontVariant="bodySmall"
                {...bindToggle(popupState)}
              />

              <Popover
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
                sx={popOverStyles}
                transitionDuration={0}
                {...bindPopover(popupState)}
              >
                <ClickAwayListener onClickAway={() => handleOutsideClick(popupState)}>
                  <Box minHeight="542px" display="flex" flexDirection="column" justifyContent="space-between">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MuiDateRangePicker
                        closeOnClickOutside={false}
                        minDate={MIN_DEFAULT_DATE.asDayjsDate().toDate()}
                        maxDate={MAX_DEFAULT_DATE.asDayjsDate().toDate()}
                        toggle={() => {}}
                        onChange={handleDateChange}
                        open={popupState.isOpen}
                        definedRanges={[]}
                        initialDateRange={
                          {
                            startDate: initialValue.startDate.toDate(),
                            endDate: initialValue.endDate.toDate(),
                          } as DateRange
                        }
                        wrapperClassName="date-range-picker"
                      />
                    </LocalizationProvider>

                    <Box display="flex">
                      <Box width="50%" px={2} py={2}>
                        <Divider color={theme.palette.baseLight.base8} />
                        <DaysOfWeekPicker
                          selectAllDaysOnLastUnselect
                          onDayOfWeekChange={handleDayOfWeekToggle}
                          initialValue={componentState.daysOfWeek}
                          title={formatMessage({ id: 'planningPreview.daysOfWeek.selection.title' })}
                          dateRange={{
                            startDate: componentState.startDate || dayjs(),
                            endDate: componentState.endDate || dayjs(MAX_DEFAULT_DATE),
                          }}
                        />
                      </Box>
                      <Box display="flex" justifyContent="flex-end" width="50%" p="8px 16px">
                        <Box alignSelf="center">
                          <Button
                            size="medium"
                            color="primary"
                            onClick={() => {
                              setComponentState(initialValue);
                              popupState.close();
                            }}
                          >
                            {formatMessage({ id: 'actions.cancel' })}
                          </Button>
                          <Button
                            size="medium"
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                              onChange(componentState);
                              popupState.close();
                            }}
                          >
                            {formatMessage({ id: 'actions.apply' })}
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </ClickAwayListener>
              </Popover>
            </>
          )}
        </PopupState>
      </Box>
    );
  },
);

DateRangeAndDayOfWeekPicker.displayName = 'DateRangeAndDayOfWeekPicker';
