import { EnumTabHelper } from '../../utils/EnumTabHelper';

export enum VehicleInformationTab {
  GeneralInformation = 'GENERAL_INFORMATION',
  TripHistory = 'TRIP_HISTORY',
}

const vehicleTranslations = {
  GeneralInformation: 'map.detailedModal.generalInformation',
  TripHistory: 'map.detailedModal.tripHistory',
};

export const VehicleInformationTabHelper = new EnumTabHelper(VehicleInformationTab, vehicleTranslations);
