import { useState, useCallback } from 'react';

export const useZoneAreaManipulationBottomActionButtons = () => {
  const [zoneAreaManipulationBottomActionButtons, setZoneAreaManipulationBottomActionButtons] = useState({
    visible: false,
    continueButtonEnabled: false,
    cancelButtonEnabled: false,
  });

  const hideZoneAreaManipulationBottomActionButtons = useCallback(() => {
    setZoneAreaManipulationBottomActionButtons({
      visible: false,
      continueButtonEnabled: false,
      cancelButtonEnabled: false,
    });
  }, []);

  const showZoneAreaManipulationBottomActionButtonsAsEnabled = useCallback(() => {
    setZoneAreaManipulationBottomActionButtons({
      visible: true,
      continueButtonEnabled: true,
      cancelButtonEnabled: true,
    });
  }, []);

  const showOnlyCancelButtonAsEnabled = useCallback(() => {
    setZoneAreaManipulationBottomActionButtons({
      visible: true,
      cancelButtonEnabled: true,
      continueButtonEnabled: false,
    });
  }, []);

  const showZoneAreaManipulationBottomActionButtonsAsDisabled = useCallback(() => {
    setZoneAreaManipulationBottomActionButtons({
      visible: true,
      cancelButtonEnabled: false,
      continueButtonEnabled: false,
    });
  }, []);

  return {
    zoneAreaManipulationBottomActionButtons,
    hideZoneAreaManipulationBottomActionButtons,
    showZoneAreaManipulationBottomActionButtonsAsEnabled,
    showOnlyCancelButtonAsEnabled,
    showZoneAreaManipulationBottomActionButtonsAsDisabled,
  };
};
