import React from 'react';

import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import { circularLoaderStyles, dotStyles } from './LoadingAnimation.styles';

// TODO: This should take size: small/medium/large arther than sizeMultiplier`
const LoadingAnimation: React.FC<{ sizeMultiplier?: number }> = ({ sizeMultiplier = 1 }) => {
  const radius = 15 * sizeMultiplier;
  const theme = useTheme();

  const dots = Array.from({ length: 8 }).map((_, index) => {
    const angle = (index * 45 * Math.PI) / 180;
    const x = 45 * sizeMultiplier + radius * Math.cos(angle);
    const y = 45 * sizeMultiplier + radius * Math.sin(angle);
    return (
      <Box
        key={index}
        sx={dotStyles(theme, sizeMultiplier)}
        style={{
          left: `${x}px`,
          top: `${y}px`,
          animationDelay: `${index * 0.2}s`,
        }}
      ></Box>
    );
  });

  return <Box sx={circularLoaderStyles(theme, sizeMultiplier)}>{dots}</Box>;
};

export default LoadingAnimation;
