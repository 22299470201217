import Box from '@mui/material/Box';

import { EnqueuedSnackbarProvider } from '../../../components/EnqueuedSnackbar';

import { SelectedVehiclePricingStateProvider, VehiclePricingStateProvider } from './contexts';
import { PageHeading } from './PageHeading';
import { VehiclePricingDetailsModal } from './VehiclePricingDetailsModal';
import { VehiclePricingTableWithLoadingOrNoContentMessage } from './VehiclePricingTableWithLoadingOrNoContentMessage';

export const VehiclePricingLayout = () => {
  return (
    <EnqueuedSnackbarProvider>
      <VehiclePricingStateProvider>
        <Box>
          <PageHeading />
          <VehiclePricingTableWithLoadingOrNoContentMessage />
          <SelectedVehiclePricingStateProvider>
            <VehiclePricingDetailsModal />
          </SelectedVehiclePricingStateProvider>
        </Box>
      </VehiclePricingStateProvider>
    </EnqueuedSnackbarProvider>
  );
};
