import { Fragment } from 'react';

import { Button, Grid, Typography } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { useIntl } from 'react-intl';

import { CustomerInfoFormFields } from './index';

import { themeOptions as theme } from '../../../init-setup/ThemeOptions';
import { DEFAULT_DATE_FORMAT_DAYJS } from '../../../utils/constants';
import CustomTextField from '../../FormFields/CustomTextField';
import FormikErrorMessage from '../../FormFields/ErrorMessage';

export function PersonalInfoSectionInputs({ isInEditMode, onEditClick }: PersonalInfoSectionInputsProps) {
  const { formatMessage } = useIntl();
  const { values } = useFormikContext<CustomerInfoFormFields>();

  return (
    <Fragment>
      <Grid container={true} direction="row" justifyContent="space-between">
        <Typography variant="bodySmallBold" color={theme.palette.dark.main}>
          {formatMessage({ id: 'maas.customer.details.personalInfo' })}
        </Typography>
        <Button variant="outlined" size="small" disabled={isInEditMode} onClick={onEditClick} color="primary" style={{ minWidth: '90px' }}>
          <Typography variant="bodySmallBold">{formatMessage({ id: 'maas.customer.details.edit' })}</Typography>
        </Button>
      </Grid>

      <Grid container={true} direction="row" flexWrap="nowrap" sx={{ marginTop: '20px' }}>
        <Grid item xs={5} sx={{ marginRight: '30px' }}>
          <Typography
            component="label"
            htmlFor="firstName"
            variant="caption"
            color={theme.palette.dark.shade60}
            sx={{ marginBottom: '4px' }}
          >
            {formatMessage({ id: 'maas.customer.details.firstName' })}
          </Typography>
          <Field as={CustomTextField} name="firstName" disabled={!isInEditMode} />
          <FormikErrorMessage name="firstName" />
        </Grid>
        <Grid item xs={5}>
          <Typography
            component="label"
            htmlFor="lastName"
            variant="caption"
            color={theme.palette.dark.shade60}
            sx={{ marginBottom: '4px' }}
          >
            {formatMessage({ id: 'maas.customer.details.lastName' })}
          </Typography>
          <Field as={CustomTextField} name="lastName" disabled={!isInEditMode} />
          <FormikErrorMessage name="lastName" />
        </Grid>
      </Grid>

      <Grid container={true} direction="row" flexWrap="nowrap" sx={{ marginTop: '20px' }}>
        <Grid item xs={5} sx={{ marginRight: '30px' }}>
          <Typography
            component="label"
            htmlFor="birthDate"
            variant="caption"
            color={theme.palette.dark.shade60}
            sx={{ marginBottom: '4px' }}
          >
            {formatMessage({ id: 'maas.customer.details.birthdate' })}
          </Typography>
          <Field
            type="date"
            name="birthDate"
            component={CustomTextField}
            value={values.birthDate}
            disabled={!isInEditMode}
            format={DEFAULT_DATE_FORMAT_DAYJS}
          />
          <FormikErrorMessage name="birthDate" />
        </Grid>
      </Grid>
    </Fragment>
  );
}

interface PersonalInfoSectionInputsProps {
  isInEditMode: boolean;
  onEditClick: () => void;
}
