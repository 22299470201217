import { useEffect } from 'react';

import { useAppDispatch } from '../../../../state';

export const pollActionCreator = (interval: number) => {
  let intervalId: ReturnType<typeof setTimeout> | null = null;

  return {
    start: (asyncCallback: () => Promise<void>) => {
      if (intervalId !== null) {
        clearInterval(intervalId);
      }

      intervalId = setInterval(() => asyncCallback(), interval);
      return intervalId;
    },
    stop: (intervalId: ReturnType<typeof setTimeout>) => {
      clearInterval(intervalId);
    },
  };
};

export function usePolling(asyncCallback: () => Promise<void>, payload: any, interval: number) {
  const dispatch = useAppDispatch();
  useEffect(() => {
    const pollingActions = pollActionCreator(interval);
    const intervalId = pollingActions.start(asyncCallback);
    return () => pollingActions.stop(intervalId);
  }, [dispatch, asyncCallback, interval, payload]);
}
