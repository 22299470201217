import type { PluginFunc, Dayjs } from 'dayjs';
import dayjs from 'dayjs';

import { DayOfWeek } from '../../models';

declare module 'dayjs' {
  function getMax(days: Dayjs[]): Dayjs | null;
  function getMin(days: Dayjs[]): Dayjs | null;
  interface Dayjs {
    getDate(): number;
    getYear(): number;
    getMonth(): number;
    getDay(): DayOfWeek;
    getHour(): number;
    getMinute(): number;
    getSecond(): number;
    getMilliSecond(): number;
  }
}

export const getterPlugin: PluginFunc = (option, dayjsClass, dayjsFactory) => {
  dayjsClass.prototype.getYear = function (): number {
    return this.get('year');
  };

  dayjsClass.prototype.getDate = function (): number {
    return this.get('date');
  };

  dayjsClass.prototype.getDay = function (): DayOfWeek {
    const dayOfWeekKey = this.format('dddd') as keyof typeof DayOfWeek;
    return DayOfWeek[dayOfWeekKey];
  };

  dayjsClass.prototype.getMonth = function (): number {
    return this.get('month');
  };

  dayjsClass.prototype.getHour = function (): number {
    return this.get('hour');
  };

  dayjsClass.prototype.getMinute = function (): number {
    return this.get('minute');
  };

  dayjsClass.prototype.getSecond = function (): number {
    return this.get('second');
  };

  dayjsClass.prototype.getMilliSecond = function (): number {
    return this.get('millisecond');
  };

  dayjsFactory.getMax = function (dates: Dayjs[]): Dayjs {
    return dayjs.max(dates)!;
  };

  dayjsFactory.getMin = function (dates: Dayjs[]): Dayjs {
    return dayjs.min(dates)!;
  };
};

export default getterPlugin;
