import { Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { LookupDurationWindow } from '../../../models';

interface FilterButtonProps {
  filter: LookupDurationWindow;
  activeFilter: LookupDurationWindow;
  label: string;
  onClick: () => void;
}

const FilterButton: React.FC<FilterButtonProps> = ({ filter, activeFilter, label, onClick }) => {
  const { palette } = useTheme();

  return (
    <Button
      variant="contained"
      color={filter === activeFilter ? 'secondary' : 'tertiary'}
      onClick={onClick}
      sx={{ whiteSpace: 'nowrap', textTransform: 'none', color: palette.baseDark.main }}
    >
      <Typography variant="bodyMediumBold">{label}</Typography>
    </Button>
  );
};

export default FilterButton;
