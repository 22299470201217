import { useState, useRef, useEffect, useCallback } from 'react';

import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';

import { useForceUpdate } from '../hooks';
import { Zone } from '../services/zone';

type UseEvaluateZonesVisibilityWithTheTime = (zones: Zone[]) => Zone[];

export const useEvaluateZonesVisibilityWithTheTime: UseEvaluateZonesVisibilityWithTheTime = (zones) => {
  const [timeGapUntilNextEval, setTimeGapUntilNextEval] = useState<number>(0);
  const allZonesRef = useRef<{ [id: number]: Zone }>({});
  const nextEvalTimerId = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [visibleZones, setVisibleZones] = useState<Zone[]>([]);
  const [updaterKey, forceUpdate] = useForceUpdate();

  const getVisibleZones = (zones: Zone[], evalPoint: Dayjs) => {
    if (isEmpty(allZonesRef.current)) return [];
    return zones.filter((zone) => {
      return allZonesRef.current[zone.id].isVisible(evalPoint);
    });
  };

  const startTimerForNextEvaluation = useCallback(
    (interval: number) => {
      if (nextEvalTimerId.current) {
        clearTimeout(nextEvalTimerId.current);
      }

      nextEvalTimerId.current = setTimeout(() => {
        const now = dayjs.now();
        const nexEvalPoints = Object.values(allZonesRef.current!).map((zone) => zone.calculateNextEvaluationPoint(now));
        const earliestVisibilityStateTransitionPoint = dayjs.getMin(nexEvalPoints);
        const timeUntilNextEval = earliestVisibilityStateTransitionPoint!.milliSecondDiff(now);
        setTimeGapUntilNextEval(timeUntilNextEval!);
        if (timeUntilNextEval === timeGapUntilNextEval) {
          forceUpdate();
        }
      }, interval);
    },
    [timeGapUntilNextEval, forceUpdate],
  );

  useEffect(() => {
    if (zones.length) {
      zones.forEach((zone) => {
        allZonesRef.current[zone.id] = zone;
      });
    }
  }, [zones]);

  useEffect(() => {
    if (zones.length) {
      const visibleZones = getVisibleZones(zones, dayjs.now());
      setVisibleZones(visibleZones);
      startTimerForNextEvaluation(timeGapUntilNextEval);
    }
  }, [zones, timeGapUntilNextEval, startTimerForNextEvaluation, updaterKey]);

  return visibleZones;
};
