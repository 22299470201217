import { EnumTabHelper } from '../../utils/EnumTabHelper';

export enum PaymentMethodType {
  Card = 'CARD',
  Budget = 'BUDGET',
}

const paymentMethodTypeTranslations = {
  Card: 'payment.method.card',
  Budget: 'payment.method.budget',
};

export const PaymentMethodTypeHelper = new EnumTabHelper(PaymentMethodType, paymentMethodTypeTranslations);
