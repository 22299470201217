import NewDataTable from '../../../../design-system/NewDataTable';

export function BudgetHistoryTable() {
  return <NewDataTable<any, 'default'> data={budgetActivityHistoryList} columns={BudgetHistoryTableColumnDefs} />;
}

const BudgetHistoryTableColumnDefs = [
  {
    header: 'common.name'.i18nText(),
    accessorKey: 'memberName',
  },
  {
    header: 'tripHistory.table.date'.i18nText(),
    accessorKey: 'date',
  },
  {
    header: 'account.profile.role'.i18nText(),
    accessorKey: 'role',
  },
  {
    header: 'common.budget'.i18nText(),
    accessorKey: 'budgetName',
  },
  {
    header: 'customerTable.row.active'.i18nText(),
    accessorKey: 'active',
  },
  {
    header: 'operator.budgetPlan.form.input.depositAmount.label'.i18nText(),
    accessorKey: 'amount',
  },
  {
    header: 'informationTable.header.tripId'.i18nText(),
    accessorKey: 'tripId',
  },
];

const budgetActivityHistoryList = [
  {
    memberName: 'Frank, Schopkuli',
    date: '22.04.2024 16:00',
    role: 'Owner',
    budgetName: 'Lagarde Budget 600',
    active: 'Deduction',
    amount: '12mins',
    tripId: 800123456,
  },
  {
    memberName: 'Frank, Schopkuli 2',
    date: '22.04.2024 16:00',
    role: 'Owner',
    budgetName: 'Lagarde Budget 600',
    active: 'Deduction',
    amount: '12mins',
    tripId: 800123456,
  },
  {
    memberName: 'Frank, Schopkuli 3',
    date: '22.04.2024 16:00',
    role: 'Owner',
    budgetName: 'Lagarde Budget 600',
    active: 'Deduction',
    amount: '12mins',
    tripId: 800123456,
  },
];
