import { EnumTabHelper } from '../../utils/EnumTabHelper';

export enum CustomerBudgetInformationTab {
  ActiveBudget = 'ACTIVE_BUDGET',
  LinkedProfiles = 'LINKED_PROFILES',
  BudgetHistory = 'BUDGET_HISTORY',
}

const customerBudgetTranslations = {
  ActiveBudget: 'common.activeBudget',
  LinkedProfiles: 'common.linkedProfiles',
  BudgetHistory: 'common.budgetHistory',
};

export const CustomerBudgetInformationTabHelper = new EnumTabHelper(CustomerBudgetInformationTab, customerBudgetTranslations);
