import { Slider, sliderClasses } from '@mui/base/Slider';
import { styled, alpha } from '@mui/system';

export const StyledSlider = styled(Slider)(
  ({ theme }) => `
    color: ${theme.palette.secondary.main};
    height: 6px;
    width: 100%;
    padding: 16px 0;
    display: inline-block;
    position: relative;
    cursor: pointer;
    touch-action: none;
    -webkit-tap-highlight-color: transparent;
  
    &:hover {
      opacity: 1;
    }
  
    &.${sliderClasses.disabled} { 
      pointer-events: none;
      cursor: default;
      color: ${theme.palette.primary.main};
      opacity: 0.5;
    }
  
    & .${sliderClasses.rail} {
      display: block;
      position: absolute;
      width: 100%;
      height: 12px;
      border-radius: 6px;
      background-color: currentColor;
      opacity: 0.4;
    }
  
    & .${sliderClasses.track} {
      display: block;
      position: absolute;
      height: 12px;
      border-radius: 6px;
      background-color: ${theme.palette.primary.dark};
      opacity: 0.8;
    }
  
    & .${sliderClasses.thumb} {
      position: absolute;
      width: 16px;
      height: 16px;
      margin-left: -6px;
      margin-top: -2px;
      border-radius: 50%;
      outline: 0;
      border: 2px solid ${theme.palette.primary.dark};
      background-color: ${theme.palette.primary.dark};

      & .label {
        font-weight: 600;
        font-size: 14px;
        background: unset;
        background-color: ${alpha(theme.palette.hexBlack.shade0, 0.5)};
        width: 150px;
        height: 50px;
        padding: 10px;
        visibility: hidden;
        color: ${theme.palette.baseLight.base0};
        border-radius: 5px;
        position: absolute;
        transform: translate(-45%, -120%);
        transition: transform 0.3s ease;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      :active,
      &.${sliderClasses.dragging} {
        & .label {
          visibility: visible;
        }
      }

      
      &.${sliderClasses.active} {
        box-shadow: 0 0 0 0.25rem ${alpha(theme.palette.secondary.main, 0.3)};
      }
    }
  `,
);

export const StyledSliderWithLabel = (props: any): JSX.Element => {
  return <StyledSlider {...props} />;
};
