import { MouseEventHandler, ReactNode, useRef } from 'react';

import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import type { ButtonProps, SlideProps } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Tooltip from '@mui/material/Tooltip';

import { ProgressAwareButton } from '../../../../wmv-components';

import { slidingConfirmationContainerSx } from './styles';

export function SlidingConfirmationButton({
  onButtonClick,
  onIconClick,
  toggleButton,
  loading,
  confirmationButtonProps,
  confirmationButtonText,
  toolTipTitle,
  icon,
  direction = 'right',
}: SlidingConfirmationButtonProps) {
  const containerRef = useRef<HTMLElement>(null);

  const button = (
    <ProgressAwareButton onClick={onButtonClick} loading={loading} sx={{ mx: 1 }} {...confirmationButtonProps} size="small">
      {confirmationButtonText}
    </ProgressAwareButton>
  );

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box sx={slidingConfirmationContainerSx} ref={containerRef}>
        <Tooltip title={toolTipTitle}>
          <IconButton onClick={handleIconClick}>{icon}</IconButton>
        </Tooltip>

        <Slide direction={direction} in={toggleButton} container={containerRef.current} mountOnEnter unmountOnExit>
          {button}
        </Slide>
      </Box>
    </ClickAwayListener>
  );

  function handleClickAway() {
    if (toggleButton) {
      onIconClick('outsideClick');
    }
  }

  function handleIconClick() {
    onIconClick('iconClick');
  }
}

interface SlidingConfirmationButtonProps {
  onButtonClick: MouseEventHandler<HTMLButtonElement>;
  onIconClick: (clickPosition: SlidingConfirmationIconButtonClickPosition) => void;
  toggleButton: boolean;
  loading: boolean;
  confirmationButtonProps: ButtonProps;
  confirmationButtonText: string;
  toolTipTitle: string;
  icon: ReactNode;
  direction?: SlideProps['direction'];
}

export type SlidingConfirmationIconButtonClickPosition = 'iconClick' | 'outsideClick';
