import { EnumWithStringValue } from '../../utils/EnumUtils';

export enum FloatingButtonType {
  ViewMode = 'VIEW_MODE',
  PlanningMode = 'PLANNING_MODE',
  ListMode = 'LIST_MODE',
  MapMode = 'MAP_MODE',
}

export namespace FloatingButtonHelper {
  export function toString(floatinButtonType: FloatingButtonType): string | null {
    return EnumWithStringValue.getEnumKeyByEnumValue(FloatingButtonType, floatinButtonType);
  }

  export function previewModeTranslationKey(button: FloatingButtonType): string {
    return `previewModes.${toString(button)}`;
  }
}
