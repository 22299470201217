import { gql } from '@apollo/client';

export const FREE_VEHICLES_QUERY = gql`
  query FreeVehiclesSubscription($radius: Float!, $lon: Float!, $lat: Float!) {
    freeVehiclesByLocation(radius: $radius, lon: $lon, lat: $lat) {
      vehicleId
      systemId
      qrCodeId
      name
      lat
      lon
      isReserved
      isDisabled
      vehicleTypeId
      lastReported
      currentRangeMeters
      currentFuelPercent
      stationId
      homeStationId
      pricingPlanId
      vehicleEquipments
      availableUntil
      stableVehicleId
      volatileVehicleId
      mspName
      formFactor
      maxRangeMeters
      propulsionType
      usageStatus
      seats
    }
  }
`;

export const FREE_VEHICLES_SUBSCRIPTION = gql`
  subscription FreeVehiclesSubscription($radius: Float!, $lon: Float!, $lat: Float!) {
    freeVehiclesByLocation(radius: $radius, lon: $lon, lat: $lat) {
      vehicleId
      systemId
      qrCodeId
      name
      lat
      lon
      isReserved
      isDisabled
      vehicleTypeId
      lastReported
      currentRangeMeters
      currentFuelPercent
      stationId
      homeStationId
      pricingPlanId
      vehicleEquipments
      availableUntil
      stableVehicleId
      volatileVehicleId
      mspName
      formFactor
      maxRangeMeters
      propulsionType
      usageStatus
      seats
    }
  }
`;

export const FREE_DONKEY_VEHICLES_QUERY = gql`
  query FreeDonkeyVehiclesSubscription($radius: Float!, $lon: Float!, $lat: Float!) {
    freeDonkeyVehiclesByLocation(radius: $radius, lon: $lon, lat: $lat) {
      vehicleId
      systemId
      qrCodeId
      name
      lat
      lon
      isReserved
      isDisabled
      vehicleTypeId
      lastReported
      currentRangeMeters
      currentFuelPercent
      stationId
      homeStationId
      pricingPlanId
      vehicleEquipments
      availableUntil
      stableVehicleId
      volatileVehicleId
      mspName
      formFactor
      maxRangeMeters
      propulsionType
      usageStatus
      seats
    }
  }
`;

export const FREE_DONKEY_VEHICLES_SUBSCRIPTION = gql`
  subscription FreeDonkeyVehiclesSubscription($radius: Float!, $lon: Float!, $lat: Float!) {
    freeDonkeyVehiclesByLocation(radius: $radius, lon: $lon, lat: $lat) {
      vehicleId
      systemId
      qrCodeId
      name
      lat
      lon
      isReserved
      isDisabled
      vehicleTypeId
      lastReported
      currentRangeMeters
      currentFuelPercent
      stationId
      homeStationId
      pricingPlanId
      vehicleEquipments
      availableUntil
      stableVehicleId
      volatileVehicleId
      mspName
      formFactor
      maxRangeMeters
      propulsionType
      usageStatus
      seats
    }
  }
`;

export const FREE_MEIAUDO_VEHICLES_QUERY = gql`
  query FreeMeiAudoVehiclesSubscription($radius: Float!, $lon: Float!, $lat: Float!) {
    freeMeiAudoVehiclesByLocation(radius: $radius, lon: $lon, lat: $lat) {
      vehicleId
      systemId
      qrCodeId
      name
      lat
      lon
      isReserved
      isDisabled
      vehicleTypeId
      lastReported
      currentRangeMeters
      currentFuelPercent
      stationId
      homeStationId
      pricingPlanId
      vehicleEquipments
      availableUntil
      stableVehicleId
      volatileVehicleId
      mspName
      formFactor
      maxRangeMeters
      propulsionType
      usageStatus
      seats
    }
  }
`;

export const FREE_MEIAUDO_VEHICLES_SUBSCRIPTION = gql`
  subscription FreeMeiAudoVehiclesSubscription($radius: Float!, $lon: Float!, $lat: Float!) {
    freeMeiAudoVehiclesByLocation(radius: $radius, lon: $lon, lat: $lat) {
      vehicleId
      systemId
      qrCodeId
      name
      lat
      lon
      isReserved
      isDisabled
      vehicleTypeId
      lastReported
      currentRangeMeters
      currentFuelPercent
      stationId
      homeStationId
      pricingPlanId
      vehicleEquipments
      availableUntil
      stableVehicleId
      volatileVehicleId
      mspName
      formFactor
      maxRangeMeters
      propulsionType
      usageStatus
      seats
    }
  }
`;

export const ALL_COMODULE_VEHICLES_SUBSCRIPTION = gql`
  subscription AllCoModuleVehiclesByLocation($radius: Float!, $lon: Float!, $lat: Float!, $formFactor: String!) {
    allCoModuleVehiclesByLocation(radius: $radius, lon: $lon, lat: $lat, formFactor: $formFactor) {
      vehicleId
      systemId
      qrCodeId
      name
      lat
      lon
      isReserved
      isDisabled
      vehicleTypeId
      lastReported
      currentRangeMeters
      currentFuelPercent
      stationId
      homeStationId
      pricingPlanId
      vehicleEquipments
      availableUntil
      stableVehicleId
      volatileVehicleId
      mspName
      formFactor
      maxRangeMeters
      propulsionType
      usageStatus
      seats
    }
  }
`;

export const ALL_CLOUDBOX_VEHICLES_SUBSCRIPTION = gql`
  subscription AllCloudBoxxVehiclesSubscription($radius: Float!, $lon: Float!, $lat: Float!) {
    allCloudBoxxVehiclesByLocation(radius: $radius, lon: $lon, lat: $lat) {
      vehicleId
      systemId
      qrCodeId
      name
      lat
      lon
      isReserved
      isDisabled
      vehicleTypeId
      lastReported
      currentRangeMeters
      currentFuelPercent
      stationId
      homeStationId
      pricingPlanId
      vehicleEquipments
      availableUntil
      stableVehicleId
      volatileVehicleId
      mspName
      formFactor
      maxRangeMeters
      propulsionType
      usageStatus
      seats
    }
  }
`;

export const All_ZONES_INFORMATION_QUERY = gql`
  query AllZoneInformationQuery {
    allZonesInformation {
      description
      effectiveDate
      geographyId
      geographyJson {
        features {
          type
          geometry {
            type
            coordinates
          }
          properties {
            name
            start
            end
            rules {
              vehicleTypeId
              rideAllowed
              rideThroughAllowed
              maximumSpeedKph
              stationParking
            }
          }
        }
        systemId
        type
      }
      geographyType
      name
      prevGeographies
      publishedDate
      retireDate
    }
  }
`;
