declare global {
  interface BigInt {
    toJSON: () => string;
  }
}

// eslint-disable-next-line no-extend-native
BigInt.prototype.toJSON = function () {
  return this.toString();
};

// https://stackoverflow.com/a/42257742
export {};
