import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

import { BudgetUsageLinearProgressbar } from './BudgetUsageLinearProgressbar';
import { MembershipInfo } from './MembershipInfo';

export function ActiveTabContent() {
  return (
    <Box mt={5}>
      <Typography variant="bodyLargeBold">Lagarde Budget 600</Typography>
      <BudgetUsageLinearProgressbar />
      <MembershipInfo />
    </Box>
  );
}
