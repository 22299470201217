import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

// Determine if the app is running in production
const isProduction = process.env.REACT_APP_ENV === 'production';

// Feature flags with environment-based initial values
export const isDashboardPageVisibleAtom = atom(!isProduction);

// Budget Refund Form under USer Trip Details is Dummy and not interacting with any API as of now, once we will have API integrated we can remove this flag
export const isTripBudgetRefundSectionVisibleAtom = atomWithStorage('SHOW_BUDGET_REFUND', false);
