import React from 'react';

import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { Button } from '@mui/material';
import { Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';

export function WmvCardActionButton({ type, btnLabel, targetUrl, email, emailSubject }: WmvCardActionButtonProps) {
  if (type === 'info') return null;
  if (type === 'link') {
    const isExternalLink = !targetUrl!.startsWith('/');
    return (
      <Link to={targetUrl!} target={isExternalLink ? '_blank' : undefined}>
        <Button variant="tertiaryContrast" color="primary" startIcon={<OpenInNewOutlinedIcon />} size="medium">
          {btnLabel}
        </Button>
      </Link>
    );
  }
  if (type === 'email')
    return (
      <MuiLink underline="none" href={`mailto:${email}?subject=${encodeURIComponent(emailSubject!)}`} variant="bodySmall">
        <Button variant="tertiary" color="primary" size="medium">
          {btnLabel}
        </Button>
      </MuiLink>
    );
}

interface WmvCardActionButtonProps {
  type: 'info' | 'link' | 'email';
  targetUrl?: string;
  email?: string;
  btnLabel?: string;
  emailSubject?: string;
}
