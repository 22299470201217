import type { ChangeEvent } from 'react';

import type { CheckboxProps, SxProps, Theme } from '@mui/material';
import MuiCheckbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

export function InternalCheckbox<T>({
  checked,
  onChange,
  name,
  value,
  size = 'small',
  label,
  disabled = false,
  color = 'secondary',
  sx,
}: CheckboxComponentProps<T>) {
  return (
    <FormControlLabel
      sx={sx}
      control={
        <MuiCheckbox checked={checked} onChange={onChange} name={name} size={size} value={value} disabled={disabled} color={color} />
      }
      label={label}
    />
  );
}

export interface CheckboxComponentProps<T> {
  name: string;
  label: string;
  value: T;
  disabled?: boolean;
  color?: CheckboxProps['color'];
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  size?: CheckboxProps['size'];
  checked: boolean;
  hasError?: boolean;
  helperText?: string;
  sx?: SxProps<Theme>;
}
