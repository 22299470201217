import { createReducer } from '@reduxjs/toolkit';

import { ApiRequestStatus, ZoneStatus } from '../../models';

import {
  getAllZonesAsyncThunkAction,
  createZoneAsyncThunkAction,
  updateZoneAsyncThunkAction,
  deleteZoneAsyncThunkAction,
  resetErrorAction,
  getPublishedZonesAsyncThunkAction,
} from './actions';
import { ZonesState } from './models';

const initialState: ZonesState = {
  entities: {
    publishedZones: [],
    allZones: [],
  },
  apiRequestStatus: {
    getZones: ApiRequestStatus.Idle,
    createZone: ApiRequestStatus.Idle,
    updateZone: ApiRequestStatus.Idle,
    deleteZone: ApiRequestStatus.Idle,
    getPublishedZones: ApiRequestStatus.Idle,
  },
  error: {
    createZone: null,
    updateZone: null,
    deleteZone: null,
  },
};

const zonesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getAllZonesAsyncThunkAction.pending, (state, action) => {
      state.apiRequestStatus.getZones = ApiRequestStatus.Pending;
    })
    .addCase(getAllZonesAsyncThunkAction.fulfilled, (state, action) => {
      state.apiRequestStatus.getZones = ApiRequestStatus.Succeeded;
      state.entities.allZones = action.payload;
    })
    .addCase(getAllZonesAsyncThunkAction.rejected, (state, action) => {
      state.apiRequestStatus.getZones = ApiRequestStatus.Failed;
    })
    .addCase(getPublishedZonesAsyncThunkAction.pending, (state, action) => {
      state.apiRequestStatus.getPublishedZones = ApiRequestStatus.Pending;
    })
    .addCase(getPublishedZonesAsyncThunkAction.fulfilled, (state, action) => {
      state.apiRequestStatus.getPublishedZones = ApiRequestStatus.Succeeded;
      state.entities.publishedZones = action.payload;
    })
    .addCase(getPublishedZonesAsyncThunkAction.rejected, (state, action) => {
      state.apiRequestStatus.getPublishedZones = ApiRequestStatus.Failed;
    })
    .addCase(createZoneAsyncThunkAction.pending, (state, action) => {
      state.apiRequestStatus.createZone = ApiRequestStatus.Pending;
      state.error.createZone = null;
    })
    .addCase(createZoneAsyncThunkAction.fulfilled, (state, action) => {
      state.apiRequestStatus.createZone = ApiRequestStatus.Succeeded;
      state.entities.allZones.push(action.payload);
    })
    .addCase(createZoneAsyncThunkAction.rejected, (state, action) => {
      state.apiRequestStatus.createZone = ApiRequestStatus.Failed;
      state.error.createZone = action.payload! || action.error;
    })
    .addCase(updateZoneAsyncThunkAction.pending, (state, action) => {
      state.apiRequestStatus.updateZone = ApiRequestStatus.Pending;
      state.error.updateZone = null;
    })
    .addCase(updateZoneAsyncThunkAction.fulfilled, (state, action) => {
      state.apiRequestStatus.updateZone = ApiRequestStatus.Succeeded;
      const editZoneIndex = state.entities.allZones.findIndex((zone) => zone.id === action.payload.id);
      state.entities.allZones[editZoneIndex] = action.payload;
      if (action.payload.status === ZoneStatus.Published) {
        const publishedZoneIndex = state.entities.publishedZones.findIndex((zone) => zone.id === action.payload.id);
        state.entities.publishedZones[publishedZoneIndex] = action.payload;
      }
    })
    .addCase(updateZoneAsyncThunkAction.rejected, (state, action) => {
      state.apiRequestStatus.updateZone = ApiRequestStatus.Failed;
      state.error.updateZone = action.payload! || action.error;
    })
    .addCase(deleteZoneAsyncThunkAction.pending, (state, action) => {
      state.apiRequestStatus.deleteZone = ApiRequestStatus.Pending;
      state.error.deleteZone = null;
    })
    .addCase(deleteZoneAsyncThunkAction.fulfilled, (state, action) => {
      state.apiRequestStatus.deleteZone = ApiRequestStatus.Succeeded;
      const updatedZones = state.entities.allZones.filter((zone) => zone.id !== action.payload.id);
      state.entities.allZones = updatedZones;
    })
    .addCase(deleteZoneAsyncThunkAction.rejected, (state, action) => {
      state.apiRequestStatus.deleteZone = ApiRequestStatus.Failed;
      state.error.deleteZone = action.payload! || action.error;
    })
    .addCase(resetErrorAction, (state, action) => {
      state.error[action.payload] = null;
    });
});

export default zonesReducer;
