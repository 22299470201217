import { object, string } from 'yup';

import { intl } from '../../../../utils/intl';
import { searchBudgetPlanMemberFormKeys } from '../BudgetPlanMemberships/helpers';

export const searchPlanMemberFormValidationSchema = () => {
  const { formatMessage } = intl;

  return object().shape({
    [searchBudgetPlanMemberFormKeys.memberEmail]: string()
      .required(formatMessage({ id: 'common.errorMessage.emailRequired' }))
      .email(formatMessage({ id: 'common.wrongFormat' })),
  });
};
