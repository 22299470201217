/* eslint-disable no-extend-native */
import type { PluginFunc, Dayjs } from 'dayjs';
import dayjs from 'dayjs';

import {
  DEFAULT_TIME_FORMAT_WITH_SECONDS,
  DEFAULT_TIME_FORMAT,
  DEFAULT_12HR_TIME_FORMAT_WITH_SECONDS,
  DEFAULT_12HR_TIME_FORMAT,
  ISO_DATE_FORMAT_DAYJS,
} from '../../utils/constants';

declare global {
  interface String {
    asDayjsTime(): Dayjs;
    as12HrDayjsTime(): Dayjs;
    asDayjsDate(format?: string): Dayjs;
  }
}

const stringToDayjsConverter: PluginFunc = (option, dayjsClass, dayjsFactory) => {
  String.prototype.asDayjsTime = function (this: string): Dayjs {
    return dayjs(this, [DEFAULT_TIME_FORMAT, DEFAULT_TIME_FORMAT_WITH_SECONDS]);
  };

  String.prototype.as12HrDayjsTime = function (this: string): Dayjs {
    return dayjs(this, [DEFAULT_12HR_TIME_FORMAT, DEFAULT_12HR_TIME_FORMAT_WITH_SECONDS]);
  };

  String.prototype.asDayjsDate = function (this: string, format: string = ISO_DATE_FORMAT_DAYJS): Dayjs {
    return dayjs(this, format);
  };
};

export default stringToDayjsConverter;
