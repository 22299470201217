import { useEffect } from 'react';

import { Map, Polygon } from 'leaflet';

export const useDrawFinishedEvent = (map: Map, onDrawDisabled: (layer: Polygon) => void) => {
  useEffect(() => {
    // @ts-ignore
    const handleLayerDrawComplete = ({ layer }) => {
      onDrawDisabled(layer as Polygon);
    };

    map.on('pm:create', handleLayerDrawComplete);

    return () => {
      map.off('pm:create', handleLayerDrawComplete);
    };
  }, [map, onDrawDisabled]);
};
