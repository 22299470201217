import { useState } from 'react';

import { useTheme } from '@mui/material/styles';
import { type Row } from '@tanstack/react-table';

import NewDataTable from '../../../design-system/NewDataTable';
import { CustomerDetails, SearchUsersQueryParams } from '../../../models';
import CustomerInformationModal from '../../CustomerInformationModal';
import { LoadingAndErrorWithRetryAndNoResults } from '../../LoadingAndErrorWithRetryAndNoResults';
import { SearchedCustomersTripsColumnDefs } from '../table-column-defs/CustomersListColumnDef';

interface CustomerDetailsTableProps {
  customerDetails: CustomerDetails[];
  loading: boolean;
  error: boolean;
  onRetry: (searchParameters: SearchUsersQueryParams) => void;
  lastSearchParameters: SearchUsersQueryParams | null;
  noSearchResults: boolean;
}

export const CustomerDetailsTable = (props: CustomerDetailsTableProps) => {
  const { customerDetails, loading, error, onRetry, lastSearchParameters, noSearchResults } = props;
  const { palette } = useTheme();

  const [openModal, setOpenModal] = useState(false);
  const [userInfo, setUserInfo] = useState<CustomerDetails>();

  const handleOpenModal = (user: CustomerDetails) => {
    setOpenModal(true);
    setUserInfo(user);
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setUserInfo(undefined);
  };
  return (
    <>
      {customerDetails.length === 0 ? (
        // 215px depends on the preceding elements; needed to center the text vertically
        <LoadingAndErrorWithRetryAndNoResults
          style={{ minHeight: `calc(100vh - 192px)`, backgroundColor: palette.white.main }}
          error={error}
          loading={loading}
          onRetry={() => {
            if (lastSearchParameters) onRetry(lastSearchParameters);
          }}
          baseTranslationKey="customerDatabase"
          noResults={noSearchResults}
        />
      ) : (
        <NewDataTable<CustomerDetails, 'default'>
          columns={SearchedCustomersTripsColumnDefs}
          data={customerDetails}
          onRowClickHandler={(row: Row<CustomerDetails>) => handleOpenModal(row.original)}
          height={`calc(100vh - 192px)`}
        />
      )}
      {openModal && userInfo && <CustomerInformationModal isOpen={openModal} onClose={handleModalClose} userInfo={userInfo} />}
    </>
  );
};
