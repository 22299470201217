import { useEffect, useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

import WikimoveMap from '../../components/Map/WikimoveMap';
import Widget from '../../components/Widget';
import { Category, Shortcuts } from '../../models';
import { categoryShortcutsMapping } from '../../utils/constants';
import { getAllDashboardWidgetsData } from '../../utils/queries';

export default function Dashboard() {
  const intl = useIntl();
  const { formatMessage } = intl;
  const [dashboardShortcuts, setDashboardShortcuts] = useState<Category[]>([]);
  const [frequentlyUsedShortcuts] = useState([
    {
      type: 'user_add_admin_profile',
      title: formatMessage({ id: 'pages.home.addAdminProfile' }),
      description: formatMessage({ id: 'home.shortcuts.user_management.user_add_admin_profile.description' }),
    },
    {
      type: 'user_support_requests',
      title: formatMessage({ id: 'pages.home.supportRequests' }),
      description: formatMessage({ id: 'home.shortcuts.user_management.user_support_requests.description' }),
    },
  ]);

  useEffect(() => {
    getAllDashboardWidgetsData().then((res) => {
      if (res && res.length > 0) {
        const result = res.map((item) => {
          const category = categoryShortcutsMapping(intl)[item.category];
          if (category) {
            const shortcuts = item.shortcuts.map((v) => {
              return (
                category.shortcuts.find((s) => s.type === v.type) || {
                  type: v.type,
                  title: v.type,
                  description: v.type,
                  url: v.type,
                }
              );
            });
            return { name: category.name, shortcuts };
          }
          const shortcuts: Shortcuts[] = item.shortcuts.map((v) => {
            return {
              type: v.type,
              title: v.metadata?.name || v.type,
              description: v.metadata?.providerType || v.type,
              url: v.type,
            };
          });
          return { name: item.category, shortcuts };
        });
        setDashboardShortcuts(result);
      } else {
        setDashboardShortcuts([]);
      }
    });
  }, [intl]);

  return (
    <Grid sx={{ marginX: 'auto', paddingX: '55px', py: 3, overflow: 'auto' }}>
      <Box sx={{ paddingTop: 1, height: '250px' }}>
        <WikimoveMap
          // style={{ height: '350px' }}
          zoomBtn={false}
          showLayers={false}
          openMarkerId={''}
          setPopupTableData={() => {}}
          // onClick={routeChange}
        >
          <></>
        </WikimoveMap>
      </Box>
      <Box>
        <Box>
          <Box m={2} p={1} borderBottom={'1px solid #aeaeae'}>
            <Typography
              variant="bodyLargeBold"
              style={{
                color: '#2F2E4152',
              }}
            >
              {formatMessage({ id: 'pages.home.frequentlyUsed' })}
            </Typography>
          </Box>
          <Box display="flex" gap={2} p={2} sx={{ overflowX: 'auto' }}>
            {(frequentlyUsedShortcuts || []).map((shortcut) => {
              return shortcut && <Widget key={shortcut.type} title={shortcut.title} description={shortcut.description} url="/zones" />;
            })}
          </Box>
        </Box>
        {dashboardShortcuts.map((item) => {
          return (
            <Box key={item.name}>
              <Box m={2} p={1} borderBottom={'1px solid #aeaeae'}>
                <Typography
                  variant="bodyLarge"
                  style={{
                    color: '#2F2E4152',
                  }}
                >
                  {item.name}
                </Typography>
              </Box>
              <Box display="flex" gap={2} p={2} sx={{ overflowX: 'auto' }}>
                {(item.shortcuts || []).map((shortcut) => {
                  return (
                    shortcut && <Widget key={shortcut.type} title={shortcut.title} description={shortcut.description} url={shortcut.url} />
                  );
                })}
              </Box>
            </Box>
          );
        })}
      </Box>
    </Grid>
  );
}
