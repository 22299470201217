import { Typography, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import isEmpty from 'lodash/isEmpty';
import { useIntl } from 'react-intl';

import { ReactComponent as Checkmark } from '../../../../../assets/icons/outline/checkmark.svg';
import { ReactComponent as Close } from '../../../../../assets/icons/outline/close.svg';
import { BudgetPlanMembershipDto } from '../../../../../services/budget-plan-memberships';
import { intl } from '../../../../../utils/intl';
import { MembershipDetailsRow } from '../common/MembershipDetailsRow';
import { MembershipLastStatusUpdate } from '../common/MembershipLastStatusUpdate';

interface MembershipInfoProps {
  membership: BudgetPlanMembershipDto;
}

export const MembershipInfo = ({ membership }: MembershipInfoProps) => {
  const { palette } = useTheme();
  const { formatMessage: fm } = useIntl();

  return (
    <Box bgcolor={palette.baseLight.base0} p={2} flex={1}>
      {renderGeneralInformation()}
      {renderPersonalInformation()}
      {!isEmpty(membership?.activeState.sharingMembers) && renderLinkedMembersInformation()}
    </Box>
  );

  function renderGeneralInformation() {
    return (
      <Box borderRadius={1}>
        <Typography variant="bodyMediumBold" mb={1}>
          {fm({ id: 'common.generalInformation' })}
        </Typography>
        <MembershipDetailsRow
          labelTranslationKey="common.lastStatusUpdate"
          title={<MembershipLastStatusUpdate membership={membership!} />}
          toolTipText={'budgetPlanMembership.lastStatusUpdateTooltip'.i18nText()}
          useGrid
        />
        <MembershipDetailsRow
          labelTranslationKey="common.membershipStatus"
          title={membershipStatus()}
          toolTipText={'budgetPlanMembership.membershipStatusTooltip'.i18nText()}
          useGrid
        />
        {membership!.isExistingMembership() && (
          <MembershipDetailsRow
            labelTranslationKey="common.budgetPlanActivationDate"
            title={membership!.activationDate?.formatAsDateString()}
            toolTipText={'budgetPlanMembership.planActivationDateTooltip'.i18nText()}
            useGrid
          />
        )}
      </Box>
    );
  }

  function renderPersonalInformation() {
    return (
      <Box mt={3}>
        <Typography variant="bodyMediumBold" mb={1}>
          {fm({ id: 'common.personalInfo' })}
        </Typography>
        <MembershipDetailsRow labelTranslationKey="common.firstName" title={membership!.firstName} useGrid />
        <MembershipDetailsRow labelTranslationKey="common.lastName" title={membership!.lastName} useGrid />
        <MembershipDetailsRow labelTranslationKey="common.emailAddress" title={membership!.email} useGrid />
      </Box>
    );
  }
  function renderLinkedMembersInformation() {
    return (
      <Box mt={3}>
        <Typography variant="bodyMediumBold">{intl.formatMessage({ id: 'common.linkedMembers' })}</Typography>
        <Stack spacing={1} mt={1}>
          {membership?.activeState.sharingMembers!.map((sharingMember) => (
            <Typography key={sharingMember.email} variant="bodyMedium">
              {sharingMember.email}
            </Typography>
          ))}
        </Stack>
      </Box>
    );
  }

  function membershipStatus() {
    return membership?.isLinked() ? (
      <Box display="flex" alignItems="center">
        <Checkmark fill={palette.success.main} height="16px" width="16px" />
        <Typography ml={1}>{'budgetPlanMembership.linked'.i18nText()}</Typography>
      </Box>
    ) : (
      <Box display="flex" alignItems="center">
        <Close fill={palette.alert.main} height="16px" width="16px" />
        <Typography ml={1}>{'budgetPlanMembership.notLinked'.i18nText()}</Typography>
      </Box>
    );
  }
};
