import type { Dayjs, PluginFunc } from 'dayjs';

declare module 'dayjs' {
  interface Dayjs {
    isSameTime(time: Dayjs): boolean;
    isBeforeTime(time: Dayjs): boolean;
    isAfterTime(time: Dayjs): boolean;
    isSameOrBeforeTime(time: Dayjs): boolean;
    isSameOrAfterTime(time: Dayjs): boolean;
    isSameDate(date: Dayjs): boolean;
    isBeforeDate(date: Dayjs): boolean;
    isAfterDate(date: Dayjs): boolean;
    isSameOrAfterDate(date: Dayjs): boolean;
  }
}

const timeAndDateUtilsPlugin: PluginFunc = (option, dayjsClass, dayjsFactory) => {
  dayjsClass.prototype.isSameTime = function (time: Dayjs): boolean {
    return this.secondsFromBeginningOfDay() === time.secondsFromBeginningOfDay();
  };

  dayjsClass.prototype.isBeforeTime = function (time: Dayjs): boolean {
    return this.secondsFromBeginningOfDay() < time.secondsFromBeginningOfDay();
  };

  dayjsClass.prototype.isAfterTime = function (time: Dayjs): boolean {
    return this.secondsFromBeginningOfDay() > time.secondsFromBeginningOfDay();
  };

  dayjsClass.prototype.isSameOrBeforeTime = function (time: Dayjs): boolean {
    return this.isSameTime(time) || this.isBeforeTime(time);
  };

  dayjsClass.prototype.isSameOrAfterTime = function (time: Dayjs): boolean {
    return this.isSameTime(time) || this.isAfterTime(time);
  };

  dayjsClass.prototype.isSameDate = function (date: Dayjs): boolean {
    return this.isSame(date, 'date');
  };

  dayjsClass.prototype.isBeforeDate = function (date: Dayjs): boolean {
    return this.isBefore(date, 'date');
  };

  dayjsClass.prototype.isAfterDate = function (date: Dayjs): boolean {
    return this.isAfter(date, 'date');
  };

  dayjsClass.prototype.isSameOrAfterDate = function (date: Dayjs): boolean {
    return this.isSameOrAfter(date, 'date');
  };
};

export default timeAndDateUtilsPlugin;
