import React from 'react';

import { CardHeader } from '@mui/material';
import Typography from '@mui/material/Typography';

import { CustomIconComponent, IconType } from '../../utils/CustomIcon';

export function WmvCardHeader({ icon, headerAlignment, titleText, color }: CardHeaderProps) {
  return (
    <CardHeader
      avatar={<CustomIconComponent icon={icon} size="48px" color={color} />}
      title={
        <Typography variant={headerAlignment === 'horizontal' ? 'h3' : 'bodyLargeBold'} component="h3" color={color}>
          {titleText}
        </Typography>
      }
      sx={{
        '&.MuiCardHeader-root': {
          flexDirection: headerAlignment === 'horizontal' ? 'row' : 'column',
          alignItems: headerAlignment === 'horizontal' ? 'center' : 'baseline',
          gap: headerAlignment === 'horizontal' ? 2 : 3,
          p: 0,
        },
      }}
    />
  );
}

interface CardHeaderProps {
  titleText: string;
  icon: IconType;
  color: string;
  headerAlignment?: 'vertical' | 'horizontal';
}
