import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useIntl } from 'react-intl';
import { useRouteError } from 'react-router';
import { Link } from 'react-router-dom';

import Checkpoint from '../assets/illustrations/checkpoint.svg';
import consoleLogger from '../services/ConsoleLogger';
import { HEADER_HEIGHT, DEFAULT_MAIN_CONTENT_HEIGHT } from '../utils/constants';
import { CustomIconComponent } from '../utils/CustomIcon';

import Header from './Header/Header';

export default function ErrorBoundary() {
  const { formatMessage } = useIntl();
  let error = useRouteError();
  consoleLogger.logError('App crashed with error', error);

  return (
    <Box style={{ marginTop: HEADER_HEIGHT }}>
      <Header />
      <Stack justifyContent="center" alignItems="center" spacing={2} height={DEFAULT_MAIN_CONTENT_HEIGHT} gap={6}>
        <Stack>
          <img src={Checkpoint} alt="" />
          <Stack justifyContent="center" alignItems="center" spacing={1}>
            <Typography variant="h3" component="h3">
              {formatMessage({ id: 'common.errorMessages.anErrorOccurredOnOurSide' })}
            </Typography>
            <Stack justifyContent="center" alignItems="center">
              <Typography variant="bodyMedium" component="div">
                {formatMessage({ id: 'common.errorMessages.weRanIntoAProblemWhileProcessingYourRequest' })}
              </Typography>
              <Typography variant="bodyMedium" component="div">
                {formatMessage({ id: 'common.errorMessages.weAreWorkingToFixIt' })}
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack direction="row" spacing={2}>
          <Link to="/">
            <Button variant="bluePrimary" startIcon={<CustomIconComponent icon="ArrowLeft" />}>
              {formatMessage({ id: 'common.buttons.returnToHomepage' })}
            </Button>
          </Link>
          <Link to="/support">
            <Button variant="tertiary">{formatMessage({ id: 'common.buttons.getAssistance' })}</Button>
          </Link>
        </Stack>
      </Stack>
    </Box>
  );
}
