import { themeOptions } from '../../init-setup/ThemeOptions';
import { EnumWithStringValue } from '../../utils/EnumUtils';
import { intl } from '../../utils/intl';
import { DepositFrequency } from '../budget-plan';

export enum LastChangeAttemptStatus {
  InProgress = 'IN_PROGRESS',
  Success = 'SUCCESS',
  Failed = 'FAILED',
}

interface LastChangeAttemptStatusToUnderivedMetadata {
  displayText: string;
  color: string;
}
export namespace LastChangeAttemptStatusHelper {
  const { formatMessage } = intl;
  const statusToUnderivedMetadata = new Map<LastChangeAttemptStatus, LastChangeAttemptStatusToUnderivedMetadata>([
    [
      LastChangeAttemptStatus.InProgress,
      { displayText: formatMessage({ id: 'common.status.inProgress' }), color: themeOptions.palette.warning.light },
    ],
    [
      LastChangeAttemptStatus.Success,
      { displayText: formatMessage({ id: 'rule.status.active' }), color: themeOptions.palette.success.main },
    ],
    [
      LastChangeAttemptStatus.Failed,
      { displayText: formatMessage({ id: 'common.status.failed' }), color: themeOptions.palette.alert.main },
    ],
  ]);

  export function metadata(status: LastChangeAttemptStatus): LastChangeAttemptStatusToUnderivedMetadata {
    const underivedMetadata = statusToUnderivedMetadata.get(status);
    if (!underivedMetadata) {
      throw Error(`
            Specified LastChangeAttemptStatus: ${toString(status)} doesnt have corresponding underived/explicitly defined metadata.
            This usually (not always) means a bug or incomplete implementation.
        `);
    }

    return { ...underivedMetadata };
  }

  export function toString(status: LastChangeAttemptStatus): string | null {
    return EnumWithStringValue.getEnumKeyByEnumValue(DepositFrequency, status);
  }
}
