import { FC, JSX, useRef, useState } from 'react';

import { Box, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';

import { ErrorMessage } from '../../components/FormFields/ErrorMessage';
import { DayOfWeek, DayOfWeekHelper } from '../../models';
import { MAX_DEFAULT_DATE } from '../../utils/constants';

import { dayOfWeekButtonStyles, daysOfWeekButtonContainerStyle, disabledOverlayShadow } from './DaysOfWeekPicker.styles';

import dayOfWeekToShortFormTranslationKey = DayOfWeekHelper.dayOfWeekToShortFormTranslationKey;

export const DaysOfWeekPicker: FC<DayOfWeekPickerProps> = ({
  onDayOfWeekChange,
  initialValue,
  title,
  disabled,
  dateRange = { startDate: dayjs(), endDate: dayjs(MAX_DEFAULT_DATE) },
  error,
  selectAllDaysOnLastUnselect = false,
}) => {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const dayOfWeekButtons: JSX.Element[] = [];

  const [prevSelectedDaysOfWeek, setPrevSelectedDaysOfWeek] = useState(initialValue);
  const [prevDateRange, setPrevDateRange] = useState(dateRange);
  const validDaysOfWeekRef = useRef<Set<DayOfWeek>>(validDaysOfWeekForSelectedDateRange(dateRange));

  if (prevSelectedDaysOfWeek !== initialValue) {
    setPrevSelectedDaysOfWeek(initialValue);
  }

  if (prevDateRange.startDate !== dateRange.startDate || prevDateRange.endDate !== dateRange.endDate) {
    setPrevDateRange(dateRange);
    validDaysOfWeekRef.current = validDaysOfWeekForSelectedDateRange(dateRange);
    // TODO: Consider doing this way https://gitlab.com/wikimove/web-app/mobility-manager/-/merge_requests/271#note_1752781686
    if (validDaysOfWeekRef.current.size < 7 && initialValue.size !== 0) {
      onDayOfWeekChange(new Set());
    }
  }

  const handleDayOfWeekChange = (dayOfWeek: DayOfWeek) => {
    let updatedSelectedDaysOfWeek = new Set([...prevSelectedDaysOfWeek]);
    if (prevSelectedDaysOfWeek.has(dayOfWeek)) {
      updatedSelectedDaysOfWeek.delete(dayOfWeek);
    } else {
      updatedSelectedDaysOfWeek.add(dayOfWeek);
    }

    if (updatedSelectedDaysOfWeek.size === 0 && selectAllDaysOnLastUnselect) {
      updatedSelectedDaysOfWeek = validDaysOfWeekRef.current;
    }

    setPrevSelectedDaysOfWeek(updatedSelectedDaysOfWeek);
    onDayOfWeekChange(updatedSelectedDaysOfWeek);
  };

  for (const [dayOfWeek, shortFormTranslationKey] of dayOfWeekToShortFormTranslationKey()) {
    const isSelected = initialValue.has(dayOfWeek);
    const isDisabled = !validDaysOfWeekRef.current.has(dayOfWeek);

    dayOfWeekButtons.push(
      <Button
        sx={dayOfWeekButtonStyles(theme, isSelected)}
        onClick={() => handleDayOfWeekChange(dayOfWeek)}
        variant="outlined"
        key={dayOfWeek}
        disabled={isDisabled}
      >
        <Typography variant={!isSelected ? 'bodyMedium' : 'bodyMediumBold'}>{formatMessage({ id: shortFormTranslationKey })}</Typography>
      </Button>,
    );
  }

  return (
    <Box py={1}>
      <Typography variant="bodySmall" color={theme.palette.baseLight.base76}>
        {title}
      </Typography>
      <Box sx={daysOfWeekButtonContainerStyle}>
        {disabled && <Box sx={disabledOverlayShadow}></Box>}
        {dayOfWeekButtons}
      </Box>
      <ErrorMessage error={error} />
    </Box>
  );
};

const validDaysOfWeekForSelectedDateRange = (dateRange: DaysOfWeekDateRange): Set<DayOfWeek> => {
  const { startDate, endDate } = dateRange;
  let validDays: Set<DayOfWeek> = DayOfWeekHelper.allDaysOfWeek();
  if (startDate && endDate) {
    const numberOfDays = endDate.diff(startDate, 'day');
    if (numberOfDays < 7) {
      const daysOfWeekBetweenDateRange = dayjs.daysOfWeekBetweenDateRange(startDate, endDate);
      validDays = new Set([...DayOfWeekHelper.allDaysOfWeek()].filter((dayOfWeek) => daysOfWeekBetweenDateRange.has(dayOfWeek)));
    }
  }
  return validDays;
};

export interface DayOfWeekPickerProps {
  onDayOfWeekChange: (value: Set<DayOfWeek>) => void;
  initialValue: Set<DayOfWeek>;
  title: string;
  disabled?: boolean;
  dateRange?: DaysOfWeekDateRange;
  error?: string;
  selectAllDaysOnLastUnselect?: boolean;
}

export interface DaysOfWeekDateRange {
  startDate: Dayjs;
  endDate: Dayjs;
}
