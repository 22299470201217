import isEmpty from 'lodash/isEmpty';

import { LoadingAndErrorWithRetryAndNoResults } from '../../../../../components/LoadingAndErrorWithRetryAndNoResults';
import { REQUEST_STATUS } from '../../atoms/budget-plan-atoms';
import { useBudgetPlanMembership } from '../../atoms/hooks/useBudgetPlanMembership';
import { searchBudgetPlanMemberFormKeys } from '../helpers';

import { SearchBudgetPlanMembershipHandler } from './MembershipSearchAndLinkModal';
import { SearchedMembershipDetailsAndLinkNewUserButton } from './SearchedMembershipDetailsAndLinkNewUserButton';

interface SearchedMembershipDetailsProps {
  onSearchBudgetPlanMembershipClick: SearchBudgetPlanMembershipHandler;
}

export const SearchedMembershipDetails = ({ onSearchBudgetPlanMembershipClick }: SearchedMembershipDetailsProps) => {
  const { bmUserToLink, bmLinkModalSearchStatus } = useBudgetPlanMembership();

  if (isEmpty(bmUserToLink)) {
    return (
      <LoadingAndErrorWithRetryAndNoResults
        error={bmLinkModalSearchStatus === REQUEST_STATUS.FAILURE}
        loading={bmLinkModalSearchStatus === REQUEST_STATUS.PENDING}
        noResults={isEmpty(bmUserToLink) && bmLinkModalSearchStatus === REQUEST_STATUS.SUCCESS}
        onRetry={handleSearchMembershipRetry}
        baseTranslationKey="searchBudgetPlanMembership"
        style={{ height: '100%', backgroundColor: 'transparent' }}
      />
    );
  }

  return <SearchedMembershipDetailsAndLinkNewUserButton />;

  function handleSearchMembershipRetry() {
    onSearchBudgetPlanMembershipClick({
      [searchBudgetPlanMemberFormKeys.memberEmail]: bmUserToLink?.details.email!,
    });
  }
};
