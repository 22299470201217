import { useState } from 'react';

import { Box, Button, InputAdornment, Link, Modal, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useIntl } from 'react-intl';

import UserService from '../../services/UserService';
import { WMV_SUPPORT_EMAIL } from '../../utils/constants';
import CustomIcon from '../../utils/CustomIcon';

import { AboutBoxStyle, CommonRowStyle, CommonSectionStyle, CommonTextFieldStyle, ContentBoxStyle, ContentInnerBoxStyle } from './styles';

const MyProfile = () => {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const currentUser = UserService.getProfileData();
  const [currentPhone, setCurrentPhone] = useState(currentUser.phone || '');
  const [inputError, setInputError] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClose = (event: React.SyntheticEvent | Event, reason?: 'backdropClick' | 'escapeKeyDown') => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  function containsOnlyNumbers(str: string) {
    // TODO: check if needed
    // str = Number(str);
    const test = /^[0-9]*$/.test(str);
    return test;
  }

  const handlePhone = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.target.value === ' ') {
      return;
    } else {
      const erg = containsOnlyNumbers(e.target.value);
      setInputError(!erg);
      setCurrentPhone(e.target.value);
    }
  };

  // const handleUndoPhone = () => {
  //   setCurrentPhone(currentUser.phone);
  //   setInputError(false);
  // };

  return (
    <Box sx={ContentBoxStyle}>
      <Box sx={ContentInnerBoxStyle}>
        {/* TODO: Add once we have the ability to update user data */}
        {/* <AccountInformationUserIcon /> */}
        <Box sx={AboutBoxStyle}>
          <Typography variant="bodyMediumBold">{formatMessage({ id: 'account.profile.about' })}</Typography>
          <Box
            sx={{
              backgroundColor: theme.palette.dark.shade3,
              display: 'flex',
              flexDirection: 'row',
              padding: theme.spacing(3),
              gap: theme.spacing(1),
              justifyContent: 'space-between',
              borderRadius: theme.spacing(1),
              flexWrap: 'wrap',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: theme.spacing(3),
                width: '550px',
              }}
            >
              <Box sx={CommonRowStyle}>
                <Box sx={CommonSectionStyle}>
                  <Typography variant="bodyMedium" color={theme.palette.dark.shade32}>
                    {formatMessage({ id: 'account.profile.firstName' })}
                  </Typography>
                  <TextField disabled value={currentUser.givenName} size="small" sx={CommonTextFieldStyle} variant="outlined" />
                </Box>
                <Box sx={CommonSectionStyle}>
                  <Typography variant="bodyMedium" color={theme.palette.dark.shade32}>
                    {formatMessage({ id: 'account.profile.lastName' })}
                  </Typography>
                  <TextField disabled value={currentUser.familyName} size="small" sx={CommonTextFieldStyle} variant="outlined" />
                </Box>
              </Box>
              {/* TODO: add department and role */}
              {/* <Box sx={CommonRowStyle}>
                <Box sx={CommonSectionStyle}>
                  <Typography variant="bodyMedium" color={theme.palette.dark.shade32}>
                    {formatMessage({ id: 'account.profile.department' })}
                  </Typography>
                  <TextField disabled value={currentUser.department} size="small" sx={CommonTextFieldStyle} variant="outlined" />
                </Box>
                <Box sx={CommonSectionStyle}>
                  <Typography variant="bodyMedium" color={theme.palette.dark.shade32}>
                    {formatMessage({ id: 'account.profile.role' })}
                  </Typography>
                  <TextField disabled value={currentUser.role} size="small" sx={CommonTextFieldStyle} variant="outlined" />
                </Box>
              </Box> */}
            </Box>
            <Box
              sx={{
                backgroundColor: theme.palette.dark.shade8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: theme.spacing(1),
                borderRadius: theme.spacing(1),
                padding: theme.spacing(1),
                width: '310px',
                justifyContent: 'space-evenly',
              }}
            >
              <Typography variant="bodySmallBold">
                {formatMessage({
                  id: 'account.profile.changePasswordCaption',
                })}
              </Typography>
              <Typography sx={{ paddingLeft: theme.spacing(3) }} variant="bodySmall">
                {formatMessage({
                  id: 'account.profile.changePasswordText',
                })}
                <Link
                  sx={{ paddingLeft: theme.spacing(0.5) }}
                  underline="none"
                  href={`mailto:${WMV_SUPPORT_EMAIL}?subject=${encodeURIComponent(formatMessage({ id: 'support.card.emailUs.title' }))}`}
                  color={theme.palette.secondary.main}
                  variant="bodySmall"
                >
                  {formatMessage({
                    id: 'account.profile.changePasswordLink',
                  })}
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={AboutBoxStyle}>
          <Typography variant="bodyMediumBold">{formatMessage({ id: 'account.profile.workInfo' })}</Typography>
          <Box
            sx={{
              backgroundColor: theme.palette.dark.shade3,
              display: 'flex',
              flexDirection: 'row',
              padding: theme.spacing(3),
              gap: theme.spacing(1),
              justifyContent: 'space-between',
              borderRadius: theme.spacing(1),
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: theme.spacing(3),
                width: '645px',
              }}
            >
              <Box sx={CommonRowStyle}>
                <Box sx={CommonSectionStyle}>
                  <Typography variant="bodyMedium" color={theme.palette.dark.shade32}>
                    {formatMessage({ id: 'account.profile.logIn' })}
                  </Typography>
                  <TextField
                    disabled
                    value={currentUser.email}
                    size="small"
                    sx={CommonTextFieldStyle}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">{CustomIcon('MailAdornmentIcon', theme.palette.dark.shade32)}</InputAdornment>
                      ),
                    }}
                  />
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: theme.spacing(1),
                    alignItems: 'flex-end',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: theme.spacing(0.5),
                    }}
                  >
                    <Typography variant="bodyMedium" color={theme.palette.dark.shade32}>
                      {formatMessage({ id: 'account.profile.phone' })}
                    </Typography>
                    <TextField
                      disabled
                      id="phoneInputField"
                      value={currentPhone}
                      onChange={(e) => handlePhone(e)}
                      sx={{ backgroundColor: '#ffffff', width: theme.spacing(33) }}
                      error={inputError}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">{CustomIcon('PhoneAdornmentIcon', theme.palette.dark.shade76)}</InputAdornment>
                        ),
                      }}
                      size="small"
                      variant="outlined"
                    />
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: theme.spacing(2) }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button variant="outlined" onClick={() => setOpen(true)}>
                    {formatMessage({ id: 'account.profile.passwordButton' })}
                  </Button>
                </Box>
                <Modal open={open} onClose={handleClose}>
                  <div>
                    <Box
                      sx={{
                        backgroundColor: theme.palette.white.main,
                        position: 'absolute',
                        display: 'flex',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        alignItems: 'center',
                        flexDirection: 'column',
                        borderRadius: theme.spacing(1),
                        width: '350px',
                        height: 'auto',
                        padding: '10px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          padding: '10px',
                        }}
                      >
                        <Typography variant="bodyMediumBold" color={theme.palette.dark.main}>
                          {formatMessage({ id: 'account.profile.passwordChangeCaption' })}
                        </Typography>
                        <Typography variant="bodySmall" color={theme.palette.dark.shade76} style={{ textAlign: 'center' }}>
                          {formatMessage({ id: 'account.profile.passwordChangeText' })}
                        </Typography>
                      </div>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginTop: '10px',
                        }}
                      >
                        <Button variant="contained" color="primary" onClick={() => setOpen(false)}>
                          {formatMessage({ id: 'account.profile.passwordChangeCancel' })}
                        </Button>
                        <Button
                          variant="outlined"
                          color="alert"
                          sx={{
                            marginTop: '.5rem',
                          }}
                          onClick={UserService.changePassword}
                        >
                          {formatMessage({ id: 'account.profile.passwordChangeConfirm' })}
                        </Button>
                      </Box>
                    </Box>
                  </div>
                </Modal>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* TODO: Add once we have the ability to update user data */}
      {/* <Box sx={{ display: 'flex', gap: theme.spacing(3) }}>
        <Button disabled={currentPhone === currentUser.phone} variant="outlined" onClick={handleUndoPhone}>
          <Typography variant="bodyMediumBold">{formatMessage({ id: 'account.button.undo' })}</Typography>
        </Button>
        <Button disabled={currentPhone === currentUser.phone || inputError === true} variant="contained">
          <Typography variant="bodyMediumBold">{formatMessage({ id: 'account.button.save' })}</Typography>
        </Button>
      </Box> */}
    </Box>
  );
};

export default MyProfile;
