import { useCallback, useState } from 'react';

export function useModalIsOpen(defaultValue?: boolean): [boolean, () => void, () => void] {
  const [isOpen, setIsOpen] = useState(!!defaultValue);

  const openModal = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  return [isOpen, openModal, closeModal];
}
