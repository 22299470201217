import { Box, Typography } from '@mui/material';
import type { ColumnDef, Row } from '@tanstack/react-table';

import CustomIcon from '../../../utils/CustomIcon';
import { LawEnforcementTripSearchResult } from '../LawEnforcementTripSearch/LawEnforcementTripSearchResultsTable';

export const LawEnforcementTripsListColumnDefs: ColumnDef<LawEnforcementTripSearchResult>[] = [
  {
    header: 'maas.vehicleTrip.details.startDate'.i18nText(),
    accessorKey: 'startDateFormatted',
  },
  {
    header: 'maas.vehicleTrip.details.startTime'.i18nText(),
    accessorKey: 'startTimeFormatted',
  },
  {
    header: 'maas.vehicleTrip.details.endDate'.i18nText(),
    accessorKey: 'endDateFormatted',
  },
  {
    header: 'maas.vehicleTrip.details.endTime'.i18nText(),
    accessorKey: 'endTimeFormatted',
  },
  {
    header: 'maas.vehicleTrip.details.vehicleType'.i18nText(),
    accessorKey: 'vehicleType',
    cell: ({ row }: { row: Row<LawEnforcementTripSearchResult> }) => {
      const vehicleTypeIcons = {
        electric_bike: CustomIcon('ebikeTooltipIcon', '', '22px'),
        electric_moped: CustomIcon('mopedTooltipIcon', '', '22px'),
        car: CustomIcon('carTooltipIcon', '', '22px'),
        cargo_bicycle: CustomIcon('cargobikeTooltipIcon', '', '22px'),
        electric_scooter: CustomIcon('scooterTooltipIcon', '', '22px'),
      };
      const IconComponent = vehicleTypeIcons[row.original.vehicleType.toLocaleLowerCase() as keyof typeof vehicleTypeIcons] || null;
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '4px',
          }}
        >
          {IconComponent}
          <Typography variant="bodySmall" px={1}>
            {`mspVehicleType.${row.original.vehicleType.toLowerCase()}`.i18nText()}
          </Typography>
        </Box>
      );
    },
  },
  { header: 'maas.vehicleTrip.details.qrCodeId'.i18nText(), accessorKey: 'qrCodeId' },
  { header: 'maas.customer.details.firstName'.i18nText(), accessorKey: 'firstName' },
  { header: 'maas.customer.details.lastName'.i18nText(), accessorKey: 'lastName' },
  { header: 'maas.customer.details.streetName'.i18nText(), accessorKey: 'streetName' },
];
