import { useAtom } from 'jotai';

import { BudgetPlanInformationTab } from '../../../../../models';
import {
  budgetPlansAtom,
  selectedPlanAtom,
  budgetPlanModalVisibleAtom,
  budgetPlanActionModeAtom,
  budgetPlanModalActiveTabAtom,
  BP_ACTION_MODE,
  budgetPlansFetchStatusAtom,
  REQUEST_STATUS,
} from '../budget-plan-atoms';

export function useBudgetPlan() {
  const [budgetPlans, setBudgetPlans] = useAtom(budgetPlansAtom);
  const [budgetPlansFetchStatus, setBudgetPlansFetchStatus] = useAtom(budgetPlansFetchStatusAtom);
  const [selectedPlan, setSelectedPlan] = useAtom(selectedPlanAtom);
  const [budgetPlanModalVisible, setBudgetPlanModalVisible] = useAtom(budgetPlanModalVisibleAtom);
  const [budgetPlanActionMode, setBudgetPlanActionMode] = useAtom(budgetPlanActionModeAtom);
  const [budgetPlanModalActiveTab, setBudgetPlanModalActiveTab] = useAtom(budgetPlanModalActiveTabAtom);

  const resetBudgetPlanState = () => {
    setSelectedPlan(null);
    setBudgetPlansFetchStatus(REQUEST_STATUS.IDLE);
    setBudgetPlanModalVisible(false);
    setBudgetPlanActionMode(BP_ACTION_MODE.VIEW);
    setBudgetPlanModalActiveTab(BudgetPlanInformationTab.PlanDetails);
  };

  return {
    budgetPlans,
    setBudgetPlans,
    budgetPlansFetchStatus,
    setBudgetPlansFetchStatus,
    selectedPlan,
    setSelectedPlan,
    budgetPlanModalVisible,
    setBudgetPlanModalVisible,
    budgetPlanActionMode,
    setBudgetPlanActionMode,
    budgetPlanModalActiveTab,
    setBudgetPlanModalActiveTab,
    resetBudgetPlanState,
  };
}
