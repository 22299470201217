import type { Theme } from '@mui/material/styles';

export const modalContainerSx = (theme: Theme) => ({
  width: '80%',
  maxWidth: '1320px',
  height: '90%',
  maxHeight: '836px',
  backgroundColor: theme.palette.tertiary.main,
});

export const modalContentSx = (theme: Theme) => ({
  display: 'flex',
  gap: theme.spacing(2),
});

export const sidebarContentStyles = (theme: Theme) => ({
  backgroundColor: theme.palette.white.main,
  minWidth: '210px',
  maxWidth: '269px',
  padding: '32px 0px 8px 8px',
  borderRadius: theme.spacing(1),
});

export const sidebarHeaderStyles = (theme: Theme) => ({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  wordWrap: 'break-word',
});

export const dividerStyles = (theme: Theme) => ({
  borderColor: theme.palette.baseLight.base24,
  margin: '16px 8px 24px 0px',
});

export const tabPanelContainerStyles = (theme: Theme) => ({
  padding: theme.spacing(2),
  overflow: 'auto',
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.white.main,
  flex: 1,
});
