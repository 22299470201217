import { useMemo, useState } from 'react';

import { Grid, Typography, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';

import { VehicleTypeHelper } from '../../models';
import { VehicleInformationTab, VehicleInformationTabHelper } from '../../models/ui/';
import { FormattedLiveVehicle } from '../../state/common/vehicle.model';
import ProviderLogo from '../../wmv-components/ProviderLogo';
import SidebarInfoModal from '../SidebarInfoModal';

import GeneralInformation from './VehicleInfoModalPanels/GeneralInformation';
import VehicleTripHistory from './VehicleInfoModalPanels/VehicleTripHistory';

const SidebarHeader = ({ vehicle }: { vehicle: FormattedLiveVehicle }) => {
  const { palette, spacing } = useTheme();
  const { formatMessage } = useIntl();

  return (
    <Grid container direction="row" sx={{ flexWrap: 'nowrap', gap: spacing(1) }}>
      <ProviderLogo provider={vehicle.mspName.toLowerCase()} />
      <Grid container direction="column" sx={{ gap: spacing(0.5), flexWrap: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        <Typography variant="bodyLargeBold" color={palette.dark.main} sx={{ whiteSpace: 'wrap' }}>
          {vehicle.mspName} {VehicleTypeHelper.metadata(vehicle.formFactor).displayText}
        </Typography>
        <Typography variant="bodyMedium" color={palette.dark.shade76}>
          {vehicle.stableVehicleId || formatMessage({ id: 'common.dash' })}
        </Typography>
      </Grid>
    </Grid>
  );
};

interface VehicleInformationModalProps {
  vehicle: FormattedLiveVehicle;
  isOpen: boolean;
  onClose: () => void;
}

const VehicleInformationModal = ({ vehicle, isOpen, onClose }: VehicleInformationModalProps) => {
  const [activeTab, setActiveTab] = useState<VehicleInformationTab>(VehicleInformationTab.GeneralInformation);

  const sidebarHeader = useMemo(() => <SidebarHeader vehicle={vehicle} />, [vehicle]);

  return (
    <SidebarInfoModal<VehicleInformationTab>
      isOpen={isOpen}
      onClose={onClose}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      tabOptions={VehicleInformationTabHelper.allLabelAndValues()}
      sidebarHeaderComponent={sidebarHeader}
    >
      <GeneralInformation vehicle={vehicle} />
      <VehicleTripHistory vehicleId={vehicle.stableVehicleId} />
    </SidebarInfoModal>
  );
};

export default VehicleInformationModal;
