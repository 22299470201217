import { useMemo, type ReactNode } from 'react';

import { Table, TableBody, TableHead, TableRow, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useIntl } from 'react-intl';

import { themeOptions as theme } from '../../../init-setup/ThemeOptions';
import { TripDurationBreakdownType, TripDurationDetails, TripStatus } from '../../../models';
import { TripDurationService } from '../../../services/trip/TripDurationService';

import { StyledTableCell } from './StyledTableCell';

interface TripDurationBreakdownProps {
  duration: TripDurationDetails;
  tripStatus: TripStatus;
}

/**
 * A React component that displays a breakdown of trip duration details in a table format.
 * This component is used to show the detailed duration of a trip, including actual and billable time.
 */
export function TripDurationBreakdown({ duration, tripStatus }: TripDurationBreakdownProps): ReactNode {
  const { formatMessage } = useIntl();
  const { breakdown: durationBreakdown } = duration;

  const {
    totalDurationBreakdown: { actualRideDuration, chargedRideDuration, actualPausedDuration, chargedPausedDuration },
    chronologicalBreakdown: chronologicalDurationBreakdown,
  } = useMemo(() => new TripDurationService(duration), [duration]);

  return (
    <Table>
      <TableHead>
        <StyledTableCell>{formatMessage({ id: 'userTripDetails.activity' })}</StyledTableCell>
        <StyledTableCell>{formatMessage({ id: 'userTripDetails.time' })}</StyledTableCell>
        <StyledTableCell>{formatMessage({ id: 'userTripDetails.actualDuration' })}</StyledTableCell>
        <StyledTableCell>{formatMessage({ id: 'userTripDetails.chargeDuration' })}</StyledTableCell>
      </TableHead>

      <TableBody>
        {chronologicalDurationBreakdown?.map((breakdown, index) => {
          const isTripOnGoing =
            index === durationBreakdown.length - 1 &&
            breakdown.type === TripDurationBreakdownType.Active &&
            tripStatus === TripStatus.Started;

          return (
            <TableRow key={breakdown.start}>
              <StyledTableCell variant="bodySmall">
                {formatMessage({ id: `userTripDetails.billing.status.${breakdown.type.toLowerCase()}` })}
              </StyledTableCell>
              <StyledTableCell variant="bodySmall">{breakdown?.start}</StyledTableCell>
              <StyledTableCell variant="bodySmall">
                {isTripOnGoing ? (
                  <Typography sx={{ color: theme.palette.alert.main }}>
                    {formatMessage({ id: 'userTripDetails.activeTrip.placeholder' })}
                  </Typography>
                ) : (
                  breakdown.actualDurationSeconds
                )}
              </StyledTableCell>
              <StyledTableCell variant="bodySmall">
                {isTripOnGoing ? (
                  <Typography sx={{ color: theme.palette.alert.main }}>
                    {formatMessage({ id: 'userTripDetails.activeTrip.placeholder' })}
                  </Typography>
                ) : (
                  breakdown.billableDurationSeconds
                )}
              </StyledTableCell>
            </TableRow>
          );
        })}

        <TableRow>
          <StyledTableCell>{formatMessage({ id: 'userTripDetails.totalRideDuration' })}</StyledTableCell>
          <Box aria-hidden={true}></Box>
          <StyledTableCell>{actualRideDuration}</StyledTableCell>
          <StyledTableCell>{chargedRideDuration}</StyledTableCell>
        </TableRow>

        <TableRow>
          <StyledTableCell>{formatMessage({ id: 'userTripDetails.totalPausedDuration' })}</StyledTableCell>
          <Box aria-hidden={true}></Box>
          <StyledTableCell>{actualPausedDuration}</StyledTableCell>
          <StyledTableCell>{chargedPausedDuration}</StyledTableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}
