import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { Theme } from '@mui/material/styles';

import { HEADER_HEIGHT } from '../../utils/constants';

export const appBarStyles = (theme: Theme) => ({
  backgroundColor: 'white.main',
  color: 'dark.main',
  height: HEADER_HEIGHT,
  display: 'flex',
  justifyContent: 'center',
  boxShadow: theme.shadowOptions.belowSmall,
});

export const navBarMenuContainerStyles = (theme: Theme) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'row',
  marginRight: '2rem',
  height: '100%',
});

export const openedMenuStyles = (theme: Theme) => ({
  backgroundColor: theme.palette.secondary.tint,
  borderBottom: `2px solid ${theme.palette.secondary.dark}`,
});

export const submenuContainerStyles = (theme: Theme) => ({
  padding: '10px 25px',
  fontSize: '16px',
  fontWeight: 400,
  cursor: 'pointer',
  '&:hover': {
    color: theme.palette.dark.shade76,
    backgroundColor: theme.palette.secondary.tint,
  },
  '&.active': {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.white.main,
    fontWeight: 600,
  },
});

export const NavbarBoxButton = styled(Box)(({ theme }) => ({
  ...theme.typography.bodyMedium,
  padding: '0px 35px',
  minWidth: '140px',
  width: 'fit-content',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  color: theme.palette.dark.shade76,
  justifyContent: 'center',
  '&:hover': {
    color: theme.palette.dark.shade76,
    backgroundColor: theme.palette.secondary.tint,
    borderBottom: `2px solid ${theme.palette.secondary.dark}`,
  },
  '&.active': {
    color: theme.palette.secondary.main,
    fontWeight: 600,
  },
}));

export const popperRootSx = (theme: Theme) => ({
  '&.base-Popper-root': {
    zIndex: 99,
  },
});
