import React, { ReactNode } from 'react';

import { Card, CardActions, CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';

import { themeOptions } from '../../init-setup/ThemeOptions';
import { IconType } from '../../utils/CustomIcon';

import { cardActionsSx, cardContainerSx, cardContentSx } from './styles';
import { WmvCardActionButton } from './WmvCardActionButton';
import { WmvCardHeader } from './WmvCardHeader';

export type SupportPageCardProps<T extends string> = T extends 'link'
  ? LinkTypeSupportPageCardProps
  : T extends 'email'
  ? EmailTypeSupportPageCardProps
  : SupportPageCardDefaultProps;

export type WmvCardSize = 'small' | 'large';
interface CommonSupportPageCardProps {
  title: string;
  description: ReactNode;
  icon: IconType;
  backgroundColor?: string;
  color?: string;
  type: 'info' | 'link' | 'email';
  headerAlignment?: 'vertical' | 'horizontal';
  size?: WmvCardSize;
  width?: string;
  height?: string;
}
interface SupportPageCardDefaultProps extends CommonSupportPageCardProps {
  redirectUrl?: string;
  btnLabel?: string;
  email?: string;
  emailSubject?: string;
}
interface LinkTypeSupportPageCardProps extends CommonSupportPageCardProps {
  redirectUrl: string;
  btnLabel: string;
}
interface EmailTypeSupportPageCardProps extends CommonSupportPageCardProps {
  email: string;
  btnLabel: string;
  emailSubject: string;
}

export function WmvCard<T extends string>({
  title,
  description,
  icon,
  backgroundColor = 'inherit',
  color = themeOptions.palette.baseLight.main,
  type,
  redirectUrl: targetUrl,
  email,
  headerAlignment = 'vertical',
  size = 'small',
  btnLabel,
  emailSubject,
  width = '100%',
  height = '100%',
}: SupportPageCardProps<T>) {
  return (
    <Card
      sx={[
        {
          backgroundColor: backgroundColor,
          width: width,
          height: height,
        },
        ...(Array.isArray(cardContainerSx) ? cardContainerSx : [cardContainerSx]),
      ]}
    >
      <WmvCardHeader titleText={title} icon={icon} color={color} headerAlignment={headerAlignment} />
      <CardContent sx={cardContentSx}>
        <Typography variant={size === 'large' ? 'bodyLarge' : 'bodyMedium'} color={color} whiteSpace="pre-line">
          {description}
        </Typography>
      </CardContent>
      <CardActions sx={cardActionsSx}>
        <WmvCardActionButton type={type} targetUrl={targetUrl} btnLabel={btnLabel} email={email} emailSubject={emailSubject} />
      </CardActions>
    </Card>
  );
}
