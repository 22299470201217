import { forwardRef } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import type { AlertProps } from '@mui/material/Alert';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import { ALERT_HIDE_DURATION } from '../../utils/constants';

export const SnackbarAlert = forwardRef<HTMLDivElement, SnackbarAlertProps>(
  ({ onClose, message, open, type = 'success', ...props }, ref) => {
    const handleSnackBarClose = () => {
      if (onClose) onClose();
    };

    return (
      <Snackbar
        ref={ref}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={open}
        onClose={handleSnackBarClose}
        autoHideDuration={ALERT_HIDE_DURATION}
        {...props}
      >
        <Alert onClose={handleSnackBarClose} severity={type} sx={{ width: '400px' }}>
          {open && message}
        </Alert>
      </Snackbar>
    );
  },
);
SnackbarAlert.displayName = 'SnackbarAlert';

export const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  let icon = null;
  if (props.severity === 'success') icon = <CheckIcon />;
  if (props.severity === 'error') icon = <InfoOutlinedIcon />;

  return <MuiAlert elevation={6} ref={ref} variant="filled" icon={icon} {...props} />;
});

interface SnackbarAlertProps {
  onClose?: () => void;
  message: string;
  open: boolean;
  type: SnackbarAlertType;
}
export type SnackbarAlertType = 'success' | 'error' | 'info' | 'warning';
