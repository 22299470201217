import { ReactElement, useMemo } from 'react';

import { Stack, Typography, Box } from '@mui/material';
import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import type { ColumnDef, Row } from '@tanstack/react-table';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { LastChangeAttemptStatusInfo } from '../../../components/LastChangeAttemptStatusInfo';
import { themeOptions } from '../../../init-setup/ThemeOptions';
import { VehicleTypeHelper } from '../../../models';
import { VehiclePricingDto } from '../../../services/vehicle-fee-pricing';
import { VehicleFeePricingActiveStateDto } from '../../../services/vehicle-fee-pricing/VehicleFeePricingActiveState';
import { VehicleFeePricingLastChangeAttemptDto } from '../../../services/vehicle-fee-pricing/VehicleFeePricingLastChangeAttemptDto';
import { changingLanguageSelector } from '../../../state';

export function VehiclePricingTableColumnDefs() {
  const { formatMessage } = useIntl();
  const langCode = useSelector(changingLanguageSelector) as 'en' | 'de';

  return useMemo(
    (): ColumnDef<VehiclePricingDto>[] => [
      {
        header: formatMessage({ id: `map.vehicleInfo.provider` }),
        accessorKey: 'msp.name[langCode]',
        accessorFn: (originalRow: VehiclePricingDto) => originalRow.msp.name[langCode],
      },
      {
        header: formatMessage({ id: `zoneControls.filters.vehicleType` }),
        accessorKey: 'vehicleType.length',
        accessorFn: (originalRow: VehiclePricingDto) => VehicleTypeHelper.metadata(originalRow.vehicleType).displayText,
      },
      {
        header: formatMessage({ id: `common.vehicleModel` }),
        accessorKey: 'modelName',
      },
      {
        header: formatMessage({ id: `common.pricingBreakDown` }),
        accessorKey: 'activeState.activationFeeAmount()?.amount.value',
        cell: ({ row: { original: vehicleFeePricing } }: CellData) => {
          const pricingActiveState = vehicleFeePricing.activeState
            ? vehicleFeePricing.activeState
            : vehicleFeePricing.lastAttemptedStateChange;

          return renderChips(pricingActiveState);
        },
      },
      {
        header: formatMessage({ id: `common.lastStatusUpdate` }),
        accessorKey: 'lastAttemptedStateChange.status',
        cell: ({ row: { original: vehicleFeePricing } }: CellData) => {
          return <LastChangeAttemptStatusInfo status={vehicleFeePricing.lastAttemptedStateChange.status} />;
        },
      },
    ],
    [formatMessage, langCode],
  );
}

function renderChips(pricingActiveState: VehicleFeePricingActiveStateDto | VehicleFeePricingLastChangeAttemptDto) {
  return (
    <Stack direction="row" spacing={1}>
      <RenderChipWithTooltip
        feeName={'common.activation'.i18nText()}
        feeAmount={pricingActiveState.activationFeeAmount()!.amount!.value!.toCents()!}
        feeUnit={'common.activation'.i18nText()}
      >
        <Chip
          label={pricingActiveState.activationFeeAmount()!.amount.value!.toCents()}
          variant="outlined"
          color="purple"
          sx={{
            '&.MuiChip-root': {
              '&&:hover': {
                backgroundColor: themeOptions.palette.purple.dark,
                color: themeOptions.palette.baseLight.base0,
                borderColor: 'transparent',
              },
            },
          }}
        />
      </RenderChipWithTooltip>

      <RenderChipWithTooltip
        feeName={'common.activeMinutes'.i18nText()}
        feeAmount={pricingActiveState.activeDurationFeeAmount()!.amount.value!.toCents()}
        feeUnit={'common.minute'.i18nText()}
      >
        <Chip
          label={pricingActiveState.activeDurationFeeAmount()?.amount.value!.toCents()}
          variant="outlined"
          sx={{
            color: themeOptions.palette.blue.dark,
            borderColor: themeOptions.palette.blue.dark,
            '&.MuiChip-root': {
              '&&:hover': {
                backgroundColor: themeOptions.palette.blue.main,
                color: themeOptions.palette.white.main,
                borderColor: 'transparent',
              },
            },
          }}
        />
      </RenderChipWithTooltip>

      <RenderChipWithTooltip
        feeName={'common.pausedMinutes'.i18nText()}
        feeAmount={pricingActiveState.pausedDurationFeeAmount()!.amount.value!.toCents()}
        feeUnit={'common.minute'.i18nText()}
      >
        <Chip
          label={pricingActiveState.pausedDurationFeeAmount()!.amount.value!.toCents()}
          variant="outlined"
          color="blue"
          sx={{
            '&.MuiChip-root': {
              '&&:hover': {
                backgroundColor: themeOptions.palette.blue.dark,
                color: themeOptions.palette.baseLight.base0,
                borderColor: 'transparent',
              },
            },
          }}
        />
      </RenderChipWithTooltip>
    </Stack>
  );
}

function RenderChipWithTooltip({
  feeName,
  feeAmount,
  feeUnit,
  children,
}: {
  feeName: string;
  feeAmount: string;
  feeUnit: string;
  children: ReactElement;
}) {
  const { spacing } = useTheme();
  return (
    <Tooltip
      title={
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Typography variant="bodySmall">{feeName}</Typography>
          <Typography variant="bodySmall">
            {feeAmount}/ {feeUnit}
          </Typography>
        </Box>
      }
      slotProps={{ tooltip: { sx: { borderRadius: spacing(1), padding: spacing(1) } } }}
      placement="bottom"
      arrow
    >
      {children}
    </Tooltip>
  );
}

interface CellData {
  row: Row<VehiclePricingDto>;
}
