import { EnumTabHelper } from '../../utils/EnumTabHelper';

export enum PaymentCardBrand {
  Visa = 'VISA',
  MasterCard = 'MASTERCARD',
  Maestro = 'MAESTRO',
  Other = 'OTHER',
}

const paymentCardBrandTranslations = {
  Visa: 'payment.cardBrand.visa',
  MasterCard: 'payment.cardBrand.mastercard',
  Maestro: 'payment.cardBrand.maestro',
  Other: 'payment.cardBrand.other',
};

export const PaymentCardBrandHelper = new EnumTabHelper(PaymentCardBrand, paymentCardBrandTranslations);
