import ProfileBox from './ProfileBox';

const Profile = () => {
  return (
    <div id="profile">
      <ProfileBox />
    </div>
  );
};
export default Profile;
