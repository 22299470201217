import type { ChangeEvent } from 'react';
import React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import type { DateValidationError } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';

import { DayOfWeek, DayOfWeekHelper, ZoneType } from '../../../../models';
import { DEFAULT_DATE_FORMAT_DAYJS, DEFAULT_TIME_FORMAT, MAX_DEFAULT_DATE } from '../../../../utils/constants';
import { Checkbox, DaysOfWeekPicker } from '../../../../wmv-components';
import { ErrorMessage } from '../../../FormFields/ErrorMessage';

import { ZoneManagementActionState } from './ZoneRuleSettingsPopover';
import { ZoneRuleFormFields, ZoneRuleFormServerError, ZoneRulesFormValues } from './ZoneRulesForm';

interface ZoneDateRangeTimeWindowAndDaysOfWeekFormGroupProps {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  startTime: Dayjs | null;
  endTime: Dayjs | null;
  selectedDaysOfWeek: Set<DayOfWeek>;
  indefiniteZone: boolean;
  representsFullDay: boolean;
  readOnlyZoneRuleForm: boolean;
  zoneManagementActionState: ZoneManagementActionState;
  serverError?: ZoneRuleFormServerError;
}

export const ZoneDateRangeTimeWindowAndDaysOfWeekFormGroup = ({
  startDate,
  endDate,
  startTime,
  endTime,
  representsFullDay,
  indefiniteZone,
  selectedDaysOfWeek,
  readOnlyZoneRuleForm,
  zoneManagementActionState,
  serverError,
}: ZoneDateRangeTimeWindowAndDaysOfWeekFormGroupProps): React.JSX.Element => {
  const { errors, setFieldValue, handleBlur, touched, setFieldError, values } = useFormikContext<ZoneRulesFormValues>();
  const { formatMessage } = useIntl();
  const updateZoneServerViolationsFieldToError = serverError?.updateError;
  const createZoneServerViolationsFieldToError = serverError?.createError;

  const zoneServerViolationsFieldToError = updateZoneServerViolationsFieldToError || createZoneServerViolationsFieldToError;
  const newZoneInformationScreen = zoneManagementActionState === ZoneManagementActionState.NewZoneInformationScreen;
  const isStopZoneAlsoMobilityStation = values.type === ZoneType.STOP && values.representsMobilityStation;
  return (
    <Stack spacing={3}>
      <Typography variant="bodyMediumBold" component="h4">
        {formatMessage({ id: 'map.forms.section.dateAndTime' })}
      </Typography>
      <Box>
        <Box>
          <Box display="flex" flexDirection="row" gap={1}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                disabled={readOnlyZoneRuleForm}
                disablePast
                onError={handleDatePickerError}
                value={startDate?.toBeginningOfDay()}
                onChange={handleStartDateChange}
                maxDate={endDate?.clone()}
                format={DEFAULT_DATE_FORMAT_DAYJS}
                label={formatMessage({ id: 'input.startDate.label' })}
                slotProps={{
                  textField: {
                    onBlur: handleBlur,
                    name: ZoneRuleFormFields.startDate,
                    error: !!errors.startDate && touched.startDate,
                    helperText: newZoneInformationScreen
                      ? !!errors.startDate && touched.startDate && errors.startDate
                      : !!errors.startDate && errors.startDate,
                  },
                }}
              />
              <DatePicker
                disablePast
                value={!indefiniteZone ? endDate?.toEndOfDay() : null}
                minDate={startDate?.clone()}
                disabled={indefiniteZone || readOnlyZoneRuleForm || isStopZoneAlsoMobilityStation}
                onChange={handleEndDateChange}
                format={DEFAULT_DATE_FORMAT_DAYJS}
                label={formatMessage({ id: 'input.endDate.label' })}
                slotProps={{
                  textField: {
                    name: ZoneRuleFormFields.endDate,
                    onBlur: handleBlur,
                    error: !!errors.endDate && touched.endDate && !indefiniteZone,
                    helperText: !!errors.endDate && touched.endDate && !indefiniteZone && errors.endDate,
                  },
                }}
              />
            </LocalizationProvider>
          </Box>
          <ErrorMessage error={zoneServerViolationsFieldToError?.['activeOn.dateRange']?.message} />
        </Box>

        <Checkbox<boolean>
          size="small"
          name={ZoneRuleFormFields.indefiniteZone}
          value={indefiniteZone}
          checked={indefiniteZone}
          disabled={readOnlyZoneRuleForm || isStopZoneAlsoMobilityStation}
          onChange={handleIndefiniteZoneToggle}
          label={formatMessage({ id: 'map.forms.indefiniteZone.label' })}
        />
      </Box>
      <Box>
        <Box>
          <Box display="flex" flexDirection="row" gap={1}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                ampm={false}
                value={startTime}
                timeSteps={{ minutes: 1 }}
                onChange={handleStartTimeChange}
                disabled={representsFullDay || readOnlyZoneRuleForm}
                maxTime={endTime?.clone()}
                format={DEFAULT_TIME_FORMAT}
                label={formatMessage({ id: 'input.startTime.label' })}
                slotProps={{
                  textField: {
                    required: true,
                    onBlur: handleBlur,
                    name: ZoneRuleFormFields.startTime,
                    error: !!errors.startTime && touched.startTime,
                    helperText: !!errors.startTime && touched.startTime && errors.startTime,
                  },
                }}
              />
              <TimePicker
                ampm={false}
                value={endTime}
                timeSteps={{ minutes: 1 }}
                onChange={handleEndTimeChange}
                disabled={representsFullDay || readOnlyZoneRuleForm}
                minTime={startTime?.clone()}
                format={DEFAULT_TIME_FORMAT}
                label={formatMessage({ id: 'input.endTime.label' })}
                slotProps={{
                  textField: {
                    required: true,
                    onBlur: handleBlur,
                    name: ZoneRuleFormFields.endTime,
                    error: !!errors.endTime && touched.endTime,
                    helperText: !!errors.endTime && touched.endTime && errors.endTime,
                  },
                }}
              />
            </LocalizationProvider>
          </Box>
          <ErrorMessage error={zoneServerViolationsFieldToError?.['activeOn.timeWindow']?.message} />
        </Box>

        <Checkbox<boolean>
          size="small"
          name={ZoneRuleFormFields.representsFullDay}
          value={representsFullDay}
          checked={representsFullDay}
          disabled={readOnlyZoneRuleForm || isStopZoneAlsoMobilityStation}
          onChange={handleFullDayWindowToggle}
          label={formatMessage({ id: 'map.forms.representsFullDay.label' })}
        />
      </Box>
      <Box>
        <DaysOfWeekPicker
          onDayOfWeekChange={handleDayOfWeekChange}
          initialValue={selectedDaysOfWeek}
          title={formatMessage({ id: 'map.forms.daysOfWeek.label' })}
          disabled={readOnlyZoneRuleForm || isStopZoneAlsoMobilityStation}
          dateRange={{ startDate: startDate || dayjs(), endDate: endDate || dayjs(MAX_DEFAULT_DATE) }}
          error={(errors.daysOfWeek as string) || zoneServerViolationsFieldToError?.['activeOn.daysOfWeek']?.message}
        />
        <ErrorMessage error={zoneServerViolationsFieldToError?.['activeOn.daysOfWeek']?.message} />
      </Box>
    </Stack>
  );

  async function handleStartDateChange(date: Dayjs | null) {
    await setFieldValue(ZoneRuleFormFields.startDate, date!);
  }

  async function handleEndDateChange(date: Dayjs | null) {
    await setFieldValue(ZoneRuleFormFields.endDate, date);
  }

  async function handleStartTimeChange(time: Dayjs | null) {
    await setFieldValue(ZoneRuleFormFields.startTime, time!);
  }

  async function handleEndTimeChange(time: Dayjs | null) {
    await setFieldValue(ZoneRuleFormFields.endTime, time!);
  }

  async function handleDayOfWeekChange(selectedDaysOfWeek: Set<DayOfWeek>) {
    await setFieldValue(ZoneRuleFormFields.daysOfWeek, selectedDaysOfWeek);
  }

  async function handleFullDayWindowToggle(event: ChangeEvent<HTMLInputElement>) {
    await setFieldValue(ZoneRuleFormFields.representsFullDay, event.target.checked);

    if (event.target.checked) {
      await setFieldValue(ZoneRuleFormFields.startTime, dayjs().toBeginningOfDay());
      await setFieldValue(ZoneRuleFormFields.endTime, dayjs().toEndOfDay());
    }
  }

  async function handleIndefiniteZoneToggle(event: ChangeEvent<HTMLInputElement>) {
    await setFieldValue(ZoneRuleFormFields.indefiniteZone, event.target.checked);

    if (event.target.checked) {
      if (!startDate?.isValid()) await setFieldValue(ZoneRuleFormFields.startDate, dayjs().toBeginningOfDay());
      await setFieldValue(ZoneRuleFormFields.endDate, null);
      setFieldError(ZoneRuleFormFields.endDate, undefined);
      await setFieldValue(ZoneRuleFormFields.daysOfWeek, DayOfWeekHelper.allDaysOfWeek());
    }
  }

  async function handleDatePickerError(error: DateValidationError) {
    if (error === 'disablePast') {
      setFieldError(ZoneRuleFormFields.startDate, formatMessage({ id: 'form.validation.errorNoPastDate' }));
    }
  }
};
