import React, { Fragment } from 'react';

import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';

import { FormatAsCents } from '../../../wmv-components/NumberInput/FormatAsCents';

import { useSelectedVehiclePricingDetails } from './contexts';
import { vehiclePricingFormFieldName } from './helpers';
import { VehiclePricingFormValues } from './VehiclePricingDetailsForm';
import { VehiclePricingInputSection } from './VehiclePricingInputSection';

export function VehicleFeePricingInputs() {
  const { formatMessage } = useIntl();
  const selectedVehiclePricingDetails = useSelectedVehiclePricingDetails();
  const isVehiclePricingTransitionInProgress = selectedVehiclePricingDetails?.isStateChangeInProgress();
  const {
    values: {
      activeActiveMinutesFee,
      newActiveMinutesFee,
      activeActivationFee,
      newActivationFee,
      activePausedMinutesFee,
      newPausedMinutesFee,
    },
    setFieldValue,
    setFieldTouched,
    errors,
    touched,
  } = useFormikContext<VehiclePricingFormValues>();

  return (
    <Fragment>
      <VehiclePricingInputSection
        activeValue={activeActivationFee!}
        activeFieldName={vehiclePricingFormFieldName.activeActivationFee}
        newValue={newActivationFee!}
        newValueFieldName={vehiclePricingFormFieldName.newActivationFee}
        onChange={handleVehiclePricingChange}
        disabled={isVehiclePricingTransitionInProgress}
        error={!!errors.newActivationFee && touched.newActivationFee}
        helperText={!!errors.newActivationFee && touched.newActivationFee ? errors.newActivationFee : null}
        sectionTitle={formatMessage({ id: 'common.activationFee' })}
        formatInputComponent={FormatAsCents}
      />
      <VehiclePricingInputSection
        activeValue={activeActiveMinutesFee!}
        activeFieldName={vehiclePricingFormFieldName.activeActiveMinutesFee}
        newValue={newActiveMinutesFee!}
        newValueFieldName={vehiclePricingFormFieldName.newActiveMinutesFee}
        onChange={handleVehiclePricingChange}
        disabled={isVehiclePricingTransitionInProgress}
        error={!!errors.newActiveMinutesFee && touched.newActiveMinutesFee}
        helperText={!!errors.newActiveMinutesFee && touched.newActiveMinutesFee ? errors.newActiveMinutesFee : null}
        sectionTitle={formatMessage({ id: 'common.activeMinutes' })}
        formatInputComponent={FormatAsCents}
      />
      <VehiclePricingInputSection
        activeValue={activePausedMinutesFee!}
        activeFieldName={vehiclePricingFormFieldName.activePausedMinutesFee}
        newValue={newPausedMinutesFee!}
        newValueFieldName={vehiclePricingFormFieldName.newPausedMinutesFee}
        onChange={handleVehiclePricingChange}
        disabled={isVehiclePricingTransitionInProgress}
        error={!!errors.newPausedMinutesFee && touched.newPausedMinutesFee}
        helperText={!!errors.newPausedMinutesFee && touched.newPausedMinutesFee ? errors.newPausedMinutesFee : null}
        sectionTitle={formatMessage({ id: 'common.pausedMinutes' })}
        formatInputComponent={FormatAsCents}
      />
    </Fragment>
  );

  async function handleVehiclePricingChange({ name, value }: { name: string; value: number }) {
    await setFieldValue(name, value);
    await setFieldTouched(name, true);
  }
}
