import React, { type ReactNode } from 'react';

import Stack from '@mui/material/Stack';
import type { Theme } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs, { Dayjs } from 'dayjs';
import { Form, Formik } from 'formik';
import { useIntl } from 'react-intl';

import { TripDetailsResponse } from '../../../models';
import { DEFAULT_DATE_FORMAT_DAYJS, DEFAULT_TIME_FORMAT } from '../../../utils/constants';
import { ProgressAwareButton } from '../../../wmv-components';

interface TripForceStopFormProps {
  tripDetails: TripDetailsResponse;
  formValues: TripForceStopFormField;
  onForceStopBtnSubmit: (values: TripForceStopFormField) => void;
}

export interface TripForceStopFormField {
  startDate: Dayjs;
  startTime: Dayjs;
  endDate: Dayjs | null;
  endTime: Dayjs | null;
}

/**
 * TripForceStopForm component renders a form that allows users to force stop a trip.
 * The form includes date and time pickers for start and end times, and performs validation before submission.
 *
 * @param {TripForceStopFormProps} props - The props for the component.
 * @param {TripDetailsResponse} props.tripDetails - The details of the trip being force stopped.
 * @param {TripForceStopFormField} props.formValues - The initial form values including start date/time and end date/time.
 * @param {Function} props.onForceStopBtnSubmit - The function to handle form submission when the force stop button is clicked.
 *
 * @returns {ReactNode} The rendered form component.
 */

export function TripForceStopForm({ tripDetails, formValues, onForceStopBtnSubmit }: TripForceStopFormProps): ReactNode {
  const { formatMessage } = useIntl();

  return (
    <Formik initialValues={formValues} validate={(values) => validate(values, tripDetails)} onSubmit={onForceStopBtnSubmit}>
      {({ values, touched, errors, handleBlur, handleSubmit, setFieldValue, setFieldError, isSubmitting }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack direction="row" gap={4} flexWrap="wrap" mt={4}>
                <Stack direction="row" gap={1}>
                  <DatePicker
                    disabled
                    value={values?.startDate}
                    onChange={async (startDate: Dayjs | null) => {
                      await setFieldValue('startDate', startDate);
                    }}
                    format={DEFAULT_DATE_FORMAT_DAYJS}
                    label={formatMessage({ id: 'common.start' })}
                    slotProps={{
                      textField: {
                        onBlur: handleBlur,
                        name: 'startDate',
                        error: !!errors.startDate && !!touched.startDate,
                        helperText: !!errors.startDate && !!touched.startDate ? (errors.startDate as string) : null,
                        sx: forceStopDatePickerSx,
                      },
                    }}
                  />
                  <TimePicker
                    disabled
                    ampm={false}
                    value={values?.startTime}
                    timeSteps={{ minutes: 1 }}
                    onChange={async (startTime: Dayjs | null) => {
                      await setFieldValue('startTime', startTime);
                    }}
                    format={DEFAULT_TIME_FORMAT}
                    slotProps={{
                      textField: {
                        name: 'startTime',
                        required: true,
                        onBlur: handleBlur,
                        error: !!errors.startTime && !!touched.startTime,
                        helperText: !!errors.startTime && touched.startTime && (errors.startTime as string),
                        sx: forceStopTimePickerSx,
                      },
                    }}
                  />
                </Stack>

                <Stack direction="row" gap={1}>
                  <DatePicker
                    value={values?.endDate}
                    minDate={values?.startDate.clone()}
                    maxDate={dayjs.now().clone()}
                    onChange={async (endDate: Dayjs | null) => {
                      await setFieldValue('endDate', endDate);
                    }}
                    onError={() => {
                      setFieldError('endDate', 'form.validation.invalidDate'.i18nText());
                    }}
                    format={DEFAULT_DATE_FORMAT_DAYJS}
                    label={formatMessage({ id: 'common.end' })}
                    slotProps={{
                      textField: {
                        onBlur: handleBlur,
                        required: true,
                        name: 'endDate',
                        error: !!errors.endDate && !!touched.endDate,
                        helperText: !!errors.endDate && touched.endDate && (errors.endDate as string),
                        sx: forceStopDatePickerSx,
                      },
                    }}
                  />
                  <TimePicker
                    ampm={false}
                    value={values?.endTime}
                    timeSteps={{ minutes: 1 }}
                    maxTime={dayjs.now().clone()}
                    onChange={async (endTime: Dayjs | null) => {
                      await setFieldValue('endTime', endTime);
                    }}
                    onError={() => {
                      setFieldError('endTime', 'form.validation.invalidDate'.i18nText());
                    }}
                    format={DEFAULT_TIME_FORMAT}
                    slotProps={{
                      textField: {
                        name: 'endTime',
                        required: true,
                        onBlur: handleBlur,
                        error: !!errors.endTime && !!touched.endTime,
                        helperText: !!errors.endTime && touched.endTime && errors.endTime,
                        sx: forceStopTimePickerSx,
                      },
                    }}
                  />
                </Stack>
                <ProgressAwareButton
                  loading={isSubmitting}
                  type="submit"
                  variant="contained"
                  loadingButtonProps={{ sx: { minWidth: '152px' } }}
                >
                  {'common.forceStopTrip'.i18nText()}
                </ProgressAwareButton>
              </Stack>
            </LocalizationProvider>
          </Form>
        );
      }}
    </Formik>
  );
}

function validate(values: TripForceStopFormField, tripDetails: TripDetailsResponse) {
  const errors: any = {};

  const { endDate, endTime, startDate } = values;

  // Validate end date
  if (!endDate) {
    errors.endDate = 'form.validation.errorFieldRequired'.i18nText();
  } else {
    const date = dayjs().diff(values.endDate, 'year');
    if (date < 0 || date > 100 || !endDate.isValid()) {
      errors.endDate = 'form.validation.invalidDate'.i18nText();
    }
  }

  // Validate end time
  if (!endTime) {
    errors.endTime = 'form.validation.errorFieldRequired'.i18nText();
  } else if (!endTime?.isValid()) {
    errors.endTime = 'form.validation.invalidTime'.i18nText();
  }

  // Further validation if both date and time are valid
  if (endDate?.isValid() && endTime?.isValid()) {
    if (endDate?.isSameDate(dayjs.now()) && endTime?.isAfterTime(dayjs.now())) {
      errors.endTime = 'form.validation.errorNoFutureDateTime'.i18nText();
    }

    if (endDate?.isAfterDate(dayjs.now())) {
      errors.endDate = 'form.validation.errorNoFutureDateTime'.i18nText();
    } else {
      const lastActivityTimestamp = tripDetails?.activityLog[tripDetails.activityLog.length - 1]?.timestamp.parseEpochMillis() || startDate;

      if (endDate?.isBeforeDate(lastActivityTimestamp)) {
        errors.endDate = 'form.validation.cannotBeBeforeRecentActivityDate'.i18nText();
      } else {
        if (endDate?.isSameDate(lastActivityTimestamp) && endTime?.isBeforeTime(lastActivityTimestamp)) {
          errors.endTime = 'form.validation.cannotBeBeforeRecentActivityTime'.i18nText();
        }
      }
    }
  }

  return errors;
}

export const forceStopDatePickerSx = (theme: Theme) => ({
  maxWidth: '220px',
  minWidth: '220px',
});

export const forceStopTimePickerSx = (theme: Theme) => ({
  maxWidth: '120px',
  minWidth: '120px',
});
