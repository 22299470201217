import React, { Fragment } from 'react';

import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import { useIntl } from 'react-intl';
import { useExpanded, useFilters, useSortBy, useTable } from 'react-table';

import { ReactComponent as ExpandMoreOutlinedIcon } from '../assets/icons/outline/chevronDown.svg';
import { ReactComponent as ExpandLessOutlinedIcon } from '../assets/icons/outline/chevronUp.svg';
import { themeOptions } from '../init-setup/ThemeOptions';
import CustomIcon from '../utils/CustomIcon';

import TablePagination from './TablePagination';
const TABLE_ROW_EXPANDER_ID = 'expander';

const TableRowStyled = styled(TableRow)((props) => ({
  shouldForwardProp: (prop) => prop !== 'count' && prop !== 'expander',
  backgroundColor:
    props.count % 2 === 0
      ? 'linear-gradient(0deg, rgba(255, 255, 255, 0.002), rgba(255, 255, 255, 0.002))'
      : 'linear-gradient(0deg, rgba(47, 46, 65, 0.08), rgba(47, 46, 65, 0.08))',
  '& > td': {
    color: 'black',
    border: '0.2px solid rgba(47, 46, 65, 0.08)',
  },
  '& > td:nth-of-type(1)': {
    borderRight: props.expander ? 'none' : '0.2px solid rgba(47, 46, 65, 0.08)',
    borderLeft: props.expander ? 'none' : '0.2px solid rgba(47, 46, 65, 0.08)',
  },
  '& > td:nth-of-type(2)': {
    borderLeft: props.expander ? 'none' : '0.2px solid rgba(47, 46, 65, 0.08)',
  },
  '& > th': {
    color: 'black',
    border: '0.2px solid rgba(47, 46, 65, 0.08)',
    background: 'rgba(47, 46, 65, 0.08)',
  },
  '& > th:nth-of-type(1)': {
    borderRight: props.expander ? 'none' : '0.2px solid rgba(47, 46, 65, 0.08)',
    borderLeft: props.expander ? 'none' : '0.2px solid rgba(47, 46, 65, 0.08)',
  },
  '& > th:nth-of-type(2)': {
    borderLeft: props.expander ? 'none' : '0.2px solid rgba(47, 46, 65, 0.08)',
  },
}));

const noBorderStyles = () => ({
  '& .MuiTableCell-root': {
    border: 'none !important',
  },
  '& .MuiTableRow-root': {
    border: 'none !important',
    '& > th': {
      border: 'none !important',
    },
    '& > td': {
      border: 'none !important',
    },
  },
});

const defaultHoverStyle = (theme) => ({
  backgroundColor: theme.palette.dark.shade4,
  cursor: 'pointer',
});

const ReactTable = ({
  columns,
  data = [],
  loading = false,
  tableContainerStyle = {},
  renderDetailsComponent = (row, originalRow) => <Fragment></Fragment>,
  onRowClickHandler = (rowProps, tableRow) => {},
  customRowHoverStyle = {},
  rowCount = 15,
  pagination = false,
  sorted = true,
  noBorder = false,
  expandedRowID = '',
  toggleExpanderHandler = (row) => {},
  expanderVariant = false,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [rowsPerPage, setRowsPerPage] = React.useState(rowCount);
  const [page, setPage] = React.useState(0);
  const { formatMessage } = useIntl();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      autoResetFilters: false,
      disableMultiSort: false,
      autoResetSortBy: false,
      autoResetPage: false,
      autoResetExpanded: false,
    },
    useFilters,
    useSortBy,
    useExpanded,
  );

  const theme = useTheme();

  return (
    <>
      <TableContainer
        sx={{
          width: '100%',
          overflow: 'auto',
          maxHeight: 'calc(100vh - 120px)',
          ...tableContainerStyle,
          ...(noBorder ? noBorderStyles(theme) : {}),
        }}
      >
        <Table {...getTableProps()} stickyHeader>
          <TableHead sx={{ zIndex: 1, position: 'relative' }}>
            {headerGroups.map((headerGroup, index) => (
              <TableRowStyled key={`headergroup-${index}`} {...headerGroup.getHeaderGroupProps()} expander={expanderVariant}>
                {expanderVariant && (
                  <TableCell
                    key={`header-${index}`}
                    sx={{
                      padding: '4px',
                      width: `40px`,
                      background: 'white !important',
                      zIndex: 0,
                    }}
                  ></TableCell>
                )}
                {headerGroup.headers.map((column, index) => (
                  <TableCell
                    key={`header-${index}`}
                    sx={{
                      padding: '4px',
                      width: column.width || `calc(100% / ${columns.length})`,
                      background: 'white !important',
                      zIndex: 0,
                    }}
                  >
                    <div
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      style={{
                        height: 'auto',
                        minHeight: '40px',
                        alignItems: 'center',
                        display: 'flex',
                        overflow: 'hidden',
                        userSelect: 'none',
                        whiteSpace: 'nowrap',
                        justifyContent: 'space-between',
                        padding: '4px',
                      }}
                    >
                      <Typography
                        variant="bodySmallBold"
                        title={typeof column.render('Header') === 'string' ? column.render('Header') : ''}
                      >
                        {column.render('Header')}
                      </Typography>
                      {sorted === true ? (
                        <span style={{ height: 20 }}>
                          {column.isSortedDesc === undefined ? (
                            <>{CustomIcon('DeselectedIcon', theme.palette.dark.shade32)}</>
                          ) : column.isSortedDesc === true ? (
                            <>{CustomIcon('DescendingIcon', theme.palette.dark.main)}</>
                          ) : (
                            <>{CustomIcon('AscendingIcon', theme.palette.dark.main)}</>
                          )}
                        </span>
                      ) : null}
                    </div>
                  </TableCell>
                ))}
              </TableRowStyled>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);

              return (
                <>
                  <TableRowStyled
                    {...row.getRowProps()}
                    key={`body-${index}`}
                    count={index}
                    expander={expanderVariant}
                    onClick={() => onRowClickHandler(row.original, row)}
                    sx={{
                      '&:is(:hover, .keepHoverState)': isFunction(customRowHoverStyle)
                        ? customRowHoverStyle(row, theme)
                        : isEmpty(customRowHoverStyle)
                        ? defaultHoverStyle
                        : customRowHoverStyle,
                    }}
                  >
                    {expanderVariant && (
                      <TableCell
                        sx={{
                          padding: '4px',
                          width: `calc(100% / ${columns.length})`,
                        }}
                        id={TABLE_ROW_EXPANDER_ID}
                      >
                        <Button
                          onClick={() => toggleExpanderHandler(row)}
                          variant="text"
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            minWidth: 'unset',
                            p: '4px',
                          }}
                        >
                          {expandedRowID === row.id ? <ExpandLessOutlinedIcon style={iconSx} /> : <ExpandMoreOutlinedIcon style={iconSx} />}
                        </Button>
                      </TableCell>
                    )}
                    {row.cells.map((cell, index) => {
                      const cellExtraProps = cell?.column?.cellExtraProps?.(cell) || {};
                      return (
                        <TableCell
                          {...cell.getCellProps()}
                          key={`bodycell-${index}`}
                          sx={{
                            padding: '4px',
                            width: `calc(100% / ${columns.length})`,
                            ...cellExtraProps?.style,
                          }}
                        >
                          <div
                            style={{
                              height: 'auto',
                              minHeight: '40px',
                              alignItems: 'center',
                              display: 'flex',
                              overflow: 'hidden',
                              userSelect: 'none',
                              whiteSpace: 'nowrap',
                              justifyContent: cell.column.justifyContent ? cell.column.justifyContent : 'flex-start',
                            }}
                          >
                            <Typography
                              variant="bodySmall"
                              sx={{
                                width: cell.column.fullWidth ? cell.column.fullWidth : {},
                              }}
                              color={theme.palette.baseLight.main}
                              title={typeof cell.render('Cell') === 'string' ? cell.render('Cell') : ''}
                            >
                              {cell.render('Cell')}
                            </Typography>
                          </div>
                        </TableCell>
                      );
                    })}
                  </TableRowStyled>
                  {renderDetailsComponent && expandedRowID === row.id ? (
                    <TableRow>
                      <TableCell colSpan={expanderVariant ? columns?.length + 1 : columns?.length || 1} sx={{ p: 0 }}>
                        {renderDetailsComponent({ rowData: row.original, row, rows })}
                      </TableCell>
                    </TableRow>
                  ) : null}
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {pagination ? (
        <TablePagination
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          style={{
            width: '100%',
            paddingRight: '94px',
            boxShadow: theme.shadowOptions.aboveSmall,
          }}
        >
          {loading && <Typography variant="bodySmall">{formatMessage({ id: 'loadingSpinner.loadingText' })}</Typography>}
        </TablePagination>
      ) : null}
    </>
  );
};

const iconSx = {
  color: themeOptions.palette.baseLight.main,
  height: '24px',
  width: '24px',
};

export default ReactTable;
