import { useMemo } from 'react';

import type { ColumnDef } from '@tanstack/react-table';
import { useIntl } from 'react-intl';

import { GraphqlVehicleUsageStatusHelper, VehicleTypeHelper } from '../../models';
import { FormattedLiveVehicle } from '../../state/common/vehicle.model';

export function MapVehiclesTableColumnDefs() {
  const { formatMessage } = useIntl();

  return useMemo(
    (): ColumnDef<FormattedLiveVehicle>[] => [
      {
        header: formatMessage({ id: 'analytics.table.column.id' }),
        accessorKey: 'qrCodeId',
      },
      {
        header: formatMessage({ id: 'analytics.table.column.vehicleId' }),
        accessorKey: 'vehicleId',
      },
      {
        header: formatMessage({ id: 'analytics.table.column.formFactor1' }),
        accessorKey: 'formFactor1',
      },
      {
        id: 'endDate',
        header: formatMessage({ id: 'analytics.table.column.mspName' }),
        accessorKey: 'mspName',
      },

      {
        header: formatMessage({ id: 'analytics.table.column.formFactor' }),
        accessorFn: (originalRow: FormattedLiveVehicle) => VehicleTypeHelper.metadata(originalRow.formFactor).displayText,
      },
      {
        header: formatMessage({ id: 'analytics.table.column.last_event_type' }),
        accessorKey: 'last_event_type',
      },
      {
        header: formatMessage({ id: 'analytics.table.column.usageStatus' }),
        accessorKey: 'usageStatus',
        accessorFn: (originalRow: FormattedLiveVehicle) => GraphqlVehicleUsageStatusHelper.metadata(originalRow.usageStatus).displayText,
      },
    ],
    [formatMessage],
  );
}
