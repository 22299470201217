import React, { ChangeEvent, KeyboardEvent, ReactNode, useMemo, useState } from 'react';

import type { FormControlProps, OutlinedInputProps, SxProps, Theme } from '@mui/material';
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import type { IconType } from '../../utils/CustomIcon';
import CustomIcon from '../../utils/CustomIcon';
import HelperText from '../HelperText';

import { FormatAsCents } from './FormatAsCents';
import { FormatAsI18nNumber } from './FormatAsI18nNumber';

export const NumberInput: React.FC<NumberInputProps> = ({
  value,
  name,
  onChange,
  label,
  placeholder,
  dataTestId,
  size = 'medium',
  min = 0,
  max = Number.MAX_SAFE_INTEGER,
  step = 1,
  helperText,
  error,
  disabled,
  sx,
  inputExtraProps,
  formControlExtraProps,
  fullWidth = true,
  formatAsCents = false,
  formatAsI18nNumber = true,
}) => {
  const theme = useTheme();
  const [hoveredButton, setHoveredButton] = useState<IconButtonType | null>(null);

  const formatInputComponent = useMemo(() => {
    if (formatAsCents) return FormatAsCents;
    if (formatAsI18nNumber) return FormatAsI18nNumber;
    return null;
  }, [formatAsCents, formatAsI18nNumber]);

  const handleIconButtonClickChange = (delta: number) => {
    if (onChange)
      onChange({
        name,
        value: Math.min(max, Math.max(min, Number(value) + delta)),
      });
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(event.target.value);
    if (!isNaN(newValue) && newValue <= max && onChange) {
      onChange({ name, value: newValue });
    } else if (newValue > max && onChange) {
      onChange({ name, value: max });
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const arrowUpOrArrowDownPressed = ['ArrowUp', 'ArrowDown'].includes(event.key) && !event.ctrlKey && !event.shiftKey && !event.altKey;
    const adding = event.key === 'ArrowUp';
    if (arrowUpOrArrowDownPressed) {
      event.preventDefault();
      handleIconButtonClickChange(adding ? step : -step);
    }
  };

  const renderIconButton = (iconType: IconButtonType) => {
    const adding = iconType === 'plus';
    const subtracting = iconType === 'minus';
    const iconButtonDisabled = (subtracting && Number(value) <= min) || (adding && Number(value) >= max);
    const isHovered = hoveredButton === iconType;

    let iconName: IconType;
    if (adding) {
      iconName = isHovered ? 'PlusIconFilled' : 'PlusIconOutlined';
    } else {
      iconName = isHovered ? 'MinusIconFilled' : 'MinusIconOutlined';
    }

    return (
      <IconButton
        onMouseEnter={() => setHoveredButton(iconType)}
        onMouseLeave={() => setHoveredButton(null)}
        onClick={() => handleIconButtonClickChange(adding ? step : -step)}
        edge={adding ? 'end' : 'start'}
        disabled={disabled || iconButtonDisabled}
      >
        {CustomIcon(iconName, iconButtonDisabled || disabled ? theme.palette.dark.shade24 : theme.palette.dark.main, theme.spacing(3))}
      </IconButton>
    );
  };

  return (
    <FormControl variant="outlined" sx={sx} fullWidth={fullWidth} {...formControlExtraProps}>
      <InputLabel htmlFor="number-input">{label}</InputLabel>
      <OutlinedInput
        value={value}
        name={name}
        label={label}
        placeholder={placeholder}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        disabled={disabled}
        size={size}
        inputProps={{ style: { textAlign: 'center' }, dataTestId: dataTestId }}
        startAdornment={<InputAdornment position="start">{renderIconButton('minus')}</InputAdornment>}
        endAdornment={<InputAdornment position="end">{renderIconButton('plus')}</InputAdornment>}
        inputComponent={formatInputComponent as any}
        {...inputExtraProps}
      />
      {helperText && <HelperText value={helperText} type={error ? 'error' : 'info'} />}
    </FormControl>
  );
};

export interface NumberInputProps {
  value: string | number;
  name: string;
  onChange?: NumberInputChangeHandler;
  label: string;
  placeholder?: string;
  dataTestId: string;
  size?: 'small' | 'medium' | 'large';
  fullWidth?: boolean;
  min?: number;
  max?: number;
  step?: number;
  helperText?: string | null;
  error?: boolean;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  formControlExtraProps?: Omit<FormControlProps, 'sx' | 'variant' | 'fullWidth'>;
  inputExtraProps?: Omit<
    OutlinedInputProps,
    'onChange' | 'name' | 'value' | 'label' | 'dataTestId' | 'size' | 'disabled' | 'onKeyDown' | 'variant' | 'InputProps'
  >;
  formatInputComponent?: ReactNode;
  formatAsCents?: boolean;
  formatAsI18nNumber?: boolean;
}

type IconButtonType = 'plus' | 'minus';
export type NumberInputChangeHandler = ({ name, value }: { name: string; value: number }) => void;
