import React, { ChangeEvent, useMemo, useState, type ReactNode } from 'react';

import Box from '@mui/material/Box';
import dayjs from 'dayjs';

import { TripActivityLogEventType, TripDetailsResponse } from '../../../models';
import { TripActivityLogService } from '../../../services/trip/TripActivityLogService';
import { WmvSwitch } from '../../../wmv-components';

import { TripForceStopForm, TripForceStopFormField } from './TripForceStopForm';

interface TripForceStopFormToggleProps {
  tripDetails: TripDetailsResponse;
  onForceStopBtnSubmit: (values: TripForceStopFormField) => void;
  isTripFinished: boolean;
}

/**
 * TripForceStopFormToggle component renders a switch to toggle the visibility of the TripForceStopForm.
 * It allows users to forcefully stop a trip by specifying start and end times.
 * If the trip is already finished, the component returns null, rendering nothing.
 *
 * @component
 * @param {TripForceStopFormToggleProps} props - The props for the TripForceStopFormToggle component.
 * @param {TripDetailsResponse} props.tripDetails - The details of the trip being managed, including activity logs.
 * @param {function} props.onForceStopBtnSubmit - Callback function to handle the submission of the force stop form.
 * @param {boolean} props.isTripFinished - Flag indicating whether the trip is finished. If true, no form is displayed.
 *
 * @returns {ReactNode} A JSX element containing a toggle switch and the TripForceStopForm, or null if the trip is finished.
 */
export function TripForceStopFormToggle({ tripDetails, onForceStopBtnSubmit, isTripFinished }: TripForceStopFormToggleProps): ReactNode {
  const [showTripForceStopForm, setShowTripForceStopForm] = useState(false);

  const defaultValues: TripForceStopFormField = useMemo(() => {
    const getTimeStampOfEvent = (event: TripActivityLogEventType) =>
      tripDetails?.activityLog.find((activity) => activity.event === event)?.timestamp;
    const terminalState = new TripActivityLogService(tripDetails.activityLog).terminalState;
    const parsedTerminalStateTimestamp = terminalState ? terminalState.timestamp.parseEpochMillis() : dayjs.now();

    return {
      startDate: getTimeStampOfEvent(TripActivityLogEventType.Started)!.parseEpochMillis(),
      startTime: getTimeStampOfEvent(TripActivityLogEventType.Started)!.parseEpochMillis(),
      endDate: parsedTerminalStateTimestamp,
      endTime: parsedTerminalStateTimestamp,
    };
    // Here eslint-disable-next-line react-hooks/exhaustive-deps, because we want to update the endDate and endTime whenever showTripForceStopForm changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripDetails?.activityLog, showTripForceStopForm]);

  if (isTripFinished) return null;

  return (
    <Box mt={5}>
      <WmvSwitch
        name="forceStopFormVisible"
        checked={showTripForceStopForm}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setShowTripForceStopForm(event.target.checked);
        }}
        label={'trip.forceStopSwitch.label'.i18nText()}
      />
      {showTripForceStopForm && (
        <TripForceStopForm formValues={defaultValues} onForceStopBtnSubmit={onForceStopBtnSubmit} tripDetails={tripDetails} />
      )}
    </Box>
  );
}
