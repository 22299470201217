import type { Theme } from '@mui/material/styles';

export const zoneRuleFormStyles = (theme: Theme) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
});

export const vehicleTypesGroupCheckboxStyle = (theme: Theme) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: 1,
  mt: 2,
  justifyItems: 'stretch',
});

export const formDeleteButtonStyle = (theme: Theme) => ({
  textTransform: 'unset',
  '&:hover': {
    fill: theme.palette.alert.main,
  },
});
