import type { Theme } from '@mui/material';

export const modalContentStyles = (theme: Theme, bgColor?: string) => ({
  position: 'absolute',
  minHeight: '400px',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: `${theme.spacing(2)}`,
  borderRadius: '8px',
  backgroundColor: bgColor || theme.palette.baseLight.base4,
  display: 'flex',
  flexDirection: 'column',
});

export const closeIconButtonStyles = (theme: Theme) => ({
  position: 'absolute',
  top: '-40px',
  right: '-40px',
});
