import { useMemo, useRef } from 'react';

import { Box, Stack } from '@mui/material';
import { Form, Formik } from 'formik';
import { useIntl } from 'react-intl';

import { enqueuedSnackbar } from '../../../components/EnqueuedSnackbar';
import { VehicleTypeHelper } from '../../../models';
import { VehiclePricingDto } from '../../../services/vehicle-fee-pricing';
import { discardFailedVehiclePricingStatusTransition, updateVehicleFeePricing } from '../../../utils/queries';
import HelperText from '../../../wmv-components/HelperText';

import {
  SelectedVehicleFeePricingActionType,
  useIsViewingActivePricingDetails,
  useIsViewingFailedPricingDetails,
  useIsViewingInProgressPricingDetails,
  useSelectedVehiclePricingDetails,
  useSelectedVehiclePricingDispatch,
  useSelectedVehiclePricingPricingId,
  useSelectedVehiclePricingState,
  useVehiclePricingDispatch,
  useVehiclePricingState,
  VehiclePricingActionType,
} from './contexts';
import { makeUpdateVehiclePricingRequestPayload, VehiclePricingDetailsActionMode } from './helpers';
import { useFetchVehiclePricingList, useUpdatePricingModalActionMode, useVehiclePricingFormInitialValues } from './hooks';
import { useVehiclePricingStateTransitionHelperText } from './hooks/useVehiclePricingStateTransitionHelperText';
import { vehiclePricingFormValidationSchema } from './validation-schemas';
import { VehicleFeePricingInputs } from './VehicleFeePricingInputs';
import { VehiclePricingFormTitleWithActionButtons } from './VehiclePricingFormTitleWithActionButtons';

export function VehiclePricingDetailsForm() {
  const { formatMessage } = useIntl();

  const fetchVehicleFeePricingListAndUpdateState = useFetchVehiclePricingList();
  const vehiclePricingFormInitialValues = useVehiclePricingFormInitialValues();
  const selectedPricingDispatch = useSelectedVehiclePricingDispatch()!;
  const selectedVehiclePricingDetails = useSelectedVehiclePricingDetails();
  const vehiclePricingDispatch = useVehiclePricingDispatch()!;
  const { updatePricingApiStatus } = useSelectedVehiclePricingState();
  const isViewingActivePricingDetails = useIsViewingActivePricingDetails();
  const isViewingFailedPricingDetails = useIsViewingFailedPricingDetails();
  const isViewingInProgressPricingDetails = useIsViewingInProgressPricingDetails();

  const updatePricingModalActionMode = useUpdatePricingModalActionMode();
  const {
    helperText,
    helperTextType,
    setHelperTextToEmpty,
    setHelperTextForFailedTransition,
    setHelperTextForInProgressTransition,
    setHelperTextForFailedRequest,
  } = useVehiclePricingStateTransitionHelperText();

  const prevSelectedPricingDetailsRef = useRef<VehiclePricingDto | null>(null);
  const vehiclePricingState = useVehiclePricingState();
  const selectedVehiclePricingId = useSelectedVehiclePricingPricingId();

  const selectedPricingDetails = useMemo(
    () => vehiclePricingState.list.find((vehiclePricing) => vehiclePricing.id === selectedVehiclePricingId),
    [selectedVehiclePricingId, vehiclePricingState.list],
  );

  if (selectedPricingDetails && prevSelectedPricingDetailsRef.current?.version !== selectedPricingDetails.version) {
    prevSelectedPricingDetailsRef.current = selectedPricingDetails;
    selectedPricingDispatch({
      type: SelectedVehicleFeePricingActionType.UpdateSelectedPlanDetails,
      payload: selectedPricingDetails,
    });
    updatePricingModalActionMode(selectedPricingDetails);
  }

  if (isViewingActivePricingDetails && helperTextType !== 'info') setHelperTextToEmpty();
  if (isViewingFailedPricingDetails && helperTextType !== 'error') setHelperTextForFailedTransition();
  if (isViewingInProgressPricingDetails && helperTextType !== 'success') setHelperTextForInProgressTransition();

  return (
    <Formik
      initialValues={vehiclePricingFormInitialValues}
      validationSchema={vehiclePricingFormValidationSchema}
      onSubmit={handleVehiclePricingDetailsSubmit}
      enableReinitialize
    >
      {(props) => (
        <Form>
          <VehiclePricingFormTitleWithActionButtons onDiscardFailedStatusTransitionSubmit={handleDiscardFailedStatusTransitionSubmit} />
          <Box display="flex" justifyContent="flex-end" width="100%">
            <HelperText
              value={helperText}
              type={helperTextType}
              showCheckIcon={isViewingInProgressPricingDetails}
              showAttentionIcon={isViewingFailedPricingDetails || updatePricingApiStatus.isFailed()}
              sx={{ justifyContent: 'flex-end', maxWidth: '380px' }}
            />
          </Box>
          <Stack gap={2} mt={3}>
            <VehicleFeePricingInputs />
          </Stack>
        </Form>
      )}
    </Formik>
  );

  async function handleDiscardFailedStatusTransitionSubmit() {
    try {
      setHelperTextToEmpty();
      selectedPricingDispatch({
        type: SelectedVehicleFeePricingActionType.DiscardFailedTransitionStart,
      });
      await discardFailedVehiclePricingStatusTransition(
        selectedVehiclePricingDetails?.msp.subjectClaim!,
        selectedVehiclePricingDetails?.modelId!,
      );
      selectedPricingDispatch({
        type: SelectedVehicleFeePricingActionType.DiscardFailedTransitionSuccess,
      });
      vehiclePricingDispatch({
        type: VehiclePricingActionType.UpdateSelectedVehicleFeePricingId,
        payload: null,
      });
      fetchVehicleFeePricingListAndUpdateState();
      enqueuedSnackbar(
        formatMessage(
          { id: 'vehicleFeePricing.successfullyUpdated' },
          {
            vehicleType: VehicleTypeHelper.metadata(selectedVehiclePricingDetails!.vehicleType).displayText,
            vehicleModel: selectedVehiclePricingDetails!.modelName,
          },
        ),
        'success',
      );
    } catch (e) {
      setHelperTextForFailedRequest();
      selectedPricingDispatch({
        type: SelectedVehicleFeePricingActionType.DiscardFailedTransitionFailure,
      });
    }
  }

  async function handleVehiclePricingDetailsSubmit(values: VehiclePricingFormValues) {
    try {
      setHelperTextToEmpty();
      selectedPricingDispatch({
        type: SelectedVehicleFeePricingActionType.UpdateVehiclePricingStart,
      });
      const request = makeUpdateVehiclePricingRequestPayload(values, selectedVehiclePricingDetails!.version);
      const resp = await updateVehicleFeePricing(
        request,
        selectedVehiclePricingDetails?.msp.subjectClaim!,
        selectedVehiclePricingDetails?.modelId!,
      );
      const newPricingDto = new VehiclePricingDto(resp);
      selectedPricingDispatch({
        type: SelectedVehicleFeePricingActionType.UpdateVehiclePricingSuccess,
        payload: newPricingDto,
      });
      fetchVehicleFeePricingListAndUpdateState();
      await updateHelperTextAndActionMode(newPricingDto);
    } catch (e) {
      setHelperTextForFailedRequest();
      selectedPricingDispatch({
        type: SelectedVehicleFeePricingActionType.UpdateVehiclePricingFailure,
      });
    }
  }

  async function updateHelperTextAndActionMode(vehiclePricing: VehiclePricingDto) {
    if (vehiclePricing.isStateChangeInProgress()) {
      setHelperTextForInProgressTransition();
      vehiclePricingDispatch({
        type: VehiclePricingActionType.UpdateActionMode,
        payload: VehiclePricingDetailsActionMode.ViewInProgressPricing,
      });
    }
    if (vehiclePricing.isStateChangeSuccessful()) {
      vehiclePricingDispatch({
        type: VehiclePricingActionType.UpdateSelectedVehicleFeePricingId,
        payload: null,
      });
      const snackbarTextOptions = {
        vehicleType: VehicleTypeHelper.metadata(vehiclePricing.vehicleType).displayText,
        vehicleModel: vehiclePricing.modelName,
      };
      enqueuedSnackbar(formatMessage({ id: 'vehicleFeePricing.successfullyUpdated' }, snackbarTextOptions), 'success');
    }
    if (vehiclePricing.isStateChangeFailed()) {
      setHelperTextForFailedTransition();
      vehiclePricingDispatch({
        type: VehiclePricingActionType.UpdateActionMode,
        payload: VehiclePricingDetailsActionMode.ViewFailedPricing,
      });
    }
  }
}

export interface VehiclePricingFormValues {
  activeActivationFee: number | null;
  activeActiveMinutesFee: number | null;
  activePausedMinutesFee: number | null;
  newActivationFee: number | null;
  newActiveMinutesFee: number | null;
  newPausedMinutesFee: number | null;
}
