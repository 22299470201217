import L from 'leaflet';

import car from '../../assets/map-illustrations/car.svg';
import cargo_bicycle from '../../assets/map-illustrations/cargo_bicycle.svg';
import ebike from '../../assets/map-illustrations/ebike.svg';
import moped from '../../assets/map-illustrations/moped.svg';
import scooter from '../../assets/map-illustrations/scooter.svg';
import { VehicleType } from '../../models';

const VehicleIcons = (className = '', vehicleType: VehicleType, selected = false) => {
  return L.divIcon({
    iconSize: [34, 34],
    className: `my-div-icon ${className} ${selected ? 'selected' : ''}`,
    html: `<img src=${vehicleTypesIconMapping.get(vehicleType)} alt='pic'/>`,
  });
};

const vehicleTypesIconMapping = new Map<VehicleType, string>([
  [VehicleType.ElectricMoped, moped],
  [VehicleType.Scooter, scooter],
  [VehicleType.CargoBicycle, cargo_bicycle],
  [VehicleType.Car, car],
  [VehicleType.ElectricBike, ebike],
]);

export default VehicleIcons;
