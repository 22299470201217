import type { FormatNumberOptions } from 'react-intl';

import { intl } from '../../utils/intl';

/**
 * Extends the Number prototype to include a method for formatting numbers as currency.
 * This method leverages `react-intl` to format the number according to the specified options and currency format.
 *
 * @function
 * @name Number#toCurrency
 * @param {FormatNumberOptions} [formatNumberOptions] - Optional formatting options for the number.
 * @param {string} [currencyFormat='EUR'] - The currency format to use. Defaults to 'EUR'.
 * @returns {string} - The formatted currency string.
 *
 * @example
 * const price = 1234.56;
 * console.log(price.toCurrency()); // Output: "€1,234.56" (depending on locale and currency settings)
 * console.log(price.toCurrency({ minimumFractionDigits: 0 }, 'USD')); // Output: "$1,235"
 */

declare global {
  interface Number {
    toCurrency(formatNumberOptions?: FormatNumberOptions, currencyFormat?: string): string;
  }
}

// eslint-disable-next-line no-extend-native
Number.prototype.toCurrency = function (formatNumberOptions?: FormatNumberOptions, currencyFormat: string = 'EUR'): string {
  const currency = { style: 'currency', currency: currencyFormat, ...formatNumberOptions } as FormatNumberOptions;

  return intl.formatNumber(Number(this), currency);
};

export {};
