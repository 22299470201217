import isEmpty from 'lodash/isEmpty';

import {
  BudgetPlanMembershipDrawingBudgetFrom,
  BudgetPlanMembershipSearch,
  BudgetPlanMembershipDetails,
  BudgetPlanMembershipLinkedBudgetPlan,
} from '../../models';

export class BudgetPlanMembershipSearchDto {
  details: BudgetPlanMembershipDetails;
  linkedBudgetPlan?: BudgetPlanMembershipLinkedBudgetPlan;
  drawingBudgetFrom?: BudgetPlanMembershipDrawingBudgetFrom;

  constructor(model: BudgetPlanMembershipSearch) {
    this.details = model.details;
    this.linkedBudgetPlan = model.linkedBudgetPlan;
    this.drawingBudgetFrom = model.drawingBudgetFrom;
  }

  isEligibleToBeLinked(): boolean {
    return !this.isLinkedToAnyBudgetPlan() && !this.isDrawingFromOtherBudgetPlan();
  }

  isLinkedTo(planId: number): boolean {
    if (!this.isLinkedToAnyBudgetPlan()) return false;
    return this.linkedBudgetPlan!.id === planId;
  }

  isDrawingFrom(planId: number): boolean {
    if (!this.isDrawingFromOtherBudgetPlan()) return false;
    return this.drawingBudgetFrom!.budgetPlan.id === planId;
  }

  isLinkedToAnyBudgetPlan(): boolean {
    return !isEmpty(this.linkedBudgetPlan);
  }

  isDrawingFromOtherBudgetPlan(): boolean {
    return !isEmpty(this.drawingBudgetFrom);
  }
}
