import type { Dayjs } from 'dayjs';

import { MAX_DEFAULT_DATE } from '../../utils/constants';

export interface VisibilityStatusPointProvider {
  isVisible(evaluationPoint: Dayjs): boolean;
  calculateNextVisiblePoint(evaluationPoint: Dayjs): Dayjs;
  calculateNextInvisiblePoint(evaluationPoint: Dayjs): Dayjs;
}

export const VisibilityStatusPointProviderConstants = {
  endOfUniverse: MAX_DEFAULT_DATE.asDayjsDate(),
};
