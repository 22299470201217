import { TripActivityLogDetails } from '../../models';

/**
 * Service class for handling and analyzing trip activity logs.
 * This class provides methods to access specific details about the trip's activity,
 * such as finding the terminal state of the trip.
 *
 * @class
 */
export class TripActivityLogService {
  private activityLog: TripActivityLogDetails[];

  constructor(activityLog: TripActivityLogDetails[]) {
    this.activityLog = activityLog;
  }

  /**
   * Retrieves the first activity log entry that indicates the terminal state of the trip.
   * The terminal state represents the final state of the trip, indicating its end.
   *
   * @type {TripActivityLogDetails | undefined}
   * @readonly
   */
  get terminalState() {
    return this.activityLog.find((trip) => trip.terminalState);
  }
}
