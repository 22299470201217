import { Box } from '@mui/material';
import { useAtom } from 'jotai';

import { ReactComponent as EyeOpenIcon } from '../../assets/icons/fill/eyeOpen.svg';
import { ReactComponent as OverlayFilledIcon } from '../../assets/icons/fill/overlayFilled.svg';
import { ReactComponent as ArrowsInactiveIcon } from '../../assets/icons/outline/arrowsLeft.svg';
import { ReactComponent as ArrowsActiveIcon } from '../../assets/icons/outline/arrowsRightActive.svg';
import { ReactComponent as EyeHideColoredIcon } from '../../assets/icons/outline/eyeHideColored.svg';
import { ReactComponent as OverlayOutlinedIcon } from '../../assets/icons/outline/overlay.svg';
import { isHexagonLayerVisibleAtom } from '../../components/Map/atoms/hexagon-layer-atoms';
import { AnalyticsLegendControl } from '../../components/Map/controls/analytics/';
import { AnalyticsLayerControlContent } from '../../components/Map/controls/analytics/AnalyticsLayerControl';
import { GroupMenuControl } from '../../components/Map/controls/GroupMenuControl';
import HexagonLayer from '../../components/Map/HexagonLayer';
import SetView from '../../components/Map/MapModes/PlanningMode/LayersView/SetView';
import WikimoveMap from '../../components/Map/WikimoveMap';
import { DEFAULT_MAIN_CONTENT_HEIGHT } from '../../utils/constants';

/**
 * The Analytics component renders a map with various layers and controls,
 * including a hexagon layer, a legend control, and a group menu control with multiple buttons.
 *
 * @returns {JSX.Element} The rendered Analytics component.
 */
const Analytics = () => {
  const [isHexagonLayerVisible, setIsHexagonLayerVisible] = useAtom(isHexagonLayerVisibleAtom);

  return (
    <Box height={DEFAULT_MAIN_CONTENT_HEIGHT} component="main">
      <WikimoveMap>
        {/* Renders the hexagon layer based on the visibility state */}
        <HexagonLayer showLayer={isHexagonLayerVisible} />

        {/* Renders the legend control for the analytics layer */}
        {isHexagonLayerVisible && <AnalyticsLegendControl />}

        {/* Renders the group menu control with multiple buttons */}
        <GroupMenuControl
          buttons={[
            {
              icon: <ArrowsInactiveIcon />,
              activeIcon: <ArrowsActiveIcon />,
              content: <AnalyticsLayerControlContent />,
            },
            {
              icon: <OverlayOutlinedIcon />,
              activeIcon: <OverlayFilledIcon />,
              content: <SetView />,
            },
            {
              icon: <EyeOpenIcon />,
              activeIcon: <EyeHideColoredIcon />,
              onClick: () => {
                setIsHexagonLayerVisible(!isHexagonLayerVisible);
              },
            },
          ]}
        />
      </WikimoveMap>
    </Box>
  );
};

export default Analytics;
