import { useState, useCallback } from 'react';

import { useIntl } from 'react-intl';

import { HelperTextType } from '../../../../wmv-components/HelperText';

export function useVehiclePricingStateTransitionHelperText() {
  const { formatMessage: fm } = useIntl();
  const [helperText, setHelperText] = useState<string>('');
  const [helperTextType, setHelperTextType] = useState<HelperTextType>('info');

  const setHelperTextToEmpty = useCallback(function () {
    setHelperText('');
    setHelperTextType('info');
  }, []);

  const setHelperTextToFailedTransition = useCallback(
    function () {
      setHelperText(fm({ id: 'common.updatingFailed' }));
      setHelperTextType('error');
    },
    [fm],
  );

  const setHelperTextToInProgressTransition = useCallback(
    function () {
      setHelperText(fm({ id: 'common.requestSuccessfullySubmitted' }));
      setHelperTextType('success');
    },
    [fm],
  );

  const setHelperTextToFailedRequest = useCallback(
    function () {
      setHelperText(fm({ id: 'common.updateRetry' }));
      setHelperTextType('error');
    },
    [fm],
  );

  return {
    helperText,
    helperTextType,
    setHelperTextToEmpty,
    setHelperTextForFailedTransition: setHelperTextToFailedTransition,
    setHelperTextForInProgressTransition: setHelperTextToInProgressTransition,
    setHelperTextForFailedRequest: setHelperTextToFailedRequest,
  };
}
