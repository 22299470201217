import { BudgetAccountSharingMember, BudgetPlanMembershipActiveState, BudgetPlanMembershipStatus } from '../../models';

export class BudgetPlanMembershipActiveStateDto {
  status: BudgetPlanMembershipStatus;
  subscribedAtEpochMillis?: number;
  isSubscribed: boolean;
  isNotSubscribed: boolean;
  sharingMembers?: BudgetAccountSharingMember[];

  constructor(activeState: BudgetPlanMembershipActiveState) {
    this.status = activeState.status;
    this.sharingMembers = activeState.budgetAccountMembers;
    this.isSubscribed = this.status === BudgetPlanMembershipStatus.Subscribed;
    this.isNotSubscribed = this.status === BudgetPlanMembershipStatus.Unsubscribed;
    if (activeState.subscribedAtEpochMillis) {
      this.subscribedAtEpochMillis = activeState.subscribedAtEpochMillis;
    }
  }
}
