import { useEffect, useRef, useState } from 'react';

import { h3SetToFeatureCollection } from 'geojson2h3';
import { useAtomValue } from 'jotai';
import L from 'leaflet';
import { useIntl } from 'react-intl';
import { useMap } from 'react-leaflet';

import { colorScaleAtom, hexagonResolutionAtom, poiDataAtom, rangesAtom } from './atoms/hexagon-layer-atoms';
// Replace with actual data once the API is ready
import { myH3data10 } from './mockData/h3_trip_counts_res10';
import { myH3data9 } from './mockData/h3_trip_counts_res9';

interface HexagonLayerProps {
  showLayer: boolean; // Prop to control the visibility of the layer
}

/**
 * HexagonLayer component adds a GeoJSON layer to a Leaflet map to visualize H3 hexagons.
 * The layer's visibility, hexagon data, and color scale can be controlled through props and state.
 *
 * @param {HexagonLayerProps} props - The props for the HexagonLayer component.
 * @returns {null} This component does not render any JSX.
 */
const HexagonLayer = ({ showLayer }: HexagonLayerProps) => {
  const map = useMap();
  const { formatMessage } = useIntl();

  const colorScale = useAtomValue(colorScaleAtom);
  const ranges = useAtomValue(rangesAtom);
  const selectedResolution = useAtomValue(hexagonResolutionAtom);
  const selectedPoiData = useAtomValue(poiDataAtom);

  const [hexagonData, setHexagonData] = useState<{ [key: string]: number }>(myH3data9);
  const hexLayerRef = useRef<L.GeoJSON | null>(null);

  /**
   * Effect to load hexagon data based on the selected resolution.
   * Updates the hexagon data when the `selectedResolution` changes.
   */
  useEffect(() => {
    /**
     * Updates hexagon data based on the selected resolution.
     *
     * @param {number} resolution - The selected H3 resolution.
     */
    const loadHexagonData = (resolution: number) => {
      let data: { [key: string]: number };
      if (resolution === 9) {
        data = myH3data9;
      } else if (resolution === 10) {
        data = myH3data10;
      } else {
        data = {};
      }
      setHexagonData(data);
    };

    loadHexagonData(selectedResolution);
  }, [selectedResolution]);

  /**
   * Effect to add the hexagon layer to the map. It recalculates the hexagons and
   * re-applies the layer when dependencies change (e.g., `colorScale`, `ranges`, `hexagonData`).
   * Also handles cleanup by removing the previous layer before adding the new one.
   */
  useEffect(() => {
    if (!map || !showLayer) return;

    /**
     * Determines the color of the hexagon based on its value.
     *
     * @param {number} value - The value of the hexagon.
     * @returns {string} The corresponding color from the color scale.
     */
    const getColor = (value: number) => {
      for (let i = ranges.length - 1; i >= 0; i--) {
        if (value > ranges[i]) return colorScale[i];
      }
      return colorScale[0];
    };

    // Convert hexagon data to GeoJSON features
    const geojsonHexes = h3SetToFeatureCollection(Object.keys(hexagonData), (hex) => ({ value: hexagonData[hex] }));

    // TODO: Add support for areas
    //const geojsonAreas = geojson2h3.h3SetToFeature(Object.keys(mockHexagons).filter((hex) => mockHexagons[hex] > 30));

    // Remove the previous hex layer if it exists
    if (hexLayerRef.current) {
      map.removeLayer(hexLayerRef.current);
    }

    // Create a new hex layer with the updated hexagon data
    const hexLayer = L.geoJSON(geojsonHexes, {
      style: (feature) => ({
        fillColor: getColor(feature?.properties.value),
        weight: 2,
        opacity: 1,
        color: 'transparent',
        // dashArray: '3',
        fillOpacity: 0.6,
      }),
      onEachFeature: (feature, layer) => {
        // Bind a tooltip to each hexagon feature
        if (feature.properties && feature.properties.value !== undefined) {
          layer.bindTooltip(`${formatMessage({ id: selectedPoiData })}: ${feature.properties.value}`, {
            permanent: false,
            direction: 'auto',
          });
        }
      },
    }).addTo(map);

    hexLayerRef.current = hexLayer; // Store the layer instance in the ref

    // TODO: Add support for areas
    // const areaLayer = L.geoJSON(geojsonAreas, {
    //   style: {
    //     color: config.colorScale[6],
    //     weight: 3,
    //   },
    // }).addTo(map);

    // Cleanup previous layers if the component unmounts or updates
    return () => {
      // Cleanup previous layers if component unmounts or updates
      if (hexLayerRef.current) {
        map.removeLayer(hexLayerRef.current);
      }
    };
  }, [map, colorScale, ranges, hexagonData, showLayer, selectedPoiData, formatMessage]);

  return null;
};

export default HexagonLayer;
