import { useTheme } from '@mui/material/styles';

import { BudgetPlanMembershipSearchDto } from '../../../../../services/budget-plan-memberships-search';
import { searchMembership } from '../../../../../utils/queries';
import { TranslatedText, Modal } from '../../../../../wmv-components';
import { REQUEST_STATUS } from '../../atoms/budget-plan-atoms';
import { useBudgetPlanMembership } from '../../atoms/hooks';
import { searchMembershipContentStyles } from '../styles';

import { SearchBudgetPlanMemberFormWithFormik } from './SearchBudgetPlanMemberForm';
import { SearchedMembershipDetails } from './SearchedMembershipDetails';

export const MembershipSearchAndLinkModal = () => {
  const { palette } = useTheme();
  const {
    bmLinkModalVisible,
    setBMLinkModalVisible,
    setBMLinkModalSearchString,
    setBMUserToLink,
    bmLinkModalSearchStatus,
    setBMLinkModalSearchStatus,
    bmLinkUserStatus,
  } = useBudgetPlanMembership();

  return (
    <Modal
      open={bmLinkModalVisible}
      onClose={handleLinkNewMembershipModalClose}
      disableClose={bmLinkModalSearchStatus === REQUEST_STATUS.PENDING || bmLinkUserStatus === REQUEST_STATUS.PENDING}
      bgColor={palette.baseLight.base0}
    >
      <Modal.Content sx={searchMembershipContentStyles}>
        <TranslatedText translationKey="common.linkNewUser" variant="bodyLargeBold" />
        <SearchBudgetPlanMemberFormWithFormik onSearchBudgetPlanMembershipClick={handleSearchBudgetPlanMembershipClick} />
        <SearchedMembershipDetails onSearchBudgetPlanMembershipClick={handleSearchBudgetPlanMembershipClick} />
      </Modal.Content>
    </Modal>
  );

  async function handleSearchBudgetPlanMembershipClick(values: SearchMemberFormValues) {
    setBMLinkModalSearchStatus(REQUEST_STATUS.PENDING);
    try {
      const resp = await searchMembership(values.memberEmail);
      setBMUserToLink(resp ? new BudgetPlanMembershipSearchDto(resp) : null);
      setBMLinkModalSearchStatus(REQUEST_STATUS.SUCCESS);
    } catch (err) {
      console.error('Error searching for membership', err);
      setBMUserToLink(null);
      setBMLinkModalSearchStatus(REQUEST_STATUS.FAILURE);
    }
  }

  async function handleLinkNewMembershipModalClose() {
    setBMLinkModalVisible(false);
    setBMLinkModalSearchString('');
    setBMUserToLink(null);
    setBMLinkModalSearchStatus(REQUEST_STATUS.IDLE);
  }
};

export interface SearchMemberFormValues {
  memberEmail: string;
}

export type SearchBudgetPlanMembershipHandler = (values: SearchMemberFormValues) => void;
