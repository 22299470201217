import { KeycloakConfig } from 'keycloak-js';

// casting is needed because the type definition in keycloak-js is not complete
const keycloakConfig: KeycloakConfig = {
  realm: process.env.REACT_APP_KEYCLOAK_REALM || 'mobility-os-dev',
  url: process.env.REACT_APP_KEYCLOAK_URL,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID || 'mobility-manager',
  'ssl-required': process.env.REACT_APP_KEYCLOAK_SSL_REQUIRED,
} as any as KeycloakConfig;

export default keycloakConfig;
