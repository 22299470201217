import { EnumTabHelper } from '../../utils/EnumTabHelper';

export enum AccountInformationTab {
  MyAccount = 'MY_ACCOUNT',
  MyProfile = 'MY_PROFILE',
}

const accountTranslations = {
  MyAccount: 'account.main.user',
  MyProfile: 'account.profile.user',
};

export const AccountInformationTabHelper = new EnumTabHelper(AccountInformationTab, accountTranslations);
