export const myH3data9 = {
  '891fa958107ffff': 35,
  '891fa95810fffff': 15,
  '891fa958117ffff': 14,
  '891fa958123ffff': 36,
  '891fa958127ffff': 40,
  '891fa95812bffff': 35,
  '891fa95812fffff': 33,
  '891fa958133ffff': 31,
  '891fa958137ffff': 38,
  '891fa95813bffff': 24,
  '891fa958163ffff': 16,
  '891fa958167ffff': 29,
  '891fa95816bffff': 1,
  '891fa95816fffff': 23,
  '891fa958173ffff': 4,
  '891fa958177ffff': 23,
  '891fa958187ffff': 3,
  '891fa9581a3ffff': 28,
  '891fa9581a7ffff': 35,
  '891fa9581abffff': 29,
  '891fa9581afffff': 28,
  '891fa9581b3ffff': 6,
  '891fa9581b7ffff': 8,
  '891fa9581bbffff': 26,
  '891fa95830bffff': 7,
  '891fa95830fffff': 2,
  '891fa958323ffff': 73,
  '891fa958327ffff': 36,
  '891fa95832bffff': 2,
  '891fa95832fffff': 13,
  '891fa958333ffff': 53,
  '891fa958337ffff': 64,
  '891fa95833bffff': 21,
  '891fa958343ffff': 18,
  '891fa958347ffff': 19,
  '891fa95834bffff': 13,
  '891fa95834fffff': 22,
  '891fa958353ffff': 5,
  '891fa958357ffff': 7,
  '891fa958363ffff': 20,
  '891fa958367ffff': 6,
  '891fa95836bffff': 24,
  '891fa95836fffff': 17,
  '891fa958373ffff': 11,
  '891fa958377ffff': 18,
  '891fa95837bffff': 18,
  '891fa9583a3ffff': 48,
  '891fa9583a7ffff': 57,
  '891fa9583abffff': 14,
  '891fa9583afffff': 58,
  '891fa958c03ffff': 19,
  '891fa958c0bffff': 29,
  '891fa958c13ffff': 52,
  '891fa958c1bffff': 66,
  '891fa958c43ffff': 12,
  '891fa958c47ffff': 24,
  '891fa958c4bffff': 11,
  '891fa958c53ffff': 44,
  '891fa958c57ffff': 67,
  '891fa958c5bffff': 33,
  '891fa958c73ffff': 4,
  '891fa958c8bffff': 32,
  '891fa958c8fffff': 12,
  '891fa958cc3ffff': 37,
  '891fa958cc7ffff': 57,
  '891fa958ccbffff': 33,
  '891fa958ccfffff': 50,
  '891fa958cd3ffff': 35,
  '891fa958cd7ffff': 53,
  '891fa958cdbffff': 32,
  '891fa958e17ffff': 1,
  '891fa958e5bffff': 2,
  '891fa958e83ffff': 20,
  '891fa958e87ffff': 27,
  '891fa958e8bffff': 27,
  '891fa958e8fffff': 23,
  '891fa958e93ffff': 32,
  '891fa958e97ffff': 32,
  '891fa958e9bffff': 535,
  '891fa958ebbffff': 12,
  '891fa958ecbffff': 6,
  '891fa958ed3ffff': 15,
  '891fa958ed7ffff': 18,
  '891fa958edbffff': 45,
  '891fa95988bffff': 16,
  '891fa959893ffff': 36,
  '891fa95989bffff': 51,
  '891fa9598c3ffff': 3,
  '891fa9598c7ffff': 17,
  '891fa9598d3ffff': 56,
  '891fa9598d7ffff': 56,
  '891fa9598dbffff': 1,
  '891fa959c03ffff': 55,
  '891fa959c07ffff': 63,
  '891fa959c0bffff': 63,
  '891fa959c0fffff': 73,
  '891fa959c13ffff': 61,
  '891fa959c17ffff': 73,
  '891fa959c1bffff': 58,
  '891fa959c23ffff': 55,
  '891fa959c27ffff': 15,
  '891fa959c2bffff': 52,
  '891fa959c2fffff': 8,
  '891fa959c33ffff': 75,
  '891fa959c37ffff': 17,
  '891fa959c3bffff': 77,
  '891fa959c43ffff': 9,
  '891fa959c47ffff': 60,
  '891fa959c4fffff': 62,
  '891fa959c57ffff': 34,
  '891fa959c63ffff': 60,
  '891fa959c67ffff': 53,
  '891fa959c6bffff': 59,
  '891fa959c6fffff': 56,
  '891fa959c73ffff': 58,
  '891fa959c77ffff': 74,
  '891fa959c7bffff': 53,
  '891fa959c83ffff': 3,
  '891fa959c87ffff': 3,
  '891fa959c8bffff': 67,
  '891fa959c8fffff': 59,
  '891fa959c9bffff': 1,
  '891fa959ca3ffff': 45,
  '891fa959ca7ffff': 18,
  '891fa959cabffff': 71,
  '891fa959cafffff': 65,
  '891fa959cb3ffff': 10,
  '891fa959cb7ffff': 13,
  '891fa959cbbffff': 60,
  '891fa959cc7ffff': 35,
  '891fa959ccfffff': 3,
  '891fa959d53ffff': 12,
  '891fa959d5bffff': 7,
  '891fa959dc3ffff': 13,
  '891fa959dcbffff': 15,
  '891fa959dcfffff': 3,
  '891fa959dd3ffff': 16,
  '891fa959dd7ffff': 5,
  '891fa959ddbffff': 22,
  '891fa959ea3ffff': 42,
  '891fa959ea7ffff': 70,
  '891fa959eb3ffff': 19,
  '891fa959eb7ffff': 57,
  '891fa95aa6bffff': 3,
  '891fa95aa6fffff': 1,
};
