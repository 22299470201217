import type { FormatNumberOptions } from 'react-intl';

import { intl } from '../../utils/intl';

declare global {
  interface Number {
    toCents(formatNumberOptions?: FormatNumberOptions): string;
  }
}

// eslint-disable-next-line no-extend-native
Number.prototype.toCents = function (formatNumberOptions?: FormatNumberOptions): string {
  const currency = { style: 'currency', currency: 'EUR', ...formatNumberOptions } as FormatNumberOptions;

  return intl.formatNumber(Number(this) / 100, currency);
};

export {};
