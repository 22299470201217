import { FormControl, Grid, MenuItem, Select, Typography } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { useIntl } from 'react-intl';

import { CustomerInfoFormFields } from './index';

import countyCodes from '../../../configs/country-codes.json';
import { themeOptions as theme } from '../../../init-setup/ThemeOptions';
import { countryCodesMenuConfig } from '../../../utils/constants';
import CustomTextField from '../../FormFields/CustomTextField';
import FormikErrorMessage from '../../FormFields/ErrorMessage';
import FormikSelectField from '../../FormFields/FormikSelectField';

export function ContactInfoSectionInputs({ isInEditMode }: { isInEditMode: boolean }) {
  const { formatMessage } = useIntl();
  const { values, handleBlur, handleChange } = useFormikContext<CustomerInfoFormFields>();

  return (
    <Grid container sx={{ marginTop: '20px' }}>
      <Typography variant="bodySmallBold" color={theme.palette.dark.main}>
        {formatMessage({ id: 'maas.customer.details.contactInformation' })}
      </Typography>
      <Grid container direction="row" alignItems="flex-start" sx={{ marginTop: '20px', display: 'flex' }}>
        <Grid item container xs={5} sx={{ marginRight: '30px', display: 'flex', flexDirection: 'column' }}>
          <Typography
            component="label"
            htmlFor="mobilephone"
            variant="caption"
            color={theme.palette.dark.shade60}
            sx={{ marginBottom: '4px', display: 'block' }}
          >
            {formatMessage({ id: 'maas.customer.details.mobilePhone' })}
          </Typography>
          <Grid item sx={{ display: 'flex' }}>
            <FormControl
              size="medium"
              sx={{
                display: 'flex',
                marginRight: '10px',
                minWidth: 'fit-content',
              }}
            >
              <Field
                as={Select}
                name="phoneNumberCountryCode"
                labelId="phoneNumberCountryCode-label"
                id="phoneNumberCountryCode"
                value={values.phoneNumberCountryCode}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={!isInEditMode}
              >
                {countyCodes.map((op) => (
                  <MenuItem key={op.dial_code} value={op.dial_code}>
                    {op.dial_code}
                  </MenuItem>
                ))}
              </Field>
            </FormControl>
            <Field as={CustomTextField} name="phoneNumberWithoutCountryCode" disabled={!isInEditMode} />
          </Grid>
          <FormikErrorMessage name="phoneNumberWithoutCountryCode" />
        </Grid>
        <Grid item xs={5} sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography component="label" htmlFor="email" variant="caption" color={theme.palette.dark.shade60} sx={{ marginBottom: '4px' }}>
            {formatMessage({ id: 'maas.customer.details.email' })}
          </Typography>
          <Field as={CustomTextField} id="email" name="email" disabled />
          <FormikErrorMessage name="email" />
        </Grid>
      </Grid>

      <Grid container={true} direction="row" flexWrap="nowrap" sx={{ marginTop: '20px' }}>
        <Grid item xs={5} sx={{ marginRight: '30px' }}>
          <Typography component="label" htmlFor="street" variant="caption" color={theme.palette.dark.shade60} sx={{ marginBottom: '4px' }}>
            {formatMessage({ id: 'maas.customer.details.streetName' })}
          </Typography>
          <Field as={CustomTextField} name="street" disabled={!isInEditMode} />
          <FormikErrorMessage name="street" />
        </Grid>
        <Grid item xs={2}>
          <Typography
            component="label"
            htmlFor="houseNumber"
            variant="caption"
            color={theme.palette.dark.shade60}
            sx={{ marginBottom: '4px' }}
          >
            {formatMessage({ id: 'maas.customer.details.houseNumber' })}
          </Typography>
          <Field as={CustomTextField} name="houseNumber" disabled={!isInEditMode} />
          <FormikErrorMessage name="houseNumber" />
        </Grid>
      </Grid>

      <Grid container={true} direction="row" flexWrap="nowrap" sx={{ marginTop: '20px' }}>
        <Grid item xs={5} sx={{ marginRight: '30px' }}>
          <Typography
            component="label"
            htmlFor="extension"
            variant="caption"
            color={theme.palette.dark.shade60}
            sx={{ marginBottom: '4px' }}
          >
            {formatMessage({ id: 'maas.customer.details.addressExtension' })}
          </Typography>
          <Field as={CustomTextField} name="extension" disabled={!isInEditMode} />
          <FormikErrorMessage name="extension" />
        </Grid>
      </Grid>

      <Grid container={true} direction="row" flexWrap="nowrap" sx={{ marginTop: '20px' }}>
        <Grid item xs={5} sx={{ marginRight: '30px' }}>
          <Typography
            component="label"
            htmlFor="postalCode"
            variant="caption"
            color={theme.palette.dark.shade60}
            sx={{ marginBottom: '4px' }}
          >
            {formatMessage({ id: 'maas.customer.details.postCode' })}
          </Typography>
          <Field as={CustomTextField} name="postalCode" disabled={!isInEditMode} />
          <FormikErrorMessage name="postalCode" />
        </Grid>
        <Grid item xs={5}>
          <Typography component="label" htmlFor="city" variant="caption" color={theme.palette.dark.shade60} sx={{ marginBottom: '4px' }}>
            {formatMessage({ id: 'maas.customer.details.city' })}
          </Typography>
          <Field as={CustomTextField} name="city" disabled={!isInEditMode} />
          <FormikErrorMessage name="city" />
        </Grid>
      </Grid>
      <Grid container={true} direction="row" flexWrap="nowrap" sx={{ marginTop: '20px' }}>
        <Grid item xs={5} sx={{ marginRight: '30px' }}>
          <Typography
            component="label"
            htmlFor="countryCode"
            variant="caption"
            color={theme.palette.dark.shade60}
            sx={{ marginBottom: '4px' }}
          >
            {formatMessage({ id: 'maas.customer.dl.issuingCountry' })}
          </Typography>
          <Field as={FormikSelectField} options={countryCodesMenuConfig} name="countryCode" disabled={!isInEditMode} size="medium" />
          <FormikErrorMessage name="countryCode" />
        </Grid>
      </Grid>
    </Grid>
  );
}
