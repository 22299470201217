import once from 'lodash/once';

import { themeOptions } from '../../init-setup/ThemeOptions';
import { EnumWithStringValue } from '../../utils/EnumUtils';
import { intl } from '../../utils/intl';

import { ListItemConfig } from './shared';

export enum GraphqlVehicleUsageStatus {
  Available = 'AVAILABLE',
  Paused = 'PAUSED',
  IssueDetected = 'ISSUE DETECTED',
  NotBookable = 'NOT BOOKABLE',
  Occupied = 'OCCUPIED',
}

export namespace GraphqlVehicleUsageStatusHelper {
  const graphqlVehicleUsageStatusToUnderivedMetadata: Map<GraphqlVehicleUsageStatus, GraphqlVehicleUsageStatusToUnderivedMetadata> =
    new Map([
      [
        GraphqlVehicleUsageStatus.Available,
        {
          displayText: intl.formatMessage({ id: 'map.vehicleInfo.status.available' }),
          color: themeOptions.palette.success.main,
        },
      ],
      [
        GraphqlVehicleUsageStatus.Paused,
        {
          displayText: intl.formatMessage({ id: 'map.vehicleInfo.status.paused' }),
          color: themeOptions.palette.warning.main,
        },
      ],
      [
        GraphqlVehicleUsageStatus.IssueDetected,
        {
          displayText: intl.formatMessage({ id: 'map.vehicleInfo.status.issueDetected' }),
          color: themeOptions.palette.alert.main,
        },
      ],
      [
        GraphqlVehicleUsageStatus.NotBookable,
        {
          displayText: intl.formatMessage({ id: 'map.vehicleInfo.status.notBookable' }),
          color: themeOptions.palette.alert.main,
        },
      ],
      [
        GraphqlVehicleUsageStatus.Occupied,
        {
          displayText: intl.formatMessage({ id: 'map.vehicleInfo.status.occupied' }),
          color: themeOptions.palette.purple.main,
        },
      ],
    ]);

  export function metadata(graphqlVehicleUsageStatus: GraphqlVehicleUsageStatus): GraphqlVehicleUsageStatusMetaData {
    const underivedMetadata = graphqlVehicleUsageStatusToUnderivedMetadata.get(graphqlVehicleUsageStatus);
    const derivedMetaData = { iconClassName: toString(graphqlVehicleUsageStatus).toLocaleLowerCase() };
    if (!underivedMetadata) {
      throw Error(`
          Specified GraphqlVehicleUsageStatus
          : ${toString(graphqlVehicleUsageStatus)} doesnt have corresponding underived/explicitly defined metadata.
          This usually (not always) means a bug or incomplete implementation.
      `);
    }

    return { ...underivedMetadata, ...derivedMetaData };
  }

  export function allTypes(): GraphqlVehicleUsageStatus[] {
    return EnumWithStringValue.enumToValues(GraphqlVehicleUsageStatus);
  }

  export function toString(status: GraphqlVehicleUsageStatus): string {
    return EnumWithStringValue.getEnumKeyByEnumValue(GraphqlVehicleUsageStatus, status)!;
  }

  export function getIconClassName(usageStatus: GraphqlVehicleUsageStatus): string {
    return toString(usageStatus).toLocaleLowerCase();
  }

  export function toUserFormattedString(usageStatus: GraphqlVehicleUsageStatus): string {
    if (allTypes().includes(usageStatus)) return toString(usageStatus);
    return intl.formatMessage({ id: 'common.dash' });
  }

  export const valuesWithLabel: ListItemConfig<GraphqlVehicleUsageStatus>[] = once(() =>
    allTypes().map((usageStatus) => {
      return {
        value: usageStatus,
        label: GraphqlVehicleUsageStatusHelper.metadata(usageStatus).displayText,
      };
    }),
  )();
}

type GraphqlVehicleUsageStatusMetaData = GraphqlVehicleUsageStatusToUnderivedMetadata & GraphqlVehicleUsageStatusToDerivedMetadata;
interface GraphqlVehicleUsageStatusToUnderivedMetadata {
  displayText: string;
  color: string;
}
interface GraphqlVehicleUsageStatusToDerivedMetadata {
  iconClassName: string;
}
