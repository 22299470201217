import { useEffect } from 'react';

import { Map, Polygon } from 'leaflet';

export const useLayerCutEvent = (map: Map, onLayerCut: (layer: Polygon, originalLayer: Polygon) => void) => {
  useEffect(() => {
    // @ts-ignore
    const handleLayerCutComplete = ({ layer, originalLayer }) => {
      onLayerCut(layer as Polygon, originalLayer as Polygon);
    };

    map.on('pm:cut', handleLayerCutComplete);

    return () => {
      map.off('pm:cut', handleLayerCutComplete);
    };
  }, [map, onLayerCut]);
};
