import { styled } from '@mui/material/styles';
import MuiSwitch from '@mui/material/Switch';

export const StyledSwitch = styled(MuiSwitch)(({ theme, size }) => ({
  padding: 0,

  '&.MuiSwitch-sizeSmall': {
    width: 28,
    height: 16,
    '& .MuiSwitch-thumb': {
      width: 12,
      height: 12,
    },
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
      },
    },
    '& .MuiSwitch-track': {
      boxSizing: 'border-box',
      borderRadius: 16 / 2,
    },
  },
  '&.MuiSwitch-sizeMedium': {
    width: 36,
    height: 20,
    '& .MuiSwitch-thumb': {
      width: 16,
      height: 16,
    },
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      '&.Mui-checked': {
        transform: 'translateX(16px)',
      },
    },
    '& .MuiSwitch-track': {
      borderRadius: 20 / 2,
    },
  },
  '&.MuiSwitch-sizeLarge ': {
    width: 44,
    height: 24,
    '& .MuiSwitch-thumb': {
      width: 20,
      height: 20,
    },
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      '&.Mui-checked': {
        transform: 'translateX(20px)',
      },
    },
    '& .MuiSwitch-track': {
      borderRadius: 24 / 2,
    },
  },

  '& .MuiSwitch-track': {
    backgroundColor: theme.palette.baseLight.base24,
    opacity: 1,
  },
  '& .MuiSwitch-switchBase': {
    transitionDuration: '300ms',
    '&.Mui-checked': {
      color: theme.palette.baseLight.base0,
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },

  '& .Mui-disabled': {
    '& .MuiSwitch-thumb': {
      color: theme.palette.baseLight.base60,
    },
  },
  '& .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track': {
    backgroundColor: theme.palette.baseLight.base24,
  },
}));
