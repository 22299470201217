import { useAtom } from 'jotai';

import {
  REQUEST_STATUS,
  bmLinkModalSearchStatusAtom,
  bmLinkModalSearchStringAtom,
  bmLinkModalVisibleAtom,
  bmLinkUserStatusAtom,
  bmModalSearchStringAtom,
  bmSelectedMembershipAtom,
  bmSelectedMembershipIdAtom,
  bmUnlinkModalVisibleAtom,
  bmUserToLinkAtom,
  discardFailedStateTransitionApiStatusAtom,
  retryStateTransitionApiStatusAtom,
} from '../budget-plan-atoms';

export function useBudgetPlanMembership() {
  const [bmModalSearchString, setBMModalSearchString] = useAtom(bmModalSearchStringAtom);
  const [bmSelectedMembershipId, setBMSelectedMembershipId] = useAtom(bmSelectedMembershipIdAtom);
  const [bmSelectedMembership, setBMSelectedMembership] = useAtom(bmSelectedMembershipAtom);
  const [bmLinkModalVisible, setBMLinkModalVisible] = useAtom(bmLinkModalVisibleAtom);
  const [bmLinkModalSearchString, setBMLinkModalSearchString] = useAtom(bmLinkModalSearchStringAtom);
  const [bmUserToLink, setBMUserToLink] = useAtom(bmUserToLinkAtom);
  const [bmLinkModalSearchStatus, setBMLinkModalSearchStatus] = useAtom(bmLinkModalSearchStatusAtom);
  const [bmLinkUserStatus, setBMLinkUserStatus] = useAtom(bmLinkUserStatusAtom);
  const [bmUnlinkModalVisible, setBMUnlinkModalVisible] = useAtom(bmUnlinkModalVisibleAtom);
  const [retryStateTransitionApiStatus, setRetryStateTransitionApiStatus] = useAtom(retryStateTransitionApiStatusAtom);
  const [discardFailedStateTransitionApiStatus, setDiscardFailedStateTransitionApiStatus] = useAtom(
    discardFailedStateTransitionApiStatusAtom,
  );

  const resetBMState = () => {
    setBMModalSearchString('');
    setBMSelectedMembershipId(null);
    setBMSelectedMembership(null);
    setBMLinkModalVisible(false);
    setBMLinkModalSearchString('');
    setBMUserToLink(null);
    setBMLinkModalSearchStatus(REQUEST_STATUS.IDLE);
    setBMLinkUserStatus(REQUEST_STATUS.IDLE);
    setBMUnlinkModalVisible(false);
    setRetryStateTransitionApiStatus(REQUEST_STATUS.IDLE);
    setDiscardFailedStateTransitionApiStatus(REQUEST_STATUS.IDLE);
  };

  return {
    bmModalSearchString,
    setBMModalSearchString,
    bmSelectedMembershipId,
    setBMSelectedMembershipId,
    bmSelectedMembership,
    setBMSelectedMembership,
    bmLinkModalVisible,
    setBMLinkModalVisible,
    bmLinkModalSearchString,
    setBMLinkModalSearchString,
    bmUserToLink,
    setBMUserToLink,
    bmLinkModalSearchStatus,
    setBMLinkModalSearchStatus,
    bmLinkUserStatus,
    setBMLinkUserStatus,
    bmUnlinkModalVisible,
    setBMUnlinkModalVisible,
    retryStateTransitionApiStatus,
    setRetryStateTransitionApiStatus,
    discardFailedStateTransitionApiStatus,
    setDiscardFailedStateTransitionApiStatus,
    resetBMState,
  };
}
