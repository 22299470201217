import { type Row } from '@tanstack/react-table';

import { LoadingAndErrorWithRetryAndNoResults } from '../../../components/LoadingAndErrorWithRetryAndNoResults';
import NewDataTable from '../../../design-system/NewDataTable';
import { BudgetPlanInformationTab } from '../../../models';
import { BudgetPlanDto } from '../../../services/budget-plan';

import { BP_ACTION_MODE, REQUEST_STATUS } from './atoms/budget-plan-atoms';
import { useBudgetPlan, useBudgetPlanMembership } from './atoms/hooks';
import { useMakeBudgetPlanDetailsTableColumns } from './hooks/useMakeBudgetPlanDetailsTableColumns';

interface BudgetPlansTableProps {
  fetchBudgetPlans: () => Promise<void>;
}

export const BudgetPlansTable = ({ fetchBudgetPlans }: BudgetPlansTableProps) => {
  const { setBudgetPlanModalVisible, setSelectedPlan, setBudgetPlanActionMode, setBudgetPlanModalActiveTab, budgetPlansFetchStatus } =
    useBudgetPlan();
  const { budgetPlans } = useBudgetPlan();
  const { setBMLinkModalVisible } = useBudgetPlanMembership();

  const budgetPlanDetailsTableColumns = useMakeBudgetPlanDetailsTableColumns(handleStatusChangeCellClick, handleAddUserButtonClick);

  function handleStatusChangeCellClick(budgetPlan: BudgetPlanDto) {
    // TODO: Implement if needed based on the new backend implementation
    return null;
  }

  function handleAddUserButtonClick(budgetPlan: BudgetPlanDto) {
    setBudgetPlanModalVisible(true);
    setSelectedPlan(budgetPlan);
    setBudgetPlanModalActiveTab(BudgetPlanInformationTab.Members);
    setBMLinkModalVisible(true);
  }

  const handleBudgetPlanRowClick = (row: Row<BudgetPlanDto>) => {
    const budgetPlan = row.original;
    setBudgetPlanModalVisible(true);
    setSelectedPlan(budgetPlan);
    setBudgetPlanModalActiveTab(BudgetPlanInformationTab.PlanDetails);
    setBudgetPlanActionMode(BP_ACTION_MODE.VIEW);
  };

  const showLoadingOrNoContentOrErrorMessage =
    !budgetPlans?.length || budgetPlansFetchStatus === REQUEST_STATUS.PENDING || budgetPlansFetchStatus === REQUEST_STATUS.FAILURE;
  return showLoadingOrNoContentOrErrorMessage ? (
    <LoadingAndErrorWithRetryAndNoResults
      error={budgetPlansFetchStatus === REQUEST_STATUS.FAILURE}
      loading={budgetPlansFetchStatus === REQUEST_STATUS.PENDING}
      onRetry={fetchBudgetPlans}
      baseTranslationKey="budgetPlan"
      noResults={!budgetPlans?.length}
      style={{ height: 'calc(100vh - 172px)' }}
    />
  ) : (
    <NewDataTable<BudgetPlanDto, 'default'>
      data={budgetPlans}
      columns={budgetPlanDetailsTableColumns}
      onRowClickHandler={handleBudgetPlanRowClick}
    />
  );
};
