import { useState } from 'react';

import { LawEnforcementTripSummaryResponse } from '../../../models';
import { fetchTripSummariesForLawEnforcement } from '../../../utils/queries';
import { StartAndEndDateTimeRangeValues } from '../../../wmv-components';

import { LawEnforcementTripSearchForm } from './LawEnforcementTripSearchForm';
import { LawEnforcementTripSearchResult, LawEnforcementTripSearchResultsTable } from './LawEnforcementTripSearchResultsTable';

const formatTripSummaryForTable = (summaryResponses: LawEnforcementTripSummaryResponse[]): LawEnforcementTripSearchResult[] => {
  return summaryResponses.map(
    (summaryResponse: LawEnforcementTripSummaryResponse): LawEnforcementTripSearchResult => ({
      startDateFormatted: summaryResponse.startedAt.parseEpochMillis().formatAsDateString(),
      startTimeFormatted: summaryResponse.startedAt.parseEpochMillis().formatAsTimeWithoutSeconds(),
      endDateFormatted: summaryResponse.lastUpdatedAt.parseEpochMillis().formatAsDateString(),
      endTimeFormatted: summaryResponse.lastUpdatedAt.parseEpochMillis().formatAsTimeWithoutSeconds(),
      vehicleType: summaryResponse.vehicleType,
      qrCodeId: summaryResponse.qrCode,
      firstName: summaryResponse.user.firstName,
      lastName: summaryResponse.user.lastName,
      streetName: summaryResponse.user.address?.street!,
      houseNumber: summaryResponse.user.address?.houseNumber!,
    }),
  );
};

export const LawEnforcementTripSearchView = () => {
  const [searchResults, setSearchResults] = useState<LawEnforcementTripSearchResult[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [noSearchResults, setNoSearchResults] = useState(false);
  const [lastSearchFormValues, setLastSearchFormValues] = useState<StartAndEndDateTimeRangeValues | null>(null);

  const onSearch = async (formValues: StartAndEndDateTimeRangeValues) => {
    setLoading(true);
    setError(false);
    setNoSearchResults(false);
    setSearchResults([]);
    setLastSearchFormValues(formValues);
    const searchParameters = {
      fromInclusiveEpochMillis: formValues.startDateTime!.valueOf(),
      toInclusiveEpochMillis: formValues.endDateTime!.valueOf(),
    };
    await fetchTripSummariesForLawEnforcement(searchParameters.fromInclusiveEpochMillis, searchParameters.toInclusiveEpochMillis)
      .then((res: LawEnforcementTripSummaryResponse[]) => {
        if (res && res.length > 0) {
          const formattedResult = formatTripSummaryForTable(res);
          setSearchResults(formattedResult);
        }
        if (res.length === 0) {
          setNoSearchResults(true);
        }
      })
      .catch((error) => {
        console.log('API call failed with error:', error);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <LawEnforcementTripSearchForm onSearch={onSearch} loading={loading} />
      <LawEnforcementTripSearchResultsTable
        searchResults={searchResults}
        loading={loading}
        error={error}
        onRetry={onSearch}
        lastSearchFormValues={lastSearchFormValues}
        noSearchResults={noSearchResults}
      />
    </>
  );
};
