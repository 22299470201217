import { memo } from 'react';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useIntl } from 'react-intl';

import { ReactComponent as CheckMark } from '../../../assets/icons/outline/checkmark.svg';
import { FloatingBox } from '../../../wmv-components';

import { InfoTooltip } from './InfoTooltip';
import {
  floatingBoxStyles,
  drawActionButtonContainerStyles,
  continueButtonStyles,
  cancelButtonStyles,
} from './ZoneAreaManipulationBottomActionButtons.styles';

interface ZoneAreaManipulationBottomActionButtonProps {
  onContinueClick: () => void;
  onCancelClick: () => void;
  cancelButtonEnabled: boolean;
  continueButtonEnabled: boolean;
}

export const ZoneAreaManipulationBottomActionButtons = memo(
  ({ onContinueClick, onCancelClick, cancelButtonEnabled, continueButtonEnabled }: ZoneAreaManipulationBottomActionButtonProps) => {
    const theme = useTheme();
    const { formatMessage } = useIntl();

    const {
      palette: { baseLight },
    } = theme;

    return (
      <FloatingBox boxStyling={floatingBoxStyles(theme)}>
        <Stack
          divider={<Divider sx={{ background: baseLight.base8 }} orientation="vertical" flexItem />}
          sx={drawActionButtonContainerStyles}
          direction="row"
          spacing={1}
        >
          <InfoTooltip placement="bottom-start" title={formatMessage({ id: 'zone.actions.cancel' })}>
            <Button
              sx={cancelButtonStyles(theme, !cancelButtonEnabled)}
              variant="text"
              disabled={!cancelButtonEnabled}
              onClick={onCancelClick}
            >
              <Typography variant="bodyLarge">{formatMessage({ id: 'actions.cancel' })}</Typography>
            </Button>
          </InfoTooltip>

          <InfoTooltip placement="bottom-start" title={formatMessage({ id: 'zone.actions.continue' })}>
            <Button
              sx={continueButtonStyles(theme, !continueButtonEnabled)}
              variant="text"
              disabled={!continueButtonEnabled}
              onClick={onContinueClick}
            >
              <CheckMark />

              <Typography variant="bodyLarge" ml={0.5}>
                {formatMessage({ id: 'actions.continue' })}
              </Typography>
            </Button>
          </InfoTooltip>
        </Stack>
      </FloatingBox>
    );
  },
);

ZoneAreaManipulationBottomActionButtons.displayName = 'ZoneAreaManipulationBottomActionButtons';
