import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';

import isEmpty from 'lodash/isEmpty';

import { LoadingAndErrorWithRetryAndNoResults } from '../../../../../components/LoadingAndErrorWithRetryAndNoResults';
import { BudgetPlanMembershipDto } from '../../../../../services/budget-plan-memberships';
import { fetchBudgetPlanMembershipSummaries } from '../../../../../utils/queries';
import { useBudgetPlan } from '../../atoms/hooks/useBudgetPlan';
import { useBudgetPlanMembership } from '../../atoms/hooks/useBudgetPlanMembership';

import { BudgetPlanMembershipTable } from './BudgetPlanMembershipTable';

export interface BudgetMembershipsListState {
  hasError: boolean;
  loading: boolean;
}

export function MembershipsTableView() {
  const [memberships, setMemberships] = useState<BudgetPlanMembershipDto[]>([]);
  const [budgetMembershipsListState, setBudgetMembershipsListState] = useState<BudgetMembershipsListState>({
    hasError: false,
    loading: false,
  });
  const { selectedPlan } = useBudgetPlan();
  const { bmSelectedMembershipId, bmUnlinkModalVisible, bmLinkModalVisible } = useBudgetPlanMembership();

  const fetchBudgetPlanMembershipsData = useCallback(async (budgetPlanId: number) => {
    setBudgetMembershipsListState({ hasError: false, loading: true });
    try {
      const budgetMemberships = (await fetchBudgetPlanMembershipSummaries(budgetPlanId)) || [];
      const processedBudgetMemberships = budgetMemberships.map((budgetMembership) => new BudgetPlanMembershipDto(budgetMembership));
      setMemberships(processedBudgetMemberships);
      setBudgetMembershipsListState({ hasError: false, loading: false });
    } catch (error) {
      console.error('Failed to fetch budget memberships:', error);
      setBudgetMembershipsListState({ hasError: true, loading: false });
    }
  }, []);

  // Fetch data when the modal dialogs are closed
  useEffect(() => {
    if (selectedPlan && bmSelectedMembershipId === null && bmLinkModalVisible === false && bmUnlinkModalVisible === false) {
      fetchBudgetPlanMembershipsData(selectedPlan.id);
    }
  }, [fetchBudgetPlanMembershipsData, selectedPlan, bmSelectedMembershipId, bmUnlinkModalVisible, bmLinkModalVisible]);

  useLayoutEffect(() => {
    if (selectedPlan) {
      fetchBudgetPlanMembershipsData(selectedPlan.id);
    }
  }, [fetchBudgetPlanMembershipsData, selectedPlan]);

  const activeOrInTransitionMembers = useMemo(() => memberships!.filter((member) => !member.isInactive()), [memberships]);

  if (activeOrInTransitionMembers.length) {
    return <BudgetPlanMembershipTable budgetPlanMemberships={activeOrInTransitionMembers} />;
  }

  return (
    <LoadingAndErrorWithRetryAndNoResults
      error={budgetMembershipsListState.hasError}
      loading={budgetMembershipsListState.loading}
      noResults={isEmpty(activeOrInTransitionMembers)}
      onRetry={async () => {
        await fetchBudgetPlanMembershipsData(selectedPlan!.id);
      }}
      baseTranslationKey={'budgetPlanMemberships'}
      style={{ backgroundColor: 'transparent' }}
    />
  );
}
