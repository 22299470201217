import { object, number } from 'yup';

import { intl } from '../../../../utils/intl';
import { vehiclePricingFormFieldName } from '../helpers';

export const vehiclePricingFormValidationSchema = () => {
  const { formatMessage } = intl;

  return object().shape({
    [vehiclePricingFormFieldName.newActivationFee]: number()
      .required(formatMessage({ id: 'common.errorMessage.required' }))
      .positive(formatMessage({ id: 'form.validation.shouldBePositiveInteger' }, { label: formatMessage({ id: 'common.activationFee' }) })),
    [vehiclePricingFormFieldName.newActiveMinutesFee]: number()
      .required(formatMessage({ id: 'common.errorMessage.required' }))
      .positive(formatMessage({ id: 'form.validation.shouldBePositiveInteger' }, { label: formatMessage({ id: 'common.activeMinutes' }) })),
    [vehiclePricingFormFieldName.newPausedMinutesFee]: number()
      .required(formatMessage({ id: 'common.errorMessage.required' }))
      .positive(formatMessage({ id: 'form.validation.shouldBePositiveInteger' }, { label: formatMessage({ id: 'common.pausedMinutes' }) })),
  });
};
