import type { MouseEventHandler, ReactNode, ForwardedRef } from 'react';
import { forwardRef, Fragment } from 'react';

import type { ButtonProps } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { LoadingAnimation } from './index';

export const ProgressAwareButton = forwardRef(function ProgressAwareButton(
  {
    loading,
    startIcon = null,
    children,
    variant = 'contained',
    type = 'submit',
    color = 'primary',
    loadingButtonProps = {},
    size = 'medium',
    onClick,
    disabled = false,
  }: ProgressAwareButtonProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  let sizeMultiplier = 0.4;
  if (size === 'small') {
    sizeMultiplier = 0.25;
  }
  return (
    <Button
      sx={{ textTransform: 'unset' }}
      disabled={disabled || loading}
      variant={variant}
      size={size}
      type={type}
      color={color}
      onClick={onClick}
      ref={ref}
      {...loadingButtonProps}
    >
      {loading ? (
        <LoadingAnimation sizeMultiplier={sizeMultiplier} />
      ) : (
        <Fragment>
          {startIcon}
          <Box ml={0.5}>{children}</Box>
        </Fragment>
      )}
    </Button>
  );
});

export interface ProgressAwareButtonProps {
  loading: boolean;
  children: ReactNode;
  type?: ButtonProps['type'];
  variant?: ButtonProps['variant'];
  startIcon?: ReactNode;
  color?: ButtonProps['color'];
  loadingButtonProps?: ButtonProps;
  size?: ButtonProps['size'];
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}
