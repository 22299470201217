import { ApolloClient, InMemoryCache, HttpLink, fromPromise, ApolloLink, ApolloError, isApolloError } from '@apollo/client';
import type { DocumentNode, NextLink, Operation, FetchResult } from '@apollo/client';
import type { DefaultContext } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import type { ErrorResponse } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { Observable, getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';

import { showAlertAction } from '../state/app';
import store from '../state/store';

import UserService from './UserService';

const params = {
  url: process.env.REACT_APP_GRAPHQL_WS_LINK || '',
  connectionParams: () => ({
    Authorization: `Bearer ${UserService.getToken()}`,
  }),
  retryAttempts: Infinity,
  shouldRetry: () => true,
};

function isSubscriptionOperation(query: DocumentNode) {
  const definition = getMainDefinition(query);
  return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
}

function retryUnauthorizedRequestWithUpdatedToken(forward: NextLink, operation: Operation): Observable<FetchResult> {
  return fromPromise(
    UserService.refreshToken(5).catch((error) => {
      store.dispatch(showAlertAction({ message: 'We are going to refreshing your token you will redirected to home page.', type: 'info' }));
      // Handle token refresh errors e.g clear stored tokens, redirect to login
      console.log('Access token refresh error: ', error);
      client.clearStore();
      return UserService.doLogout();
    }),
  )
    .filter((value) => Boolean(value))
    .flatMap((refreshed) => {
      const newToken = UserService.getToken();
      const oldHeaders = operation.getContext().headers;
      // apply the new token in operation context.
      operation.setContext({
        headers: {
          ...oldHeaders,
          authorization: `Bearer ${newToken}`,
        },
      });
      // clear cache.
      client.clearStore();
      // check if the error was caused to a subscription call then we terminate the ws and create new one and retry.
      if (isSubscriptionOperation(operation.query)) {
        console.log('Websocket is being terminated, a retry will happen.');
        wsLink.client.terminate();
      }
      // retry the request.
      return forward(operation);
    });
}

const wsLink = new GraphQLWsLink(createClient(params));
const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_HTTPS_LINK,
  credentials: 'include',
});
const authLink = new ApolloLink((operation, forward) => {
  operation.setContext((context: DefaultContext) => ({
    ...context,
    headers: {
      ...(context.headers || {}),
      authorization: UserService.getToken() ? `Bearer ${UserService.getToken()}` : '',
    },
  }));

  return forward(operation);
});
const splitLink = ApolloLink.split(({ query }) => isSubscriptionOperation(query), wsLink, httpLink);

// https://www.apollographql.com/docs/react/data/error-handling#advanced-error-handling-with-apollo-link
// https://master--apollo-client-docs.netlify.app/docs/react/api/link/apollo-link-error/
const errorLink = onError((errorParams: ErrorResponse) => {
  const { networkError, forward, operation } = errorParams;

  if (isApolloError(networkError as Error)) {
    for (let err of (networkError as ApolloError).graphQLErrors) {
      switch (err.extensions.classification) {
        case 'UNAUTHORIZED':
          console.log('Access UNAUTHORIZED');
          if (UserService.isLoggedIn()) {
            console.log('User token in still valid! Invalidating it to get a fresh one.');
            UserService.invalidateToken();
          }
          return retryUnauthorizedRequestWithUpdatedToken(forward, operation);
        case 'FORBIDDEN':
          console.log('Access FORBIDDEN');
          break;
        case 'ValidationError':
          console.log('Validation Error');
          break;
        default:
          console.log(`[Network error]: ${networkError}`);
      }
    }
  }
});

export const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, splitLink]),
  cache: new InMemoryCache(),
});
