import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import type { Theme } from '@mui/material/styles';

interface DotIndicatorProps {
  color: string;
}

export function DotIndicator({ color }: DotIndicatorProps) {
  const theme = useTheme();

  return <Box sx={dotIndicatorStyles(theme, color)} />;
}

export const dotIndicatorStyles = (theme: Theme, color: string) => ({
  height: '14px',
  width: '14px',
  borderRadius: '50%',
  backgroundColor: color,
});
