import { useRef, useState } from 'react';

import { Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import type { FormikProps } from 'formik';

import { themeOptions as theme } from '../../../init-setup/ThemeOptions';
import { CustomerAddress, CustomerDetails, DrivingLicense } from '../../../models';
import { localDateTime } from '../../../utils/DateMethods';
import { getCustomerDetails, putCustomerDetails } from '../../../utils/queries';
import { LoadingAndErrorWithRetryAndNoResults } from '../../LoadingAndErrorWithRetryAndNoResults';
import { SidebarCustomerInfo } from '../index';
import { customerPersonalInfoFormValidationSchema } from '../validation-schemas/CustomerPersonalInfoFormValidationSchema';

import { BottomActionButtons } from './BottomActionButtons';
import { ContactInfoSectionInputs } from './ContactInfoSectionInputs';
import { PersonalInfoSectionInputs } from './PersonalInfoSectionInputs';

export interface CustomerDetailsProps {
  subjectClaim: string;
  updateSidebarCustomerInfo: (sidebarCustomerInfo: SidebarCustomerInfo) => void;
}

function CustomerPersonalDetails({ subjectClaim, updateSidebarCustomerInfo }: CustomerDetailsProps) {
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [personalInfoFormState, setPersonalInfoFormState] = useState<CustomerInfoFormFields>(initialPersonalInfoFormValues);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [originalValues, setOriginalValues] = useState<CustomerDetails | null>(null);

  const prevSubjectClaim = useRef<string | null>(null);
  if (subjectClaim && prevSubjectClaim.current !== subjectClaim) {
    prevSubjectClaim.current = subjectClaim;
    fetchCustomerDetails(subjectClaim);
  }

  const formikRef = useRef<FormikProps<CustomerInfoFormFields>>(null);
  function handleCancelEdit() {
    formikRef.current?.resetForm();
    setIsInEditMode(false);
  }

  if (loading || error) {
    return (
      <LoadingAndErrorWithRetryAndNoResults
        style={{ height: '100%', backgroundColor: theme.palette.white.main }}
        error={error}
        loading={loading}
        onRetry={() => {
          !isInEditMode && fetchCustomerDetails(subjectClaim);
        }}
        baseTranslationKey={'customerDetails'}
      />
    );
  }

  return (
    <Formik
      initialValues={personalInfoFormState}
      validationSchema={customerPersonalInfoFormValidationSchema}
      innerRef={formikRef}
      onSubmit={updateCustomerPersonalInfo}
      initialTouched={initTouchedFields}
      enableReinitialize
      validateOnMount
      validateOnChange
      validateOnBlur
    >
      <Form>
        <Grid container={true} direction="row">
          <PersonalInfoSectionInputs
            isInEditMode={isInEditMode}
            onEditClick={() => {
              setIsInEditMode(true);
            }}
          />
          <ContactInfoSectionInputs isInEditMode={isInEditMode} />
          <BottomActionButtons isInEditMode={isInEditMode} onCancelEdit={handleCancelEdit} onUpdateSubmit={updateCustomerPersonalInfo} />
        </Grid>
      </Form>
    </Formik>
  );

  async function fetchCustomerDetails(subjectClaim: string) {
    setLoading(true);
    setError(false);
    try {
      const userData = await getCustomerDetails(subjectClaim);
      updateCustomerDetailsState(userData);
      setOriginalValues(userData);
      setLoading(false);
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  }

  function updateCustomerDetailsState(customerDetails: CustomerDetails) {
    const { firstName, lastName, email, phoneNumber, birthDate, address, drivingLicense } = customerDetails;

    const { street = '', houseNumber = '', extension = '', postalCode = '', city = '', countryCode = '' } = address || {};
    const phoneNumberParts = phoneNumber.split(/\s+/);
    const phoneNumberCountryCode = phoneNumberParts[0];
    // Older phone numbers might be having spaces in them such as +43 123 456 789
    // To show those numbers properly, we are merging all space delimited segments after country code i.e 1st space
    const phoneNumberWithoutCountryCode = phoneNumberParts
      .slice(1)
      .map((phoneNumberPart) => phoneNumberPart)
      .join('');

    const customerInfoForm: CustomerInfoFormFields = {
      firstName,
      lastName,
      email,
      drivingLicense,
      phoneNumberCountryCode,
      phoneNumberWithoutCountryCode,
      birthDate,
      street,
      houseNumber,
      extension,
      postalCode,
      city,
      countryCode,
    };
    setPersonalInfoFormState(customerInfoForm);
    setOriginalValues(customerDetails);
  }

  function updateCustomerInfoReqPayload(customerInfoForm: CustomerInfoFormFields) {
    const {
      phoneNumberWithoutCountryCode,
      phoneNumberCountryCode,
      street,
      houseNumber,
      extension,
      postalCode,
      city,
      countryCode,
      firstName,
      lastName,
      birthDate,
      drivingLicense,
    } = customerInfoForm;
    const fullPhoneNumber = `${phoneNumberCountryCode} ${phoneNumberWithoutCountryCode}`;
    const address = { street, houseNumber, extension, postalCode, city, countryCode };
    return {
      firstName,
      lastName,
      birthDate,
      phoneNumber: fullPhoneNumber,
      address,
      drivingLicense,
    };
  }

  async function updateCustomerPersonalInfo(customerInfoForm: CustomerInfoFormFields) {
    setLoading(true);
    setError(false);

    const payload = updateCustomerInfoReqPayload(customerInfoForm);
    try {
      const customerData = await putCustomerDetails(payload, subjectClaim);
      const updatedCustomerData = {
        ...originalValues!,
        address: customerData?.address!,
        firstName: customerData?.firstName!,
        lastName: customerData?.lastName!,
        phoneNumber: customerData?.phoneNumber!,
        birthDate: customerData?.birthDate!,
        drivingLicense: customerData?.drivingLicense!,
        email: customerData?.email!,
      };
      updateCustomerDetailsState(updatedCustomerData);
      updateSidebarCustomerInfo({
        firstName: customerData?.firstName!,
        lastName: customerData?.lastName!,
        subjectClaim: originalValues?.subjectClaim,
      });

      setIsInEditMode(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(`Error updating customer details with ID ${subjectClaim}`, error);
    }
  }
}

export interface CustomerInfoFormFields extends CustomerAddress {
  firstName: string;
  lastName: string;
  birthDate: string;
  email: string;
  phoneNumberCountryCode: string;
  phoneNumberWithoutCountryCode: string;
  drivingLicense: DrivingLicense | null;
}

const initialPersonalInfoFormValues: CustomerInfoFormFields = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumberCountryCode: '+49',
  phoneNumberWithoutCountryCode: '',
  birthDate: localDateTime(5).toISOString().slice(0, -8),
  street: '',
  houseNumber: '',
  postalCode: '',
  city: '',
  extension: '',
  countryCode: '',
  drivingLicense: null,
};

const initTouchedFields = {
  firstName: true,
  lastName: true,
  email: true,
  phoneNumberCountryCode: true,
  phoneNumberWithoutCountryCode: true,
  birthDate: true,
  street: true,
  houseNumber: true,
  postalCode: true,
  city: true,
  extension: true,
  countryCode: true,
};

export default CustomerPersonalDetails;
