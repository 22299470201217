import type { MouseEvent } from 'react';
import React, { memo, useState } from 'react';

import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import { useIntl } from 'react-intl';

import { ModeType } from '../../../models';
import { Mode } from '../../../services/zone';
import { TranslatedText } from '../../../wmv-components';

import { menuItemStyles, modeTextStyles } from './ModeSelectionPopover.styles';
import { ModeSelectionPopOverOption } from './PlanningModePreviewPredicatesView.helpers';
import { PredicatePopoverAnchorButton } from './PredicatesPopoverAnchorButton';

interface ModeSelectionPopoverProps {
  options: ModeSelectionPopOverOption[];
  onModeChange: (mode: Mode) => void;
  mode: ModeType;
}

const ModeSelectionPopover = ({ options, onModeChange, mode }: ModeSelectionPopoverProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { formatMessage } = useIntl();

  const handleModeButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => setAnchorEl(null);

  const handleModeSelectionChange = (mode: ModeType) => {
    onModeChange(new Mode(mode));
    handleMenuClose();
  };

  const modeLabelIntlId = options.find((option) => option.value === mode)?.labelTranslationKey as string;

  const open = anchorEl !== null;
  return (
    <Box>
      <PredicatePopoverAnchorButton
        label={formatMessage({ id: modeLabelIntlId })}
        labelFontVariant="bodySmallBold"
        onClick={handleModeButtonClick}
        endIcon={!open ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
      />
      <Menu
        variant="menu"
        open={open}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        {options.map((option) => {
          return (
            <Tooltip
              arrow
              key={option.value}
              placement="right"
              title={
                <React.Fragment>
                  <TranslatedText variant="bodyMedium" translationKey={option.descriptionTranslationKey} />
                </React.Fragment>
              }
            >
              <MenuItem sx={menuItemStyles} onClick={() => handleModeSelectionChange(option.value)}>
                <TranslatedText variant="bodyMediumBold" translationKey={option.labelTranslationKey} />
                <TranslatedText variant="bodyMedium" translationKey={option.granularityTranslationKey} sx={modeTextStyles} />
              </MenuItem>
            </Tooltip>
          );
        })}
      </Menu>
    </Box>
  );
};
export default memo(ModeSelectionPopover);
