import { useMemo } from 'react';

import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Button, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import { useIntl } from 'react-intl';

import { MspTypeHelper, VehicleTypeHelper, ZoneTypeHelper } from '../../models';
import { GraphqlVehicleUsageStatusHelper } from '../../models';
import { TABLE_FOOTER_HEIGHT } from '../../utils/constants';
import CustomIcon, { availableIcons } from '../../utils/CustomIcon';

import { MapFilters } from './ZoneControls/MapFilters';

const BottomBarContainer = styled('div')(({ theme }) => ({
  padding: '0px 24px',
  backgroundColor: theme.palette.white.main,
  width: '100%',
  display: 'flex',
  zIndex: 999,
  alignItems: 'center',
  height: `${TABLE_FOOTER_HEIGHT}`,

  '& .map-info__title': {
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    marginRight: '16px',
  },

  '& .map-info__options': {
    height: '24px',
    padding: '0px',
    marginRight: '24px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  '& .map-info__colors': {
    width: '20px',
    height: '20px',
    borderRadius: '50px',
    marginRight: '8px',
    display: 'flex',
    flexDirection: 'row',

    '&.available': {
      backgroundColor: theme.palette.success.main,
    },

    '&.pause': {
      backgroundColor: theme.palette.warning.main,
    },

    '&.issue': {
      backgroundColor: theme.palette.error.main,
    },
  },

  '& .map-info__text': {
    lineHeight: '24px',
    fontWeight: 400,
    fontSize: '16px',
  },

  '& .map-info__filters': {
    display: 'flex',
    flexDirection: 'row',
    padding: '0px',
    position: 'absolute',
    right: '24px',
    alignItems: 'center',
    gap: theme.spacing(1),
  },

  '& .map-info__icons': {
    backgroundColor: theme.palette.dark.shade4,
    width: '32px',
    height: '32px',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '&.firstIcon': {
      marginLeft: '8px',
    },
  },

  '& .map-info__resetButton': {
    color: theme.palette.alert.main,
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    padding: '0px 8px',
    border: '2px solid #fdf3f2',
    borderRadius: '6px',
    height: '32px',
    width: 'fix-content',
    minWidth: '124px',
    position: 'relative',
    marginLeft: theme.spacing(3),
    alignItems: 'center',
  },

  '& .map-info__filterIcon': {
    width: '20px',
    height: '20px',
    position: 'absolute',
    top: '6px',
    left: '94px',
  },

  '& .map-info__filterText': {
    padding: '6px 6.5px 6px 12px',
    lineHeight: '20px',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
  },

  '& .map-info__loading': {
    position: 'absolute',
    right: '16px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px',
    gap: '8px',
  },
}));

interface FilterIconProps {
  type: keyof typeof availableIcons;
  active: boolean;
  size?: number;
}

const FilterIcon: React.FC<FilterIconProps> = ({ type, active, size = 24 }) => {
  const theme = useTheme();
  const color = active ? theme.palette.dark.shade76 : theme.palette.dark.shade32;

  return (
    <Box component="span" className={`map-info__icons`}>
      {CustomIcon(type, color, size)}
    </Box>
  );
};

interface VehicleStateLegendAndResetFilterBottomBarFilterConfig {
  type: keyof typeof availableIcons;
  active: boolean;
}

const VehicleStateLegendAndResetFilterBottomBar = ({
  filters,
  isAnyFilterApplied,
  onFiltersReset,
  loadingVehicles,
  loadingVehiclesError,
}: VehicleStateLegendAndResetFilterBottomBarProps) => {
  const { formatMessage } = useIntl();

  const filterConfigs: VehicleStateLegendAndResetFilterBottomBarFilterConfig[] = useMemo(
    () => [
      { type: 'PublicTransportIcon', active: filters.vehicleTypes.length !== VehicleTypeHelper.allTypes().length },
      {
        type: 'VehicleStatusIcon',
        active: filters.vehicleStatuses.length !== GraphqlVehicleUsageStatusHelper.allTypes().length,
      },
      { type: 'ProvidersIcon', active: filters.mspTypes.length !== MspTypeHelper.allTypes().length },
      { type: 'ZonesIcon', active: filters.zoneTypes.length !== ZoneTypeHelper.allTypes().length },
      { type: 'InfrastructuresIcon', active: !!filters.infrastructureTypes.length },
    ],
    [filters],
  );

  return (
    <BottomBarContainer>
      <Typography sx={{ marginRight: '16px' }} variant="bodyMediumBold">
        {formatMessage({ id: 'map.mapInfo.vehicleStatus' })}
      </Typography>
      <Box className="map-info__options">
        <Box className="map-info__colors available"></Box>
        <Typography variant="bodyMedium">{formatMessage({ id: 'map.mapInfo.available' })}</Typography>
      </Box>
      <Box className="map-info__options">
        <Box className="map-info__colors pause"></Box>
        <Typography variant="bodyMedium">{formatMessage({ id: 'map.mapInfo.paused' })}</Typography>
      </Box>
      <Box className="map-info__options">
        <Box className="map-info__colors issue"></Box>
        <Typography variant="bodyMedium">{formatMessage({ id: 'map.mapInfo.issue' })}</Typography>
      </Box>

      {loadingVehicles || loadingVehiclesError ? (
        <Box className="map-info__loading">
          {loadingVehicles ? (
            <>
              <CircularProgress size={24} sx={{ color: '#2F4858' }} />
              <Typography sx={{ color: 'rgba(47, 46, 65, 0.24)' }} variant="bodySmall">
                {formatMessage({ id: 'loadingSpinner.loadingText' })}
              </Typography>
            </>
          ) : loadingVehiclesError ? (
            <>
              <Typography sx={{ color: 'rgba(47, 46, 65, 0.24)' }} variant="bodySmall">
                {formatMessage({ id: 'loadingSpinner.errorText' })}
              </Typography>
            </>
          ) : (
            ' '
          )}
        </Box>
      ) : (
        isAnyFilterApplied && (
          <Box className="map-info__filters">
            <Typography variant="bodyMedium">{formatMessage({ id: 'map.mapInfo.filters' })}</Typography>
            {filterConfigs.map((config, index) => (
              <FilterIcon key={index} type={config.type} active={config.active} />
            ))}
            <Button className="map-info__resetButton" onClick={onFiltersReset} size="medium">
              <Typography variant="bodySmallBold" sx={{ whiteSpace: 'nowrap' }}>
                {formatMessage({ id: 'map.mapInfo.filterText' })}
              </Typography>
              <RefreshIcon sx={{ height: '24px', width: '24px' }} />
            </Button>
          </Box>
        )
      )}
    </BottomBarContainer>
  );
};

interface VehicleStateLegendAndResetFilterBottomBarProps {
  filters: MapFilters;
  isAnyFilterApplied: boolean;
  onFiltersReset: () => void;
  loadingVehicles: boolean;
  loadingVehiclesError: string | boolean;
}

export default VehicleStateLegendAndResetFilterBottomBar;
