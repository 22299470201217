import { useCallback } from 'react';

import { Map } from 'leaflet';

import { Layer } from '../../../../services/layer-management';

export const useDisableActiveLayerManagementTool = (map: Map, workingLayer: Layer | null) => {
  return useCallback(() => {
    if (map.pm.globalDrawModeEnabled()) map.pm.disableDraw();
    if (map.pm.globalCutModeEnabled()) map.pm.disableGlobalCutMode();
    if (workingLayer?.editEnabled()) workingLayer!.disableEdit();
    if (workingLayer?.dragEnabled()) workingLayer!.disableDrag();
  }, [map.pm, workingLayer]);
};
