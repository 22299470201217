import { useCallback, useState } from 'react';

import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { LoadingAndErrorWithRetryAndNoResults } from '../../../components/LoadingAndErrorWithRetryAndNoResults';
import MapFiltersAndZoneTypeInfoControls from '../../../components/Map/ZoneControls';
import { MapTableView } from '../../../components/MapTableView';
import NewDataTable from '../../../design-system/NewDataTable';
import {
  useApplicableVehiclesForFilters,
  useEvaluateZonesVisibilityWithTheTime,
  useFilterZonesByPredicates,
  useMapFilterChange,
} from '../../../hooks';
import { AggregatedVehicle, FloatingButtonType } from '../../../models';
import { formattedAllVehiclesSelector, loadingVehiclesErrorSelector, loadingVehiclesSelector } from '../../../state';
import { HEADER_HEIGHT, HEADER_TABLE_GAP_HEIGHT, TABLE_HEIGHT_NO_SECONDARY } from '../../../utils/constants';
import { FloatingBox, NonClickableBreadCrumb, WmvButtonGroup } from '../../../wmv-components';

import { FleetManagerVehiclesListColumnDefs } from './FleetManagerVehiclesListCoumnDefs';
import { switchButtonConfig } from './helpers';
import useFetchMspVehicles from './hooks/useFetchMspVehicles';
import useFetchPublishedZones from './hooks/useFetchPublishedZones';

const Operator = () => {
  const location = useLocation();
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const queryParams = new URLSearchParams(location.search);
  const listViewQueryParam = queryParams.get('openListView');
  const initialMapViewType = listViewQueryParam === 'true' ? FloatingButtonType.ListMode : FloatingButtonType.MapMode;

  const [openMarkerId, setOpenMarkerId] = useState<string | null | undefined>(null);
  const [activeView, setActiveView] = useState(initialMapViewType);

  const { allMspVehicles: dataVehicles, loading: loadingMspVehicle, error: errorMspVehicle, getAggregatedVehicles } = useFetchMspVehicles();
  const { allPublishedZones: publishedZones, getPublishedZones } = useFetchPublishedZones();

  const zonesApplicableAtCurrentTime = useEvaluateZonesVisibilityWithTheTime(publishedZones);
  const { filters, anyFilterApplied, handleFilterChange, resetFilters } = useMapFilterChange();

  const loadingVehiclesError = useSelector(loadingVehiclesErrorSelector);
  const loadingVehicles: boolean = useSelector(loadingVehiclesSelector);
  const allVehicles = useSelector(formattedAllVehiclesSelector);

  const filteredVehicles = useApplicableVehiclesForFilters(allVehicles, filters);
  const filteredZones = useFilterZonesByPredicates(zonesApplicableAtCurrentTime, {
    zoneTypes: filters.zoneTypes,
    vehicleTypes: filters.vehicleTypes,
    infraTypes: filters.infrastructureTypes,
  });

  const handleVehicleLocationClick = useCallback((markerId: string) => {
    setOpenMarkerId(markerId);
    setActiveView(FloatingButtonType.MapMode);
  }, []);

  const handleSwitchModeButtonClick = (e: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>, switchModeButtonId: string) => {
    setActiveView(switchModeButtonId as FloatingButtonType);
  };

  const fetchAggregateVehiclesAndPublishedZones = async () => {
    await Promise.all([getAggregatedVehicles(), getPublishedZones()]);
  };

  const breadCrumbTexts = [formatMessage({ id: 'menu.support' }), formatMessage({ id: 'support.fleetManagement' })];
  const isMapMode = activeView === FloatingButtonType.MapMode;
  const isSupporterMode = listViewQueryParam === 'true';
  const MemoizedFleetManagerVehiclesListColumnDefs = FleetManagerVehiclesListColumnDefs({
    onVehicleLocationClick: handleVehicleLocationClick,
    getAggregatedVehicles,
  });
  return (
    <>
      {loadingMspVehicle || errorMspVehicle || !dataVehicles.length ? (
        <LoadingAndErrorWithRetryAndNoResults
          loading={loadingMspVehicle}
          error={errorMspVehicle}
          baseTranslationKey="operator"
          onRetry={fetchAggregateVehiclesAndPublishedZones}
          style={{ height: `calc(100vh - ${HEADER_HEIGHT})` }}
          noResults={!dataVehicles.length}
        />
      ) : (
        <Box sx={{ backgroundColor: theme.palette.dark.shade3 }}>
          {isSupporterMode && <NonClickableBreadCrumb values={breadCrumbTexts} />}
          <FloatingBox boxStyling={{ top: isSupporterMode ? '156px' : '104px' }}>
            <WmvButtonGroup config={switchButtonConfig} activeBtn={activeView} onClick={handleSwitchModeButtonClick} />
            {isMapMode && (
              <MapFiltersAndZoneTypeInfoControls
                filters={filters}
                isAnyFilterApplied={anyFilterApplied}
                onFilterChange={handleFilterChange}
              />
            )}
          </FloatingBox>

          {!isMapMode ? (
            <Box sx={{ paddingTop: HEADER_TABLE_GAP_HEIGHT }}>
              {dataVehicles.length > 0 && (
                <NewDataTable<AggregatedVehicle, 'default'>
                  columns={MemoizedFleetManagerVehiclesListColumnDefs}
                  data={dataVehicles}
                  height={`calc(100vh - 168px)`}
                />
              )}
            </Box>
          ) : (
            <Box sx={{ height: TABLE_HEIGHT_NO_SECONDARY }}>
              <MapTableView
                zones={filteredZones}
                vehicles={filteredVehicles}
                filters={filters}
                isAnyFilterApplied={anyFilterApplied}
                onFiltersReset={resetFilters}
                loadingVehicles={loadingVehicles}
                loadingVehiclesError={loadingVehiclesError}
                defaultMarkerId={openMarkerId}
              />
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default Operator;
