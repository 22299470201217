import { useCallback, useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';

import { LookupDurationWindow, LookupDurationWindowHelper, VehicleTripsListResponse } from '../../../../models';
import { DEFAULT_DATETIME_FORMAT_DAYJS, NO_CONTENT_RESPONSE_BODY } from '../../../../utils/constants';
import { getVehicleTripsList } from '../../../../utils/queries';
import { StartAndEndDateTimeRangeValues } from '../../../../wmv-components';
import CustomTripDateFilter from '../../../CustomerInformationModal/CustomerTrips/CustomTripDateFilter';
import FilterButton from '../../../CustomerInformationModal/CustomerTrips/FilterButton';
import { LoadingAndErrorWithRetryAndNoResults } from '../../../LoadingAndErrorWithRetryAndNoResults';

import { filtersContainerSx, filtersContentSx, tripContainerSx, tripContentSx } from './styles';
import VehicleInfoReactTable from './VehicleInfoReactTable';

export type LastSearchInfo = {
  startEpochMillis: number;
  endEpochMillis: number;
  lookupDuration?: LookupDurationWindow;
};

const VehicleTripHistory = ({ vehicleId }: { vehicleId: string }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [filteredTrips, setFilteredTrips] = useState<VehicleTripsListResponse>([]);
  const [activeLookupDuration, setActiveLookupDuration] = useState(LookupDurationWindow.SixHours);
  const [showCustomFilters, setShowCustomFilters] = useState(false);
  const [noTripHistoryResults, setNoTripHistoryResults] = useState(false);
  const [lastSearchInfo, setLastSearchInfo] = useState<LastSearchInfo>({
    startEpochMillis: 0,
    endEpochMillis: 0,
  });
  const [formInitialValues, setFormInitialValues] = useState({
    startDate: '',
    endDate: '',
  });

  const theme = useTheme();
  const { formatMessage } = useIntl();

  const fetchTripsStandard = useCallback(
    async (lookupDuration: LookupDurationWindow) => {
      if (lookupDuration === LookupDurationWindow.Custom) {
        setLoading(false);
        setFilteredTrips([]);
        return;
      }

      let fromInclusiveEpochMillis = 0,
        toInclusiveEpochMillis = new Date().getTime();

      const timeThreshold = LookupDurationWindowHelper.duration(lookupDuration);
      if (timeThreshold) {
        fromInclusiveEpochMillis = toInclusiveEpochMillis - timeThreshold;
      }

      setLastSearchInfo({
        startEpochMillis: fromInclusiveEpochMillis,
        endEpochMillis: toInclusiveEpochMillis,
        lookupDuration: lookupDuration,
      });

      await fetchTripsForVehicleApiCall(vehicleId, fromInclusiveEpochMillis, toInclusiveEpochMillis, lookupDuration);
    },
    [vehicleId],
  );

  const fetchTripsForVehicleApiCall = async (
    vehicleId: string,
    fromInclusiveEpochMillis: number,
    toInclusiveEpochMillis: number,
    lookupDuration: LookupDurationWindow,
  ) => {
    try {
      setLoading(true);
      setError(false);
      setNoTripHistoryResults(false);
      setFilteredTrips([]);

      setLastSearchInfo({
        startEpochMillis: fromInclusiveEpochMillis,
        endEpochMillis: toInclusiveEpochMillis,
        lookupDuration: lookupDuration,
      });

      let trips = await getVehicleTripsList({
        vehicleId,
        fromInclusiveEpochMillis,
        toInclusiveEpochMillis,
      });

      setFilteredTrips(trips);
      setLoading(false);

      if (trips.length === 0) {
        setNoTripHistoryResults(true);
      }
    } catch (error) {
      setError(true);
      setLoading(false);
      setFilteredTrips([]);
    }
  };

  const handleFilterClick = (lookupDuration: LookupDurationWindow) => {
    setError(false);
    setLoading(false);
    setNoTripHistoryResults(false);
    setActiveLookupDuration(lookupDuration);

    if (lookupDuration !== LookupDurationWindow.Custom) {
      fetchTripsStandard(lookupDuration);
    } else if (lookupDuration === LookupDurationWindow.Custom) {
      const timeStampStartDate = dayjs(`${formInitialValues.startDate}`);
      const timeStampEndDate = dayjs(`${formInitialValues.endDate}`);
      setLastSearchInfo({
        startEpochMillis: timeStampStartDate.valueOf(),
        endEpochMillis: timeStampEndDate.valueOf(),
        lookupDuration: LookupDurationWindow.Custom,
      });
    }
    setShowCustomFilters(lookupDuration === LookupDurationWindow.Custom);
  };

  const searchCustomTrips = async ({ startDateTime, endDateTime }: StartAndEndDateTimeRangeValues) => {
    const startEpochMillis = startDateTime!.valueOf();
    const endEpochMillis = endDateTime!.valueOf();

    setFormInitialValues({
      startDate: startDateTime!.format(DEFAULT_DATETIME_FORMAT_DAYJS),
      endDate: endDateTime!.format(DEFAULT_DATETIME_FORMAT_DAYJS),
    });

    await fetchTripsForVehicleApiCall(vehicleId, startEpochMillis, endEpochMillis, LookupDurationWindow.Custom);
  };

  useEffect(() => {
    setFilteredTrips([]);
    if (activeLookupDuration !== LookupDurationWindow.Custom) {
      fetchTripsStandard(activeLookupDuration);
    }
  }, [activeLookupDuration, fetchTripsStandard]);

  const retryLastSearch = () => {
    setError(false);

    if (lastSearchInfo.lookupDuration === LookupDurationWindow.Custom) {
      searchCustomTrips({
        startDateTime: dayjs(lastSearchInfo.startEpochMillis),
        endDateTime: dayjs(lastSearchInfo.endEpochMillis),
      });
    } else {
      fetchTripsStandard(lastSearchInfo.lookupDuration || activeLookupDuration);
    }
  };

  return (
    <Box sx={tripContainerSx}>
      <Box sx={tripContentSx}>
        <Typography variant="bodySmallBold">{formatMessage({ id: 'account.tripHistory.title' })}</Typography>

        <Box sx={filtersContainerSx}>
          <Box sx={filtersContentSx}>
            <Typography variant="bodyMedium" color={theme.palette.dark.shade60}>
              {formatMessage({ id: 'account.tripHistory.recent' })}
            </Typography>
            <>
              {LookupDurationWindowHelper.allTabLabelAndValues().map(({ value, label }, index) => (
                <FilterButton
                  key={index}
                  label={label}
                  filter={value}
                  activeFilter={activeLookupDuration}
                  onClick={() => handleFilterClick(value)}
                />
              ))}
            </>
          </Box>

          {showCustomFilters && <CustomTripDateFilter initialValues={formInitialValues} onSubmit={searchCustomTrips} />}
        </Box>
      </Box>

      {filteredTrips.length === 0 || filteredTrips === NO_CONTENT_RESPONSE_BODY ? (
        <LoadingAndErrorWithRetryAndNoResults
          style={{ height: '100%', backgroundColor: theme.palette.white.main }}
          error={error}
          loading={loading}
          onRetry={retryLastSearch}
          baseTranslationKey={
            activeLookupDuration !== LookupDurationWindow.Custom ? 'tripSearchHistoryFixedDuration' : 'tripSearchHistoryCustomDuration'
          }
          noResults={noTripHistoryResults}
        />
      ) : (
        <>
          <VehicleInfoReactTable filteredTrips={filteredTrips} />
        </>
      )}
    </Box>
  );
};

export default VehicleTripHistory;
