import { Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';

import { VehicleTypeHelper } from '../../../models';

import { useSelectedVehiclePricingDetails } from './contexts';
import { VehiclePricingFormActionButtons } from './VehiclePricingFormActionButtons';

export function VehiclePricingFormTitleWithActionButtons({
  onDiscardFailedStatusTransitionSubmit,
}: VehiclePricingFormTitleWithActionButtonsProps) {
  const selectedVehiclePricingDetails = useSelectedVehiclePricingDetails();

  if (!selectedVehiclePricingDetails) return null;

  const { vehicleType, modelName } = selectedVehiclePricingDetails;
  const title = `${VehicleTypeHelper.metadata(vehicleType).displayText} ${modelName}`;
  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between">
      <Typography variant="bodyLargeBold">{title}</Typography>
      <Stack direction="row" gap={1} justifyContent="flex-end">
        <VehiclePricingFormActionButtons onDiscardFailedVehiclePricingStatusTransition={onDiscardFailedStatusTransitionSubmit} />
      </Stack>
    </Box>
  );
}

interface VehiclePricingFormTitleWithActionButtonsProps {
  onDiscardFailedStatusTransitionSubmit: () => Promise<void>;
}
