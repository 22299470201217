import { Unit, UnitHelper, TripDurationBreakdownDetails, TripDurationDetails } from '../../models';
import { SECONDS_IN_A_MINUTE } from '../../utils/constants';

/**
 * Service class for handling and calculating trip duration breakdown details.
 * This class provides methods to access and format various duration-related details
 * for a specific trip, including chronological breakdown and total duration calculations.
 *
 * @class
 */
export class TripDurationService {
  private duration: TripDurationDetails;
  private durationBreakdown: TripDurationBreakdownDetails[];

  constructor(duration: TripDurationDetails) {
    this.durationBreakdown = duration.breakdown;
    this.duration = duration;
  }

  /**
   * Provides a chronological breakdown of the trip's duration segments, sorted by start time.
   * Each segment includes formatted start time, actual duration, and billable duration.
   *
   * @type {Object[]}
   * @property {string} start - The start time of the segment, formatted as a date-time string.
   * @property {string} actualDurationSeconds - The actual duration of the segment, formatted as a duration string.
   * @property {string} billableDurationSeconds - The billable duration of the segment, formatted as a duration string.
   * @readonly
   */
  get chronologicalBreakdown() {
    return this.durationBreakdown
      .sort((a: TripDurationBreakdownDetails, b: TripDurationBreakdownDetails) => a.start - b.start)
      .map((breakdown) => ({
        ...breakdown,
        start: breakdown?.start?.parseEpochMillis()?.formatAsDateTimeString(),
        actualDurationSeconds: breakdown.actualDurationSeconds.formatSecondsAsDuration(),
        billableDurationSeconds: breakdown.billableDurationSeconds.formatSecondsAsDuration(),
      }));
  }

  /**
   * Provides a total breakdown of the trip's duration, including actual and charged times for ride and paused states.
   * It also calculates the total charged duration in minutes.
   *
   * @type {Object}
   * @property {string} actualRideDuration - The actual duration of active ride time, formatted as a duration string.
   * @property {string} chargedRideDuration - The charged duration of active ride time, formatted as a duration string.
   * @property {string} actualPausedDuration - The actual duration of paused time, formatted as a duration string.
   * @property {string} chargedPausedDuration - The charged duration of paused time, formatted as a duration string.
   * @property {string} chargedTotalDuration - The total charged duration of the trip in minutes.
   * @readonly
   */
  get totalDurationBreakdown() {
    return {
      actualRideDuration: this.duration.actualActiveSeconds?.formatSecondsAsDuration(),
      chargedRideDuration: this.duration.billableActiveSeconds?.formatSecondsAsDuration(),
      actualPausedDuration: this.duration.actualPausedSeconds?.formatSecondsAsDuration(),
      chargedPausedDuration: this.duration.billablePausedSeconds?.formatSecondsAsDuration(),
      chargedTotalDuration:
        (this.duration.billableActiveSeconds + this.duration.billablePausedSeconds) / SECONDS_IN_A_MINUTE +
        ' ' +
        UnitHelper.metadata(Unit.Minutes).displayText,
    };
  }
}
