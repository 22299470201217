import { Box, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { ReactComponent as PlusIcon } from '../../../assets/icons/fill/plus.svg';
import { TranslatedText } from '../../../wmv-components';

import { BP_ACTION_MODE } from './atoms/budget-plan-atoms';
import { useBudgetPlan } from './atoms/hooks/useBudgetPlan';
import { headerSectionContainerStyles } from './styles';

export const HeadingSection = () => {
  const theme = useTheme();
  const { palette } = theme;

  const { setBudgetPlanModalVisible, setSelectedPlan, setBudgetPlanActionMode } = useBudgetPlan();

  return (
    <Box component="section" sx={headerSectionContainerStyles}>
      <Box display="flex" flexDirection="column">
        <TranslatedText variant="bodyLargeBold" translationKey="operator.budgetPlan.titlePage" />
        <TranslatedText variant="bodyMedium" translationKey="operator.budgetPlan.bodyPage" sx={{ width: '440px' }} />
      </Box>
      <Button variant="contained" onClick={handleAddBudgetPlanClick}>
        <PlusIcon fill={palette.white.main} />
        <TranslatedText variant="bodyMediumBold" translationKey="operator.budgetPlan.addBudgetPlan" sx={{ pl: 0.5 }} />
      </Button>
    </Box>
  );

  function handleAddBudgetPlanClick() {
    setBudgetPlanModalVisible(true);
    setSelectedPlan(null);
    setBudgetPlanActionMode(BP_ACTION_MODE.ADD);
  }
};
