import { type Dispatch, type SetStateAction, useState } from 'react';

import { Box, Modal, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useIntl } from 'react-intl';

import { AggregatedVehicleUsageStatus } from '../../../../models';
import CustomIcon from '../../../../utils/CustomIcon';
import { changeVehicleStatus } from '../../../../utils/queries';

import StatusChangeSuccessModal from './StatusChangeSuccessModal';
import ModalChangeButton, { StatusModalChangeButtonUsage } from './StatusModalChangeButton';
import ModalChangeStatement from './StatusModalChangeStatement';

function StatusChangeModal({
  isOpen,
  vehicleId,
  vehicleVersion,
  vehicleQrCode,
  currentStatus,
  futureStatus,
  onClose,
  handleStatusChangeConfirm,
  openSuccessModal,
  setOpenSuccessModal,
}: StatusChangeModalProps) {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const confirmStatusChange = async () => {
    if (!vehicleId) {
      setLoading(false);
      setError(true);
      return;
    }

    setLoading(true);
    try {
      await changeVehicleStatus({ version: vehicleVersion, status: futureStatus }, vehicleId);
      onClose();
      setOpenSuccessModal(true);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleStatusChangeSuccessModal = () => {
    setOpenSuccessModal(false);
    handleStatusChangeConfirm();
  };

  const handleCancelStatus = () => {
    setError(false);
    onClose();
  };

  return (
    <Box>
      <Modal open={isOpen}>
        <Box sx={statusChangeModalStyle}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(0.5) }}>
            <Typography variant="bodyMediumBold" color={theme.palette.dark.main} sx={{ textAlign: 'center' }}>
              {formatMessage({ id: 'operator.changeModalCaption' })} {vehicleQrCode}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(0.5) }}>
              <>
                <ModalChangeStatement usageStatement="operator.changeModalFrom" status={currentStatus} />
                <ModalChangeStatement usageStatement="operator.changeModalTo" status={futureStatus} />
              </>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacing(1),
                width: '140px',
              }}
            >
              <ModalChangeButton
                usage={StatusModalChangeButtonUsage.Change}
                message="common.confirm"
                onClickFunction={confirmStatusChange}
                loading={loading}
              />
              <ModalChangeButton
                usage={StatusModalChangeButtonUsage.Cancel}
                message="common.dialog.cancel"
                onClickFunction={handleCancelStatus}
                disabled={loading}
              />
            </Box>
          </Box>

          {error ? (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flexStart', gap: '4px' }}>
              {CustomIcon('AttentionIcon', theme.palette.alert.main, theme.typography.caption.lineHeight)}
              <Typography variant="caption" color={theme.palette.alert.main}>
                {formatMessage({ id: 'operator.statusChange.changeModal.failed' })}
              </Typography>
            </Box>
          ) : null}
        </Box>
      </Modal>
      {openSuccessModal && (
        <StatusChangeSuccessModal
          isOpen={openSuccessModal}
          vehicleQrCode={vehicleQrCode}
          handleStatusChangeSuccessModal={handleStatusChangeSuccessModal}
          modalType="change"
        />
      )}
    </Box>
  );
}

interface StatusChangeModalProps {
  isOpen: boolean;
  vehicleId: number;
  vehicleVersion: number;
  vehicleQrCode: string;
  currentStatus: AggregatedVehicleUsageStatus;
  futureStatus: AggregatedVehicleUsageStatus;
  onClose: () => void;
  handleStatusChangeConfirm: () => void;
  openSuccessModal: boolean;
  setOpenSuccessModal: Dispatch<SetStateAction<boolean>>;
}

export const statusChangeModalStyle = (theme: { spacing: (arg0: number) => any; palette: { white: { main: any } } }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignContent: 'center',
  height: 'fit-content',
  width: '320px',
  gap: theme.spacing(2),
  backgroundColor: theme.palette.white.main,
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

export default StatusChangeModal;
