import React, { useState } from 'react';

import { Button, Card, CardHeader, FormControl, FormControlLabel, Popover, Radio, RadioGroup, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { ReactComponent as WikimoveLogo } from '../../assets/logo/Flow_Logo.svg';
import routes from '../../routes';
import UserService from '../../services/UserService';
import { AvailableLanguage } from '../../state';
import { changeLanguageAction } from '../../state';
import { changingLanguageSelector } from '../../state';
import CustomIcon from '../../utils/CustomIcon';
import AccountInformationModal from '../AccountInformationModal';

const ProfileBox = () => {
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const open = Boolean(anchorEl);
  const [openModal, setOpenModal] = React.useState(false);
  const userData = UserService.getProfileData();
  const [anchorElPopover, setAnchorElPopover] = React.useState<Element | null>(null);
  const openPopover = Boolean(anchorElPopover);
  const { formatMessage } = useIntl();

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorElPopover(null);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };
  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const dispatch = useDispatch();
  const langCode = useSelector(changingLanguageSelector) === AvailableLanguage.EN ? 1 : 0;
  const [toggleLanguage, setToggleLanguage] = useState(langCode);

  const handleToggleLanguage = () => {
    if (toggleLanguage === 1) {
      setToggleLanguage(0);
      dispatch(changeLanguageAction(AvailableLanguage.DE));
      localStorage.setItem('language', AvailableLanguage.DE);
    } else {
      setToggleLanguage(1);
      dispatch(changeLanguageAction(AvailableLanguage.EN));
      localStorage.setItem('language', AvailableLanguage.EN);
    }
    window.location.reload();
  };

  const styleCard = {
    boxShadow: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };

  const theme = useTheme();

  return (
    <React.Fragment>
      <Box onClick={handleClick} sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account settings">
          <IconButton
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}></Avatar>
          </IconButton>
        </Tooltip>
        {CustomIcon('ChevronDown', theme.palette.dark.main)}
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            borderRadius: 2,
            boxShadow: theme.shadowOptions.aboveMedium,
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 45,
              height: 45,
              mr: 0,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgColor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Card sx={styleCard}>
          <CardHeader
            sx={{
              pt: 1,
              pb: 1,
              '& .MuiCardHeader-avatar': {
                mr: 1,
              },
            }}
            avatar={<Avatar />}
            title={`${userData.givenName} ${userData.familyName}`}
            subheader={userData.email}
            titleTypographyProps={{ color: theme.palette.dark.main, variant: 'bodyMediumBold' }}
            subheaderTypographyProps={{ color: theme.palette.dark.shade60, variant: 'bodyMedium' }}
          />
          {toggleLanguage === 0 ? (
            <Button onClick={handleClickPopover} sx={{ padding: '8px' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '4px' }}>
                {CustomIcon('Globe', theme.palette.dark.main)}
                <Typography variant="bodySmallBold" color={theme.palette.dark.main}>
                  {formatMessage({ id: 'common.language.ger' })}
                </Typography>
                {CustomIcon('ChevronDown', theme.palette.dark.main)}
              </Box>
            </Button>
          ) : (
            <Button onClick={handleClickPopover} sx={{ padding: '8px 16px' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '4px' }}>
                {CustomIcon('Globe', theme.palette.dark.main)}
                <Typography variant="bodySmallBold" color={theme.palette.dark.main}>
                  {formatMessage({ id: 'common.language.eng' })}
                </Typography>
                {CustomIcon('ChevronDown', theme.palette.dark.main)}
              </Box>
            </Button>
          )}

          <Popover
            open={openPopover}
            anchorEl={anchorElPopover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            onClose={handleClosePopover}
          >
            <FormControl>
              <RadioGroup name="language-control-group" value={toggleLanguage} onChange={handleToggleLanguage} sx={{ minWidth: '170px' }}>
                <FormControlLabel
                  value="1"
                  control={
                    <Radio
                      sx={{
                        padding: '0 16px 0 16px',
                        color: theme.palette.dark.shade32,
                        '&.Mui-checked': {
                          color: theme.palette.secondary.main,
                        },
                      }}
                    />
                  }
                  label={`${formatMessage({ id: 'common.language.engEnglish' })}`}
                />
                <FormControlLabel
                  value="0"
                  control={
                    <Radio
                      sx={{
                        padding: '0 16px 0 16px',
                        color: theme.palette.dark.shade32,
                        '&.Mui-checked': {
                          color: theme.palette.secondary.main,
                        },
                      }}
                    />
                  }
                  label={`${formatMessage({ id: 'common.language.gerGerman' })}`}
                />
              </RadioGroup>
            </FormControl>
          </Popover>
        </Card>
        <Divider />
        <MenuItem
          onClick={() => {
            handleModalOpen();
          }}
          sx={{
            '&:hover': {
              backgroundColor: theme.palette.secondary.tint,
            },
          }}
        >
          <ListItemIcon>{CustomIcon('User', theme.palette.dark.shade76)}</ListItemIcon>
          <Typography color={theme.palette.dark.shade76} variant="bodyMediumBold">
            {formatMessage({ id: 'common.settings' })}
          </Typography>
        </MenuItem>
        <NavLink to={routes.support}>
          <MenuItem
            onClick={handleClose}
            sx={{
              '&:hover': {
                backgroundColor: theme.palette.secondary.tint,
              },
            }}
          >
            <ListItemIcon>{CustomIcon('Support', theme.palette.dark.shade76)}</ListItemIcon>
            <Typography color={theme.palette.dark.shade76} variant="bodyMediumBold">
              {formatMessage({ id: 'common.support' })}
            </Typography>
          </MenuItem>
        </NavLink>

        <Divider />
        <MenuItem
          onClick={() => {
            handleClose();
            UserService.doLogout();
          }}
          sx={{
            '&:hover': {
              backgroundColor: theme.palette.secondary.tint,
            },
          }}
        >
          <ListItemIcon>{CustomIcon('Logout', theme.palette.dark.shade76)}</ListItemIcon>
          <Typography color={theme.palette.dark.shade76} variant="bodyMediumBold">
            {formatMessage({ id: 'common.logout' })}
          </Typography>
        </MenuItem>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            padding: '8px 16px',
          }}
        >
          <Typography color={theme.palette.dark.shade32} variant="bodySmall">
            {process.env.REACT_APP_VERSION}
          </Typography>
          <MenuItem
            disableRipple
            dense
            sx={{
              p: '0 0 0 16px',
              '&:hover': {
                backgroundColor: theme.palette.white.main,
              },
              cursor: 'unset',
            }}
          >
            <Typography color={theme.palette.dark.shade60} variant="bodySmall" display="flex" alignItems="center">
              <WikimoveLogo style={{ width: '20px', height: '20px' }} />
              <Typography variant="bodySmall" color={theme.palette.dark.shade60}>
                {formatMessage({ id: 'common.poweredBy' })}{' '}
                <Typography variant="bodySmall" color={theme.palette.dark.shade76}>
                  {' '}
                  {formatMessage({ id: 'common.wikimove' })}
                </Typography>
              </Typography>
            </Typography>
          </MenuItem>
        </Box>
        <Card sx={{ boxShadow: 0 }}>
          {
            // Todo: Add settings modal
            openModal ? <AccountInformationModal isOpen={openModal} onClose={handleModalClose} /> : <></>
          }
        </Card>
      </Menu>
    </React.Fragment>
  );
};

export default ProfileBox;
