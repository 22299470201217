import dayjs, { Dayjs } from 'dayjs';

import {
  ActiveOnPredicate,
  DayOfWeek,
  DayOfWeekHelper,
  ZoneStatus,
  InfrastructureType,
  VehicleType,
  ZoneResponse,
  ZoneType,
  ZoneTypeHelper,
} from '../../models';
import { END_OF_DAY_MINUTES_FROM_BEGINNING_OF_DAY, START_OF_DAY_MINUTES_FROM_BEGINNING_OF_DAY } from '../../utils/constants';

import { ZoneGeoJsonFeature } from './models';
import { VisibilityStatusPointProviderConstants } from './VisibilityStatusPointProvider';
import { ZoneDateRange } from './ZoneDateRange';
import { ZoneDaysOfWeek } from './ZoneDaysOfWeek';
import { ZoneTimeWindow } from './ZoneTimeWindow';

export class Zone {
  private _zone: ZoneResponse;
  id: number;
  name: string;
  type: ZoneType;
  representsMobilityStation: boolean;
  activeOn: ActiveOnPredicate;
  applicableVehicleTypes: VehicleType[];
  openEnded: boolean;
  createdAtEpochMillis: number;
  updatedAtEpochMillis: number;
  geoJson: GeoJSON.Feature;

  private readonly _dateRange: ZoneDateRange;
  private readonly _timeWindow: ZoneTimeWindow;
  private readonly _daysOfWeek: ZoneDaysOfWeek;

  constructor(model: ZoneResponse) {
    this._zone = model;
    const {
      activeOn: { dateRange, timeWindow, daysOfWeek },
    } = model;

    this.type = model.type;
    this.representsMobilityStation = model.representsMobilityStation;
    this.activeOn = model.activeOn;
    this.name = model.name;
    this.id = model.id;
    this.applicableVehicleTypes = model.applicableVehicleTypes;
    this.openEnded = dateRange.openEnded;
    this.createdAtEpochMillis = model.createdAtEpochMillis;
    this.updatedAtEpochMillis = model.updatedAtEpochMillis;
    this.geoJson = model.geoJson;
    this.applicableVehicleTypes = model.applicableVehicleTypes;

    this._dateRange = new ZoneDateRange(dateRange);
    this._timeWindow = new ZoneTimeWindow(timeWindow);
    this._daysOfWeek = new ZoneDaysOfWeek(daysOfWeek);
  }

  typeCompatibleWith(predicateZoneTypes: Set<ZoneType>): boolean {
    return predicateZoneTypes.has(this.type);
  }

  applicableInfraTypesCompatibleWith(predicateInfraTypes: Set<InfrastructureType>): boolean {
    const zoneInfrastructureTypes = this.representsMobilityStation ? [InfrastructureType.MobilityStation] : [];
    return predicateInfraTypes.size === 0 || zoneInfrastructureTypes.some((infraType) => predicateInfraTypes.has(infraType));
  }

  applicableVehicleTypesOverlapsWith(applicableVehicleTypes: Set<VehicleType>) {
    return this.applicableVehicleTypes.some((applicableVehicleType) => applicableVehicleTypes.has(applicableVehicleType));
  }

  dateRangeOverlapsWith(predicateStartDateInclusive: Dayjs, predicateEndDateInclusive: Dayjs): boolean {
    return this._dateRange.overlapsWith(predicateStartDateInclusive, predicateEndDateInclusive);
  }

  timeWindowOverlapsWith(predicateStartTimeSecondsFromBeginningOfDay: number, predicateEndTimeSecondsFromBeginningOfDay: number): boolean {
    return this._timeWindow.overlapsWith(predicateStartTimeSecondsFromBeginningOfDay, predicateEndTimeSecondsFromBeginningOfDay);
  }

  dayOfWeeksOverlapsWith(predicateDayOfWeeks: Set<DayOfWeek> = DayOfWeekHelper.allDaysOfWeek()) {
    return this._daysOfWeek.overlapsWith(predicateDayOfWeeks);
  }

  calculateNextEvaluationPoint(evaluationPoint: Dayjs): Dayjs {
    const now: Dayjs = evaluationPoint;
    const visibleNow: boolean = this.isVisible(now);

    if (visibleNow) {
      const dateRangeNextInvisiblePoint: Dayjs = this._dateRange.calculateNextInvisiblePoint(now);
      const daysOfWeekNextInvisiblePoint: Dayjs = this._daysOfWeek.calculateNextInvisiblePoint(now);
      const timeOfDayNextInvisiblePoint: Dayjs = this._timeWindow.calculateNextInvisiblePoint(now);
      // prettier-ignore
      return dayjs.getMin([dateRangeNextInvisiblePoint, daysOfWeekNextInvisiblePoint, timeOfDayNextInvisiblePoint])!;
    } else {
      let nextEvaluationPoint: Dayjs = now;
      do {
        nextEvaluationPoint = this._dateRange.calculateNextVisiblePoint(nextEvaluationPoint);
        nextEvaluationPoint = this._daysOfWeek.calculateNextVisiblePoint(nextEvaluationPoint);
        nextEvaluationPoint = this._timeWindow.calculateNextVisiblePoint(nextEvaluationPoint);
      } while (this.isInvisible(nextEvaluationPoint) && this.isBeforeEndOfUniverse(nextEvaluationPoint));

      if (nextEvaluationPoint.isAfter(VisibilityStatusPointProviderConstants.endOfUniverse)) {
        return VisibilityStatusPointProviderConstants.endOfUniverse;
      }

      return nextEvaluationPoint;
    }
  }

  isBeforeEndOfUniverse(evaluationPoint: Dayjs): boolean {
    return evaluationPoint.isBefore(VisibilityStatusPointProviderConstants.endOfUniverse);
  }

  isInvisible(evaluationPoint: Dayjs) {
    return !this.isVisible(evaluationPoint);
  }

  isVisible(evaluationPoint: Dayjs): boolean {
    return (
      this._dateRange.isVisible(evaluationPoint) &&
      this._daysOfWeek.isVisible(evaluationPoint) &&
      this._timeWindow.isVisible(evaluationPoint)
    );
  }

  geoJsonFeature(): ZoneGeoJsonFeature {
    const { timeWindow } = this.activeOn;
    const representsFullDay =
      timeWindow.startInclusive.asDayjsTime().minutesFromBeginningOfDay() === START_OF_DAY_MINUTES_FROM_BEGINNING_OF_DAY &&
      timeWindow.endExclusive.asDayjsTime().minutesFromBeginningOfDay() === END_OF_DAY_MINUTES_FROM_BEGINNING_OF_DAY;
    return {
      type: this.geoJson.type,
      properties: {
        id: this.id,
        name: this.name,
        type: this.type,
        representsMobilityStation: this.representsMobilityStation,
        applicableVehicleTypes: this.applicableVehicleTypes,
        startDate: this.activeOn.dateRange.startInclusive.asDayjsDate(),
        endDate: !this.openEnded ? this.activeOn.dateRange.endInclusive!.asDayjsDate() : null,
        indefiniteZone: this.openEnded,
        startTime: this.activeOn.timeWindow.startInclusive.asDayjsTime(),
        endTime: this.activeOn.timeWindow.endExclusive.asDayjsTime(),
        representsFullDay,
        daysOfWeek: new Set(this.activeOn.daysOfWeek),
        strokeColor: ZoneTypeHelper.metadata(this.type).color,
        fillColor: ZoneTypeHelper.metadata(this.type).color,
      },
      id: this.id,
      geometry: this.geoJson.geometry,
    };
  }

  isPublished(): boolean {
    return this._zone.status === ZoneStatus.Published;
  }
}
