import React from 'react';

import { Box, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { ReactComponent as CargoBicycleIcon } from '../../../../assets/illustrations/cargoBike.svg';
import { ReactComponent as EBikeIcon } from '../../../../assets/illustrations/eBike.svg';
import { ReactComponent as MopedIcon } from '../../../../assets/illustrations/eMoped.svg';
import { ReactComponent as EScooterIcon } from '../../../../assets/illustrations/eScooter.svg';
import { ReactComponent as CarIcon } from '../../../../assets/illustrations/meiaudoCar.svg';
import { VehicleType } from '../../../../models';

const vehicleTypeIconMapping = new Map([
  [VehicleType.CargoBicycle, CargoBicycleIcon],
  [VehicleType.Scooter, EScooterIcon],
  [VehicleType.ElectricBike, EBikeIcon],
  [VehicleType.ElectricMoped, MopedIcon],
  [VehicleType.Car, CarIcon],
]);

const VehicleIllustrations: React.FC<VehicleIllustrationsProps> = ({ formFactor }) => {
  const theme = useTheme();
  const IconComponent = vehicleTypeIconMapping.get(formFactor);

  if (!IconComponent) return null;

  return (
    <div>
      {IconComponent && (
        <Box
          sx={{
            backgroundColor: theme.palette.dark.shade3,
            borderRadius: '8px',
            width: '268px',
            height: '190px',
          }}
        >
          <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
            <IconComponent width="174px" height="171px" />
          </Grid>
        </Box>
      )}
    </div>
  );
};

interface VehicleIllustrationsProps {
  formFactor: VehicleType;
}

export default VehicleIllustrations;
