import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import { SearchUsersQueryParams } from '../../../models';
import { DEFAULT_DATE_FORMAT_DAYJS } from '../../../utils/constants';
import { intl } from '../../../utils/intl';
import { FormFieldWithLabelInfo } from '../../FormFields/FormFieldWithLabel';
import { SearchForm } from '../SearchForm';

const formFields: FormFieldWithLabelInfo[] = [
  { name: 'firstName', label: intl.formatMessage({ id: 'maas.customer.details.firstName' }), type: 'text', isMandatory: true },
  { name: 'lastName', label: intl.formatMessage({ id: 'maas.customer.details.lastName' }), type: 'text', isMandatory: true },
  {
    name: 'birthDate',
    label: intl.formatMessage({ id: 'maas.customer.details.birthdate' }),
    type: 'date',
    isMandatory: true,
    format: DEFAULT_DATE_FORMAT_DAYJS,
  },
];

export interface CustomerDetailsFormProps {
  onSearch: (params: SearchUsersQueryParams) => void;
  loading: boolean;
}

export const CustomerDetailsForm = (props: CustomerDetailsFormProps) => {
  const { onSearch, loading } = props;
  const { formatMessage } = useIntl();

  const initialValues = { firstName: '', lastName: '', birthDate: '' };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .required(formatMessage({ id: 'form.validation.errorFieldRequired' })),
    lastName: Yup.string()
      .trim()
      .required(formatMessage({ id: 'form.validation.errorFieldRequired' })),
    birthDate: Yup.date()
      .required(formatMessage({ id: 'form.validation.errorFieldRequired' }))
      .max(new Date(), formatMessage({ id: 'form.validation.errorNoFutureDate' })),
  });

  return (
    <SearchForm
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSearch={onSearch}
      formFields={formFields}
      loading={loading}
    ></SearchForm>
  );
};
