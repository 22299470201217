import { ReactNode, CSSProperties } from 'react';

import Popover, { type PopoverOrigin } from '@mui/material/Popover';

interface CustomPopoverProps {
  children: ReactNode;
  open: boolean;
  anchorEl?: HTMLElement | null;
  handleClose: (event: React.MouseEvent<HTMLElement>) => void;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  style?: CSSProperties;
}

const CustomPopover: React.FC<CustomPopoverProps> = ({
  children,
  open,
  anchorEl,
  handleClose,
  anchorOrigin = { vertical: 'top', horizontal: 70 },
  transformOrigin = { vertical: 'top', horizontal: 'left' },
  style,
}) => {
  return (
    <Popover
      id="popover"
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      elevation={2}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '8px',
        },
      }}
    >
      <div style={{ maxWidth: 400, padding: 24, ...style }}>{children}</div>
    </Popover>
  );
};

export default CustomPopover;
