import { LastChangeAttemptStatus } from '../../models';
import {
  VehicleFeePricingBreakdownAmount,
  VehicleFeePricingBreakdown,
  VehicleFeePricingLastAttemptedStateChange,
} from '../../models/vehicle-fee-pricing';

import { VehicleFeeAmount } from './VehicleFeeAmount';

export class VehicleFeePricingLastChangeAttemptDto extends VehicleFeeAmount {
  breakdown: VehicleFeePricingBreakdown[];
  activationAmount: VehicleFeePricingBreakdownAmount;
  status: LastChangeAttemptStatus;

  constructor(lastAttemptedStateChange: VehicleFeePricingLastAttemptedStateChange) {
    super(lastAttemptedStateChange.details.breakdown);
    this.breakdown = lastAttemptedStateChange.details.breakdown;
    this.activationAmount = lastAttemptedStateChange.details.breakdown[0].amount;
    this.status = lastAttemptedStateChange.status;
  }
}
