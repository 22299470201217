import { Box, Typography, useTheme } from '@mui/material';
import { useAtomValue } from 'jotai';
import { createPortal } from 'react-dom';
import { useIntl } from 'react-intl';

import { colorScaleAtom, poiDataAtom, rangesAtom } from '../../atoms/hexagon-layer-atoms';
import useLeafletControl from '../../hooks/useLeafletControl';

/**
 * Component for rendering the Analytics Legend control within the specified container.
 *
 * @param {Object} props - Component props.
 * @param {HTMLDivElement} props.container - The HTML container element where the legend should be rendered.
 * @returns {JSX.Element} The rendered legend component.
 */
function AnalyticsLegendControlComponent({ container }: { container: HTMLDivElement }) {
  const colorScale = useAtomValue(colorScaleAtom);
  const ranges = useAtomValue(rangesAtom);
  const selectedPoiData = useAtomValue(poiDataAtom);
  const { palette, spacing } = useTheme();
  const { formatMessage } = useIntl();

  // We split the color ranges into two columns
  const half = Math.ceil(ranges.length / 2);
  const splitRanges = [ranges.slice(0, half), ranges.slice(half)];

  return createPortal(
    <Box
      className="info legend"
      width="200px"
      sx={{
        borderRadius: spacing(1),
        padding: spacing(1, 2),
        backgroundColor: palette.white.main,
      }}
    >
      <Box display="flex" flexDirection="column" gap={spacing(0.5)} marginBottom={spacing(2)}>
        <Typography variant="bodyMediumBold" color={palette.baseLight.base76}>
          {formatMessage({ id: 'analytics.heatmap.legend' })}
        </Typography>
        <Typography variant="bodySmall" color={palette.baseLight.base76}>
          {formatMessage({ id: selectedPoiData })}
        </Typography>
      </Box>
      <Box display="grid" gridTemplateColumns="1fr 1fr" gap={spacing(2)}>
        {splitRanges.map((columnRanges, columnIndex) => (
          <Box key={columnIndex}>
            {columnRanges.map((range: number, index: number) => {
              const colorIndex = columnIndex * half + index;
              return (
                <Box key={colorIndex}>
                  <i
                    style={{
                      background: colorScale[colorIndex],
                      display: 'inline-block',
                      width: '14px',
                      height: '14px',
                      borderRadius: '7px',
                      marginRight: spacing(1),
                    }}
                  />
                  <Typography variant="bodyMedium" color={palette.baseLight.base76}>
                    {range}
                    {ranges[colorIndex + 1] ? `- ${ranges[colorIndex + 1]}` : '+'}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        ))}
      </Box>
    </Box>,
    container,
  );
}

/**
 * Wrapper component that mounts the Analytics Legend control on the Leaflet map.
 *
 * @returns {JSX.Element | null} The rendered legend component, or null if not mounted.
 */
export function AnalyticsLegendControl() {
  const { containerRef, isMounted } = useLeafletControl('bottomleft');

  if (!isMounted || !containerRef.current) return null;

  return <AnalyticsLegendControlComponent container={containerRef.current} />;
}
