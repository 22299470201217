/**
 * A collection of regular expressions for various input validation patterns.
 *
 * @type {Record<string, RegExp>}
 * @property {RegExp} onlyLetters - Matches strings containing only Unicode letters (`\p{Letter}`).
 * @property {RegExp} lettersWithSpace - Matches strings containing only Unicode letters (`\p{Letter}`) and spaces.
 * @property {RegExp} onlyDigits - Matches strings containing only digits.
 * @property {RegExp} lettersWithDigitsAndSpace - Matches strings containing letters (`\p{Letter}`), digits, and spaces.
 * @property {RegExp} postalCode - Matches postal codes containing Unicode letters (`\p{L}`), numbers (`\p{N}`), slashes, or hyphens, up to 10 characters long.
 * @property {RegExp} dialCode - Matches international dial codes, allowing an optional `+` followed by up to 7 digits.
 * @property {RegExp} name - Matches names with Unicode letters (`\p{L}`), apostrophes, hyphens, and spaces, with no leading or trailing spaces, up to 250 characters.
 * @property {RegExp} email - Matches valid email addresses based on common email format standards.
 * @property {RegExp} streetName - Matches street names with Unicode letters (`\p{L}`), digits, spaces, and special characters like dots, apostrophes, and hyphens, up to 250 characters.
 * @property {RegExp} houseNumber - Matches house numbers with optional letters, digits, and separators (spaces, hyphens, etc.), ensuring at least one digit.
 * @property {RegExp} address - Matches valid addresses containing Unicode letters (`\p{L}`), digits, spaces, and various punctuation characters, up to 250 characters.
 * @property {RegExp} drivingLicence - Matches driving license numbers containing Unicode letters (`\p{L}`), digits, and hyphens, up to 25 characters.
 * @property {RegExp} cityName - Matches city names containing Unicode letters (`\p{L}`), spaces, and various punctuation characters, up to 250 characters.
 * @property {RegExp} numberOnly - Matches numerical values with optional currency symbols, commas, and decimal points, ensuring at least one digit.
 */

export const regexPatterns: Record<string, RegExp> = {
  onlyLetters: /^[\p{Letter}]+$/u,
  lettersWithSpace: /^[\p{Letter}\s]+$/u,

  onlyDigits: /^\d+$/,
  lettersWithDigitsAndSpace: /^[\p{Letter}0-9\s]+$/u,

  postalCode: /^[\p{L}\p{N}/-]{1,10}$/u,
  dialCode: /^\+?[0-9]{1,7}$/, // Regular expression to allow only numbers and + as the first character
  name: /^(?=\S)[ \p{L}'-]{0,250}(?<=\S)/u,
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  streetName: /^(?=\S)[\p{L}]{1}[\d\p{L} './-]{0,249}(?<=\S)$/u,
  houseNumber: /^(?=\S)[1-9]\d*[ –—/-]?[a-zA-Z]{0,2}(?:[ ]?[–—/-][ ]?[1-9]\d*[ –—/-]?[a-zA-Z]{0,2})?(?<=\S)$/u,
  address: /$|^(?=\S)[\d\p{L} './,:;(){}[\]-]{0,250}(?<=\S)$/u,
  drivingLicence: /^[\p{L}\p{N}-]{1,25}$/u,

  cityName: /^(?=\S)[\p{L} './,:;()-]{0,250}(?<=\S)$/u,

  numberOnly: /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
};
