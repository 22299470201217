import type { TabsOwnProps } from '@mui/material';
import type { Theme } from '@mui/material/styles';

export const tabsStyle = (theme: Theme, orientation: TabsOwnProps['orientation']) => ({
  '& .MuiTab-root': {
    alignItems: orientation === 'vertical' ? 'flex-start' : 'center',
    margin: 0,
  },
  '& .Mui-selected': {
    fontWeight: 700,
  },
  '& .MuiTab-root:hover': {
    backgroundColor: theme.palette.secondary.tint,
  },
});

export const tabsContainerStyles = (theme: Theme, orientation: TabsOwnProps['orientation']) =>
  orientation === 'vertical'
    ? {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: 'initial',
      }
    : {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      };

export const tabsOrientationStyles = (theme: Theme, orientation: TabsOwnProps['orientation']) =>
  orientation === 'vertical'
    ? {
        display: 'flex',
        height: '100%',
        width: '100%',
        '& .MuiTabs-root': {
          width: '100%',
          alignItems: 'baseline',
        },
      }
    : {
        width: '100%',
      };

export const tabHoverStyle = (theme: Theme) => {
  return {
    '&:hover': {
      backgroundColor: theme.palette.secondary.tint,
    },
  };
};
