import { useEffect, useRef, useState } from 'react';

import L from 'leaflet';
import { useMap } from 'react-leaflet';

/**
 * Custom hook to create a Leaflet control and manage its lifecycle.
 * This hook adds a control to the map at the specified position and provides a reference
 * to the control's container and a flag indicating whether the control is mounted.
 *
 * @param {L.ControlPosition} position - The position on the map where the control should be added.
 * @returns {Object} An object containing:
 *   - `containerRef`: A reference to the control's container element.
 *   - `isMounted`: A boolean indicating whether the control has been mounted.
 */
function useLeafletControl(position: L.ControlPosition) {
  const map = useMap();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    // Create a new Leaflet control and set its position
    const control = new L.Control({ position });

    control.onAdd = () => {
      // Create a div element to serve as the container for the control
      const div = L.DomUtil.create('div');
      containerRef.current = div;
      setIsMounted(true); // Indicate that the control is mounted once the container is ready
      return div;
    };

    // Add the control to the map
    control.addTo(map);

    return () => {
      // Remove the control from the map when the component unmounts
      control.remove();
    };
  }, [map, position]);

  return { containerRef, isMounted };
}

export default useLeafletControl;
