export const myH3data10 = {
  '8a1fa9581047fff': 4,
  '8a1fa958104ffff': 5,
  '8a1fa9581057fff': 9,
  '8a1fa9581067fff': 10,
  '8a1fa958106ffff': 5,
  '8a1fa9581077fff': 3,
  '8a1fa95810c7fff': 1,
  '8a1fa95810cffff': 4,
  '8a1fa95810dffff': 3,
  '8a1fa95810e7fff': 2,
  '8a1fa95810effff': 3,
  '8a1fa958114ffff': 4,
  '8a1fa958115ffff': 2,
  '8a1fa958116ffff': 5,
  '8a1fa9581207fff': 6,
  '8a1fa958120ffff': 7,
  '8a1fa9581217fff': 4,
  '8a1fa958121ffff': 4,
  '8a1fa9581227fff': 6,
  '8a1fa958122ffff': 4,
  '8a1fa9581237fff': 6,
  '8a1fa9581247fff': 5,
  '8a1fa958124ffff': 5,
  '8a1fa9581257fff': 6,
  '8a1fa958125ffff': 10,
  '8a1fa9581267fff': 3,
  '8a1fa958126ffff': 4,
  '8a1fa9581277fff': 6,
  '8a1fa9581287fff': 3,
  '8a1fa958128ffff': 3,
  '8a1fa9581297fff': 4,
  '8a1fa958129ffff': 6,
  '8a1fa95812a7fff': 5,
  '8a1fa95812affff': 4,
  '8a1fa95812b7fff': 5,
  '8a1fa95812c7fff': 3,
  '8a1fa95812cffff': 6,
  '8a1fa95812d7fff': 7,
  '8a1fa95812dffff': 4,
  '8a1fa95812e7fff': 3,
  '8a1fa95812effff': 4,
  '8a1fa95812f7fff': 6,
  '8a1fa9581307fff': 7,
  '8a1fa958130ffff': 4,
  '8a1fa9581317fff': 4,
  '8a1fa958131ffff': 4,
  '8a1fa9581327fff': 2,
  '8a1fa958132ffff': 5,
  '8a1fa9581337fff': 7,
  '8a1fa9581347fff': 3,
  '8a1fa958134ffff': 4,
  '8a1fa9581357fff': 7,
  '8a1fa958135ffff': 8,
  '8a1fa9581367fff': 5,
  '8a1fa958136ffff': 5,
  '8a1fa9581377fff': 6,
  '8a1fa9581387fff': 4,
  '8a1fa958138ffff': 4,
  '8a1fa9581397fff': 2,
  '8a1fa958139ffff': 1,
  '8a1fa95813a7fff': 6,
  '8a1fa95813affff': 2,
  '8a1fa95813b7fff': 6,
  '8a1fa9581607fff': 3,
  '8a1fa958160ffff': 3,
  '8a1fa958161ffff': 2,
  '8a1fa9581627fff': 2,
  '8a1fa958162ffff': 4,
  '8a1fa9581647fff': 4,
  '8a1fa958164ffff': 7,
  '8a1fa9581657fff': 2,
  '8a1fa958165ffff': 3,
  '8a1fa9581667fff': 5,
  '8a1fa958166ffff': 5,
  '8a1fa9581677fff': 6,
  '8a1fa95816affff': 2,
  '8a1fa95816c7fff': 2,
  '8a1fa95816cffff': 7,
  '8a1fa95816d7fff': 2,
  '8a1fa95816dffff': 3,
  '8a1fa95816e7fff': 5,
  '8a1fa95816effff': 2,
  '8a1fa95816f7fff': 1,
  '8a1fa958170ffff': 3,
  '8a1fa9581747fff': 4,
  '8a1fa958174ffff': 6,
  '8a1fa9581757fff': 5,
  '8a1fa958175ffff': 3,
  '8a1fa9581767fff': 5,
  '8a1fa958176ffff': 1,
  '8a1fa9581777fff': 4,
  '8a1fa958184ffff': 3,
  '8a1fa9581a07fff': 3,
  '8a1fa9581a0ffff': 5,
  '8a1fa9581a17fff': 5,
  '8a1fa9581a1ffff': 5,
  '8a1fa9581a27fff': 6,
  '8a1fa9581a2ffff': 4,
  '8a1fa9581a37fff': 1,
  '8a1fa9581a47fff': 8,
  '8a1fa9581a4ffff': 4,
  '8a1fa9581a57fff': 5,
  '8a1fa9581a5ffff': 2,
  '8a1fa9581a67fff': 5,
  '8a1fa9581a6ffff': 6,
  '8a1fa9581a77fff': 5,
  '8a1fa9581a87fff': 3,
  '8a1fa9581a8ffff': 2,
  '8a1fa9581a97fff': 6,
  '8a1fa9581a9ffff': 4,
  '8a1fa9581aa7fff': 3,
  '8a1fa9581aaffff': 3,
  '8a1fa9581ab7fff': 6,
  '8a1fa9581ac7fff': 2,
  '8a1fa9581acffff': 6,
  '8a1fa9581ad7fff': 6,
  '8a1fa9581adffff': 4,
  '8a1fa9581ae7fff': 3,
  '8a1fa9581aeffff': 2,
  '8a1fa9581af7fff': 6,
  '8a1fa9581b17fff': 5,
  '8a1fa9581b1ffff': 3,
  '8a1fa9581b57fff': 4,
  '8a1fa9581b5ffff': 3,
  '8a1fa9581b87fff': 2,
  '8a1fa9581b8ffff': 5,
  '8a1fa9581b97fff': 6,
  '8a1fa9581b9ffff': 4,
  '8a1fa9581ba7fff': 4,
  '8a1fa9581baffff': 2,
  '8a1fa9581bb7fff': 3,
  '8a1fa958308ffff': 1,
  '8a1fa9583097fff': 4,
  '8a1fa958309ffff': 2,
  '8a1fa95830d7fff': 1,
  '8a1fa95830dffff': 1,
  '8a1fa9583207fff': 14,
  '8a1fa958320ffff': 6,
  '8a1fa9583217fff': 13,
  '8a1fa958321ffff': 13,
  '8a1fa9583227fff': 5,
  '8a1fa958322ffff': 11,
  '8a1fa9583237fff': 11,
  '8a1fa9583247fff': 5,
  '8a1fa9583257fff': 11,
  '8a1fa958325ffff': 5,
  '8a1fa9583267fff': 12,
  '8a1fa9583277fff': 5,
  '8a1fa95832a7fff': 1,
  '8a1fa95832b7fff': 1,
  '8a1fa95832e7fff': 7,
  '8a1fa95832effff': 4,
  '8a1fa9583307fff': 10,
  '8a1fa958330ffff': 8,
  '8a1fa9583317fff': 8,
  '8a1fa958331ffff': 9,
  '8a1fa9583327fff': 5,
  '8a1fa958332ffff': 7,
  '8a1fa9583337fff': 4,
  '8a1fa9583347fff': 7,
  '8a1fa958334ffff': 8,
  '8a1fa9583357fff': 11,
  '8a1fa958335ffff': 8,
  '8a1fa9583367fff': 7,
  '8a1fa958336ffff': 11,
  '8a1fa9583377fff': 12,
  '8a1fa9583387fff': 1,
  '8a1fa958338ffff': 10,
  '8a1fa9583397fff': 2,
  '8a1fa958339ffff': 4,
  '8a1fa95833affff': 7,
  '8a1fa9583407fff': 3,
  '8a1fa958340ffff': 2,
  '8a1fa9583417fff': 5,
  '8a1fa958341ffff': 3,
  '8a1fa958342ffff': 2,
  '8a1fa9583437fff': 5,
  '8a1fa9583447fff': 4,
  '8a1fa958344ffff': 3,
  '8a1fa9583457fff': 1,
  '8a1fa958345ffff': 2,
  '8a1fa9583467fff': 2,
  '8a1fa958346ffff': 5,
  '8a1fa9583477fff': 2,
  '8a1fa9583487fff': 2,
  '8a1fa9583497fff': 1,
  '8a1fa958349ffff': 5,
  '8a1fa95834a7fff': 1,
  '8a1fa95834affff': 3,
  '8a1fa95834c7fff': 3,
  '8a1fa95834cffff': 3,
  '8a1fa95834d7fff': 4,
  '8a1fa95834dffff': 6,
  '8a1fa95834effff': 1,
  '8a1fa95834f7fff': 2,
  '8a1fa9583507fff': 1,
  '8a1fa958350ffff': 2,
  '8a1fa958351ffff': 1,
  '8a1fa958352ffff': 1,
  '8a1fa958354ffff': 2,
  '8a1fa9583557fff': 1,
  '8a1fa958355ffff': 2,
  '8a1fa9583577fff': 2,
  '8a1fa9583607fff': 2,
  '8a1fa958360ffff': 3,
  '8a1fa9583617fff': 2,
  '8a1fa958361ffff': 3,
  '8a1fa9583627fff': 3,
  '8a1fa958362ffff': 3,
  '8a1fa9583637fff': 3,
  '8a1fa9583657fff': 5,
  '8a1fa9583667fff': 3,
  '8a1fa9583677fff': 1,
  '8a1fa9583687fff': 5,
  '8a1fa958368ffff': 2,
  '8a1fa9583697fff': 2,
  '8a1fa958369ffff': 5,
  '8a1fa95836a7fff': 3,
  '8a1fa95836affff': 4,
  '8a1fa95836b7fff': 3,
  '8a1fa95836c7fff': 2,
  '8a1fa95836cffff': 1,
  '8a1fa95836d7fff': 2,
  '8a1fa95836dffff': 2,
  '8a1fa95836e7fff': 4,
  '8a1fa95836f7fff': 3,
  '8a1fa9583707fff': 3,
  '8a1fa958370ffff': 1,
  '8a1fa9583717fff': 1,
  '8a1fa958371ffff': 2,
  '8a1fa9583727fff': 2,
  '8a1fa9583737fff': 2,
  '8a1fa9583747fff': 1,
  '8a1fa958374ffff': 2,
  '8a1fa9583757fff': 3,
  '8a1fa958375ffff': 3,
  '8a1fa9583767fff': 2,
  '8a1fa958376ffff': 4,
  '8a1fa9583777fff': 1,
  '8a1fa9583787fff': 5,
  '8a1fa958378ffff': 1,
  '8a1fa9583797fff': 6,
  '8a1fa95837a7fff': 1,
  '8a1fa95837affff': 1,
  '8a1fa95837b7fff': 5,
  '8a1fa9583a07fff': 8,
  '8a1fa9583a0ffff': 8,
  '8a1fa9583a17fff': 6,
  '8a1fa9583a1ffff': 7,
  '8a1fa9583a27fff': 7,
  '8a1fa9583a2ffff': 6,
  '8a1fa9583a37fff': 7,
  '8a1fa9583a47fff': 8,
  '8a1fa9583a4ffff': 7,
  '8a1fa9583a57fff': 17,
  '8a1fa9583a5ffff': 5,
  '8a1fa9583a6ffff': 8,
  '8a1fa9583a77fff': 12,
  '8a1fa9583a8ffff': 5,
  '8a1fa9583a9ffff': 5,
  '8a1fa9583aaffff': 6,
  '8a1fa9583ac7fff': 14,
  '8a1fa9583acffff': 4,
  '8a1fa9583ad7fff': 7,
  '8a1fa9583adffff': 6,
  '8a1fa9583ae7fff': 9,
  '8a1fa9583aeffff': 11,
  '8a1fa9583af7fff': 5,
  '8a1fa958c017fff': 7,
  '8a1fa958c027fff': 1,
  '8a1fa958c037fff': 9,
  '8a1fa958c087fff': 6,
  '8a1fa958c097fff': 8,
  '8a1fa958c09ffff': 3,
  '8a1fa958c0a7fff': 8,
  '8a1fa958c0b7fff': 3,
  '8a1fa958c107fff': 13,
  '8a1fa958c10ffff': 2,
  '8a1fa958c117fff': 10,
  '8a1fa958c11ffff': 9,
  '8a1fa958c127fff': 9,
  '8a1fa958c12ffff': 4,
  '8a1fa958c137fff': 4,
  '8a1fa958c187fff': 10,
  '8a1fa958c18ffff': 5,
  '8a1fa958c197fff': 9,
  '8a1fa958c19ffff': 8,
  '8a1fa958c1a7fff': 8,
  '8a1fa958c1affff': 14,
  '8a1fa958c1b7fff': 13,
  '8a1fa958c427fff': 5,
  '8a1fa958c42ffff': 5,
  '8a1fa958c437fff': 2,
  '8a1fa958c467fff': 5,
  '8a1fa958c46ffff': 16,
  '8a1fa958c477fff': 2,
  '8a1fa958c487fff': 1,
  '8a1fa958c497fff': 2,
  '8a1fa958c49ffff': 1,
  '8a1fa958c4a7fff': 1,
  '8a1fa958c4b7fff': 5,
  '8a1fa958c507fff': 6,
  '8a1fa958c50ffff': 7,
  '8a1fa958c517fff': 2,
  '8a1fa958c51ffff': 6,
  '8a1fa958c527fff': 4,
  '8a1fa958c52ffff': 11,
  '8a1fa958c537fff': 4,
  '8a1fa958c547fff': 8,
  '8a1fa958c54ffff': 13,
  '8a1fa958c557fff': 9,
  '8a1fa958c55ffff': 9,
  '8a1fa958c567fff': 9,
  '8a1fa958c56ffff': 6,
  '8a1fa958c577fff': 17,
  '8a1fa958c587fff': 3,
  '8a1fa958c58ffff': 5,
  '8a1fa958c597fff': 2,
  '8a1fa958c59ffff': 6,
  '8a1fa958c5a7fff': 5,
  '8a1fa958c5affff': 3,
  '8a1fa958c5b7fff': 7,
  '8a1fa958c737fff': 5,
  '8a1fa958c887fff': 2,
  '8a1fa958c88ffff': 7,
  '8a1fa958c897fff': 8,
  '8a1fa958c89ffff': 10,
  '8a1fa958c8b7fff': 7,
  '8a1fa958c8c7fff': 1,
  '8a1fa958c8d7fff': 8,
  '8a1fa958c8f7fff': 3,
  '8a1fa958cc07fff': 3,
  '8a1fa958cc0ffff': 11,
  '8a1fa958cc17fff': 4,
  '8a1fa958cc1ffff': 1,
  '8a1fa958cc27fff': 3,
  '8a1fa958cc2ffff': 14,
  '8a1fa958cc37fff': 3,
  '8a1fa958cc47fff': 7,
  '8a1fa958cc4ffff': 8,
  '8a1fa958cc57fff': 9,
  '8a1fa958cc5ffff': 7,
  '8a1fa958cc67fff': 7,
  '8a1fa958cc6ffff': 9,
  '8a1fa958cc77fff': 9,
  '8a1fa958cc87fff': 3,
  '8a1fa958cc8ffff': 7,
  '8a1fa958cc97fff': 7,
  '8a1fa958cc9ffff': 5,
  '8a1fa958cca7fff': 4,
  '8a1fa958ccaffff': 3,
  '8a1fa958ccb7fff': 6,
  '8a1fa958ccc7fff': 2,
  '8a1fa958cccffff': 8,
  '8a1fa958ccd7fff': 6,
  '8a1fa958ccdffff': 9,
  '8a1fa958cce7fff': 8,
  '8a1fa958cceffff': 10,
  '8a1fa958ccf7fff': 5,
  '8a1fa958cd07fff': 4,
  '8a1fa958cd0ffff': 2,
  '8a1fa958cd17fff': 7,
  '8a1fa958cd1ffff': 6,
  '8a1fa958cd27fff': 4,
  '8a1fa958cd2ffff': 6,
  '8a1fa958cd37fff': 7,
  '8a1fa958cd47fff': 11,
  '8a1fa958cd4ffff': 13,
  '8a1fa958cd57fff': 6,
  '8a1fa958cd5ffff': 11,
  '8a1fa958cd67fff': 2,
  '8a1fa958cd6ffff': 3,
  '8a1fa958cd77fff': 6,
  '8a1fa958cd87fff': 5,
  '8a1fa958cd8ffff': 6,
  '8a1fa958cd97fff': 2,
  '8a1fa958cd9ffff': 5,
  '8a1fa958cda7fff': 6,
  '8a1fa958cdaffff': 2,
  '8a1fa958cdb7fff': 6,
  '8a1fa958e5b7fff': 2,
  '8a1fa958e807fff': 3,
  '8a1fa958e80ffff': 1,
  '8a1fa958e817fff': 2,
  '8a1fa958e81ffff': 3,
  '8a1fa958e827fff': 3,
  '8a1fa958e82ffff': 2,
  '8a1fa958e837fff': 6,
  '8a1fa958e847fff': 4,
  '8a1fa958e84ffff': 3,
  '8a1fa958e857fff': 6,
  '8a1fa958e85ffff': 3,
  '8a1fa958e867fff': 3,
  '8a1fa958e86ffff': 4,
  '8a1fa958e877fff': 5,
  '8a1fa958e887fff': 3,
  '8a1fa958e88ffff': 4,
  '8a1fa958e897fff': 1,
  '8a1fa958e8a7fff': 8,
  '8a1fa958e8affff': 7,
  '8a1fa958e8b7fff': 4,
  '8a1fa958e8c7fff': 3,
  '8a1fa958e8cffff': 1,
  '8a1fa958e8d7fff': 3,
  '8a1fa958e8dffff': 3,
  '8a1fa958e8e7fff': 3,
  '8a1fa958e8effff': 2,
  '8a1fa958e8f7fff': 9,
  '8a1fa958e907fff': 5,
  '8a1fa958e90ffff': 3,
  '8a1fa958e917fff': 5,
  '8a1fa958e91ffff': 5,
  '8a1fa958e927fff': 7,
  '8a1fa958e92ffff': 4,
  '8a1fa958e937fff': 3,
  '8a1fa958e947fff': 5,
  '8a1fa958e94ffff': 4,
  '8a1fa958e957fff': 5,
  '8a1fa958e95ffff': 6,
  '8a1fa958e967fff': 5,
  '8a1fa958e96ffff': 6,
  '8a1fa958e977fff': 2,
  '8a1fa958e987fff': 7,
  '8a1fa958e98ffff': 3,
  '8a1fa958e997fff': 4,
  '8a1fa958e99ffff': 506,
  '8a1fa958e9a7fff': 7,
  '8a1fa958e9affff': 2,
  '8a1fa958e9b7fff': 4,
  '8a1fa958eb97fff': 10,
  '8a1fa958ebb7fff': 3,
  '8a1fa958ec97fff': 4,
  '8a1fa958ecb7fff': 2,
  '8a1fa958ed17fff': 3,
  '8a1fa958ed27fff': 1,
  '8a1fa958ed2ffff': 7,
  '8a1fa958ed37fff': 4,
  '8a1fa958ed47fff': 2,
  '8a1fa958ed67fff': 4,
  '8a1fa958ed6ffff': 8,
  '8a1fa958ed77fff': 5,
  '8a1fa958ed87fff': 8,
  '8a1fa958ed97fff': 10,
  '8a1fa958ed9ffff': 5,
  '8a1fa958eda7fff': 13,
  '8a1fa958edaffff': 2,
  '8a1fa958edb7fff': 9,
  '8a1fa9598897fff': 2,
  '8a1fa95988a7fff': 6,
  '8a1fa95988b7fff': 8,
  '8a1fa9598907fff': 3,
  '8a1fa9598917fff': 12,
  '8a1fa959891ffff': 1,
  '8a1fa9598927fff': 8,
  '8a1fa9598937fff': 11,
  '8a1fa9598987fff': 10,
  '8a1fa959898ffff': 5,
  '8a1fa9598997fff': 2,
  '8a1fa959899ffff': 6,
  '8a1fa95989a7fff': 11,
  '8a1fa95989affff': 9,
  '8a1fa95989b7fff': 10,
  '8a1fa9598c27fff': 1,
  '8a1fa9598c2ffff': 2,
  '8a1fa9598c67fff': 8,
  '8a1fa9598c6ffff': 6,
  '8a1fa9598c77fff': 2,
  '8a1fa9598d07fff': 9,
  '8a1fa9598d0ffff': 7,
  '8a1fa9598d17fff': 7,
  '8a1fa9598d1ffff': 12,
  '8a1fa9598d27fff': 4,
  '8a1fa9598d2ffff': 9,
  '8a1fa9598d37fff': 7,
  '8a1fa9598d47fff': 11,
  '8a1fa9598d4ffff': 3,
  '8a1fa9598d57fff': 6,
  '8a1fa9598d5ffff': 9,
  '8a1fa9598d67fff': 10,
  '8a1fa9598d6ffff': 8,
  '8a1fa9598d77fff': 8,
  '8a1fa9598da7fff': 3,
  '8a1fa959c007fff': 8,
  '8a1fa959c00ffff': 9,
  '8a1fa959c017fff': 10,
  '8a1fa959c01ffff': 3,
  '8a1fa959c027fff': 5,
  '8a1fa959c02ffff': 9,
  '8a1fa959c037fff': 13,
  '8a1fa959c047fff': 12,
  '8a1fa959c04ffff': 7,
  '8a1fa959c057fff': 3,
  '8a1fa959c05ffff': 13,
  '8a1fa959c067fff': 12,
  '8a1fa959c06ffff': 11,
  '8a1fa959c077fff': 6,
  '8a1fa959c087fff': 7,
  '8a1fa959c08ffff': 6,
  '8a1fa959c097fff': 12,
  '8a1fa959c09ffff': 11,
  '8a1fa959c0a7fff': 13,
  '8a1fa959c0affff': 7,
  '8a1fa959c0b7fff': 8,
  '8a1fa959c0c7fff': 10,
  '8a1fa959c0cffff': 13,
  '8a1fa959c0d7fff': 6,
  '8a1fa959c0dffff': 11,
  '8a1fa959c0e7fff': 10,
  '8a1fa959c0effff': 13,
  '8a1fa959c0f7fff': 10,
  '8a1fa959c107fff': 3,
  '8a1fa959c10ffff': 10,
  '8a1fa959c117fff': 8,
  '8a1fa959c11ffff': 4,
  '8a1fa959c127fff': 13,
  '8a1fa959c12ffff': 13,
  '8a1fa959c137fff': 10,
  '8a1fa959c147fff': 9,
  '8a1fa959c14ffff': 12,
  '8a1fa959c157fff': 11,
  '8a1fa959c15ffff': 9,
  '8a1fa959c167fff': 12,
  '8a1fa959c16ffff': 13,
  '8a1fa959c177fff': 8,
  '8a1fa959c187fff': 6,
  '8a1fa959c18ffff': 9,
  '8a1fa959c197fff': 11,
  '8a1fa959c19ffff': 9,
  '8a1fa959c1a7fff': 5,
  '8a1fa959c1affff': 10,
  '8a1fa959c1b7fff': 9,
  '8a1fa959c207fff': 5,
  '8a1fa959c20ffff': 1,
  '8a1fa959c217fff': 11,
  '8a1fa959c21ffff': 6,
  '8a1fa959c227fff': 13,
  '8a1fa959c22ffff': 2,
  '8a1fa959c237fff': 17,
  '8a1fa959c247fff': 1,
  '8a1fa959c24ffff': 2,
  '8a1fa959c257fff': 1,
  '8a1fa959c25ffff': 2,
  '8a1fa959c267fff': 3,
  '8a1fa959c26ffff': 3,
  '8a1fa959c277fff': 2,
  '8a1fa959c287fff': 10,
  '8a1fa959c28ffff': 6,
  '8a1fa959c297fff': 9,
  '8a1fa959c29ffff': 4,
  '8a1fa959c2a7fff': 4,
  '8a1fa959c2affff': 10,
  '8a1fa959c2b7fff': 9,
  '8a1fa959c2cffff': 1,
  '8a1fa959c2e7fff': 4,
  '8a1fa959c2effff': 3,
  '8a1fa959c2f7fff': 1,
  '8a1fa959c307fff': 17,
  '8a1fa959c30ffff': 12,
  '8a1fa959c317fff': 11,
  '8a1fa959c31ffff': 16,
  '8a1fa959c327fff': 6,
  '8a1fa959c32ffff': 6,
  '8a1fa959c337fff': 9,
  '8a1fa959c34ffff': 5,
  '8a1fa959c357fff': 2,
  '8a1fa959c35ffff': 1,
  '8a1fa959c367fff': 2,
  '8a1fa959c36ffff': 2,
  '8a1fa959c377fff': 4,
  '8a1fa959c387fff': 13,
  '8a1fa959c38ffff': 9,
  '8a1fa959c397fff': 12,
  '8a1fa959c39ffff': 11,
  '8a1fa959c3a7fff': 6,
  '8a1fa959c3affff': 9,
  '8a1fa959c3b7fff': 14,
  '8a1fa959c40ffff': 6,
  '8a1fa959c42ffff': 3,
  '8a1fa959c447fff': 9,
  '8a1fa959c44ffff': 8,
  '8a1fa959c457fff': 6,
  '8a1fa959c45ffff': 10,
  '8a1fa959c467fff': 8,
  '8a1fa959c46ffff': 13,
  '8a1fa959c477fff': 7,
  '8a1fa959c4c7fff': 8,
  '8a1fa959c4cffff': 14,
  '8a1fa959c4d7fff': 2,
  '8a1fa959c4dffff': 17,
  '8a1fa959c4e7fff': 7,
  '8a1fa959c4effff': 14,
  '8a1fa959c4f7fff': 2,
  '8a1fa959c547fff': 11,
  '8a1fa959c54ffff': 7,
  '8a1fa959c55ffff': 4,
  '8a1fa959c567fff': 3,
  '8a1fa959c56ffff': 6,
  '8a1fa959c607fff': 10,
  '8a1fa959c60ffff': 10,
  '8a1fa959c617fff': 9,
  '8a1fa959c61ffff': 5,
  '8a1fa959c627fff': 10,
  '8a1fa959c62ffff': 6,
  '8a1fa959c637fff': 9,
  '8a1fa959c647fff': 10,
  '8a1fa959c64ffff': 8,
  '8a1fa959c657fff': 4,
  '8a1fa959c65ffff': 1,
  '8a1fa959c667fff': 9,
  '8a1fa959c66ffff': 10,
  '8a1fa959c677fff': 6,
  '8a1fa959c687fff': 7,
  '8a1fa959c68ffff': 5,
  '8a1fa959c697fff': 10,
  '8a1fa959c69ffff': 8,
  '8a1fa959c6a7fff': 10,
  '8a1fa959c6affff': 11,
  '8a1fa959c6b7fff': 11,
  '8a1fa959c6c7fff': 6,
  '8a1fa959c6cffff': 11,
  '8a1fa959c6d7fff': 5,
  '8a1fa959c6dffff': 9,
  '8a1fa959c6e7fff': 5,
  '8a1fa959c6effff': 10,
  '8a1fa959c6f7fff': 12,
  '8a1fa959c707fff': 8,
  '8a1fa959c70ffff': 12,
  '8a1fa959c717fff': 9,
  '8a1fa959c71ffff': 8,
  '8a1fa959c727fff': 6,
  '8a1fa959c72ffff': 7,
  '8a1fa959c737fff': 10,
  '8a1fa959c747fff': 8,
  '8a1fa959c74ffff': 14,
  '8a1fa959c757fff': 9,
  '8a1fa959c75ffff': 7,
  '8a1fa959c767fff': 11,
  '8a1fa959c76ffff': 7,
  '8a1fa959c777fff': 16,
  '8a1fa959c787fff': 7,
  '8a1fa959c78ffff': 6,
  '8a1fa959c797fff': 11,
  '8a1fa959c79ffff': 9,
  '8a1fa959c7a7fff': 6,
  '8a1fa959c7affff': 7,
  '8a1fa959c7b7fff': 6,
  '8a1fa959c81ffff': 4,
  '8a1fa959c84ffff': 3,
  '8a1fa959c86ffff': 1,
  '8a1fa959c887fff': 10,
  '8a1fa959c88ffff': 15,
  '8a1fa959c897fff': 5,
  '8a1fa959c89ffff': 10,
  '8a1fa959c8a7fff': 4,
  '8a1fa959c8affff': 13,
  '8a1fa959c8b7fff': 9,
  '8a1fa959c8c7fff': 6,
  '8a1fa959c8cffff': 9,
  '8a1fa959c8d7fff': 10,
  '8a1fa959c8dffff': 16,
  '8a1fa959c8e7fff': 2,
  '8a1fa959c8effff': 8,
  '8a1fa959c8f7fff': 7,
  '8a1fa959c99ffff': 2,
  '8a1fa959ca07fff': 9,
  '8a1fa959ca0ffff': 8,
  '8a1fa959ca17fff': 12,
  '8a1fa959ca1ffff': 10,
  '8a1fa959ca27fff': 1,
  '8a1fa959ca2ffff': 2,
  '8a1fa959ca37fff': 6,
  '8a1fa959ca47fff': 1,
  '8a1fa959ca4ffff': 1,
  '8a1fa959ca57fff': 8,
  '8a1fa959ca5ffff': 2,
  '8a1fa959ca67fff': 1,
  '8a1fa959ca6ffff': 3,
  '8a1fa959ca77fff': 2,
  '8a1fa959ca87fff': 13,
  '8a1fa959ca8ffff': 13,
  '8a1fa959ca97fff': 10,
  '8a1fa959ca9ffff': 8,
  '8a1fa959caa7fff': 8,
  '8a1fa959caaffff': 11,
  '8a1fa959cab7fff': 11,
  '8a1fa959cac7fff': 13,
  '8a1fa959cacffff': 5,
  '8a1fa959cad7fff': 10,
  '8a1fa959cadffff': 9,
  '8a1fa959cae7fff': 8,
  '8a1fa959caeffff': 6,
  '8a1fa959caf7fff': 11,
  '8a1fa959cb07fff': 1,
  '8a1fa959cb0ffff': 1,
  '8a1fa959cb17fff': 2,
  '8a1fa959cb1ffff': 2,
  '8a1fa959cb27fff': 1,
  '8a1fa959cb2ffff': 3,
  '8a1fa959cb37fff': 1,
  '8a1fa959cb4ffff': 4,
  '8a1fa959cb57fff': 3,
  '8a1fa959cb5ffff': 4,
  '8a1fa959cb6ffff': 2,
  '8a1fa959cb87fff': 12,
  '8a1fa959cb8ffff': 11,
  '8a1fa959cb97fff': 13,
  '8a1fa959cb9ffff': 10,
  '8a1fa959cba7fff': 1,
  '8a1fa959cbaffff': 5,
  '8a1fa959cbb7fff': 3,
  '8a1fa959cc47fff': 7,
  '8a1fa959cc4ffff': 10,
  '8a1fa959cc5ffff': 8,
  '8a1fa959cc67fff': 1,
  '8a1fa959cc6ffff': 10,
  '8a1fa959cceffff': 2,
  '8a1fa959cd4ffff': 1,
  '8a1fa959d507fff': 2,
  '8a1fa959d517fff': 1,
  '8a1fa959d51ffff': 1,
  '8a1fa959d527fff': 2,
  '8a1fa959d537fff': 5,
  '8a1fa959d587fff': 1,
  '8a1fa959d5a7fff': 2,
  '8a1fa959d5affff': 1,
  '8a1fa959d5b7fff': 3,
  '8a1fa959dc07fff': 3,
  '8a1fa959dc17fff': 1,
  '8a1fa959dc1ffff': 2,
  '8a1fa959dc27fff': 2,
  '8a1fa959dc2ffff': 2,
  '8a1fa959dc37fff': 2,
  '8a1fa959dc87fff': 2,
  '8a1fa959dc8ffff': 2,
  '8a1fa959dc97fff': 2,
  '8a1fa959dc9ffff': 5,
  '8a1fa959dca7fff': 6,
  '8a1fa959dcaffff': 1,
  '8a1fa959dcb7fff': 1,
  '8a1fa959dcd7fff': 1,
  '8a1fa959dcf7fff': 1,
  '8a1fa959dd07fff': 2,
  '8a1fa959dd0ffff': 4,
  '8a1fa959dd17fff': 3,
  '8a1fa959dd1ffff': 1,
  '8a1fa959dd27fff': 1,
  '8a1fa959dd2ffff': 3,
  '8a1fa959dd37fff': 2,
  '8a1fa959dd57fff': 2,
  '8a1fa959dd67fff': 1,
  '8a1fa959dd77fff': 2,
  '8a1fa959dd87fff': 4,
  '8a1fa959dd8ffff': 3,
  '8a1fa959dd97fff': 2,
  '8a1fa959dd9ffff': 2,
  '8a1fa959dda7fff': 6,
  '8a1fa959ddaffff': 4,
  '8a1fa959ea07fff': 11,
  '8a1fa959ea0ffff': 9,
  '8a1fa959ea17fff': 1,
  '8a1fa959ea1ffff': 1,
  '8a1fa959ea27fff': 6,
  '8a1fa959ea2ffff': 10,
  '8a1fa959ea37fff': 5,
  '8a1fa959ea47fff': 9,
  '8a1fa959ea4ffff': 9,
  '8a1fa959ea57fff': 14,
  '8a1fa959ea5ffff': 6,
  '8a1fa959ea67fff': 16,
  '8a1fa959ea6ffff': 6,
  '8a1fa959ea77fff': 5,
  '8a1fa959eb0ffff': 9,
  '8a1fa959eb1ffff': 1,
  '8a1fa959eb2ffff': 8,
  '8a1fa959eb47fff': 7,
  '8a1fa959eb4ffff': 10,
  '8a1fa959eb57fff': 10,
  '8a1fa959eb5ffff': 11,
  '8a1fa959eb67fff': 6,
  '8a1fa959eb6ffff': 10,
  '8a1fa959eb77fff': 6,
  '8a1fa95aa697fff': 1,
  '8a1fa95aa69ffff': 2,
  '8a1fa95aa6dffff': 1,
};
