import type { ReactNode } from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { styled, useTheme } from '@mui/material/styles';
import { Form, Formik } from 'formik';
import type { FormikValues } from 'formik';
import * as Yup from 'yup';

import { SearchAndResetButtonContainer } from '../../wmv-components';
import { FormFieldWithLabelInfo, FormFieldWithLabel } from '../FormFields/FormFieldWithLabel';

export const StyledFormContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  // padding: theme.spacing(3),
  boxShadow: theme.shadowOptions.belowSmall,
  borderBottom: `1px solid ${theme.palette.dark.shade8}`,
}));

export interface CustomerDatabaseFormProps<S extends Yup.AnyObject, T> {
  onSearch: (params: T) => void;
  validationSchema: Yup.ObjectSchema<S>;
  initialValues: FormikValues & T;
  formFields: FormFieldWithLabelInfo[];
  loading: boolean;
}

export const FieldsContainer = ({ children }: { children: ReactNode }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        pt: { xs: theme.spacing(1), lg: theme.spacing(4) },
        pb: { xs: theme.spacing(1), lg: theme.spacing(1.5) },
      }}
    >
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={3} alignItems={{ xs: 'left', md: 'center' }} minWidth={'200px'}>
        <Divider orientation="vertical" flexItem sx={{ display: { sm: 'none', md: 'block' }, m: 0, height: theme.spacing(5) }}></Divider>
        {children}
      </Stack>
    </Box>
  );
};

export const SearchForm = <S extends Yup.AnyObject, T>(props: CustomerDatabaseFormProps<S, T>) => {
  const { onSearch, initialValues, validationSchema, formFields, loading } = props;
  const theme = useTheme();

  return (
    <StyledFormContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values: FormikValues & T) => {
          onSearch(values);
        }}
        validateOnMount={true}
      >
        {({ isValid, dirty, errors, touched }) => (
          <Form>
            <FieldsContainer>
              {formFields.map(({ name, label, type, isMandatory, format }) => (
                <FormFieldWithLabel
                  key={name}
                  name={name}
                  label={label}
                  type={type}
                  isMandatory={isMandatory}
                  error={!!(touched[name] && errors[name])}
                  format={format}
                />
              ))}
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  marginRight: theme.spacing(3),
                  marginLeft: theme.spacing(3),
                  display: { sm: 'none', md: 'block' },
                  height: theme.spacing(5),
                }}
              ></Divider>
              <SearchAndResetButtonContainer isValid={isValid} dirty={dirty} loading={loading} />
            </FieldsContainer>
          </Form>
        )}
      </Formik>
    </StyledFormContainer>
  );
};
