import { atom } from 'jotai';

import { BudgetPlanInformationTab } from '../../../../models';
import { BudgetPlanDto } from '../../../../services/budget-plan';
import { BudgetPlanMembershipDto } from '../../../../services/budget-plan-memberships';
import { BudgetPlanMembershipSearchDto } from '../../../../services/budget-plan-memberships-search';

export enum REQUEST_STATUS {
  IDLE = 'idle',
  PENDING = 'pending',
  SUCCESS = 'success',
  FAILURE = 'failure',
}

export enum BP_ACTION_MODE {
  VIEW = 'view',
  EDIT = 'edit',
  ADD = 'add',
}

// Budget Plan
export const budgetPlansAtom = atom<BudgetPlanDto[]>([]);
export const budgetPlansFetchStatusAtom = atom<REQUEST_STATUS>(REQUEST_STATUS.IDLE);
export const selectedPlanAtom = atom<BudgetPlanDto | null>(null);
export const budgetPlanModalVisibleAtom = atom<boolean>(false);
export const budgetPlanActionModeAtom = atom<BP_ACTION_MODE>(BP_ACTION_MODE.VIEW);
export const budgetPlanModalActiveTabAtom = atom<BudgetPlanInformationTab>(BudgetPlanInformationTab.PlanDetails);

// Budget Plan Details
export const bpDetailsSubmitStatusAtom = atom<REQUEST_STATUS>(REQUEST_STATUS.IDLE);
export const bpDetailsErrorMsgAtom = atom<string>('');

// BM Modal
export const bmModalSearchStringAtom = atom<string>('');
export const bmSelectedMembershipIdAtom = atom<number | null>(null);
export const bmSelectedMembershipAtom = atom<BudgetPlanMembershipDto | null>(null);

// BM Link Modal
export const bmLinkModalVisibleAtom = atom<boolean>(false);
export const bmLinkModalSearchStringAtom = atom<string>('');
export const bmUserToLinkAtom = atom<BudgetPlanMembershipSearchDto | null>(null);
export const bmLinkModalSearchStatusAtom = atom<REQUEST_STATUS>(REQUEST_STATUS.IDLE);
export const bmLinkUserStatusAtom = atom<REQUEST_STATUS>(REQUEST_STATUS.IDLE);
export const retryStateTransitionApiStatusAtom = atom<REQUEST_STATUS>(REQUEST_STATUS.IDLE);
export const discardFailedStateTransitionApiStatusAtom = atom<REQUEST_STATUS>(REQUEST_STATUS.IDLE);

// BM Unlink Modal
export const bmUnlinkModalVisibleAtom = atom<boolean>(false);
