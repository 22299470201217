import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import type { ColumnDef, Row } from '@tanstack/react-table';

import { themeOptions } from '../../../init-setup/ThemeOptions';
import { CustomerTrip, PaymentStatus, PaymentStatusHelper, TripStatus } from '../../../models';
import { formatDistanceFromMeters } from '../../../utils/DateMethods';
import { DotIndicator } from '../../DotIndicator';

const { palette } = themeOptions;

export const customerTripsTableColumDef: ColumnDef<CustomerTrip>[] = [
  {
    header: 'informationTable.header.tripId'.i18nText(),
    accessorFn: (originalRow: CustomerTrip) => 'customerTable.heading.id'.i18nText() + originalRow.id.toString(),
    maxSize: 128,
  },
  {
    header: 'informationTable.header.startEpochMillis'.i18nText(),
    accessorFn: (originalRow: CustomerTrip) => originalRow.duration.startedAt.parseEpochMillis().formatAsDateTimeString(),
    maxSize: 128,
  },
  {
    header: 'informationTable.header.endEpochMillis'.i18nText(),
    accessorFn: (originalRow: CustomerTrip) => {
      if (originalRow.status === TripStatus.ForceStopped || originalRow.status === TripStatus.Stopped)
        return originalRow.duration!.finishedAt?.parseEpochMillis()?.formatAsDateTimeString();
      return 'common.dash'.i18nText();
    },
    maxSize: 128,
  },
  {
    header: 'informationTable.header.actualDurationSeconds'.i18nText(),
    accessorFn: (originalRow: CustomerTrip) => {
      if (originalRow.status === TripStatus.ForceStopped || originalRow.status === TripStatus.Stopped)
        return ((originalRow.duration?.finishedAt! - originalRow.duration?.startedAt) / 1000).formatSecondsAsDuration();
      return 'common.dash'.i18nText();
    },
    maxSize: 128,
  },
  {
    header: 'informationTable.header.totalDistanceMeters'.i18nText(),
    accessorFn: (originalRow: CustomerTrip) => {
      if (originalRow.status === TripStatus.ForceStopped || originalRow.status === TripStatus.Stopped)
        return formatDistanceFromMeters(originalRow.distanceMeters!);
      return 'common.dash'.i18nText();
    },
    maxSize: 128,
  },
  {
    header: 'common.paymentStatus'.i18nText(),
    accessorFn: (originalRow: CustomerTrip) =>
      PaymentStatusHelper.metadata(originalRow.payment?.status || PaymentStatus.NotPaid).displayText,
    cell: ({ row }: { row: Row<CustomerTrip> }) => {
      const paymentStatus = row.original.payment?.status;
      const textColor = !paymentStatus
        ? palette.baseLight.main
        : paymentStatus === PaymentStatus.NotPaid
        ? palette.alert.main
        : palette.baseLight.main;
      return (
        <Typography color={textColor}>
          {row.original.status === TripStatus.ForceStopped || (row.original.status === TripStatus.Stopped && paymentStatus)
            ? PaymentStatusHelper.metadata(paymentStatus!).displayText
            : 'common.dash'.i18nText()}
        </Typography>
      );
    },
    maxSize: 128,
  },
  {
    header: 'common.status'.i18nText(),
    accessorFn: (originalRow: CustomerTrip) => {
      return originalRow.status === TripStatus.ForceStopped || originalRow.status === TripStatus.Stopped
        ? 'customerTable.row.completed'.i18nText()
        : 'customerTable.row.active'.i18nText();
    },
    cell: ({ row }: { row: Row<CustomerTrip> }) => {
      return (
        <Stack direction="row" spacing={1} alignItems="center" display="inline-flex">
          <DotIndicator
            color={
              row.original.status === TripStatus.ForceStopped || row.original.status === TripStatus.Stopped
                ? palette.success.main
                : palette.warning.main
            }
          />
          <Typography variant="bodyMedium" color={palette.baseLight.main} component="div">
            {row.original.status === TripStatus.ForceStopped || row.original.status === TripStatus.Stopped
              ? 'customerTable.row.completed'.i18nText()
              : 'customerTable.row.active'.i18nText()}
          </Typography>
        </Stack>
      );
    },
    maxSize: 128,
  },
];
