import { useEffect, useState } from 'react';

import {
  useIsViewingActivePricingDetails,
  useIsViewingFailedPricingDetails,
  useIsViewingInProgressPricingDetails,
  useSelectedVehiclePricingDetails,
} from '../contexts';
import { vehiclePricingFormFieldName } from '../helpers';
import { VehiclePricingFormValues } from '../VehiclePricingDetailsForm';

const vehiclePricingFormInitialValues: VehiclePricingFormValues = {
  [vehiclePricingFormFieldName.activeActivationFee]: null,
  [vehiclePricingFormFieldName.activeActiveMinutesFee]: null,
  [vehiclePricingFormFieldName.activePausedMinutesFee]: null,
  [vehiclePricingFormFieldName.newActivationFee]: null,
  [vehiclePricingFormFieldName.newActiveMinutesFee]: null,
  [vehiclePricingFormFieldName.newPausedMinutesFee]: null,
};

export function useVehiclePricingFormInitialValues(): VehiclePricingFormValues {
  const [formInitialValues, setFormInitialValues] = useState<VehiclePricingFormValues>(vehiclePricingFormInitialValues);

  const selectedVehiclePricingDetails = useSelectedVehiclePricingDetails();
  const isViewingActivePricingDetails = useIsViewingActivePricingDetails();
  const isUpdatingFailedPricingDetails = useIsViewingFailedPricingDetails();
  const isViewingInProgressPricingDetails = useIsViewingInProgressPricingDetails();

  useEffect(() => {
    if (isViewingActivePricingDetails && selectedVehiclePricingDetails) {
      const { activeState } = selectedVehiclePricingDetails;
      setFormInitialValues({
        [vehiclePricingFormFieldName.activeActivationFee]: activeState?.activationFeeAmount()?.amount.value!,
        [vehiclePricingFormFieldName.activeActiveMinutesFee]: activeState?.activeDurationFeeAmount()?.amount.value!,
        [vehiclePricingFormFieldName.activePausedMinutesFee]: activeState?.pausedDurationFeeAmount()?.amount.value!,
        [vehiclePricingFormFieldName.newActivationFee]: activeState?.activationFeeAmount()?.amount.value!,
        [vehiclePricingFormFieldName.newActiveMinutesFee]: activeState?.activeDurationFeeAmount()?.amount.value!,
        [vehiclePricingFormFieldName.newPausedMinutesFee]: activeState?.pausedDurationFeeAmount()?.amount.value!,
      });
    }

    if ((isUpdatingFailedPricingDetails || isViewingInProgressPricingDetails) && selectedVehiclePricingDetails) {
      const { activeState, lastAttemptedStateChange } = selectedVehiclePricingDetails;
      setFormInitialValues({
        [vehiclePricingFormFieldName.activeActivationFee]: activeState?.activationFeeAmount()?.amount.value!,
        [vehiclePricingFormFieldName.activeActiveMinutesFee]: activeState?.activeDurationFeeAmount()?.amount.value!,
        [vehiclePricingFormFieldName.activePausedMinutesFee]: activeState?.pausedDurationFeeAmount()?.amount.value!,
        [vehiclePricingFormFieldName.newActivationFee]: lastAttemptedStateChange.activationFeeAmount()?.amount.value!,
        [vehiclePricingFormFieldName.newActiveMinutesFee]: lastAttemptedStateChange.activeDurationFeeAmount()?.amount.value!,
        [vehiclePricingFormFieldName.newPausedMinutesFee]: lastAttemptedStateChange.pausedDurationFeeAmount()?.amount.value!,
      });
    }
  }, [isUpdatingFailedPricingDetails, isViewingActivePricingDetails, selectedVehiclePricingDetails, isViewingInProgressPricingDetails]);

  return formInitialValues;
}
