import type { Theme } from '@mui/material/styles';

export const searchMembershipContentStyles = (theme: Theme) => ({
  width: '680px',
  minHeight: '400px',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  gap: 2,
});

export const unlinkContentStyles = (theme: Theme) => ({
  width: '660px',
  minHeight: '684px',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  gap: 2,
});

export const dotIndicatorStyles = (theme: Theme, color: string) => ({
  height: '14px',
  width: '14px',
  borderRadius: '50%',
  backgroundColor: color,
});
