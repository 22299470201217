import { useMemo } from 'react';

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

import { PaymentStatus, TripDetailsResponse, TripStatus } from '../../../models';
import { TripDurationService } from '../../../services/trip/TripDurationService';

import { TripDurationBreakdown } from './TripDurationBreakdown';
import { TripPaymentTransactions } from './TripPaymentTransactions';
import { TripPricingBreakdown } from './TripPricingBreakdown';

const TripTimePricingAndPaymentDetails = ({ tripDetails }: TripDetailsAccordionProps) => {
  const { duration, payment, status } = tripDetails;
  const isTripCompleted = status === TripStatus.Stopped || status === TripStatus.ForceStopped;

  const {
    totalDurationBreakdown: { chargedTotalDuration },
  } = useMemo(() => new TripDurationService(duration), [duration]);

  return (
    <Box display="flex" flexDirection="column" pt={4}>
      <Typography variant="bodyLargeBold" mb={1}>
        {'common.timeBreakdown'.i18nText()}
      </Typography>
      <TripDurationBreakdown tripStatus={tripDetails.status} duration={duration} />
      {payment && <TripPricingBreakdown isTripCompleted={isTripCompleted} payment={payment} totalChargedMins={chargedTotalDuration} />}
      {payment && payment.status === PaymentStatus.Paid && <TripPaymentTransactions payment={payment} />}
    </Box>
  );
};

interface TripDetailsAccordionProps {
  tripDetails: TripDetailsResponse;
}

export default TripTimePricingAndPaymentDetails;
