import type { ChangeEvent, ForwardRefExoticComponent, ReactNode } from 'react';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import { ListItemConfig } from '../models';

export const RadioButtonComponent = ({ name, label, value = '', onChange, disabled }: RadioButtonComponentProps) => {
  return (
    <FormControl>
      <FormControlLabel
        control={<Radio checked={value} name={name} onChange={onChange} value={value} disabled={disabled} />}
        label={label}
      />
    </FormControl>
  );
};

const RadioButtonGroupComponent = ({
  name,
  value = '',
  onChange,
  options,
  direction = 'horizontal',
  label,
  disabled = false,
  hasError,
  helperText,
}: RadioButtonGroupComponentProps) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>, value: string) => {
    onChange(event, value);
  };

  return (
    <FormControl error={hasError}>
      {label && <FormLabel>{label}</FormLabel>}
      <RadioGroup value={value} name={name} row={direction === 'horizontal'} onChange={handleChange}>
        {options.map(({ value, label }) => {
          return <FormControlLabel value={value} control={<Radio disabled={disabled} color="secondary" />} label={label} key={value} />;
        })}
      </RadioGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export interface RadioButtonComponentProps {
  name: string;
  value: any;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  disabled?: boolean;
}

export interface RadioButtonGroupComponentProps {
  options: ListItemConfig<any>[];
  name: string;
  value: any;
  onChange: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
  direction?: 'horizontal' | 'vertical';
  label?: string;
  disabled?: boolean;
  hasError?: boolean;
  helperText?: ReactNode;
}

type CompoundedRadioButtonComponent = ForwardRefExoticComponent<RadioButtonComponentProps> & {
  Group: typeof RadioButtonGroupComponent;
};
export const RadioButton = RadioButtonComponent as CompoundedRadioButtonComponent;
RadioButton.Group = RadioButtonGroupComponent;
