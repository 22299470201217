import { Fragment } from 'react';

import { Typography, Button } from '@mui/material';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';

import { ProgressAwareButton } from '../../../wmv-components';

import {
  useIsEditingActivePricingDetails,
  useIsViewingActivePricingDetails,
  useIsViewingFailedPricingDetails,
  useIsViewingInProgressPricingDetails,
  useVehiclePricingDispatch,
  VehiclePricingActionType,
  useSelectedVehiclePricingState,
} from './contexts';
import { VehiclePricingDetailsActionMode } from './helpers';
import { VehiclePricingFormValues } from './VehiclePricingDetailsForm';

export function VehiclePricingFormActionButtons({ onDiscardFailedVehiclePricingStatusTransition }: VehiclePricingFormActionButtonsProps) {
  const { formatMessage } = useIntl();
  const { updatePricingApiStatus, discardFailedTransitionApiStatus } = useSelectedVehiclePricingState();
  const isViewingActivePricingDetails = useIsViewingActivePricingDetails();
  const isEditingActivePricingDetails = useIsEditingActivePricingDetails();
  const isViewingFailedPricingDetails = useIsViewingFailedPricingDetails();
  const isViewingInProgressPricingDetails = useIsViewingInProgressPricingDetails();
  const vehiclePricingDispatch = useVehiclePricingDispatch();
  const { resetForm } = useFormikContext<VehiclePricingFormValues>();

  if (isViewingActivePricingDetails)
    return (
      <Fragment>
        <Button disabled size="small" variant="secondaryLight">
          <Typography variant="bodyMediumBold">{formatMessage({ id: 'common.dialog.cancel' })}</Typography>
        </Button>
        <Button size="small" variant="contained" onClick={handleEditClick} sx={{ minWidth: '125px' }}>
          <Typography variant="bodyMediumBold">{formatMessage({ id: 'common.edit' })}</Typography>
        </Button>
      </Fragment>
    );

  if (isEditingActivePricingDetails)
    return (
      <Fragment>
        <ProgressAwareButton
          size="small"
          variant="secondaryLight"
          onClick={handleEditCancelClick}
          loading={false}
          disabled={updatePricingApiStatus.isPending()}
        >
          <Typography variant="bodyMediumBold">{formatMessage({ id: 'common.dialog.cancel' })}</Typography>
        </ProgressAwareButton>
        <ProgressAwareButton
          type="submit"
          size="small"
          variant="contained"
          disabled={updatePricingApiStatus.isPending()}
          loading={updatePricingApiStatus.isPending()}
          loadingButtonProps={{ sx: { minWidth: '125px' } }}
        >
          <Typography variant="bodyMediumBold">{formatMessage({ id: 'actions.saveChanges' })}</Typography>
        </ProgressAwareButton>
      </Fragment>
    );

  if (isViewingFailedPricingDetails)
    return (
      <Fragment>
        <ProgressAwareButton
          size="small"
          variant="secondaryLight"
          onClick={onDiscardFailedVehiclePricingStatusTransition}
          loading={discardFailedTransitionApiStatus.isPending()}
          disabled={discardFailedTransitionApiStatus.isPending() || updatePricingApiStatus.isPending()}
        >
          <Typography variant="bodyMediumBold">{formatMessage({ id: 'common.dialog.cancel' })}</Typography>
        </ProgressAwareButton>
        <ProgressAwareButton
          type="submit"
          size="small"
          variant="contained"
          loading={updatePricingApiStatus.isPending()}
          disabled={discardFailedTransitionApiStatus.isPending() || updatePricingApiStatus.isPending()}
          loadingButtonProps={{ sx: { minWidth: '125px' } }}
        >
          <Typography variant="bodyMediumBold">{formatMessage({ id: 'actions.saveChanges' })}</Typography>
        </ProgressAwareButton>
      </Fragment>
    );

  if (isViewingInProgressPricingDetails)
    return (
      <Fragment>
        <Button disabled size="small" variant="secondaryLight">
          <Typography variant="bodyMediumBold">{formatMessage({ id: 'common.dialog.cancel' })}</Typography>
        </Button>
        <Button disabled size="small" variant="contained" sx={{ minWidth: '125px' }}>
          <Typography variant="bodyMediumBold">{formatMessage({ id: 'actions.saveChanges' })}</Typography>
        </Button>
      </Fragment>
    );

  function handleEditClick() {
    vehiclePricingDispatch!({
      type: VehiclePricingActionType.UpdateActionMode,
      payload: VehiclePricingDetailsActionMode.EditActivePricing,
    });
  }

  function handleEditCancelClick() {
    vehiclePricingDispatch!({
      type: VehiclePricingActionType.UpdateActionMode,
      payload: VehiclePricingDetailsActionMode.ViewActivePricing,
    });
    resetForm();
  }
}

interface VehiclePricingFormActionButtonsProps {
  onDiscardFailedVehiclePricingStatusTransition: () => Promise<void>;
}
