import { useMemo } from 'react';

import SidebarInfoModal from '../../../components/SidebarInfoModal';
import { BudgetPlanInformationTab, BudgetPlanInformationTabHelper } from '../../../models';

import { useBudgetPlan, useBudgetPlanDetails, useBudgetPlanMembership } from './atoms/hooks';
import { BudgetPlanDetailsView } from './BudgetPlanDetails';
import { BudgetPlanMembershipsView } from './BudgetPlanMemberships';

export const BudgetPlanModal = () => {
  const { budgetPlanModalVisible, selectedPlan, budgetPlanModalActiveTab, setBudgetPlanModalActiveTab, resetBudgetPlanState } =
    useBudgetPlan();
  const { resetBudgetPlanDetailsState } = useBudgetPlanDetails();
  const { resetBMState } = useBudgetPlanMembership();

  const disabledTabs = useMemo(() => (selectedPlan ? [] : [BudgetPlanInformationTab.Members]), [selectedPlan]);

  const handleBudgetPlanModalClose = () => {
    resetBMState();
    resetBudgetPlanDetailsState();
    resetBudgetPlanState();
  };

  return (
    <SidebarInfoModal<BudgetPlanInformationTab>
      isOpen={budgetPlanModalVisible}
      onClose={handleBudgetPlanModalClose}
      activeTab={budgetPlanModalActiveTab}
      setActiveTab={setBudgetPlanModalActiveTab}
      tabOptions={BudgetPlanInformationTabHelper.allLabelAndValues()}
      title={selectedPlan?.activeState.name}
      disabledTabs={disabledTabs}
    >
      <BudgetPlanDetailsView onBudgetPlanModalClose={handleBudgetPlanModalClose} />
      <BudgetPlanMembershipsView />
    </SidebarInfoModal>
  );
};

export type BudgetPlanModalCloseHandler = () => void;
