import { MouseEventHandler } from 'react';

import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import { type Theme, useTheme } from '@mui/material/styles';

import { ReactComponent as NextIcon } from '../../assets/icons/outline/arrowRight.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/outline/close.svg';

interface TripViewBottomActionButtonsProps {
  onCloseTripBtnClick: MouseEventHandler<HTMLButtonElement>;
  onNextTripBtnClick: MouseEventHandler<HTMLButtonElement>;
  disableCloseBtn?: boolean;
  disableNextBtn?: boolean;
}

export function TripViewBottomActionButtons({
  onCloseTripBtnClick,
  onNextTripBtnClick,
  disableCloseBtn = false,
  disableNextBtn = false,
}: TripViewBottomActionButtonsProps) {
  const { palette } = useTheme();
  return (
    <Box sx={tripViewToggleActionBtnSx}>
      <Button variant="outlined" onClick={onCloseTripBtnClick} disabled={disableCloseBtn}>
        <CloseIcon style={actionBtnIconStyle} fill={disableCloseBtn ? palette.baseLight.base32 : 'inherit'} />
        {'trip.close'.i18nText()}
      </Button>
      <Button variant="outlined" onClick={onNextTripBtnClick} disabled={disableNextBtn}>
        {'trip.next'.i18nText()}
        <NextIcon style={actionBtnIconStyle} fill={disableNextBtn ? palette.baseLight.base32 : 'inherit'} />
      </Button>
    </Box>
  );
}

export const tripViewToggleActionBtnSx = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 2,
});

export const actionBtnIconStyle = {
  marginRight: '4px',
};
