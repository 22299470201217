import { useMemo } from 'react';

import dayjs from 'dayjs';
import { useIntl } from 'react-intl';

import { ZoneTypeHelper } from '../../../models';
import { Zone } from '../../../services/zone';
import { END_OF_DAY_TIME, START_OF_DAY_TIME } from '../../../utils/constants';

export function PlanningModePreviewTableColumnDefs() {
  const { formatMessage } = useIntl();

  return useMemo(
    () => [
      {
        id: 'name',
        header: formatMessage({ id: 'planningPreview.table.name' }),
        accessorKey: 'name',
      },
      {
        id: 'ruleType',
        header: formatMessage({ id: 'planningPreview.table.ruleType' }),
        accessorKey: 'type',
        accessorFn: (originalRow: Zone) =>
          ZoneTypeHelper.allTypes().includes(originalRow.type)
            ? formatMessage({ id: `zoneType.${originalRow.type}` })
            : formatMessage({ id: 'common.dash' }),
      },
      {
        id: 'startDate',
        header: formatMessage({ id: 'planningPreview.table.startDate' }),
        accessorKey: 'startsAtEpochMillis',
        accessorFn: (originalRow: Zone) => dayjs(originalRow.activeOn.dateRange.startInclusive).formatAsDateString(),
      },
      {
        id: 'endDate',
        header: formatMessage({ id: 'planningPreview.table.endDate' }),
        accessorFn: (originalRow: Zone) =>
          originalRow.activeOn.dateRange.endInclusive
            ? dayjs(originalRow.activeOn.dateRange.endInclusive).formatAsDateString()
            : formatMessage({ id: 'common.na' }),
        accessorKey: 'activeOn.dateRange.endInclusive',
      },

      {
        id: 'startTime',
        header: formatMessage({ id: 'planningPreview.table.startTime' }),
        accessorFn: (originalRow: Zone) => originalRow.activeOn.timeWindow?.startInclusive || START_OF_DAY_TIME,
      },
      {
        id: 'endTime',
        header: formatMessage({ id: 'planningPreview.table.endTime' }),
        accessorFn: (originalRow: Zone) =>
          ((originalRow.activeOn.timeWindow?.endExclusive || END_OF_DAY_TIME) as string)
            .asDayjsTime()
            .toInclusive()
            .formatAsTimeWithoutSeconds(),
      },
      {
        id: 'publishedDate',
        header: formatMessage({ id: 'planningPreview.table.publishedDate' }),
        accessorFn: (originalRow: Zone) => originalRow.createdAtEpochMillis.parseEpochMillis().formatAsDateString(),
      },
    ],
    [formatMessage],
  );
}
