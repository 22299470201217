import { TableCell, Typography } from '@mui/material';
import type { SxProps } from '@mui/material/styles';
import type { TypographyProps } from '@mui/material/Typography';

import { themeOptions } from '../../../init-setup/ThemeOptions';

export const StyledTableCell = ({
  children,
  variant = 'bodySmallBold',
  color = themeOptions.palette.baseDark.main,
  sx,
}: {
  children?: React.ReactNode;
  variant?: TypographyProps['variant'];
  color?: TypographyProps['color'];
  sx?: SxProps;
}) => (
  <TableCell
    sx={[
      {
        border: 'none',
        py: 1,
        px: 0,
        width: '25%',
        whiteSpace: 'noWrap',
        verticalAlign: 'baseline',
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
  >
    <Typography variant={variant} color={color}>
      {children}
    </Typography>
  </TableCell>
);
