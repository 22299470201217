import { Button } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import { styled } from '@mui/system';

export const btnGroupSx = (theme: Theme) => ({
  minHeight: '48px',
  marginBottom: '12px',
  boxShadow: theme.shadowOptions.belowMedium,
});

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive: boolean }>(({ theme, isActive }) => ({
  border: 'none',
  boxSizing: 'border-box',
  minWidth: '100px !important',
  width: 'auto',
  height: '48px',
  backgroundColor: isActive ? theme.palette.secondary.main : theme.palette.white.main,
  borderRadius: '8px',
  textAlign: 'center',
  '&:hover': {
    backgroundColor: isActive ? theme.palette.secondary.main : theme.palette.secondary.tint,
  },
}));
