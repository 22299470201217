import { Dayjs } from 'dayjs';

import {
  DayOfWeek,
  DayOfWeekHelper,
  InfrastructureType,
  InfrastructureTypeHelper,
  VehicleType,
  VehicleTypeHelper,
  ZoneType,
} from '../../models';

import { Mode } from './Mode';
import { SliderPointsWithGranularity } from './models';
import { Zone } from './Zone';

export class ZonePreviewPredicates {
  mode: Mode;
  zoneTypes: Set<ZoneType>;
  startDateInclusive: Dayjs;
  endDateInclusive: Dayjs;
  startTimeSecondsSinceBeginningOfDay: number;
  endTimeSecondsSinceBeginningOfDay: number;
  daysOfWeek: Set<DayOfWeek>;
  applicableVehicleTypes: Set<VehicleType>;
  infraStructureTypes: Set<InfrastructureType>;

  constructor(
    mode: Mode,
    zoneTypes: Set<ZoneType>,
    startDateInclusive: Dayjs,
    endDateInclusive: Dayjs,
    startTimeSecondsSinceBeginningOfDay: number,
    endTimeSecondsSinceBeginningOfDay: number,
    daysOfWeek: Set<DayOfWeek> = DayOfWeekHelper.allDaysOfWeek(),
    applicableVehicleTypes: Set<VehicleType> = new Set(VehicleTypeHelper.allTypes()),
    infraStructureTypes: Set<InfrastructureType> = new Set(InfrastructureTypeHelper.allTypes()),
  ) {
    this.mode = mode;
    this.zoneTypes = zoneTypes;
    this.startDateInclusive = startDateInclusive;
    this.endDateInclusive = endDateInclusive;
    this.startTimeSecondsSinceBeginningOfDay = startTimeSecondsSinceBeginningOfDay;
    this.endTimeSecondsSinceBeginningOfDay = endTimeSecondsSinceBeginningOfDay;
    this.daysOfWeek = daysOfWeek;
    this.applicableVehicleTypes = applicableVehicleTypes;
    this.infraStructureTypes = infraStructureTypes;
  }

  provideApplicableZones(computationTimestampEpochMillis: number, allZones: Zone[]): Zone[] {
    return this.mode.provideApplicableZones(computationTimestampEpochMillis, this, allZones);
  }

  provideSliderPoints(allZones: Zone[]): SliderPointsWithGranularity {
    return this.mode.provideSliderPoints(this, allZones);
  }

  dayOfWeekOverlaps(dayOfWeek: DayOfWeek): boolean {
    return this.daysOfWeek.has(dayOfWeek);
  }
}
