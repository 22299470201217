import { useState } from 'react';

import { Box } from '@mui/material';
import { useIntl } from 'react-intl';

import { AccountInformationTab, AccountInformationTabHelper } from '../../models/ui/AccountInformationTab';
import Placeholder from '../Placeholder/Placeholder';
import SidebarInfoModal from '../SidebarInfoModal';

import MyProfile from './MyProfile';

interface AccountInformationModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const AccountInformationModal = ({ isOpen, onClose }: AccountInformationModalProps) => {
  const [activeTab, setActiveTab] = useState<AccountInformationTab>(AccountInformationTab.MyProfile);
  const { formatMessage } = useIntl();

  return (
    <SidebarInfoModal<AccountInformationTab>
      isOpen={isOpen}
      onClose={onClose}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      disabledTabs={[AccountInformationTab.MyAccount]}
      tabOptions={AccountInformationTabHelper.allLabelAndValues()}
      title={formatMessage({ id: 'account.main.title' })}
    >
      <Box display="flex" alignItems="center" justifyContent="center" height="100%">
        <Placeholder />
      </Box>
      <MyProfile />
    </SidebarInfoModal>
  );
};

export default AccountInformationModal;
