import { themeOptions } from '../../init-setup/ThemeOptions';
import { EnumWithStringValue } from '../../utils/EnumUtils';
import { intl } from '../../utils/intl';

import { DepositFrequency } from './DepositFrequency';

export enum BudgetPlanStatus {
  NotActive = 'NOT_ACTIVE',
  Active = 'ACTIVE',
}

interface BudgetPlanStatusUnderivedMetadata {
  displayText: string;
  color: string;
}

export namespace BudgetPlanStatusHelper {
  const { formatMessage } = intl;
  const statusToUnderivedMetadata = new Map<BudgetPlanStatus, BudgetPlanStatusUnderivedMetadata>([
    [BudgetPlanStatus.NotActive, { displayText: formatMessage({ id: 'common.NotActive' }), color: themeOptions.palette.baseDark.base32 }],
    [BudgetPlanStatus.Active, { displayText: formatMessage({ id: 'common.Active' }), color: themeOptions.palette.success.main }],
  ]);

  export function metadata(status: BudgetPlanStatus): BudgetPlanStatusUnderivedMetadata {
    const underivedMetadata = statusToUnderivedMetadata.get(status);
    if (!underivedMetadata) {
      throw Error(`
            Specified Status: ${toString(status)} doesnt have corresponding underived/explicitly defined metadata.
            This usually (not always) means a bug or incomplete implementation.
        `);
    }

    return { ...underivedMetadata };
  }

  export function toString(status: BudgetPlanStatus): string | null {
    return EnumWithStringValue.getEnumKeyByEnumValue(DepositFrequency, status);
  }
}
