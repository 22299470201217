import { EnumTabHelper } from '../../utils/EnumTabHelper';

export enum TripInfoTabType {
  TripDetails = 'tripDetails',
  BudgetRefund = 'budgetRefund',
  AuditLog = 'auditLog',
}

const tripInfoTabTranslations = {
  TripDetails: 'common.tripDetails',
  BudgetRefund: 'common.budgetRefund',
  AuditLog: 'common.auditLog',
};

export const TripInfoTabTypeHelper = new EnumTabHelper(TripInfoTabType, tripInfoTabTranslations);
