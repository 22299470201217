import { createReducer } from '@reduxjs/toolkit';

import { updatePropertiesAction } from './actions';

export type StateType = number;
export const initialState: StateType = 0;

const toUpdateReducer = createReducer<StateType>(initialState, (builder) =>
  builder.addCase(updatePropertiesAction, (state: StateType) => {
    return state + 1;
  }),
);

export default toUpdateReducer;
