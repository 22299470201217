import Box from '@mui/material/Box';

import { EnqueuedSnackbarProvider } from '../../../../components/EnqueuedSnackbar';

import { MembershipDetailsModal } from './MembershipDetailsModal/MembershipDetailsModal';
import { MembershipSearchAndLinkBar } from './MembershipSearchAndLinkBar/MembershipSearchAndLinkBar';
import { MembershipSearchAndLinkModal } from './MembershipSearchAndLinkModal/MembershipSearchAndLinkModal';
import { MembershipsTableView } from './MembershipTableView/MembershipTableView';

export const BudgetPlanMembershipsView = () => {
  return (
    <EnqueuedSnackbarProvider>
      <Box height="100%" display="flex" flexDirection="column">
        <MembershipSearchAndLinkBar />
        <MembershipsTableView />
        {/* Modal dialogs */}
        <MembershipSearchAndLinkModal />
        <MembershipDetailsModal />
      </Box>
    </EnqueuedSnackbarProvider>
  );
};
