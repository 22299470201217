import type { Theme } from '@mui/material/styles';

export const timePickerStyles = (theme: Theme) => ({
  width: '60px',
  ...theme.typography.bodySmall,
  '&  .MuiInputBase-input': {
    padding: '8px 6px',
    textAlign: 'center',
  },
});
