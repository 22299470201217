import type { ChangeEvent, ReactElement, ReactNode, RefAttributes } from 'react';

import { FormControl } from '@mui/material';
import type { CheckboxProps } from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import type { SxProps, Theme } from '@mui/material/styles';

import { InternalCheckbox } from './Checkbox';

function CheckboxGroup<T>({
  options,
  name,
  size = 'small',
  disabled,
  checkedValues,
  onChange,
  sx,
  color = 'secondary',
  hasError,
  helperText,
}: CheckboxGroupComponentProps<T>) {
  return (
    <FormControl error={hasError}>
      <FormGroup sx={sx}>
        {options.map((option: CheckboxMetadata<T>, index: number) => {
          const { value, label, disabled: checkboxDisabled = false } = option;

          return (
            <InternalCheckbox
              checked={checkedValues.includes(value)}
              disabled={checkboxDisabled || disabled}
              onChange={handleChange}
              key={index}
              name={name}
              value={value}
              size={size}
              color={color}
              label={label}
            />
          );
        })}
      </FormGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    const index = checkedValues.indexOf(value as T);
    if (index > -1) {
      checkedValues = checkedValues.toSpliced(index, 1);
    } else {
      checkedValues = [...checkedValues, value as T];
    }
    onChange(checkedValues);
  }
}

export interface CheckboxGroupComponentProps<T> {
  options: CheckboxMetadata<T>[];
  name: string;
  size?: CheckboxProps['size'];
  disabled?: boolean;
  checkedValues: T[];
  onChange: (values: T[]) => void;
  sx?: SxProps<Theme>;
  color?: CheckboxProps['color'];
  hasError?: boolean;
  helperText?: ReactNode;
}

export interface CheckboxMetadata<T> {
  label: string;
  value: T;
  disabled?: boolean;
  color?: CheckboxProps['color'];
}

export default CheckboxGroup as <T = any>(props: CheckboxGroupComponentProps<T> & RefAttributes<HTMLDivElement>) => ReactElement;
