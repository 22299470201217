import { useState } from 'react';

import { Box, useTheme, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import { useIntl } from 'react-intl';

import { SelectionMenu, SelectionMenuOptionMetadata } from '../../../../wmv-components';
import { hexagonResolutionAtom, poiDataAtom } from '../../atoms/hexagon-layer-atoms';

import ColorScaleGenerator from './ColorScaleGenerator';

type AnalyticsOptions = {
  [key: string]: SelectionMenuOptionMetadata[];
};

function useAnalyticsOptions(): AnalyticsOptions {
  const { formatMessage } = useIntl();

  // TODO: Add all options once the API is ready
  return {
    availableLayers: [
      {
        value: 'analytics.heatmap.heatmapLayer',
        label: formatMessage({ id: 'analytics.heatmap.heatmapLayer' }),
      },
    ],
    poiData: [
      {
        value: 'analytics.heatmap.tripsBooked',
        label: formatMessage({ id: 'analytics.heatmap.tripsBooked' }),
      },
    ],
    providers: [
      {
        value: 'analytics.heatmap.showAll',
        label: formatMessage({ id: 'analytics.heatmap.showAll' }),
      },
    ],
    vehicleTypes: [
      {
        value: 'analytics.heatmap.showAll',
        label: formatMessage({ id: 'analytics.heatmap.showAll' }),
      },
    ],
    statisticalAreas: [
      { label: `Hexagon 0.1 km\u00B2`, value: 9 },
      { label: 'Hexagon 0.01 km\u00B2', value: 10 },
    ],
  };
}

/**
 * AnalyticsLayerControlContent component provides a control panel for selecting and configuring
 * various layers and properties related to the analytics heatmap, including the data type, provider,
 * vehicle type, statistical area, and color scale.
 *
 * @returns {JSX.Element} The rendered AnalyticsLayerControlContent component.
 */
export function AnalyticsLayerControlContent() {
  const { palette, spacing, shadows } = useTheme();
  const { formatMessage } = useIntl();

  const { availableLayers, poiData, providers, vehicleTypes, statisticalAreas } = useAnalyticsOptions();

  const [selectedLayer, setSelectedLayer] = useState<string>(availableLayers[0].value as string);
  const [selectedProvider, setSelectedProvider] = useState<string>(providers[0].value as string);
  const [selectedVehicleType, setSelectedVehicleType] = useState<string>(vehicleTypes[0].value as string);
  const [selectedStatisticalArea, setSelectedStatisticalArea] = useAtom(hexagonResolutionAtom);
  const [selectedPoiData, setSelectedPoiData] = useAtom(poiDataAtom);

  return (
    <Box style={{ borderRadius: spacing(1), padding: spacing(2), backgroundColor: palette.baseLight.base0, boxShadow: shadows[3] }}>
      <Box sx={{ display: 'flex', gap: spacing(4), flexDirection: 'column', width: '300px' }}>
        <Box display="flex" flexDirection="column" gap={spacing(2)}>
          <Typography variant="bodyMediumBold" color={palette.baseLight.main}>
            {formatMessage({ id: 'analytics.heatmap.mapType' })}
          </Typography>
          <SelectionMenu
            label=""
            name="mapType"
            options={availableLayers}
            required={false}
            value={selectedLayer}
            onChange={(e) => setSelectedLayer(e.target.value as string)}
          />
        </Box>

        <Box display="flex" flexDirection="column" gap={spacing(3)}>
          <Typography variant="bodyMediumBold" color={palette.baseLight.main}>
            {formatMessage({ id: 'analytics.heatmap.properties' })}
          </Typography>
          <Box display="flex" flexDirection="column" gap={spacing(3)}>
            <SelectionMenu
              label={formatMessage({ id: 'analytics.heatmap.poiData' })}
              name="poiData"
              options={poiData}
              required={false}
              value={selectedPoiData}
              onChange={(e) => setSelectedPoiData(e.target.value as string)}
            />
            <SelectionMenu
              label={formatMessage({ id: 'analytics.heatmap.provider' })}
              name="provider"
              options={providers}
              required={false}
              value={selectedProvider}
              onChange={(e) => setSelectedProvider(e.target.value as string)}
            />
            <SelectionMenu
              label={formatMessage({ id: 'analytics.heatmap.vehicleType' })}
              name="vehicleType"
              options={vehicleTypes}
              required={false}
              value={selectedVehicleType}
              onChange={(e) => setSelectedVehicleType(e.target.value as string)}
            />
            <SelectionMenu
              label={formatMessage({ id: 'analytics.heatmap.statisticalArea' })}
              name="statisticalArea"
              options={statisticalAreas}
              required={false}
              value={selectedStatisticalArea}
              onChange={(e) => setSelectedStatisticalArea(e.target.value as number)}
            />
            <ColorScaleGenerator />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
