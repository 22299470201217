import type { Theme } from '@mui/material/styles';

export const cardContainerSx = (theme: Theme) => ({
  p: 3,
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.spacing(2),
  boxShadow: 'unset',
  '&:hover': {
    boxShadow: theme.shadowOptions.belowLarge,
  },
});

export const cardContentSx = (theme: Theme) => ({
  p: 0,
  pt: 1,
});

export const cardActionsSx = (theme: Theme) => ({
  p: 0,
  mt: 'auto',
});
