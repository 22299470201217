import { EnumWithStringValue } from '../../utils/EnumUtils';
import { intl } from '../../utils/intl';

export enum PaymentStatus {
  Paid = 'PAID',
  NotPaid = 'NOT_PAID',
}

interface PaymentStatusUnderivedData {
  displayText: string;
}

export namespace PaymentStatusHelper {
  const unitToUnderivedData = new Map<PaymentStatus, PaymentStatusUnderivedData>([
    [
      PaymentStatus.Paid,
      {
        displayText: intl.formatMessage({ id: 'common.paid' }),
      },
    ],
    [
      PaymentStatus.NotPaid,
      {
        displayText: intl.formatMessage({ id: 'common.notPaid' }),
      },
    ],
  ]);

  export function metadata(paymentStatus: PaymentStatus): PaymentStatusUnderivedData {
    const underivedMetadata = unitToUnderivedData.get(paymentStatus);
    if (!underivedMetadata) {
      throw Error(`
            Specified PaymentStatus type: ${toString(paymentStatus)} doesnt have corresponding underived/explicitly defined metadata.
            This usually (not always) means a bug or incomplete implementation.
        `);
    }

    return { ...underivedMetadata };
  }

  export function toString(paymentStatus: PaymentStatus): string | null {
    return EnumWithStringValue.getEnumKeyByEnumValue(PaymentStatus, paymentStatus);
  }
}
