import { createReducer } from '@reduxjs/toolkit';

import { GraphqlVehicleType, GraphqlVehicleTypeHelper } from '../../models';

import {
  changeLanguageAction,
  loadingVehiclesDataAction,
  loadingVehiclesErrorAction,
  toggleAccessForbiddenAlertAction,
  updateAllVehiclesAction,
} from './actions';
import { AvailableLanguage } from './common.model';
import { FormattedLiveVehicle, LiveVehicle } from './vehicle.model';

import toMMVehicleType = GraphqlVehicleTypeHelper.toMMVehicleType;

const lslang = localStorage.getItem('language');
const navLang = navigator.language.split(/[-_]/)[0];

export type CommonState = {
  allVehicles: LiveVehicle[];
  formattedAllVehicles: FormattedLiveVehicle[];
  loadingVehiclesData: boolean;
  loadingVehiclesError: string | boolean;
  language: AvailableLanguage | string | null;
  accessForbiddenAlertVisible: boolean;
};

const initialState: CommonState = {
  allVehicles: [],
  formattedAllVehicles: [],
  loadingVehiclesData: false,
  loadingVehiclesError: false,
  language: lslang || navLang, // TODO: check if language is available
  accessForbiddenAlertVisible: false,
};

const commonReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(updateAllVehiclesAction, (state, action) => {
      const formattedVehicles = formatAllVehicles(action.payload);
      return { ...state, allVehicles: [...action.payload], formattedAllVehicles: [...formattedVehicles] };
    })
    .addCase(loadingVehiclesDataAction, (state, action) => {
      return { ...state, loadingVehiclesData: action.payload };
    })
    .addCase(loadingVehiclesErrorAction, (state, action) => {
      return { ...state, loadingVehiclesError: action.payload };
    })
    .addCase(changeLanguageAction, (state, action) => {
      return { ...state, language: action.payload };
    })
    .addCase(toggleAccessForbiddenAlertAction, (state, action) => {
      return { ...state, accessForbiddenAlertVisible: action.payload };
    }),
);

// TODO: Move this away from here
function formatVehicleType(vehicle: LiveVehicle): FormattedLiveVehicle {
  return {
    ...vehicle,
    ...{
      formFactor: toMMVehicleType(vehicle.formFactor || GraphqlVehicleType.OTHER)!,
    },
  };
}

function formatAllVehicles(vehicles: LiveVehicle[]): FormattedLiveVehicle[] {
  return vehicles.map((vehicle: LiveVehicle) => formatVehicleType(vehicle));
}

export default commonReducer;
