import type { FormatXMLElementFn } from 'intl-messageformat';
import type { PrimitiveType } from 'react-intl';

import { intl } from '../../utils/intl';

declare global {
  interface String {
    i18nText(options?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>): string;
  }
}

declare global {
  interface Number {
    i18nNumber(options?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>): string;
    toPercentage(): string;
  }
}

// eslint-disable-next-line no-extend-native
String.prototype.i18nText = function (options?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>): string {
  let key = String(this);
  return intl.formatMessage({ id: key }, options);
};

// eslint-disable-next-line no-extend-native
Number.prototype.i18nNumber = function (options?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>): string {
  let value = Number(this);
  return intl.formatNumber(value, options);
};

// eslint-disable-next-line no-extend-native
Number.prototype.toPercentage = function (options?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>) {
  return intl.formatNumber(Number(this) / 100, { style: 'percent', ...options });
};
