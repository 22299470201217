import type { ButtonProps, TypographyProps } from '@mui/material';
import { Button, Typography } from '@mui/material';

import { anchorButtonStyles, anchorButtonTextStyles } from './PredicatesPopoverAnchorButton.styles';

export type PredicatePopoverAnchorButtonProps = ButtonProps & {
  label: string;
  labelFontVariant?: TypographyProps['variant'];
  endIcon?: React.ReactNode;
};

export const PredicatePopoverAnchorButton = ({
  labelFontVariant = 'bodySmall',
  label = '',
  endIcon = null,
  ...props
}: PredicatePopoverAnchorButtonProps) => {
  return (
    <Button variant="outlined" size="medium" endIcon={endIcon} sx={anchorButtonStyles} {...props}>
      <Typography variant={labelFontVariant} sx={anchorButtonTextStyles}>
        {label}
      </Typography>
    </Button>
  );
};
