import { EnumWithStringValue } from '../../utils/EnumUtils';
import { intl } from '../../utils/intl';

export enum Unit {
  Minutes = 'MINUTES',
  PerMinuteRate = 'PER_MINUTE_RATE',
  Fixed = 'FIXED',
}

interface UnitUnderivedData {
  displayText: string;
}

export namespace UnitHelper {
  const unitToUnderivedData = new Map<Unit, UnitUnderivedData>([
    [
      Unit.Minutes,
      {
        displayText: intl.formatMessage({ id: `map.sidebar.rule.unit.minutes` }),
      },
    ],
    [
      Unit.PerMinuteRate,
      {
        displayText: intl.formatMessage({ id: 'common.perMin' }),
      },
    ],
    [
      Unit.Fixed,
      {
        displayText: 'fixed',
      },
    ],
  ]);

  export function metadata(unit: Unit): UnitUnderivedData {
    const underivedMetadata = unitToUnderivedData.get(unit);
    if (!underivedMetadata) {
      throw Error(`
            Specified Unit type: ${toString(unit)} doesnt have corresponding underived/explicitly defined metadata.
            This usually (not always) means a bug or incomplete implementation.
        `);
    }

    return { ...underivedMetadata };
  }

  export function toString(unit: Unit): string | null {
    return EnumWithStringValue.getEnumKeyByEnumValue(Unit, unit);
  }
}
