/* eslint-disable */
import { startTransition, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import debounce from 'lodash/debounce';
import { useDispatch, useSelector } from 'react-redux';

import WikimoveMap from '../../components/Map/WikimoveMap';
import { ReactComponent as OverlayFilledIcon } from '../../assets/icons/fill/overlayFilled.svg';
import { ReactComponent as OverlayOutlinedIcon } from '../../assets/icons/outline/overlay.svg';
import MapFiltersAndZoneTypeInfoControls from '../../components/Map/ZoneControls';
import { useApplicableVehiclesForFilters } from '../../hooks';
import { PreviewPredicates } from '../../models';
import { Mode, SliderGranularity, SliderPointsWithGranularity, Zone, ZonePreviewPredicates } from '../../services/zone';
import { AppDispatch, formattedAllVehiclesSelector } from '../../state';
import { allZonesSelector, getAllZonesAsyncThunkAction } from '../../state/zones-management';
import { VIEW_PANNING_MODE_VIEW_HEIGHT, VIEW_PLANNING_MODE_TABLE_HEIGHT } from '../../utils/constants';
import { FloatingBox } from '../../wmv-components';

import { defaultPreviewPredicates } from './helpers';
import { ZoneManagement } from './ZoneManagement';
import { PlanningModePreviewPredicatesView, PlanningModePreviewTable } from './ZonePlanningMode';
import { MapFilters } from '../../components/Map/ZoneControls/MapFilters';
import { GroupMenuControl } from '../../components/Map/controls/GroupMenuControl';
import SetView from '../../components/Map/MapModes/PlanningMode/LayersView/SetView';

const PlanningModeLayout = ({ filters, onFilterChange, anyFilterApplied }: PlanningModeLayoutProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();

  const [zonesPreviewTableCollapsed, setZonePreviewTableCollapsed] = useState(true);
  const [activeSliderPointIndex, setActiveSliderPointIndex] = useState<number>(0);
  const [filteredZones, setFilteredZones] = useState<Zone[]>([]);
  const [previewPredicates, setPreviewPredicates] = useState<PreviewPredicates>(defaultPreviewPredicates);
  const [sliderPointsWithGranularity, setSliderPointsWithGranularity] = useState<SliderPointsWithGranularity>({
    granularity: SliderGranularity.AGGREGATED,
    pointsAsEpochMillis: [],
  });
  const currentSliderPointEpochMillis = sliderPointsWithGranularity.pointsAsEpochMillis[activeSliderPointIndex];

  const zonePredicatesRef = useRef<ZonePreviewPredicates | null>(null);
  const provideApplicableZonesFnRef = useRef<((currentSliderPointEpochMillis: number, allZones: Zone[]) => Zone[]) | null>(null);

  const allVehicles = useSelector(formattedAllVehiclesSelector);
  const allZones = useSelector(allZonesSelector);
  const filteredVehicles = useApplicableVehiclesForFilters(allVehicles, filters);

  useEffect(() => {
    dispatch(getAllZonesAsyncThunkAction());
  }, []);

  const zonesPreviewTableHeight = useMemo(
    () => `calc(100vh - ${zonesPreviewTableCollapsed ? VIEW_PLANNING_MODE_TABLE_HEIGHT : VIEW_PANNING_MODE_VIEW_HEIGHT})`,
    [zonesPreviewTableCollapsed],
  );

  function getZonePreviewPredicates(previewPredicates: PreviewPredicates): ZonePreviewPredicates {
    const {
      dateRangeAndDaysOfWeek: { startDate, endDate },
      timeRange: { startSecondsFromBeginningOfDay, endSecondsFromBeginningOfDay },
      dateRangeAndDaysOfWeek,
      modeType,
    } = previewPredicates;
    const { daysOfWeek } = dateRangeAndDaysOfWeek;
    return new ZonePreviewPredicates(
      new Mode(modeType),
      new Set(filters.zoneTypes),
      startDate,
      endDate,
      startSecondsFromBeginningOfDay,
      endSecondsFromBeginningOfDay,
      daysOfWeek,
      new Set(filters.vehicleTypes),
      new Set(filters.infrastructureTypes),
    );
  }

  useEffect(() => {
    if (allZones.length) {
      const zonePredicates = getZonePreviewPredicates(defaultPreviewPredicates);
      const sliderPointsWithGranularity = zonePredicates.provideSliderPoints(allZones);
      const {
        pointsAsEpochMillis: [startEpochMillis, endEpochMillis],
      } = sliderPointsWithGranularity;
      setPreviewPredicates(() => ({
        ...defaultPreviewPredicates,
        dateRangeAndDaysOfWeek: {
          ...previewPredicates.dateRangeAndDaysOfWeek,
          startDate: startEpochMillis.parseEpochMillis(),
          endDate: endEpochMillis.parseEpochMillis(),
        },
      }));
      setSliderPointsWithGranularity(sliderPointsWithGranularity);
      setActiveSliderPointIndex(0);
    }
  }, [allZones]);

  const debouncedFilteredZonesUpdate = useCallback(
    debounce((...args: [currentSliderPointEpochMillis: number, allZones: Zone[]]) => {
      const filteredZones = provideApplicableZonesFnRef.current!.apply(null, args);
      setFilteredZones(filteredZones);
    }, 100),
    [],
  );

  const provideApplicableZonesCallback = (currentSliderPointEpochMillis: number, allZones: Zone[]) =>
    zonePredicatesRef.current!.provideApplicableZones(currentSliderPointEpochMillis, allZones);

  useEffect(() => {
    zonePredicatesRef.current = getZonePreviewPredicates(previewPredicates);
    provideApplicableZonesFnRef.current = provideApplicableZonesCallback;
  }, [previewPredicates, filters.zoneTypes, filters.vehicleTypes, filters.infrastructureTypes]);

  useEffect(() => {
    if (allZones.length && zonePredicatesRef.current !== null) {
      startTransition(() => {
        const sliderPointsWithGranularity = zonePredicatesRef.current!.provideSliderPoints(allZones);
        setActiveSliderPointIndex(0);
        setSliderPointsWithGranularity(sliderPointsWithGranularity);
      });
      const filteredZones = zonePredicatesRef.current!.provideApplicableZones(currentSliderPointEpochMillis, allZones);
      setFilteredZones(filteredZones);
    } else {
      setFilteredZones([]);
    }
  }, [previewPredicates, allZones, filters.zoneTypes, filters.vehicleTypes, filters.infrastructureTypes]);

  useEffect(() => {
    if (allZones.length && zonePredicatesRef.current) {
      debouncedFilteredZonesUpdate(currentSliderPointEpochMillis, allZones);
    }
  }, [currentSliderPointEpochMillis]);

  const handleSliderChange = useCallback(
    (sliderActiveIndex: number) => setActiveSliderPointIndex(sliderActiveIndex),
    [sliderPointsWithGranularity],
  );

  const handlePreviewPredicateFilterChange = useCallback(
    (previewPredicates: PreviewPredicates) => {
      setPreviewPredicates(previewPredicates);
    },
    [previewPredicates],
  );

  const handleCollapseToggle = useCallback((collapsed: boolean) => setZonePreviewTableCollapsed(collapsed), []);

  return (
    <Box sx={{ height: zonesPreviewTableHeight }}>
      <WikimoveMap vehicles={filteredVehicles}>
        <FloatingBox boxStyling={{ top: theme.spacing(9) }}>
          <MapFiltersAndZoneTypeInfoControls filters={filters} isAnyFilterApplied={anyFilterApplied} onFilterChange={onFilterChange} />
        </FloatingBox>
        <GroupMenuControl
          buttons={[
            {
              icon: <OverlayOutlinedIcon />,
              activeIcon: <OverlayFilledIcon />,
              content: <SetView />,
            },
          ]}
        />
        <ZoneManagement zones={filteredZones} />
      </WikimoveMap>
      <Box>
        <PlanningModePreviewTable
          zones={filteredZones}
          collapsed={zonesPreviewTableCollapsed}
          onCollapseToggle={handleCollapseToggle}
          previewPredicates={previewPredicates}
        />
        <PlanningModePreviewPredicatesView
          previewPredicates={previewPredicates}
          onChange={handlePreviewPredicateFilterChange}
          sliderPointsWithGranularity={sliderPointsWithGranularity}
          onSliderChange={handleSliderChange}
          activeSliderPointIndex={activeSliderPointIndex}
        />
      </Box>
    </Box>
  );
};

interface PlanningModeLayoutProps {
  onFilterChange: (values: MapFilters) => void;
  filters: MapFilters;
  anyFilterApplied: boolean;
}

export default PlanningModeLayout;
