import { useEffect, useState } from 'react';

import { CustomerTrip } from '../../../models';
import { getMobilityCustomerTripsList } from '../../../utils/queries';

function useFetchCustomerTripsList(customerSubjectClaim: string) {
  const [allCustomerTrips, setAllCustomerTrips] = useState<CustomerTrip[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const fetchCustomerTripsList = async (customerSubjectClaim: string) => {
    try {
      setError(false);
      setLoading(true);
      setAllCustomerTrips([]);
      const customerTrips = (await getMobilityCustomerTripsList(customerSubjectClaim)) || [];
      setAllCustomerTrips(customerTrips || []);
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchCustomerTripsList(customerSubjectClaim);
    })();
  }, [customerSubjectClaim]);

  return { allCustomerTrips, loading, error, fetchCustomerTripsList, setAllCustomerTrips };
}

export default useFetchCustomerTripsList;
