import { Polygon, Layer } from 'leaflet';

export class LeafletLayer extends Layer {
  layer: Polygon;

  constructor(layer: Polygon) {
    super();
    this.layer = layer;
  }

  editEnabled() {
    return this.layer.pm.enabled();
  }

  disableEdit() {
    return this.layer.pm.enable({ allowEditing: false });
  }

  enableEdit() {
    return this.layer.pm.enable({ allowEditing: true });
  }

  dragEnabled() {
    return this.layer.pm.layerDragEnabled();
  }

  disableDrag() {
    return this.layer.pm.disableLayerDrag();
  }

  enableDrag() {
    return this.layer.pm.enableLayerDrag();
  }

  layerColor(color: string) {
    return this.layer.setStyle({ color });
  }

  layerFillColor(fillColor: string) {
    return this.layer.setStyle({ fillColor });
  }

  strokeWeight(weight: number) {
    return this.layer.setStyle({ weight });
  }
}
