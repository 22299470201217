import { PropsWithChildren } from 'react';

import { styled } from '@mui/system';

const sharedStyles = {
  fontWeight: 'bold',
  margin: '4px 8px 4px 0',
  display: 'inline-block',
};

const StyledLabel = styled('label')({
  ...sharedStyles,
  '& span': {
    ...sharedStyles,
  },
});

const GenericLabel = (props: PropsWithChildren<{ htmlFor?: string }>) => <StyledLabel {...props}>{props.children}</StyledLabel>;

export default GenericLabel;
