import * as Yup from 'yup';

import { licenseClasses } from '../../../utils/constants';
import { intl } from '../../../utils/intl';
import { drivingLicenceValidationSchema } from '../../../utils/ValidationSchemas';

const { formatMessage } = intl;

export const CustomerLicenceInfoFormValidationSchema = Yup.object().shape({
  id: drivingLicenceValidationSchema,
  issuedOn: Yup.date()
    .required(formatMessage({ id: 'form.validation.errorFieldRequired' }))
    .max(new Date(), formatMessage({ id: 'form.validation.errorNoFutureDate' })),
  expiresOn: Yup.date()
    .required(formatMessage({ id: 'form.validation.errorFieldRequired' }))
    .min(Yup.ref('issuedOn'), formatMessage({ id: 'form.validation.notAfterIssueDate' })),
  countryCode: Yup.string().required(formatMessage({ id: 'form.validation.errorFieldRequired' })),
  placeOfIssuingAuthority: Yup.string().required(formatMessage({ id: 'form.validation.errorFieldRequired' })),
  classes: Yup.array()
    .of(Yup.string())
    .min(1, formatMessage({ id: 'form.validation.errorOneRequired' }))
    .test(
      'is-valid-class',
      formatMessage({ id: 'form.validation.invalidClass' }),
      (value) => value && value.every((classValue) => licenseClasses.some((option) => option.value === classValue)),
    ),
});
