import { useEffect, useRef } from 'react';

import { Map, Polygon } from 'leaflet';

export const useDrawStartEvent = (
  map: Map,
  onDrawStart: (workingLayer: Polygon, shape: string) => void,
  onVertexAdd?: (vertexCount: number) => void,
) => {
  const vertexCountRef = useRef<number>(0);

  useEffect(() => {
    const handleDrawStart = ({ workingLayer, shape }: { workingLayer: Polygon; shape: string }) => {
      // Cut mode also triggers pm:drawstart event, so we need to check if shape is not Cut then only call onDraw
      if (shape === 'Cut') {
        return;
      } else {
        vertexCountRef.current = 0;
        onDrawStart(workingLayer, shape);
      }

      workingLayer.on('pm:vertexadded', () => {
        vertexCountRef.current += 1;
        if (onVertexAdd) onVertexAdd(vertexCountRef.current);
      });
    };

    // @ts-ignore
    map.on('pm:drawstart', handleDrawStart);

    return () => {
      // @ts-ignore
      map.off('pm:drawstart', handleDrawStart);
    };
  }, [map, onDrawStart, onVertexAdd]);
};
