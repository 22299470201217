import { ReactNode } from 'react';
import type { FC } from 'react';

import { ApolloProvider } from '@apollo/client';
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';

import ConnectedIntlProvider from '../configs/ConnectedIntlProvider';
import { client } from '../services/ApolloClient';
import { store } from '../state';

import DataSubscription from './DataSubscription';
import { themeOptions } from './ThemeOptions';

const InitialSetup: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Provider store={store}>
      <ApolloProvider client={client}>
        <ConnectedIntlProvider>
          <ThemeProvider theme={createTheme(themeOptions)}>
            <DataSubscription>
              <CssBaseline />
              {children}
            </DataSubscription>
          </ThemeProvider>
        </ConnectedIntlProvider>
      </ApolloProvider>
    </Provider>
  );
};

// TODO: Rename this appropriately after seeing how others named this
export default InitialSetup;
