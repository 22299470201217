import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';

import { ErrorMessage } from '../../../../components/FormFields/ErrorMessage';
import { TranslatedText, ProgressAwareButton } from '../../../../wmv-components';
import { BP_ACTION_MODE, REQUEST_STATUS } from '../atoms/budget-plan-atoms';
import { useBudgetPlan, useBudgetPlanDetails } from '../atoms/hooks';
import { actionButtonStyles, contentHeadingStyles } from '../styles';

import { BudgetPlanDetailsFormValues } from './BudgetPlanDetailsView';

const BUDGET_PLAN_ALREADY_EXIST_ERROR_TITLE = 'Budget plan already exists';

interface BudgetPlanFormHeadingAndActionButtonsProps {
  onCancelClick: () => void;
  onCancelEdit: () => void;
  currentBudgetPlanFormValues?: BudgetPlanDetailsFormValues;
}

export const BudgetPlanFormHeadingAndActionButtons = ({
  onCancelClick,
  onCancelEdit,
  currentBudgetPlanFormValues,
}: BudgetPlanFormHeadingAndActionButtonsProps) => {
  const { formatMessage } = useIntl();
  const { isSubmitting } = useFormikContext<BudgetPlanDetailsFormValues>();
  const { bpDetailsSubmitStatus, bpDetailsErrorMsg } = useBudgetPlanDetails();
  const { budgetPlanActionMode, setBudgetPlanActionMode } = useBudgetPlan();

  // TODO: This check needs to be removed as we are comparing the hardcoded text. Discuss with BE team to send some kind of code.
  const isSameNameError = bpDetailsErrorMsg === BUDGET_PLAN_ALREADY_EXIST_ERROR_TITLE;

  const handleEditPlanClick = () => {
    setBudgetPlanActionMode(BP_ACTION_MODE.EDIT);
  };

  const handleCancelClick = async () => {
    if (budgetPlanActionMode === BP_ACTION_MODE.EDIT) {
      setBudgetPlanActionMode(BP_ACTION_MODE.VIEW);
      onCancelEdit();
    } else {
      onCancelClick();
    }
  };

  return (
    <Box sx={contentHeadingStyles}>
      <Box marginLeft="auto">
        <Button
          variant="outlined"
          onClick={handleCancelClick}
          size="small"
          disabled={isSubmitting || budgetPlanActionMode === BP_ACTION_MODE.VIEW}
        >
          <TranslatedText translationKey="actions.cancel" variant="bodySmallBold" />
        </Button>
        {budgetPlanActionMode === BP_ACTION_MODE.ADD && (
          <ProgressAwareButton loading={isSubmitting} loadingButtonProps={{ sx: actionButtonStyles }} size="small">
            {formatMessage({ id: 'actions.save' })}
          </ProgressAwareButton>
        )}
        {budgetPlanActionMode === BP_ACTION_MODE.VIEW && (
          <Button onClick={handleEditPlanClick} sx={actionButtonStyles} variant="contained" size="small">
            <TranslatedText translationKey="actions.edit" variant="bodySmallBold" />
          </Button>
        )}
        {budgetPlanActionMode === BP_ACTION_MODE.EDIT && (
          <ProgressAwareButton loading={isSubmitting} loadingButtonProps={{ sx: actionButtonStyles }} size="small">
            <TranslatedText translationKey="actions.update" variant="bodySmallBold" />
          </ProgressAwareButton>
        )}
      </Box>
      {bpDetailsSubmitStatus === REQUEST_STATUS.FAILURE && (
        <ErrorMessage
          error={
            isSameNameError ? formatMessage({ id: 'budgetPlan.samePlanNameExists' }) : formatMessage({ id: 'systemStatus.error.caption' })
          }
        />
      )}
    </Box>
  );
};
