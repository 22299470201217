import { Dayjs } from 'dayjs';

import { DayOfWeek, DayOfWeekHelper } from '../../models';

import { VisibilityStatusPointProviderConstants, VisibilityStatusPointProvider } from './VisibilityStatusPointProvider';

export class ZoneDaysOfWeek implements VisibilityStatusPointProvider {
  static allDaysOfWeekCount: number = 7;
  private readonly _daysOfWeek: Set<DayOfWeek>;
  private readonly _visibleAllTheDays: boolean;

  constructor(daysOfWeek?: DayOfWeek[]) {
    this._daysOfWeek = daysOfWeek ? new Set<DayOfWeek>(daysOfWeek) : DayOfWeekHelper.allDaysOfWeek();
    this._visibleAllTheDays = this._daysOfWeek.size === ZoneDaysOfWeek.allDaysOfWeekCount;
  }

  isVisible(evaluationPoint: Dayjs): boolean {
    if (this._visibleAllTheDays) {
      return true;
    }

    return this.daysOfWeekContains(evaluationPoint.dayOfWeek());
  }

  calculateNextVisiblePoint(evaluationPoint: Dayjs): Dayjs {
    const evaluationPointVisible: boolean = this._visibleAllTheDays || this.daysOfWeekContains(evaluationPoint.dayOfWeek());
    if (evaluationPointVisible) {
      return evaluationPoint;
    }

    return this.nextVisibleDayOfWeekTimestamp(evaluationPoint).toBeginningOfDay();
  }

  calculateNextInvisiblePoint(evaluationPoint: Dayjs): Dayjs {
    if (this._visibleAllTheDays) {
      return VisibilityStatusPointProviderConstants.endOfUniverse;
    }

    return this.nextInVisibleDayOfWeekTimestamp(evaluationPoint).toBeginningOfDay();
  }

  overlapsWith(predicateDayOfWeeks: Set<DayOfWeek> = DayOfWeekHelper.allDaysOfWeek()) {
    return [...predicateDayOfWeeks].some((predicateDay) => this._daysOfWeek.has(predicateDay));
  }

  private nextVisibleDayOfWeekTimestamp(evaluationPoint: Dayjs): Dayjs {
    let nextEvaluationPointTimestamp: Dayjs = evaluationPoint;
    let nextEvaluationPointDayOfWeek: DayOfWeek;
    do {
      nextEvaluationPointTimestamp = nextEvaluationPointTimestamp.plusDays(1);
      nextEvaluationPointDayOfWeek = nextEvaluationPointTimestamp.dayOfWeek();
    } while (!this.daysOfWeekContains(nextEvaluationPointDayOfWeek));

    return nextEvaluationPointTimestamp;
  }

  private nextInVisibleDayOfWeekTimestamp(evaluationPoint: Dayjs): Dayjs {
    let nextEvaluationPointTimestamp: Dayjs = evaluationPoint;
    let nextEvaluationPointDayOfWeek: DayOfWeek;
    do {
      nextEvaluationPointTimestamp = nextEvaluationPointTimestamp.plusDays(1);
      nextEvaluationPointDayOfWeek = nextEvaluationPointTimestamp.dayOfWeek();
    } while (this.daysOfWeekContains(nextEvaluationPointDayOfWeek));

    return nextEvaluationPointTimestamp;
  }

  private daysOfWeekContains(evalPointDayOfWeek: DayOfWeek): boolean {
    return this._daysOfWeek.has(evalPointDayOfWeek);
  }
}
