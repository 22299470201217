import { type PopoverOrigin } from '@mui/material/Popover';
import { useTheme } from '@mui/material/styles';
import { useIntl } from 'react-intl';

import { ZoneType } from '../../../../models';
import CustomPopover from '../../../CustomPopover';

import { ZoneRuleFormServerError, ZoneRulesForm, ZoneRulesFormValues } from './ZoneRulesForm';

const POPOVER_WIDTH = 440;
const CONTROL_BUTTON_WIDTH = 52;
const CONTROL_RIGHT_MARGIN = 24;
const CONTROL_POPOVER_GAP = 8;

export enum ZoneManagementActionState {
  ZonesPreview = 'zonesPreview',
  DrawingNewZone = 'drawingNewZone',
  NewZoneAreaManipulation = 'newZoneAreaManipulation',
  NewZoneInformationScreen = 'newZoneInformationScreen',
  ViewZoneInformationScreen = 'viewZoneInformationScreen',
  EditZoneAreaManipulation = 'editZoneAreaManipulation',
  EditZoneInformationScreen = 'editZoneInformationScreen',
}

interface ZoneRuleSettingsPopoverProps {
  isOpen: boolean;
  zoneSettings: ZoneRulesFormValues;
  popOverAnchor?: HTMLElement | null;
  onZoneTypeChange?(zoneType: ZoneType): void;
  onZoneRuleFormClose(): void;
  onZoneRuleSubmitButtonClick?(values: ZoneRulesFormValues): void;
  onEditZoneClick?(): void;
  onZoneDeleteClick?(): void;
  zoneManagementActionState: ZoneManagementActionState;
  popOverAnchorOrigin?: PopoverOrigin;
  popOverTransformOrigin?: PopoverOrigin;
  readOnly?: boolean;
  serverError?: ZoneRuleFormServerError;
}

export function ZoneRuleSettingsPopover({
  isOpen,
  onZoneTypeChange = () => {},
  onZoneRuleFormClose,
  onZoneRuleSubmitButtonClick = () => {},
  onEditZoneClick = () => {},
  onZoneDeleteClick = () => {},
  zoneSettings,
  zoneManagementActionState,
  popOverAnchor,
  popOverAnchorOrigin = { vertical: 'top', horizontal: 'right' },
  popOverTransformOrigin = {
    vertical: 'top',
    horizontal: CONTROL_RIGHT_MARGIN + CONTROL_BUTTON_WIDTH + CONTROL_POPOVER_GAP + POPOVER_WIDTH,
  },
  readOnly = false,
  serverError,
}: ZoneRuleSettingsPopoverProps) {
  const { spacing } = useTheme();
  const intl = useIntl();

  return (
    <CustomPopover
      open={isOpen}
      anchorOrigin={popOverAnchorOrigin}
      transformOrigin={popOverTransformOrigin}
      handleClose={onZoneRuleFormClose}
      anchorEl={popOverAnchor}
      style={{ padding: `${spacing(0)}`, maxWidth: POPOVER_WIDTH }}
    >
      <ZoneRulesForm
        onSubmit={onZoneRuleSubmitButtonClick}
        intl={intl}
        onDelete={onZoneDeleteClick}
        onZoneTypeChange={onZoneTypeChange}
        initialValues={zoneSettings}
        onCancel={onZoneRuleFormClose}
        onZoneEditClick={onEditZoneClick}
        zoneManagementActionState={zoneManagementActionState}
        readOnly={readOnly}
        serverError={serverError}
      />
    </CustomPopover>
  );
}
