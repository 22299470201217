import { useState } from 'react';

import { MapFilters } from '../components/Map/ZoneControls/MapFilters';
import { GraphqlVehicleUsageStatusHelper, MspTypeHelper, VehicleTypeHelper, ZoneTypeHelper } from '../models';

const initialFilters = {
  vehicleTypes: VehicleTypeHelper.allTypes(),
  vehicleStatuses: GraphqlVehicleUsageStatusHelper.allTypes(),
  mspTypes: MspTypeHelper.allTypes(),
  zoneTypes: ZoneTypeHelper.allTypes(),
  infrastructureTypes: [],
};

export const useMapFilterChange: UseMapFilterChange = () => {
  const [mapEntitiesFilters, setMapEntitiesFilter] = useState<MapFilters>(initialFilters);

  const handleFilterChange = (values: MapFilters) => {
    const newFilters = {
      vehicleTypes: values.vehicleTypes,
      vehicleStatuses: values.vehicleStatuses,
      mspTypes: values.mspTypes,
      zoneTypes: values.zoneTypes,
      infrastructureTypes: values.infrastructureTypes,
    };
    setMapEntitiesFilter(newFilters);
  };

  const resetFilters = () => {
    setMapEntitiesFilter(initialFilters);
  };

  const anyFilterApplied =
    mapEntitiesFilters.vehicleTypes.length !== VehicleTypeHelper.allTypes().length ||
    mapEntitiesFilters.vehicleStatuses.length !== GraphqlVehicleUsageStatusHelper.allTypes().length ||
    mapEntitiesFilters.mspTypes.length !== MspTypeHelper.allTypes().length ||
    mapEntitiesFilters.zoneTypes.length !== ZoneTypeHelper.allTypes().length ||
    !!mapEntitiesFilters.infrastructureTypes.length;

  return {
    filters: mapEntitiesFilters,
    anyFilterApplied,
    handleFilterChange,
    resetFilters,
  };
};

type UseMapFilterChange = () => UseMapFilterChangeReturnType;

interface UseMapFilterChangeReturnType {
  filters: MapFilters;
  anyFilterApplied: boolean;
  handleFilterChange: (values: MapFilters) => void;
  resetFilters: () => void;
}
