import { useState, useEffect, useCallback } from 'react';

import { Box, FormControl, FormControlLabel, MenuItem, Select, Slider, Switch, Typography, useTheme } from '@mui/material';
import chroma from 'chroma-js';
import { useAtom, useSetAtom } from 'jotai';
import { useIntl } from 'react-intl';

import {
  colorScaleAtom,
  rangesAtom,
  reverseColorsAtom,
  selectedColorScaleIndexAtom,
  totalColorsAtom,
} from '../../atoms/hexagon-layer-atoms';

const PREDEFINED_COLOR_SCALES = [
  { label: 'Default', colors: ['#F5E231', '#C62E16'] },
  { label: 'Cool Blues', colors: ['#e0f3f8', '#313695'] },
  { label: 'Warm Reds', colors: ['#fee0d2', '#a50f15'] },
];

/**
 * Generates color scales based on the predefined scales and the given number of colors.
 * Optionally reverses the color scales.
 *
 * @param {number} count - The number of colors to generate for the color scale.
 * @param {boolean} [reverseColors=false] - Whether to reverse the generated color scales.
 * @returns {string[][]} An array of color scales.
 */
function loadColors(count: number, reverseColors = false) {
  return PREDEFINED_COLOR_SCALES.map(({ colors }) => {
    let scale = chroma.scale(colors).colors(count);
    return reverseColors ? scale.reverse() : scale;
  });
}

/**
 * ColorScaleGenerator component provides a UI for selecting and modifying color scales
 * based on predefined options. It allows users to reverse colors, adjust the number of colors,
 * and preview the selected color scale.
 *
 * @returns {JSX.Element} The rendered ColorScaleGenerator component.
 */
function ColorScaleGenerator() {
  const { formatMessage } = useIntl();
  const { palette, spacing } = useTheme();

  const [totalColors, setTotalColors] = useAtom(totalColorsAtom);
  const [reverseColors, setReverseColors] = useAtom(reverseColorsAtom);
  const [selectedColorScaleIndex, setSelectedColorScaleIndex] = useAtom(selectedColorScaleIndexAtom);
  const setColorScale = useSetAtom(colorScaleAtom);
  const setRanges = useSetAtom(rangesAtom);

  const [colorScales, setColorScales] = useState<string[][]>([]);

  // Generates the color scales based on the current state and sets the selected color scale.
  const generateColorScales = useCallback(() => {
    const scales = loadColors(totalColors, reverseColors);
    setColorScales(scales);
    setColorScale(scales[selectedColorScaleIndex]);
  }, [totalColors, reverseColors, selectedColorScaleIndex, setColorScale]);

  useEffect(() => {
    generateColorScales();
  }, [generateColorScales]);

  // Calculates and sets the ranges based on the total number of colors.
  useEffect(() => {
    const min = 1;
    const max = 30;
    const step = (max - min) / totalColors;
    const newRanges = Array.from({ length: totalColors }, (_, i) => Math.round(min + i * step));
    setRanges(newRanges);
  }, [totalColors, setRanges]);

  // Handles the slider change event to update the total number of colors.
  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setTotalColors(newValue as number);
  };

  // Handles the switch toggle event to update the reverse colors state.
  const handleReverseChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReverseColors(event.target.checked);
  };

  // Handles the color scale selection change event to update the selected color scale.
  const handleColorScaleChange = (event: any) => {
    setSelectedColorScaleIndex(event.target.value as number);
    setColorScale(colorScales[event.target.value as number]);
  };

  return (
    <Box display="flex" flexDirection="column" gap={spacing(2)}>
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" gap={spacing(2)}>
        <Typography variant="bodySmall" color={palette.baseLight.base60}>
          {formatMessage({ id: 'analytics.heatmap.colours' })}
        </Typography>
        <FormControlLabel
          control={<Switch checked={reverseColors} onChange={handleReverseChange} />}
          label={
            <Typography variant="bodySmall" color={palette.baseLight.main}>
              {formatMessage({ id: 'analytics.heatmap.reversed' })}
            </Typography>
          }
          labelPlacement="start"
        />
      </Box>
      <FormControl fullWidth>
        <Select
          value={selectedColorScaleIndex}
          onChange={handleColorScaleChange}
          sx={{
            minHeight: 'auto',
            '& .MuiSelect-select': {
              minHeight: 'auto',
            },
          }}
        >
          {colorScales.map((scale, index) => (
            <MenuItem key={index} value={index}>
              <Box display="flex" justifyContent="center" alignItems="center" width="100%" height={spacing(2.8)} borderRadius={spacing(1)}>
                {scale.map((color, index) => (
                  <Box
                    key={index}
                    style={{
                      backgroundColor: color,
                      flex: 1,
                      height: '100%',
                    }}
                  />
                ))}
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Box>
        <Typography id="color-slider" gutterBottom>
          {formatMessage({ id: 'analytics.heatmap.gradationScale' })}
        </Typography>
        <Slider
          value={totalColors}
          onChange={handleSliderChange}
          aria-labelledby="color-slider"
          step={1}
          min={3}
          max={7}
          marks={[
            { value: 3, label: '3' },
            { value: 7, label: '7' },
          ]}
          sx={{
            '& .MuiSlider-track': {
              height: spacing(1),
              backgroundColor: palette.baseLight.base8,
              border: 'none',
            },
            '& .MuiSlider-rail': {
              height: spacing(1),
              backgroundColor: palette.baseLight.base8,
            },
            '& .MuiSlider-thumb': {
              backgroundColor: palette.secondary.dark,
            },
            '& .MuiSlider-mark': {
              display: 'none', // Hide the dots
            },
            '& .MuiSlider-markLabel': {
              color: palette.baseLight.base60,
            },
          }}
        />
      </Box>
    </Box>
  );
}

export default ColorScaleGenerator;
