import { useCallback, useEffect } from 'react';

import type { Row } from '@tanstack/react-table';
import isEmpty from 'lodash/isEmpty';

import { LoadingAndErrorWithRetryAndNoResults } from '../../../components/LoadingAndErrorWithRetryAndNoResults';
import NewDataTable from '../../../design-system/NewDataTable';
import { themeOptions as theme } from '../../../init-setup/ThemeOptions';
import { VehiclePricingDto } from '../../../services/vehicle-fee-pricing';

import { useVehiclePricingDispatch, useVehiclePricingState, VehiclePricingActionType } from './contexts';
import { useFetchVehiclePricingList, usePollInProgressVehiclePricingTransitions, useUpdatePricingModalActionMode } from './hooks';
import { VehiclePricingTableColumnDefs } from './VehiclePricingTableColumnDefs';

export const VehiclePricingTableWithLoadingOrNoContentMessage = () => {
  const updatePricingModalActionMode = useUpdatePricingModalActionMode();

  const MemoizedVehiclePricingTableColumnDefs = VehiclePricingTableColumnDefs();
  const vehiclePricingState = useVehiclePricingState();
  const vehiclePricingDispatch = useVehiclePricingDispatch()!;
  const fetchVehicleFeePricingListAndUpdateState = useFetchVehiclePricingList();

  useEffect(() => {
    (async () => {
      await fetchVehicleFeePricingListAndUpdateState();
    })();
  }, [fetchVehicleFeePricingListAndUpdateState]);

  usePollInProgressVehiclePricingTransitions();

  const handleTableRowClick = useCallback(
    function handleTableRowClick(row: Row<VehiclePricingDto>) {
      vehiclePricingDispatch({
        type: VehiclePricingActionType.UpdateSelectedVehicleFeePricingId,
        payload: row.original.id,
      });
      updatePricingModalActionMode(row.original);
    },
    [vehiclePricingDispatch, updatePricingModalActionMode],
  );

  if (isEmpty(vehiclePricingState.list)) {
    return (
      <LoadingAndErrorWithRetryAndNoResults
        loading={vehiclePricingState.listStatus.isPending()}
        noResults={vehiclePricingState.listStatus.isSucceeded()}
        error={vehiclePricingState.listStatus.isFailed()}
        onRetry={fetchVehicleFeePricingListAndUpdateState}
        baseTranslationKey="vehicleFeePricingList"
        style={{ height: 'calc(100vh - 172px)', backgroundColor: theme.palette.tertiary.main }}
      />
    );
  }
  return (
    <NewDataTable<VehiclePricingDto, 'default'>
      columns={MemoizedVehiclePricingTableColumnDefs}
      data={vehiclePricingState.list}
      onRowClickHandler={handleTableRowClick}
      height={`calc(100vh - 200px)`}
    />
  );
};
