import { useCallback, useEffect, useState } from 'react';

import { AggregatedVehicle } from '../../../../models';
import { fetchAggregatedVehicles } from '../../../../utils/queries';

function useFetchMspVehicles() {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allMspVehicles, setAllMspVehicles] = useState<AggregatedVehicle[]>([]);

  const getAggregatedVehicles = useCallback(async () => {
    setError(false);
    setLoading(true);
    try {
      const res = await fetchAggregatedVehicles();
      if (res.length === 0) {
        setAllMspVehicles([]);
      } else {
        setAllMspVehicles(res);
      }
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await getAggregatedVehicles();
    })();
  }, [getAggregatedVehicles]);

  return { allMspVehicles, loading, error, getAggregatedVehicles };
}

export default useFetchMspVehicles;
