import { EnumTabHelper } from '../../utils/EnumTabHelper';

export enum BudgetPlanInformationTab {
  PlanDetails = 'PLAN_DETAILS',
  Members = 'MEMBERS',
}

const budgetPlanTranslations = {
  PlanDetails: 'budgetPlanTab.planDetails',
  Members: 'common.members',
};

export const BudgetPlanInformationTabHelper = new EnumTabHelper(BudgetPlanInformationTab, budgetPlanTranslations);
