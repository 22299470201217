import type { Theme } from '@mui/material/styles';

export const sliderRailStyles = (theme: Theme) => ({
  height: '16px',
  color: theme.palette.secondary.tint,
});

export const sliderStyles = (theme: Theme) => ({
  flex: 1,
  '&.MuiSlider-root': {
    pointerEvents: 'all !important',
    cursor: 'pointer !important',
  },
});

export const nonWideIntervalPreviewTooltipStyles = (theme: Theme) => ({
  whiteSpace: 'pre',
  textAlign: 'center',
});
