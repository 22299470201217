class ConsoleLogger {
  static logInfo(message: string, ...optionalParams: any[]): void {
    console.info(`[INFO] ${message}`, ...optionalParams);
  }

  static logWarn(message: string, ...optionalParams: any[]): void {
    console.warn(`[WARN] ${message}`, ...optionalParams);
  }

  static logError(message: string, ...optionalParams: any[]): void {
    console.error(`[ERROR] ${message}`, ...optionalParams);
  }

  static logDebug(message: string, ...optionalParams: any[]): void {
    console.debug(`[DEBUG] ${message}`, ...optionalParams);
  }

  static logTrace(message: string, ...optionalParams: any[]): void {
    console.trace(`[TRACE] ${message}`, ...optionalParams);
  }
}

export default ConsoleLogger;
