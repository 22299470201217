import { useCallback, useEffect } from 'react';

import Box from '@mui/material/Box';

import { BudgetPlanDto } from '../../../services/budget-plan';
import { listBudgetPlans } from '../../../utils/queries';

import { REQUEST_STATUS } from './atoms/budget-plan-atoms';
import { useBudgetPlan } from './atoms/hooks';
import { BudgetPlanModal } from './BudgetPlanModal';
import { BudgetPlansTable } from './BudgetPlansTable';
import { HeadingSection } from './HeadingSection';

export const BudgetPlanLayout = () => {
  const { setBudgetPlans, budgetPlanModalVisible, setBudgetPlansFetchStatus } = useBudgetPlan();

  const fetchBudgetPlansData = useCallback(async () => {
    setBudgetPlansFetchStatus(REQUEST_STATUS.PENDING);
    try {
      const plans = (await listBudgetPlans()) || [];
      const processedPlans = plans.map((budgetPlan) => new BudgetPlanDto(budgetPlan));
      setBudgetPlans(processedPlans);
      setBudgetPlansFetchStatus(REQUEST_STATUS.SUCCESS);
    } catch (error) {
      console.error('Failed to fetch budget plans:', error);
      setBudgetPlansFetchStatus(REQUEST_STATUS.FAILURE);
    }
  }, [setBudgetPlans, setBudgetPlansFetchStatus]);

  useEffect(() => {
    if (!budgetPlanModalVisible) {
      fetchBudgetPlansData();
    }
  }, [budgetPlanModalVisible, fetchBudgetPlansData]);

  return (
    <Box component="main">
      <HeadingSection />
      <BudgetPlansTable fetchBudgetPlans={fetchBudgetPlansData} />
      <BudgetPlanModal />
      {/* TODO: Add retry modal if needed */}
      {/* {selectedPlanState.selectedFailedPlanId && (
        <RetryBudgetStatusModal
          open={!!selectedPlanState.selectedFailedPlanId}
          onClose={() => resetFailedPlanIdToNull()}
          planName={planIdToBudgetPlan[selectedPlanState.selectedFailedPlanId!].activeState.name}
          onRetry={handleTransitionFailedPlanStatusUpdateRetry}
        />
      )} */}
    </Box>
  );
};
