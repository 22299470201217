import { forwardRef } from 'react';
import type { ReactNode, MouseEvent } from 'react';

import { Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { InfoTooltip } from './InfoTooltip';
import { controlButtonStyles, titleStyles } from './MapOverlayActionButton.styles';

interface MapOverlayActionButtonProps {
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  selected: boolean;
  icon: ReactNode;
  label: string;
  tooltipTitle: string;
  disabled?: boolean;
}

export const MapOverlayActionButton = forwardRef<HTMLButtonElement, MapOverlayActionButtonProps>(
  ({ onClick, selected, icon, label, tooltipTitle, disabled = false }, ref) => {
    const theme = useTheme();
    return (
      <InfoTooltip title={tooltipTitle}>
        <Button ref={ref} onClick={onClick} sx={controlButtonStyles(theme, selected, disabled)} disabled={disabled}>
          {icon}
          <Typography component="span" sx={titleStyles}>
            {label}
          </Typography>
        </Button>
      </InfoTooltip>
    );
  },
);

MapOverlayActionButton.displayName = 'MapOverlayActionButton';
