import React, { Fragment, type ReactNode, useCallback } from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
  IconButton,
  type TableRowProps,
} from '@mui/material';
import Box from '@mui/material/Box';
import { type Theme, type SxProps, useTheme } from '@mui/material/styles';
import TableSortLabel from '@mui/material/TableSortLabel';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  type Row,
  type ColumnDef,
  type CellContext,
  getSortedRowModel,
  type SortDirection,
  type Header,
  type Cell,
} from '@tanstack/react-table';

import { ReactComponent as ExpandMoreOutlinedIcon } from '../assets/icons/outline/chevronDown.svg';
import { ReactComponent as ExpandLessOutlinedIcon } from '../assets/icons/outline/chevronUp.svg';
import { themeOptions } from '../init-setup/ThemeOptions';

const TABLE_HEADER_HEIGHT = '46px';

// TODO: Check this before raising MR
declare module '@tanstack/react-table' {
  interface ColumnMeta<TData, TValue> {
    // Your additional properties here
    getCellContext: (context: CellContext<TData, TValue>) => { styles: any };
  }
}

type WmvTableVariants = 'default' | 'no-border';
type WmvTableCategories = 'default' | 'expander';

interface TableDefaultProps<RowData> {
  columns: ColumnDef<RowData>[];
  data: RowData[];
  onRowClickHandler?: (row: Row<RowData>) => void;
  variant?: WmvTableVariants;
  category?: WmvTableCategories;
  height?: string | number;
  enableSorting?: boolean;
  tableContainerSx?: SxProps<Theme>;
}

interface TableExpanderProps<RowData> extends TableDefaultProps<RowData> {
  expandedRowId: string;
  expanderComponent: (row: Row<RowData>, expanderIndex: number, rows: Row<RowData>[]) => ReactNode;
}

export type UserTablePropsNew<RowData, T extends string> = T extends 'default' ? TableDefaultProps<RowData> : TableExpanderProps<RowData>;

export function NewDataTable<RowData extends object, Variant extends string>({
  columns,
  data,
  onRowClickHandler,
  variant = 'default',
  category = 'default',
  expandedRowId,
  expanderComponent,
  height = '100%',
  enableSorting = true,
  tableContainerSx,
}: UserTablePropsNew<RowData, Variant>) {
  const table = useReactTable<RowData>({
    data,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(), //client-side sorting
    enableSorting,
  });
  const theme = useTheme();
  const { typography } = theme;

  const TableCellWithSort = useCallback(
    ({ header, children }: { header: Header<RowData, unknown>; children: ReactNode }) => (
      <TableSortLabel
        active={!!header.column.getIsSorted()}
        direction={!!header.column.getIsSorted() ? (header.column.getIsSorted() as SortDirection) : header.column.getAutoSortDir()}
        onClick={header.column.getToggleSortingHandler()}
        sx={tableSortLabelSx}
        title={
          header.column.getNextSortingOrder() === 'asc'
            ? 'Sort ascending'
            : header.column.getNextSortingOrder() === 'desc'
            ? 'Sort descending'
            : 'Clear sort'
        }
      >
        <Box
          sx={{
            overflow: 'hidden',
            width: 'inherit',
            textOverflow: 'ellipsis',
          }}
          title={typeof header.column.columnDef.header === 'string' ? header.column.columnDef.header : ''}
        >
          {children}
        </Box>
      </TableSortLabel>
    ),
    [],
  );

  const ExpanderCell = useCallback(
    ({ row }: { row: Row<RowData> }) => (
      <TableCell sx={cellDefaultSx}>
        <IconButton onClick={() => onRowClickHandler && onRowClickHandler(row)} sx={{ p: 0 }}>
          {expandedRowId === row.id ? <ExpandLessOutlinedIcon style={iconSx} /> : <ExpandMoreOutlinedIcon style={iconSx} />}
        </IconButton>
      </TableCell>
    ),
    [expandedRowId, onRowClickHandler],
  );

  const TableHeaderCell = useCallback(
    ({ header }: { header: Header<RowData, unknown> }) => (
      <TableCell
        key={header.id}
        sx={[
          {
            ...typography.bodyMediumBold,
            ...(header.column.getCanSort() ? { cursor: 'pointer' } : {}),
            maxWidth: header.getSize(),
            textOverflow: 'ellipsis',
          },
          ...(Array.isArray(cellDefaultSx) ? cellDefaultSx : [cellDefaultSx]),
        ]}
        sortDirection={header.column.getIsSorted()}
        title={typeof header.column.columnDef.header === 'string' ? header.column.columnDef.header : ''}
      >
        {header.column.getCanSort() ? (
          <TableCellWithSort header={header}>{flexRender(header.column.columnDef.header, header.getContext())}</TableCellWithSort>
        ) : (
          flexRender(header.column.columnDef.header, header.getContext())
        )}
      </TableCell>
    ),
    [TableCellWithSort, typography],
  );

  const TableBodyCell = useCallback(
    ({ cell }: { cell: Cell<RowData, unknown> }) => (
      <TableCell
        key={cell.id}
        sx={[
          {
            ...typography.bodyMedium,
            ...cell.getContext().cell.column.columnDef.meta?.getCellContext(cell.getContext())?.styles,
            maxWidth: cell.column.getSize(),
          },
          ...(Array.isArray(cellDefaultSx) ? cellDefaultSx : [cellDefaultSx]),
        ]}
        title={typeof cell.getValue() === 'string' ? (cell.getValue() as string) : ''}
      >
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </TableCell>
    ),
    [typography],
  );

  return (
    <TableContainer
      component={Paper}
      sx={[
        { boxShadow: 'none', height: height, ...(variant === 'no-border' ? noBorderStyles(theme) : {}) },
        ...(Array.isArray(tableContainerSx) ? tableContainerSx : [tableContainerSx]),
      ]}
    >
      <Table stickyHeader sx={{ border: '1px solid rgba(47, 46, 65, 0.08)', backgroundColor: 'inherit' }}>
        <TableHead>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {category === 'expander' && <TableCell key="expander" sx={cellDefaultSx}></TableCell>}
              {headerGroup.headers.map((header, index) => (
                <Fragment key={header.id}>
                  <TableHeaderCell header={header} />
                </Fragment>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody sx={{ backgroundColor: 'inherit' }}>
          {table.getRowModel().rows.map((row, index) => (
            <Fragment key={row.id}>
              <TableRowStyled
                key={row.id}
                onClick={() => onRowClickHandler && onRowClickHandler(row)}
                sx={[
                  {
                    ...(!!expanderComponent &&
                      expandedRowId === row.id && { position: 'sticky', top: TABLE_HEADER_HEIGHT, zIndex: 2, backgroundColor: 'inherit' }),
                  },
                ]}
              >
                {category === 'expander' && <ExpanderCell row={row} />}
                {row.getVisibleCells().map((cell) => (
                  <Fragment key={cell.id}>
                    <TableBodyCell cell={cell} />
                  </Fragment>
                ))}
              </TableRowStyled>
              {!!expanderComponent && expandedRowId === row.id ? (
                <TableRowStyled enableHover={false}>
                  <TableCell colSpan={columns?.length + 1} sx={{ p: 0 }}>
                    {expanderComponent(row, index, table.getRowModel().rows)}
                  </TableCell>
                </TableRowStyled>
              ) : null}
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

interface StyledTableRowProps extends TableRowProps {
  enableHover?: boolean;
}

const TableRowStyled = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== 'enableHover',
})<StyledTableRowProps>(({ theme, enableHover = true }) => ({
  cursor: 'pointer',
  ...(enableHover && {
    '&:hover': {
      backgroundColor: theme.palette.secondary.tint,
    },
  }),
}));

const cellDefaultSx = (theme: Theme) => ({
  p: theme.spacing(1.25, 1.5),
  color: theme.palette.baseLight.main,
  userSelect: 'none',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
});

const iconSx = {
  color: themeOptions.palette.baseLight.main,
  height: '24px',
  width: '24px',
};

const noBorderStyles = (theme: Theme) => ({
  '& .MuiTable-root': {
    border: 'none !important',
  },
  '& .MuiTableCell-root': {
    border: 'none !important',
  },
  '& .MuiTableRow-root': {
    border: 'none !important',
    '& > th': {
      border: 'none !important',
    },
    '& > td': {
      border: 'none !important',
    },
  },
});

const tableSortLabelSx = (theme: Theme) => ({
  width: '100%',
  '& > .MuiSvgIcon-root': {
    marginLeft: 'auto',
    color: themeOptions.palette.baseLight.main,
    fill: themeOptions.palette.baseLight.main,
  },
});

export default NewDataTable;
