import { Box, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { ReactComponent as DonkeyProviderIcon } from '../assets/illustrations/donkeyProvider.svg';
import { ReactComponent as MeiaudoProviderIcon } from '../assets/illustrations/maiaudoProvider.svg';
import { ReactComponent as STWBProviderIcon } from '../assets/illustrations/stwbProvider.svg';
import { ReactComponent as ZeusProviderIcon } from '../assets/illustrations/zeusProvider.svg';

const ProviderLogo = ({ provider }: { provider: string }) => {
  const theme = useTheme();
  return (
    <Grid
      sx={{
        backgroundColor: theme.palette.white.main,
        width: theme.spacing(8),
        height: theme.spacing(8),
        boxShadow: theme.shadowOptions.belowSmall,
        border: `2px solid ${theme.palette.dark.shade8}`,
        borderRadius: theme.spacing(8),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: theme.spacing(7),
          height: theme.spacing(7),
          minWidth: '0px',
          padding: '0px',
        }}
      >
        {provider === 'zeus scooters' ? <ZeusProviderIcon /> : <></>}
        {provider === 'stwb bamberg' ? <STWBProviderIcon /> : <></>}
        {provider === 'donkey republic' ? <DonkeyProviderIcon /> : <></>}
        {provider === 'meiaudo' ? <MeiaudoProviderIcon /> : <></>}
      </Box>
    </Grid>
  );
};

export default ProviderLogo;
