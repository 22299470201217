import type { Theme } from '@mui/material/styles';

export const InputModalStyle = (theme: Theme) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: theme.palette.white.main,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(1),
  isolation: 'isolate',
  borderRadius: theme.spacing(1),
  width: '500px',
  height: '400px',
});

export const ImageHeaderStyle = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'flex-end',
  borderBottom: `1px solid ${theme.palette.dark.shade8}`,
  paddingBottom: theme.spacing(1),
});

export const ContentBoxStyle = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  gap: theme.spacing(4),
  height: '100%',
  overflow: 'auto',
  scrollbarWidth: 'thin',
});

export const ContentInnerBoxStyle = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  padding: '24px 0px 0px',
  gap: theme.spacing(2),
  width: '100%',
});

export const AboutBoxStyle = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'strech',
  gap: theme.spacing(1),
  width: '100%',
  paddingTop: theme.spacing(2),
});

export const CommonSectionStyle = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
});

export const CommonRowStyle = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
});

export const CommonTextFieldStyle = (theme: Theme) => ({
  backgroundColor: theme.palette.dark.shade8,
  width: theme.spacing(33),
});
