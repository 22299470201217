import type { ReactNode } from 'react';

import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import type { TooltipProps } from '@mui/material/Tooltip';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';

import CustomIcon from '../../utils/CustomIcon';

export interface InfoIconWithTooltipProps {
  title: ReactNode;
  placement?: TooltipProps['placement'];
  arrow?: TooltipProps['arrow'];
  tooltipProps?: Omit<TooltipProps, 'title' | 'children'>;
  iconSize?: number;
  iconColor?: string;
  disabled?: boolean;
}

export const InfoIconWithTooltip = ({
  placement = 'bottom',
  arrow = true,
  title,
  tooltipProps,
  iconSize = 16,
  iconColor = 'initial',
  disabled = false,
}: InfoIconWithTooltipProps) => {
  const { palette } = useTheme();

  if (!title) return null;
  if (isString(title) || isNumber(title)) title = <Typography variant="bodyMedium">{title}</Typography>;
  if (disabled) iconColor = palette.baseLight.base32;

  return (
    <Tooltip
      placement={placement}
      arrow={arrow}
      {...tooltipProps}
      title={
        <Box py={1} px={1}>
          {title}
        </Box>
      }
    >
      <IconButton disabled={disabled}>{CustomIcon('Info', iconColor, iconSize)}</IconButton>
    </Tooltip>
  );
};
