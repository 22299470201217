import './utils/intl';
import './polyfills';
import './plugins';

import BrowserRouterProvider from './BrowserRouterProvider';
import { AccessForbiddenAlert } from './components/AccessForbiddenAlert';
import { AppSnackbarAlert } from './components/AppSnackbarAlert';
import RenderOnAuthenticated from './components/RenderOnAuthenticated';
import InitialSetup from './init-setup';

function App() {
  return (
    <InitialSetup>
      <RenderOnAuthenticated>
        <AppSnackbarAlert />
        <AccessForbiddenAlert />
        <BrowserRouterProvider />
      </RenderOnAuthenticated>
    </InitialSetup>
  );
}

export default App;
