import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import type { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useIntl } from 'react-intl';

export function Header() {
  const { palette } = useTheme();
  const { formatMessage: fm } = useIntl();

  return (
    <Box sx={headerContainerSx}>
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-between">
        <Typography variant="h3" color={palette.baseLight.base76} component="h1">
          {fm({ id: 'support.page.title' })}
        </Typography>
        <Typography variant="bodyLarge" color={palette.baseLight.base60} component="h2">
          {fm({ id: 'support.page.subtitle' })}
        </Typography>
      </Box>
    </Box>
  );
}

export const headerContainerSx = (theme: Theme) => ({
  gridArea: 'header',
  alignItems: 'center',
  mb: 8,
});
