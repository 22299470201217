import { useTheme } from '@mui/material/styles';

import NewDataTable from '../../../design-system/NewDataTable';
import { StartAndEndDateTimeRangeValues } from '../../../wmv-components';
import { LoadingAndErrorWithRetryAndNoResults } from '../../LoadingAndErrorWithRetryAndNoResults';
import { LawEnforcementTripsListColumnDefs } from '../table-column-defs/LawEnforcementTripsListColumnDef';

export interface LawEnforcementTripSearchResult {
  startDateFormatted: string;
  startTimeFormatted: string;
  endDateFormatted: string;
  endTimeFormatted: string;
  vehicleType: string;
  qrCodeId: string;
  firstName: string;
  lastName: string;
  streetName: string;
  houseNumber: string;
}
interface LawEnforcementTripSearchResultsTableProps {
  searchResults: LawEnforcementTripSearchResult[];
  loading: boolean;
  error: boolean;
  onRetry: (formValues: StartAndEndDateTimeRangeValues) => void;
  lastSearchFormValues: StartAndEndDateTimeRangeValues | null;
  noSearchResults: boolean;
}

export const LawEnforcementTripSearchResultsTable = (props: LawEnforcementTripSearchResultsTableProps) => {
  const { searchResults, loading, error, onRetry, lastSearchFormValues, noSearchResults } = props;
  const { palette } = useTheme();

  return (
    <>
      {searchResults.length > 0 ? (
        <NewDataTable<LawEnforcementTripSearchResult, 'default'>
          columns={LawEnforcementTripsListColumnDefs}
          data={searchResults}
          height={`calc(100vh - 192px)`}
        />
      ) : (
        // 280px depends on the preceding elements; needed to center the text vertically
        <LoadingAndErrorWithRetryAndNoResults
          style={{ minHeight: `calc(100vh - (192px))`, backgroundColor: palette.white.main }}
          error={error}
          loading={loading}
          onRetry={() => {
            if (lastSearchFormValues) onRetry(lastSearchFormValues);
          }}
          baseTranslationKey="lawEnforcementTripSearch"
          noResults={noSearchResults}
        />
      )}
    </>
  );
};
