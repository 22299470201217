import type { ReactElement } from 'react';

import { Typography, Box } from '@mui/material';
import type { TooltipProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';

interface InfoTooltipProps {
  children: ReactElement;
  title: string;
  placement?: TooltipProps['placement'];
}

export const InfoTooltip = ({ children, title, placement = 'left-end' }: InfoTooltipProps) => {
  const theme = useTheme();

  return (
    <Tooltip
      slotProps={{ tooltip: { style: { borderRadius: theme.spacing(1) } } }}
      placement={placement}
      title={
        <Box px={2} py={1}>
          <Typography variant="caption">{title}</Typography>
        </Box>
      }
    >
      {children}
    </Tooltip>
  );
};
