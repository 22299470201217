import account from './account.json';
import actions from './actions.json';
import analytics from './analytics.json';
import budgetPlanning from './budgetPlanning.json';
import common from './common.json';
import supportPage from './supportPage.json';
import unsorted from './unsorted.json';

export const messages_en = {
  ...account,
  ...actions,
  ...analytics,
  ...common,
  ...unsorted,
  ...supportPage,
  ...budgetPlanning,
};

export default messages_en;
