import { ComponentsPropsList } from '@mui/material/styles/props';

import OpenSansBold from '../assets/fonts/OpenSans-Bold.ttf';
import OpenSans from '../assets/fonts/OpenSans-Regular.ttf';

export const colorPalette = {
  primary: {
    main: '#2F4858',
    dark: '#04212f',
    light: '#5a7385',
    tint: '#F0F9FE',
  },
  secondary: {
    main: '#4EC9B0',
    dark: '#009881',
    light: '#85fde2',
    tint: '#D3F5EE',
  },
  tertiary: {
    main: '#F6F6F6',
    dark: '#F0F9FE',
    light: '#F0F9FE',
    contrastText: '#FFFFFF',
  },
  dark: {
    main: 'rgba(47, 46, 65, 1)',
    shade76: 'rgba(47, 46, 65, 0.76)',
    shade60: 'rgba(47, 46, 65, 0.6)',
    shade32: 'rgba(47, 46, 65, 0.32)',
    shade24: 'rgba(47, 46, 65, 0.24)',
    shade8: 'rgba(47, 46, 65, 0.08)',
    shade4: 'rgba(47, 46, 65, 0.04)',
    shade3: 'rgba(47, 46, 65, 0.03)',
    contrastText: 'rgba(255,255,255,0)',
  },
  white: {
    main: 'rgba(255,255,255,1)',
    shade80: 'rgba(255,255,255,0.8)',
    shade56: 'rgba(255,255,255,0.56)',
    shade32: 'rgba(255,255,255,0.32)',
    shade20: 'rgba(255,255,255,0.2)',
    shade10: 'rgba(255,255,255,0.1)',
    shade6: 'rgba(255,255,255,0.06)',
    shade0: 'rgba(255,255,255,0)',
    contrastText: 'rgba(255,255,255,0)',
  },
  black: {
    main: 'rgba(255,255,255,1)',
    shade80: 'rgba(255,255,255,0.8)',
    shade56: 'rgba(255,255,255,0.56)',
    shade32: 'rgba(255,255,255,0.32)',
    shade20: 'rgba(255,255,255,0.2)',
    shade10: 'rgba(255,255,255,0.1)',
    shade6: 'rgba(255,255,255,0.06)',
    shade0: 'rgba(255,255,255,0)',
    contrastText: 'rgba(255,255,255,0)',
  },
  hexBlack: {
    main: '#ffffff',
    shade80: '#cccccc',
    shade56: '#8c8c8c',
    shade32: '#515151',
    shade20: '#333333',
    shade10: '#1a1a1a',
    shade6: '#0f0f0f',
    shade0: '#000000',
    contrastText: '#FFFFFF',
  },
  baseLight: {
    main: '#2F2E41',
    base76: '#61606F',
    base60: '#82828D',
    base32: '#BCBCC2',
    base24: '#CDCDD1',
    base8: '#EEEEF0',
    base4: '#F6F6F6',
    base3: '#F9F9F9',
    base0: '#FFFFFF',
    contrastText: '#FFFFFF',
  },
  baseDark: {
    main: '#2F2E41',
    base80: '#D5D5D9',
    base76: '#ACABB3',
    base60: '#82828D',
    base32: '#A3A3AB',
    base24: '#72717E',
    base8: '#595867',
    base4: '#444354',
    base3: '#3B3B4C',
    base0: '#2F2E41',
    contrastText: '#FFFFFF',
  },
  shadow: {
    main: 'rgba(4, 9, 33, 0.08)',
    hexMain: '#04092114',
  },
  success: {
    main: '#499F68',
    dark: '#428F5E',
    light: '#6DB286',
    tint: '#E8F3EC',
  },
  warning: {
    main: '#FFAF21',
    dark: '#E69E1E',
    light: '#FFBF4D',
    tint: '#FFF9F0',
  },
  alert: {
    main: '#DD5147',
    dark: '#C74940',
    light: '#E4746C',
    tint: '#FDF3F2',
    contrastText: '#FFFFFF',
  },
  blue: {
    main: '#0C8AFF',
    dark: '#0861B3',
    light: '#9BCFFF',
    tint: '#E7F3FF',
    contrastText: '#FFFFFF',
  },
  purple: {
    main: '#790CFF',
    dark: '#610ACC',
    light: '#943DFF',
    tint: '#F2E7FF',
    contrastText: '#FFFFFF',
  },
};

export const shadowOptions = {
  belowSmall: '0 0 2px rgba(4,9,33,0.03), 0 2px 0 rgba(4,9,33,0.05), 0 2px 6px rgba(4,9,33,0.1)',
  belowMedium: '0 6px 12px rgba(4,9,33,0.08)',
  belowLarge: '0 16px 32px rgba(4,9,33,0.1)',
  belowJumbo: '0 36px 72px rgba(4,9,33,0.12)',

  aboveSmall: '0 0 2px rgba(4,9,33,0.03), 0 -2px 0 rgba(4,9,33,0.05), 0 -2px 6px rgba(4,9,33,0.1)',
  aboveMedium: '0 -6px 12px rgba(4,9,33,0.08)',
  aboveLarge: '0 -16px 32px rgba(4,9,33,0.1)',
  aboveJumbo: '0 -36px 72px rgba(4,9,33,0.12)',

  primarySmall: '0 0 2px rgba(10,74,204,0.03), 0 2px 0 rgba(10,74,204,0.05), 0 2px 6px rgba(10,74,204,0.1)',
  primaryMedium: '0 6px 12px rgba(10,74,204,0.24)',
  primaryLarge: '0 16px 32px rgba(10,74,204,0.3)',
  primaryJumbo: '0 36px 72px rgba(10,74,204,0.3)',

  successSmall: '0 0 2px rgba(66,143,94,0.03), 0 2px 0 rgba(66,143,94,0.05), 0 2px 6px rgba(66,143,94,0.1)',
  successMedium: '0 6px 12px rgba(66,143,94,0.24)',
  successLarge: '0 16px 32px rgba(66,143,94,0.3)',
  successJumbo: '0 36px 72px rgba(66,143,94,0.3)',

  warningSmall: '0 0 2px rgba(230,158,30,0.03), 0 2px 0 rgba(230,158,30,0.05), 0 2px 6px rgba(230,158,30,0.1)',
  warningMedium: '0 6px 12px rgba(230,158,30,0.24)',
  warningLarge: '0 16px 32px rgba(230,158,30,0.3)',
  warningJumbo: '0 36px 72px rgba(230,158,30,0.3)',

  alertSmall: '0 0 2px rgba(199,73,64,0.03), 0 2px 0 rgba(199,73,64,0.05), 0 2px 6px rgba(199,73,64,0.1)',
  alertMedium: '0 6px 12px rgba(199,73,64,0.24)',
  alertLarge: '0 16px 32px rgba(199,73,64,0.3)',
  alertJumbo: '0 36px 72px rgba(199,73,64,0.3)',
};

export const utils = {
  ellipsisText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
};

export const typography = {
  allVariants: {
    fontFamily: 'OpenSans, sans-serif',
  },
  h1: {
    fontFamily: 'OpenSansBold, sans-serif',
    fontSize: '56px',
    fontWeight: '800',
    lineHeight: '72px',
  },
  h2: {
    fontFamily: 'OpenSansBold, sans-serif',
    fontSize: '42px',
    fontWeight: '800',
    lineHeight: '56px',
  },
  h3: {
    fontFamily: 'OpenSansBold, sans-serif',
    fontSize: '30px',
    fontWeight: '800',
    lineHeight: '40px',
  },
  bodyLargeBold: {
    fontFamily: 'OpenSansBold, sans-serif',
    fontSize: '20px',
    fontWeight: '700',
    lineHeight: '32px',
  },
  bodyLarge: {
    fontFamily: 'OpenSans, sans-serif',
    fontSize: '20px',
    fontWeight: '400',
    lineHeight: '32px',
  },
  bodyMediumBold: {
    fontFamily: 'OpenSansBold, sans-serif',
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '24px',
  },
  bodyMedium: {
    fontFamily: 'OpenSans, sans-serif',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
  },
  bodySmallBold: {
    fontFamily: 'OpenSansBold, sans-serif',
    fontSize: '14px',
    fontWeight: '700',
    lineHeight: '20px',
  },
  bodySmall: {
    fontFamily: 'OpenSans, sans-serif',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
  },
  captionBold: {
    fontFamily: 'OpenSansBold, sans-serif',
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '16px',
  },
  caption: {
    fontFamily: 'OpenSans, sans-serif',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '16px',
  },
};

export const themeOptions = {
  palette: colorPalette,
  typography: typography,
  breakpoints: {
    values: {
      xs: 0,
      sm: 320,
      md: 600,
      lg: 905,
      xl: 1240,
      xxl: 1440,
    },
  },
  shadowOptions: shadowOptions,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@font-face': [
          {
            fontFamily: 'OpenSans',
            fontStyle: 'normal',
            fontWeight: 400,
            src: `url(${OpenSans}) format('truetype')`,
          },
          {
            fontFamily: 'OpenSansBold',
            fontStyle: 'normal',
            fontWeight: 700,
            src: ` url(${OpenSansBold}) format('truetype')`,
          },
        ],
        body: {
          '.leaflet-tooltip': {
            backgroundColor: colorPalette.dark.shade76,
            fontFamily: 'OpenSans, sans-serif',
            color: colorPalette.baseLight.base0,
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '24px',
            padding: '8px 24px',
            border: 'none',
            borderRadius: '8px',
          },
          'leaflet-tooltip-pane': {
            backgroundColor: colorPalette.dark.shade76,
          },
          '.leaflet-tooltip-bottom::before': {
            borderBottomColor: colorPalette.dark.shade76,
          },
          // scrollbarColor and scrollbarWidth are used for firefox
          scrollbarColor: `${colorPalette.dark.shade24} ${colorPalette.dark.shade3} `,
          scrollbarWidth: 'thin',
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            width: 5,
            height: 5,
          },
          '&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track': {
            backgroundColor: colorPalette.dark.shade3,
            minHeight: 24,
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            borderRadius: 4,
            backgroundColor: colorPalette.dark.shade24,
            minHeight: 24,
          },
          '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
            backgroundColor: colorPalette.dark.shade32,
          },
          '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
            backgroundColor: colorPalette.dark.shade32,
          },
          '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
            backgroundColor: colorPalette.dark.shade32,
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
      },
      styleOverrides: {
        root: ({ ownerState }: { ownerState: ComponentsPropsList['MuiButton'] }) => ({
          textTransform: 'unset' as const, // Typescript is bombing if we remove as const, https://github.com/mui/material-ui/issues/16307
          borderRadius: '6px',
          '&.Mui-disabled': {
            backgroundColor: colorPalette.baseLight.base32,
            color: colorPalette.baseLight.base8,
            border: 'none',
          },
          ...(ownerState.size === 'large' && {
            height: '64px',
            ...typography.bodyLargeBold,
          }),
          ...(ownerState.size === 'medium' && {
            height: '44px',
            ...typography.bodyMediumBold,
          }),
          ...(ownerState.size === 'small' && {
            height: '32px',
            ...typography.bodySmallBold,
          }),
        }),
        text: {
          '&.Mui-disabled': {
            backgroundColor: 'transparent',
          },
          '&:hover': {
            backgroundColor: 'transparent',
            color: colorPalette.baseLight.main,
          },
        },
        outlined: {
          backgroundColor: colorPalette.baseLight.base0,
          borderWidth: '2px',
          borderColor: colorPalette.baseLight.base8,
          '&:hover': {
            backgroundColor: colorPalette.baseLight.base0,
            borderWidth: '2px',
            color: colorPalette.primary.dark,
          },
          '&.Mui-disabled': {
            borderWidth: '2px',
            border: 'solid',
            backgroundColor: colorPalette.baseLight.base0,
            borderColor: colorPalette.baseLight.base32,
            color: colorPalette.baseLight.base32,
          },
        },
      },
      variants: [
        {
          props: { variant: 'tertiaryContrast' } as const,
          style: {
            backgroundColor: 'transparent',
            color: colorPalette.baseLight.base0,
            border: `2px solid ${colorPalette.baseLight.base0}`,
            '&:hover': {
              border: `2px solid ${colorPalette.baseDark.base24}`,
            },
            '&.Mui-disabled': {
              color: colorPalette.baseDark.base24,
              border: `2px solid ${colorPalette.baseDark.base76}`,
            },
          },
        },
        {
          props: { variant: 'bluePrimary' } as const,
          style: {
            backgroundColor: colorPalette.primary.main,
            color: colorPalette.white.main,
            '&:hover': {
              backgroundColor: colorPalette.primary.dark,
            },
            '&.Mui-disabled': {
              backgroundColor: colorPalette.baseLight.base8,
              color: colorPalette.baseLight.base32,
            },
          },
        },
        {
          props: { variant: 'tertiary' } as const,
          style: {
            backgroundColor: 'transparent',
            color: colorPalette.baseLight.main,
            border: `2px solid ${colorPalette.baseLight.base8}`,
            '&:hover': {
              border: `2px solid ${colorPalette.baseLight.main}`,
            },
            '&.Mui-disabled': {
              color: colorPalette.baseLight.base32,
              border: `2px solid ${colorPalette.baseLight.base32}`,
            },
          },
        },
        {
          props: { variant: 'secondaryLight' } as const,
          style: {
            backgroundColor: colorPalette.baseLight.base4,
            '&:hover': {
              backgroundColor: colorPalette.primary.tint,
            },
            '&.Mui-disabled': {
              backgroundColor: colorPalette.baseLight.base8,
              color: colorPalette.baseLight.base32,
            },
          },
        },
        {
          props: { variant: 'alert' } as const,
          style: {
            backgroundColor: colorPalette.alert.main,
            color: colorPalette.baseLight.base0,
            '&:hover': {
              backgroundColor: colorPalette.alert.main,
            },
            '&.Mui-disabled': {
              backgroundColor: colorPalette.baseLight.base8,
              color: colorPalette.baseLight.base32,
            },
          },
        },
      ],
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ ownerState }: { ownerState: ComponentsPropsList['MuiOutlinedInput'] }) => ({
          backgroundColor: colorPalette.white.main,
          '& fieldset': {
            borderColor: colorPalette.dark.shade32,
            borderWidth: '1px',
            borderRadius: '4px',
          },
          '&:hover fieldset': {
            borderColor: colorPalette.primary.main,
          },
          '&.Mui-focused fieldset': {
            borderWidth: '2px !important',
            borderColor: colorPalette.primary.main,
          },
          '&.Mui-disabled:hover fieldset': {
            borderColor: colorPalette.dark.shade32,
          },
          ...(ownerState.size === 'medium' && {
            height: '44px',
          }),
          ...(ownerState.size === 'small' && {
            height: '32px',
          }),
        }),
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: colorPalette.baseLight.base76,
          fontFamily: 'OpenSans, sans-serif',
        },
        arrow: {
          color: colorPalette.baseLight.base76,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        valueLabel: {
          backgroundColor: colorPalette.baseLight.base76,
          borderRadius: '4px',
          boxShadow: shadowOptions.belowMedium,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: '8px',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: colorPalette.baseLight.base60,
          marginLeft: '4px',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        InputLabelProps: {
          shrink: true,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: '2px 16px',
          margin: '4px',
          textTransform: 'none',
          /* Body Large */
          fontStyle: 'normal',
          fontSize: '20px',
          fontWeight: 400,
          lineHeight: '32px',
        },
        '.Mui-selected': {
          fontSize: '20px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: '32px',
        },
      },
    } as const,
  },
  utils,
};
