import { useMemo, useState } from 'react';

import { CustomerInformationTab, CustomerInformationTabHelper, CustomerDetails } from '../../models';
import SidebarInfoModal from '../SidebarInfoModal';

import { CustomerBudgetInformation } from './CustomerBudgetInformation';
import UserLicenceInformation from './CustomerLicenceInformation';
import { CustomerNameAndId, UserNameAndIdProps } from './CustomerNameAndId';
import CustomerPersonalDetails from './CustomerPersonalDetails';
import CustomerTrips from './CustomerTrips';

function CustomerInformationModal({ isOpen, onClose, userInfo }: CustomerInformationModalProps) {
  const [activeTab, setActiveTab] = useState<CustomerInformationTab>(CustomerInformationTab.CustomerDetails);
  const [sidebarCustomerInfo, setSidebarCustomerInfo] = useState<SidebarCustomerInfo | null>(null);

  function handleSidebarInfoUpdate(sidebarInfo: SidebarCustomerInfo) {
    setSidebarCustomerInfo(sidebarInfo);
  }

  const sidebarHeaderComponent = useMemo(
    () => (
      <CustomerNameAndId
        firstName={sidebarCustomerInfo?.firstName ?? userInfo.firstName}
        lastName={sidebarCustomerInfo?.lastName ?? userInfo.lastName}
        subjectClaim={userInfo.subjectClaim}
      />
    ),
    [sidebarCustomerInfo, userInfo],
  );

  return (
    <SidebarInfoModal<CustomerInformationTab>
      isOpen={isOpen}
      onClose={onClose}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      tabOptions={CustomerInformationTabHelper.allLabelAndValues()}
      sidebarHeaderComponent={sidebarHeaderComponent}
    >
      <CustomerPersonalDetails subjectClaim={userInfo.subjectClaim} updateSidebarCustomerInfo={handleSidebarInfoUpdate} />
      <CustomerTrips customerSubjectClaim={userInfo.subjectClaim} />
      <CustomerBudgetInformation />
      <UserLicenceInformation subjectClaim={userInfo.subjectClaim} />
    </SidebarInfoModal>
  );
}

interface CustomerInformationModalProps {
  isOpen: boolean;
  onClose: () => void;
  userInfo: CustomerDetails;
}

export interface SidebarCustomerInfo extends UserNameAndIdProps {}

export default CustomerInformationModal;
