import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';

import { VehicleType, VehicleTypeHelper } from '../../../../models';
import { Checkbox } from '../../../../wmv-components';
import { ErrorMessage } from '../../../FormFields/ErrorMessage';

import { ZoneRuleFormFields, ZoneRuleFormServerError, ZoneRulesFormValues } from './ZoneRulesForm';
import { vehicleTypesGroupCheckboxStyle } from './ZoneRulesForm.styles';

interface ZoneVehicleTypesFormFieldProps {
  selectedVehicleTypes: VehicleType[];
  readOnlyZoneRuleForm: boolean;
  serverError?: ZoneRuleFormServerError;
}

export const ZoneVehicleTypesFormField = ({ selectedVehicleTypes, readOnlyZoneRuleForm, serverError }: ZoneVehicleTypesFormFieldProps) => {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const { errors, setFieldValue, setFieldTouched, touched } = useFormikContext<ZoneRulesFormValues>();

  const updateZoneServerViolationsFieldToError = serverError?.updateError;
  const createZoneServerViolationsFieldToError = serverError?.createError;
  const zoneServerViolationsFieldToError = updateZoneServerViolationsFieldToError || createZoneServerViolationsFieldToError;

  const handleVehicleChange = async (vehicleTypes: VehicleType[]) => {
    await setFieldTouched(ZoneRuleFormFields.applicableVehicleTypes, true);
    await setFieldValue(ZoneRuleFormFields.applicableVehicleTypes, vehicleTypes);
  };

  return (
    <Stack>
      <Typography variant="bodyMediumBold" component="h4">
        {formatMessage({ id: 'map.forms.section.vehicleRestrictions' })}
      </Typography>
      <Typography variant="caption" color={theme.palette.baseLight.base76}>
        {formatMessage({ id: 'map.forms.vehicleRestrictions.label' })}
        <Box display="inline-block" ml={0.5}>
          <ErrorMessage
            error={
              (touched.applicableVehicleTypes && (errors.applicableVehicleTypes as string)) ||
              zoneServerViolationsFieldToError?.['applicableVehicleTypes']?.message
            }
          />
        </Box>
      </Typography>
      <Box>
        <Checkbox.Group<VehicleType>
          name={ZoneRuleFormFields.applicableVehicleTypes}
          options={VehicleTypeHelper.valuesWithLabel()}
          onChange={handleVehicleChange}
          checkedValues={selectedVehicleTypes}
          sx={vehicleTypesGroupCheckboxStyle}
          size="medium"
          disabled={readOnlyZoneRuleForm}
        />
      </Box>
    </Stack>
  );
};
