import { useCallback } from 'react';

import { Typography } from '@mui/material';

import { Modal } from '../../../../../wmv-components';
import { REQUEST_STATUS } from '../../atoms/budget-plan-atoms';
import { useBudgetPlan, useBudgetPlanMembership } from '../../atoms/hooks';
import { unlinkContentStyles } from '../styles';

import { MembershipDetailsModalContent } from './MembershipDetailsModalContent';

export const MembershipDetailsModal = () => {
  const { bmSelectedMembershipId, setBMSelectedMembershipId, retryStateTransitionApiStatus, discardFailedStateTransitionApiStatus } =
    useBudgetPlanMembership();
  const { selectedPlan } = useBudgetPlan();

  const handleUnlinkMembershipModalClose = useCallback(() => {
    setBMSelectedMembershipId(null);
  }, [setBMSelectedMembershipId]);

  return (
    <Modal
      open={!!bmSelectedMembershipId}
      onClose={handleUnlinkMembershipModalClose}
      disableClose={
        retryStateTransitionApiStatus === REQUEST_STATUS.PENDING || discardFailedStateTransitionApiStatus === REQUEST_STATUS.PENDING
      }
    >
      <Modal.Content sx={unlinkContentStyles}>
        <Typography variant="bodyLargeBold">{selectedPlan?.activeState.name}</Typography>
        <MembershipDetailsModalContent onUnlinkMembershipModalClose={handleUnlinkMembershipModalClose} />
      </Modal.Content>
    </Modal>
  );
};
