import { type ReactNode, type SyntheticEvent } from 'react';

import type { TabsOwnProps } from '@mui/material';
import Box from '@mui/material/Box';
import type { SxProps, Theme } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { ListItemConfig } from '../../models';

import { tabHoverStyle, tabsContainerStyles, tabsOrientationStyles, tabsStyle } from './styles';

export function WmvTabs<TabValue extends {}>({
  options,
  activeTab,
  onChange,
  orientation = 'horizontal',
  variant = 'fullWidth',
  indicatorColor = 'secondary',
  textColor,
  children,
  disabledTabs = [],
}: WmvTabsProps<TabValue>) {
  const theme = useTheme();

  return (
    <Box sx={tabsContainerStyles(theme, orientation)}>
      <Box sx={tabsOrientationStyles(theme, orientation)}>
        <Tabs
          value={activeTab}
          onChange={handleChange}
          variant={variant}
          orientation={orientation}
          indicatorColor={indicatorColor}
          textColor={textColor}
          sx={tabsStyle(theme, orientation)}
          allowScrollButtonsMobile
          visibleScrollbar
        >
          {options.map(({ value, label }) => (
            <Tab sx={tabHoverStyle(theme)} label={label} key={label} value={value} disabled={disabledTabs.includes(value)} />
          ))}
        </Tabs>
      </Box>
      {children}
    </Box>
  );

  function handleChange(event: SyntheticEvent, newValue: TabValue) {
    onChange(newValue);
  }
}

export function WmvTabPanel<TabValue extends {}>({ children, activeTab, currentTab, sx, ...other }: TabPanelProps<TabValue>) {
  return (
    <Box hidden={activeTab !== currentTab} {...other} sx={sx}>
      {activeTab === currentTab && children}
    </Box>
  );
}

export interface WmvTabsProps<TabValue> {
  options: ListItemConfig<TabValue>[];
  activeTab: TabValue;
  onChange: (value: TabValue) => void;
  children?: ReactNode;
  orientation?: TabsOwnProps['orientation'];
  variant?: TabsOwnProps['variant'];
  indicatorColor?: TabsOwnProps['indicatorColor'];
  textColor?: TabsOwnProps['textColor'];
  disabledTabs?: TabValue[];
}

interface TabPanelProps<TabValue> {
  activeTab: TabValue;
  currentTab: TabValue;
  children?: ReactNode;
  sx?: SxProps<Theme>;
}
