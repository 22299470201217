import type { Dayjs, PluginFunc } from 'dayjs';
import dayjs from 'dayjs';

import {
  DEFAULT_DATE_FORMAT_DAYJS,
  DEFAULT_DATETIME_FORMAT_DAYJS,
  DEFAULT_TIME_FORMAT,
  DEFAULT_TIME_FORMAT_WITH_SECONDS,
  ISO_DATE_FORMAT_DAYJS,
} from '../../utils/constants';

declare module 'dayjs' {
  interface Dayjs {
    formatAsTimeWithoutSeconds(): string;

    formatAsReadableTimeWithoutSeconds(): string;

    formatAsTimeWithoutMilliSeconds(): string;

    formatAsDateString(): string;

    formatAsDateTimeString(): string;

    formatAsISODateString(): string;
  }
}

declare global {
  interface Number {
    parseSecondsSinceBeginningOfDay(): Dayjs;
    formatSecondsAsDuration(): string;
  }
}

const formatTimestampPlugin: PluginFunc = (option, dayjsClass, dayjsFactory) => {
  dayjsClass.prototype.formatAsTimeWithoutSeconds = function (): string {
    return this.format(DEFAULT_TIME_FORMAT);
  };

  dayjsClass.prototype.formatAsReadableTimeWithoutSeconds = function (): string {
    const hrs = this.getHour();
    const min = this.getMinute();
    return `${hrs}h ${min}m`;
  };

  dayjsClass.prototype.formatAsTimeWithoutMilliSeconds = function (): string {
    return this.format(DEFAULT_TIME_FORMAT_WITH_SECONDS);
  };

  dayjsClass.prototype.formatAsDateString = function (): string {
    return this.format(DEFAULT_DATE_FORMAT_DAYJS);
  };

  dayjsClass.prototype.formatAsDateTimeString = function (): string {
    return this.format(DEFAULT_DATETIME_FORMAT_DAYJS);
  };

  dayjsClass.prototype.formatAsISODateString = function (): string {
    return this.format(ISO_DATE_FORMAT_DAYJS);
  };

  // eslint-disable-next-line no-extend-native
  Number.prototype.parseSecondsSinceBeginningOfDay = function (this: number): Dayjs {
    return dayjs().toBeginningOfDay().plusSeconds(this);
  };

  // eslint-disable-next-line no-extend-native
  Number.prototype.formatSecondsAsDuration = function (this: number): string {
    const duration = dayjs.duration(Number(this), 'seconds');
    const nbDays = duration.get('days');
    const nbHrs = duration.get('hours');
    const nbMinutes = duration.get('minutes');
    const nbSecs = duration.get('seconds');

    const totalHrs = nbDays * 24 + nbHrs;

    return dayjs
      .duration({
        seconds: nbSecs,
        minutes: nbMinutes,
        hours: totalHrs,
      })
      .format('HH[h] mm[m] ss[s]');
  };
};

export default formatTimestampPlugin;
