import type { ChangeEvent } from 'react';

import type { FormControlLabelProps } from '@mui/material/FormControlLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import type { FormGroupProps } from '@mui/material/FormGroup';
import FormGroup from '@mui/material/FormGroup';
import type { SwitchProps } from '@mui/material/Switch';

import { StyledSwitch } from './styles';

export function WmvSwitch({
  name,
  checked,
  onChange,
  label,
  size = 'medium',
  disabled = false,
  formGroupStyles,
  switchExtraProps,
  formControlLabelExtraProps,
}: WmvSwitchProps) {
  return (
    <FormGroup row {...formGroupStyles}>
      <FormControlLabel
        control={
          <StyledSwitch
            name={name}
            checked={checked}
            onChange={onChange}
            size={size}
            disabled={disabled}
            edge={false}
            {...switchExtraProps}
          />
        }
        label={label}
        labelPlacement="start"
        componentsProps={{
          typography: {
            sx: { mr: 2 },
          },
        }}
        sx={{ m: 0 }}
        {...formControlLabelExtraProps}
      />
    </FormGroup>
  );
}

interface WmvSwitchProps {
  name: string;
  checked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  label: string;
  size?: SwitchProps['size'];
  disabled?: boolean;
  formGroupStyles?: FormGroupProps;
  switchExtraProps?: Omit<SwitchProps, 'name' | 'checked' | 'onChange' | 'size' | 'disabled'>;
  formControlLabelExtraProps?: Omit<FormControlLabelProps, 'label'>;
}
