import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useIntl } from 'react-intl';

import { BudgetPlanDto } from '../../../../../services/budget-plan';
import { BudgetPlanMembershipDto } from '../../../../../services/budget-plan-memberships';
import { ConfirmationModal } from '../../../../../wmv-components';
import { REQUEST_STATUS } from '../../atoms/budget-plan-atoms';
import { useBudgetPlanMembership } from '../../atoms/hooks';
import { MembershipDetailsRow } from '../common/MembershipDetailsRow';

interface UnlinkMembershipConfirmationModalProps {
  membership: BudgetPlanMembershipDto;
  selectedPlan: BudgetPlanDto;
  unlinkingApiStatus: REQUEST_STATUS;
  onUnlinkConfirmClick: () => Promise<void>;
}

export const UnlinkMembershipConfirmationModal = ({
  membership,
  selectedPlan,
  unlinkingApiStatus,
  onUnlinkConfirmClick,
}: UnlinkMembershipConfirmationModalProps) => {
  const { palette } = useTheme();
  const { formatMessage: fm } = useIntl();

  const { bmUnlinkModalVisible, setBMUnlinkModalVisible } = useBudgetPlanMembership();

  return (
    <ConfirmationModal
      open={bmUnlinkModalVisible}
      title={fm({ id: 'common.unlinkingUser' })}
      handleClose={closeUnlinkConfirmationModal}
      handleConfirm={onUnlinkConfirmClick}
      loading={unlinkingApiStatus === REQUEST_STATUS.PENDING}
      error={unlinkingApiStatus === REQUEST_STATUS.FAILURE ? fm({ id: 'common.submitFailed' }) : null}
    >
      <Box>
        <MembershipDetailsRow
          title={selectedPlan?.activeState.name}
          titleVariant="bodyMediumBold"
          labelTranslationKey="common.budget"
          applyLabelStyles={false}
        />
        <MembershipDetailsRow
          title={membership?.name()}
          titleVariant="bodyMediumBold"
          labelTranslationKey="common.user"
          applyLabelStyles={false}
        />
        <Typography typography="bodyMedium" color={palette.baseLight.base60} mt={2}>
          {fm({ id: 'budgetPlanMembership.unlinkConfirmation' })}
        </Typography>
      </Box>
    </ConfirmationModal>
  );

  function closeUnlinkConfirmationModal() {
    setBMUnlinkModalVisible(false);
  }
};
