import { VehicleType, LastChangeAttemptStatus } from '../common';

export interface VehicleFeePricing {
  id: number;
  version: number;
  vehicleModel: VehicleModel;
  lastAttemptedStateChange: VehicleFeePricingLastAttemptedStateChange;
  activeState?: VehicleFeePricingActiveState;
  createdAtEpochMillis: number;
  updatedAtEpochMillis?: number;
  mobilityServiceProvider: VehicleFeePricingMSP;
}

export interface VehicleModel {
  id: number;
  name: string;
  vehicleType: VehicleType;
}

export interface VehicleFeePricingLastAttemptedStateChange {
  status: LastChangeAttemptStatus;
  currency?: Currency;
  breakdown: VehicleFeePricingBreakdown[];
  details: VehicleFeePricingDetails;
}

export interface VehicleFeePricingMSP {
  id: number;
  subjectClaim: string;
  name: VehicleFeePricingMSPName;
}

export interface VehicleFeePricingMSPName {
  en: string;
  de: string;
}

enum DurationRoundingMode {
  EveryStableTransitionToNextMinute = 'EVERY_STABLE_TRANSITION_TO_NEXT_MINUTE',
}

export enum Currency {
  Euro = 'EUR',
}

export interface VehicleFeePricingBreakdown {
  type: VehicleFeePricingBreakDownType;
  subType: VehicleFeePricingBreakDownSubtype;
  amount: VehicleFeePricingBreakdownAmount;
}

export enum VehicleFeePricingBreakDownSubtype {
  ActivationFee = 'ACTIVATION_FEE',
  ActiveDuration = 'ACTIVE_DURATION',
  PausedDuration = 'PAUSED_DURATION',
}

export enum VehicleFeePricingBreakDownType {
  UsageCharges = 'USAGE_CHARGES',
}

export interface VehicleFeePricingBreakdownAmount {
  type: VehicleFeePricingBreakdownAmountType;
  value: number;
}

export enum VehicleFeePricingBreakdownAmountType {
  PerMinuteRate = 'PER_MINUTE_RATE',
  Fixed = 'FIXED',
}

export interface VehicleFeePricingDetails {
  failureReasonCode?: FailureReasonCode;
  durationRoundingMode: DurationRoundingMode;
  currency: Currency;
  breakdown: VehicleFeePricingBreakdown[];
  createdAtEpochMillis: number;
  updatedAtEpochMillis: number;
}

export interface Amount2 {
  type: string;
  value: number;
}

export enum FailureReasonCode {
  InvalidRoundingMethodSpecified = 'INVALID_ROUNDING_METHOD_SPECIFIED',
}

export interface VehicleFeePricingActiveState {
  effectiveSinceEpochMillis: number;
  details: VehicleFeePricingDetails;
}
