import camelCase from 'lodash/camelCase';

import { EnumWithStringValue } from '../../utils/EnumUtils';
import { intl } from '../../utils/intl';

import { GraphqlVehicleType } from './GraphqlVehicleType';

export enum VehicleType {
  ElectricMoped = 'ELECTRIC_MOPED',
  ElectricBike = 'ELECTRIC_BIKE',
  Car = 'CAR',
  Bicycle = 'BICYCLE',
  CargoBicycle = 'CARGO_BICYCLE',
  Scooter = 'SCOOTER',
  ScooterStanding = 'SCOOTER_STANDING',
  ScooterSeated = 'SCOOTER_SEATED',
  Other = 'OTHER',
}

interface VehicleTypeUnderivedMetadata {
  displayText: string;
}

export namespace VehicleTypeHelper {
  const { formatMessage } = intl;
  const VehicleTypeUnderivedMetadata = new Map<VehicleType, VehicleTypeUnderivedMetadata>([
    [VehicleType.ElectricMoped, { displayText: formatMessage({ id: `vehicleType.${camelCase(VehicleType.ElectricMoped)}` }) }],
    [VehicleType.ElectricBike, { displayText: formatMessage({ id: `vehicleType.${camelCase(VehicleType.ElectricBike)}` }) }],
    [VehicleType.Car, { displayText: formatMessage({ id: `vehicleType.${camelCase(VehicleType.Car)}` }) }],
    [VehicleType.Bicycle, { displayText: formatMessage({ id: `vehicleType.${camelCase(VehicleType.Bicycle)}` }) }],
    [VehicleType.CargoBicycle, { displayText: formatMessage({ id: `vehicleType.${camelCase(VehicleType.CargoBicycle)}` }) }],
    [VehicleType.Scooter, { displayText: formatMessage({ id: `vehicleType.${camelCase(VehicleType.Scooter)}` }) }],
    [VehicleType.ScooterStanding, { displayText: formatMessage({ id: `vehicleType.${camelCase(VehicleType.ScooterStanding)}` }) }],
    [VehicleType.ScooterSeated, { displayText: formatMessage({ id: `vehicleType.${camelCase(VehicleType.ScooterSeated)}` }) }],
    [VehicleType.Other, { displayText: formatMessage({ id: `vehicleType.${camelCase(VehicleType.Other)}` }) }],
  ]);

  export function metadata(vehicleType: VehicleType): VehicleTypeUnderivedMetadata {
    const underivedMetadata = VehicleTypeUnderivedMetadata.get(vehicleType);
    if (!underivedMetadata) {
      throw Error(`
              Specified Vehicle Type: ${toString(vehicleType)} doesnt have corresponding underived/explicitly defined metadata.
              This usually (not always) means a bug or incomplete implementation.
          `);
    }

    return { ...underivedMetadata };
  }

  export function toString(vehicleType: VehicleType): string | null {
    return EnumWithStringValue.getEnumKeyByEnumValue(VehicleType, vehicleType);
  }

  export function allTypes(): VehicleType[] {
    return EnumWithStringValue.enumToValues(VehicleType);
  }

  export function allEnumKeys() {
    return EnumWithStringValue.enumToKeys(VehicleType);
  }

  export function valuesWithLabel(): { value: VehicleType; label: string }[] {
    return allTypes().map((vehicleType) => {
      return {
        value: vehicleType,
        label: metadata(vehicleType).displayText,
      };
    });
  }

  export function toGraphqlVehicleTypes() {
    return new Map<VehicleType, GraphqlVehicleType>([
      [VehicleType.ElectricMoped, GraphqlVehicleType.ELECTRIC_MOPED],
      [VehicleType.ElectricBike, GraphqlVehicleType.ELECTRIC_BIKE],
      [VehicleType.Car, GraphqlVehicleType.CAR],
      [VehicleType.Bicycle, GraphqlVehicleType.BICYCLE],
      [VehicleType.CargoBicycle, GraphqlVehicleType.CARGO_BICYCLE],
      [VehicleType.Scooter, GraphqlVehicleType.SCOOTER],
      [VehicleType.ScooterStanding, GraphqlVehicleType.SCOOTER_STANDING],
      [VehicleType.ScooterSeated, GraphqlVehicleType.SCOOTER_SEATED],
      [VehicleType.Other, GraphqlVehicleType.OTHER],
    ]);
  }

  export function toGraphqlVehicleType(vehicleType: VehicleType) {
    return toGraphqlVehicleTypes().get(vehicleType);
  }
}
