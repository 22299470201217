import type { ColumnDef } from '@tanstack/react-table';
import dayjs from 'dayjs';

import { CustomerDetails } from '../../../models';

export const SearchedCustomersTripsColumnDefs: ColumnDef<CustomerDetails>[] = [
  {
    header: 'maas.customer.details.firstName'.i18nText(),
    accessorKey: 'firstName',
  },
  {
    header: 'maas.customer.details.lastName'.i18nText(),
    accessorKey: 'lastName',
  },
  {
    header: 'maas.customer.details.birthdateShort'.i18nText(),
    accessorFn: (originalRow: CustomerDetails) => dayjs(originalRow.birthDate).formatAsDateString(),
  },
  {
    header: 'maas.customer.details.email'.i18nText(),
    accessorKey: 'email',
  },
];
