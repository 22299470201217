import { useEffect, memo } from 'react';

import { useLeafletContext } from '@react-leaflet/core';
// eslint-disable-next-line import/order
import L, { Layer, Polygon } from 'leaflet';

import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import { useSelector } from 'react-redux';

import { VehicleTypeHelper, ZoneTypeHelper, DayOfWeekHelper } from '../../models';
import { Zone, ZoneLayerProperties } from '../../services/zone';
import { AvailableLanguage, changingLanguageSelector } from '../../state';
import { intl } from '../../utils/intl';
interface GeoJsonZonesProps {
  zones: Zone[];
  onLayerClick?: (layer: Polygon) => void;
}

// TODO: Convert this component to hook, as this component does not render anything
const GeoJsonZones = ({ zones, onLayerClick }: GeoJsonZonesProps) => {
  const context = useLeafletContext();
  const map = context.map;
  const locale = useSelector(changingLanguageSelector) as AvailableLanguage;

  map.pm.setLang(locale);

  useEffect(() => {
    map.eachLayer((layer: Layer) => {
      if (layer instanceof Polygon) {
        map.removeLayer(layer);
      }
    });

    zones.forEach((zone) => {
      const { strokeColor, fillColor } = zone.geoJsonFeature().properties;
      L.geoJSON<ZoneLayerProperties>(zone.geoJsonFeature(), {
        style: { color: strokeColor, fillColor: fillColor },
        onEachFeature: function (feature, layer) {
          if (localStorage.getItem('SHOW_ZONE_CONFIG') === 'true') {
            layer.bindPopup(zonePropertiesHtml(feature.properties));
          }

          layer.on('click', () => {
            if (onLayerClick) {
              onLayerClick(layer as Polygon);
            }
          });
        },
      }).addTo(map);
    });
  }, [zones, map, onLayerClick]);

  return null;
};

const zonePropertiesHtml = (properties: ZoneLayerProperties) => {
  const { id, name, type, startTime, endTime, startDate, endDate, daysOfWeek, applicableVehicleTypes } = properties;
  return `
<div style="padding-left: 12px">
  <div style="font-size: 16px"><b>Name: </b>
    ${name}
  </div>
  <div style="font-size: 16px"><b>Id: </b>
    ${id}
  </div>
  <div style="font-size: 16px"><b>Type: </b>
    ${intl.formatMessage({ id: ZoneTypeHelper.metadata(type).translationKey })}
  </div>
  <div style="font-size: 16px"><b>Start Time: </b>
    ${startTime.formatAsTimeWithoutSeconds()}
  </div>
  <div style="font-size: 16px"><b>End Time: </b>
    ${endTime.formatAsTimeWithoutSeconds()}
  </div>
  <div style="font-size: 16px"><b>Start Date: </b>
    ${startDate.formatAsISODateString()}
  </div>
  <div style="font-size: 16px"><b>End Date: </b>
    ${endDate?.formatAsISODateString()}
  </div>
  <div style="font-size: 16px"><b>Applicable DaysOfWeek: </b>
    ${[...daysOfWeek].map((dayOfWeek) => DayOfWeekHelper.toString(dayOfWeek)).join(', ')}
  </div>
  <div style="font-size: 16px"><b>Applicable VehicleTypes: </b>
    ${applicableVehicleTypes.map((vehicleType) => VehicleTypeHelper.metadata(vehicleType).displayText).join(',')}
  </div>
</div>
`;
};

export default memo(GeoJsonZones);
