import type { Theme } from '@mui/material/styles';

export const tripContainerSx = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  alignItems: 'stretch',
  height: '100%',
  backgroundColor: theme.palette.white.main,
});

export const tripContentSx = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});

export const filtersContainerSx = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  justifyContent: 'center',
  alignItems: 'flex-start',
  borderRadius: '8px',
});

export const filtersContentSx = (theme: Theme) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '16px',
  borderRadius: '8px',
  height: '48px',
  padding: '8px',
});
