import { useTheme } from '@mui/material/styles';

import { Modal } from '../../../wmv-components';

import {
  SelectedVehicleFeePricingActionType,
  useDiscardFailedTransitionApiStatus,
  useSelectedVehiclePricingDispatch,
  useSelectedVehiclePricingPricingId,
  useUpdatePricingApiStatus,
  useVehiclePricingDispatch,
  VehiclePricingActionType,
} from './contexts';
import { pricingDetailsContentStyles } from './styles';
import { VehiclePricingDetailsForm } from './VehiclePricingDetailsForm';

export function VehiclePricingDetailsModal() {
  const { palette } = useTheme();

  const vehiclePricingDispatch = useVehiclePricingDispatch();
  const selectedVehiclePricingId = useSelectedVehiclePricingPricingId();
  const selectedPricingDispatch = useSelectedVehiclePricingDispatch()!;

  const updatePricingApiStatus = useUpdatePricingApiStatus();
  const discardFailedTransitionApiStatus = useDiscardFailedTransitionApiStatus();

  return (
    <Modal
      open={!!selectedVehiclePricingId}
      onClose={handlePricingModalClose}
      disableClose={updatePricingApiStatus.isPending() || discardFailedTransitionApiStatus.isPending()}
      bgColor={palette.white.main}
    >
      <Modal.Content sx={pricingDetailsContentStyles}>
        <VehiclePricingDetailsForm />
      </Modal.Content>
    </Modal>
  );

  function handlePricingModalClose() {
    vehiclePricingDispatch!({
      type: VehiclePricingActionType.UpdateSelectedVehicleFeePricingId,
      payload: null,
    });
    selectedPricingDispatch({
      type: SelectedVehicleFeePricingActionType.ResetToInitialState,
    });
  }
}
