import React from 'react';

import isEmpty from 'lodash/isEmpty';
import type { NumericFormatProps } from 'react-number-format';

import { NumberFormatter } from './NumberFormatter';

export const FormatAsCents = React.forwardRef<NumericFormatProps, FormatAsCentsProps>(function FormatAsCents(props, ref) {
  const { prefix = '', suffix = '', allowEmptyFormatting = true } = props;

  function currencyFormatter(value: any) {
    if (allowEmptyFormatting && isEmpty(value)) return prefix + suffix;
    return Number(value).toCents({ signDisplay: 'never' }).replace('€', '').trim();
  }

  return <NumberFormatter formatter={currencyFormatter} {...props} />;
});

interface FormatAsCentsProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  prefix: string;
  suffix: string;
  value: number;
  allowEmptyFormatting?: boolean;
}
