import type { Dayjs, PluginFunc } from 'dayjs';
import dayjs from 'dayjs';

import { DayOfWeek } from '../../models';
import {
  END_OF_DAY_MINUTES_FROM_BEGINNING_OF_DAY,
  END_OF_DAY_SECONDS_FROM_BEGINNING_OF_DAY,
  START_OF_DAY_SECONDS_FROM_BEGINNING_OF_DAY,
} from '../../utils/constants';

declare module 'dayjs' {
  function daysOfWeekBetweenDateRange(startDate: Dayjs, endDate: Dayjs): Set<DayOfWeek>;
  function now(): Dayjs;

  interface Dayjs {
    toEpochMillis(): number;
    toInclusive(): Dayjs;
    dayOfWeek(): DayOfWeek;
    secondsFromBeginningOfDay(): number;
    minutesFromBeginningOfDay(): number;
    representsExactHourWithoutMinutesAndSeconds(): boolean;
    isBeginningOfDay(): boolean;
    isEndOfDaySeconds(): boolean;
    isEndOfDayMinutes(): boolean;
  }
}

const miscPlugin: PluginFunc = (option, dayjsClass, dayjsFactory) => {
  dayjsFactory.now = function (): Dayjs {
    return dayjs();
  };

  dayjsClass.prototype.toEpochMillis = function (): number {
    return this.valueOf();
  };

  dayjsClass.prototype.toInclusive = function (): Dayjs {
    return this.subtract(1, 'second');
  };

  dayjsClass.prototype.secondsFromBeginningOfDay = function () {
    const hours = this.get('hour');
    const minutes = this.get('minute');
    const seconds = this.get('second');
    return hours * 60 * 60 + minutes * 60 + seconds;
  };

  dayjsClass.prototype.minutesFromBeginningOfDay = function () {
    const hours = this.get('hour');
    const minutes = this.get('minute');
    return hours * 60 + minutes;
  };

  dayjsClass.prototype.dayOfWeek = function (): DayOfWeek {
    const dayOfWeekKey = this.format('dddd') as keyof typeof DayOfWeek;
    return DayOfWeek[dayOfWeekKey];
  };

  dayjsClass.prototype.representsExactHourWithoutMinutesAndSeconds = function (): boolean {
    return this.toBeginningOfHour().toEpochMillis() === this.toEpochMillis();
  };

  dayjsClass.prototype.isBeginningOfDay = function (): boolean {
    return this.secondsFromBeginningOfDay() === START_OF_DAY_SECONDS_FROM_BEGINNING_OF_DAY;
  };

  dayjsClass.prototype.isEndOfDaySeconds = function (): boolean {
    return this.secondsFromBeginningOfDay() === END_OF_DAY_SECONDS_FROM_BEGINNING_OF_DAY;
  };

  dayjsClass.prototype.isEndOfDayMinutes = function (): boolean {
    return this.minutesFromBeginningOfDay() === END_OF_DAY_MINUTES_FROM_BEGINNING_OF_DAY;
  };

  dayjsFactory.daysOfWeekBetweenDateRange = function (startDate: Dayjs, endDate: Dayjs): Set<DayOfWeek> {
    const daysOfWeek: Set<DayOfWeek> = new Set([]);
    let currentDate = startDate;
    while (currentDate.isSameOrBefore(endDate)) {
      const dayOfWeek = currentDate.dayOfWeek();
      daysOfWeek.add(dayOfWeek);
      currentDate = currentDate.plusDays(1);
    }

    return daysOfWeek;
  };
};

export default miscPlugin;
