import { useCallback } from 'react';

import { VehiclePricingDto } from '../../../../services/vehicle-fee-pricing';
import { fetchVehicleFeeList } from '../../../../utils/queries';
import { useVehiclePricingDispatch, VehiclePricingActionType } from '../contexts';

export function useFetchVehiclePricingList(): () => Promise<void> {
  const vehiclePricingDispatch = useVehiclePricingDispatch()!;

  return useCallback(
    async function fetchVehicleFeePricingListAndUpdateState() {
      try {
        vehiclePricingDispatch({
          type: VehiclePricingActionType.VehiclePricingListFetchStart,
        });
        let feePricingList = await fetchVehicleFeeList();
        if (feePricingList === '') feePricingList = [];

        vehiclePricingDispatch({
          type: VehiclePricingActionType.VehiclePricingListFetchSuccess,
          payload: feePricingList.map((feePricing) => new VehiclePricingDto(feePricing)),
        });
      } catch (e) {
        vehiclePricingDispatch({
          type: VehiclePricingActionType.VehiclePricingListFetchFailure,
        });
      }
    },
    [vehiclePricingDispatch],
  );
}
