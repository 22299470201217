import { useEffect } from 'react';

import { useLeafletContext } from '@react-leaflet/core';
import L, { Polygon } from 'leaflet';

import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import { Zone } from '../../../../services/zone';

type onLayerClick = (layer: Polygon) => void;

export const useGeoJsonZones = (zones: Zone[], onLayerClick: onLayerClick, currentWorkingLayer: Polygon | null | undefined) => {
  const context = useLeafletContext();
  const map = context.map;

  useEffect(() => {
    map.eachLayer((layer) => {
      if (layer instanceof Polygon && layer !== currentWorkingLayer) {
        map.removeLayer(layer);
      }
    });
  }, [zones, map, currentWorkingLayer, onLayerClick]);

  useEffect(() => {
    zones.forEach((zone) => {
      const workingLayerProperties = currentWorkingLayer?.toGeoJSON().properties;
      const { strokeColor, fillColor } = zone.geoJsonFeature().properties;

      if (workingLayerProperties?.id !== zone.id) {
        L.geoJSON(zone.geoJsonFeature(), {
          style: { color: strokeColor, fillColor: fillColor },
          onEachFeature: function (feature, layer) {
            layer.once('click', (e) => {
              onLayerClick(e.target);
            });
          },
        }).addTo(map);
      }
    });
  }, [zones, map, currentWorkingLayer, onLayerClick]);
};
