import { intl } from './intl';

export const localDateTime = (addedMinutes = 0) => {
  const offsetMinutes = new Date().getTimezoneOffset() * 60000;
  return new Date(Date.now() - offsetMinutes + addedMinutes * 60000);
};

export const formatDistanceFromMeters = (meters: number) => {
  if (meters === 0) return intl.formatNumber(0, { style: 'unit', unit: 'meter' });
  if (meters < 1000) return intl.formatNumber(meters, { style: 'unit', unit: 'meter' });
  return intl.formatNumber(meters / 1000, { style: 'unit', unit: 'kilometer' });
};
