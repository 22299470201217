import React from 'react';

import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { SUPPORT_SUBHEADER_HEIGHT } from '../utils/constants';
import CustomIcon from '../utils/CustomIcon';

function NonClickableBreadCrumb({ values }: { values: string[] }) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: theme.palette.white.main,
        height: SUPPORT_SUBHEADER_HEIGHT,
        gap: theme.spacing(1),
        width: '100%',
        padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
      }}
    >
      {values.map((value, index) => (
        <React.Fragment key={value}>
          <Typography color={index === values.length - 1 ? theme.palette.dark.main : theme.palette.dark.shade60} variant="bodySmall">
            {value}
          </Typography>
          {index < values.length - 1 && CustomIcon('ChevronRight', theme.palette.dark.shade60)}
        </React.Fragment>
      ))}
    </Box>
  );
}

export default NonClickableBreadCrumb;
