import { useCallback, useState } from 'react';

import { type ColumnDef, type Row } from '@tanstack/react-table';

import NewDataTable from '../../../../design-system/NewDataTable';
import { NO_CONTENT_RESPONSE_TYPE, VehicleTripsListDetails, VehicleTripsListResponse } from '../../../../models';
import { formatDistanceFromMeters } from '../../../../utils/DateMethods';
import { TripDetailsAndManagement } from '../../../TripDetailsAndManagement';

function VehicleInfoReactTable({ filteredTrips }: { filteredTrips: Exclude<VehicleTripsListResponse, NO_CONTENT_RESPONSE_TYPE> }) {
  const [expandedRowID, setExpandedRowID] = useState<string>('');

  const renderTripDetailsView = useCallback(
    (row: Row<VehicleTripsListDetails>, expanderIndex: number, rows: Row<VehicleTripsListDetails>[]) => {
      return (
        <TripDetailsAndManagement
          tripId={row.original.id}
          includeForceStop={false}
          disableNextBtn={expanderIndex + 1 === rows.length}
          onCloseTripBtnClick={() => setExpandedRowID('')}
          onNextTripBtnClick={() => setExpandedRowID(rows[expanderIndex + 1].id)}
        />
      );
    },
    [],
  );

  const toggleExpanderRow = useCallback(
    (row: Row<VehicleTripsListDetails>) => {
      if (row.id === expandedRowID) setExpandedRowID('');
      else setExpandedRowID(row.id);
    },
    [expandedRowID],
  );

  return (
    <NewDataTable<VehicleTripsListDetails, 'expander'>
      columns={vehicleTripsListColumnDefs}
      data={filteredTrips}
      onRowClickHandler={toggleExpanderRow}
      expanderComponent={renderTripDetailsView}
      category="expander"
      expandedRowId={expandedRowID}
    />
  );
}

const vehicleTripsListColumnDefs: ColumnDef<VehicleTripsListDetails>[] = [
  {
    header: 'informationTable.header.tripId'.i18nText(),
    accessorFn: (originalRow: VehicleTripsListDetails) => originalRow.id.toString(),
  },
  {
    header: 'informationTable.header.startEpochMillis'.i18nText(),
    accessorFn: (originalRow: VehicleTripsListDetails) => originalRow.startedAt.parseEpochMillis().formatAsDateTimeString(),
  },
  {
    header: 'informationTable.header.endEpochMillis'.i18nText(),
    accessorFn: (originalRow: VehicleTripsListDetails) =>
      originalRow.finishedAt ? originalRow.finishedAt.parseEpochMillis().formatAsDateTimeString() : 'common.dash'.i18nText(),
  },
  {
    header: 'informationTable.header.actualDurationSeconds'.i18nText(),
    accessorFn: (originalRow: VehicleTripsListDetails) =>
      originalRow.finishedAt ? originalRow.totalDurationSeconds.formatSecondsAsDuration() : 'common.dash'.i18nText(),
  },
  {
    header: 'informationTable.header.totalDistanceMeters'.i18nText(),
    accessorFn: (originalRow: VehicleTripsListDetails) =>
      originalRow.finishedAt ? formatDistanceFromMeters(originalRow.totalDistanceMeters) : 'common.dash'.i18nText(),
  },
];

export default VehicleInfoReactTable;
