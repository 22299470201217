import React from 'react';

import { NumberFormatBase } from 'react-number-format';
import type { NumericFormatProps } from 'react-number-format';

export const NumberFormatter = React.forwardRef<NumericFormatProps, NumberFormatterProps>(function NumberFormatter(props, ref) {
  const { onChange, formatter, value, ...other } = props;

  return (
    <NumberFormatBase
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      value={value}
      format={formatter}
    />
  );
});

interface NumberFormatterProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  value: number;
  allowEmptyFormatting?: boolean;
  formatter: (value: any) => string;
}
