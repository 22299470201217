import camelCase from 'lodash/camelCase';

import { EnumWithStringValue } from '../../utils/EnumUtils';
import { intl } from '../../utils/intl';

export enum MspType {
  Zeus = 'Zeus Scooters',
  Stwb = 'STWB Bamberg',
  DonkeyRepublic = 'Donkey Republic',
  Meiaudo = 'meiaudo',
}

export namespace MspTypeHelper {
  export function allTypes(): MspType[] {
    return EnumWithStringValue.enumToValues(MspType);
  }

  export const valuesWithLabel = Object.entries(MspType).map(([key, value]) => ({
    value: value,
    label: intl.formatMessage({ id: `filter.providers.${camelCase(key)}` }),
  }));
}
