import { useRef } from 'react';

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useIntl } from 'react-intl';

import { BudgetPlanDto } from '../../../../../services/budget-plan';
import { BudgetPlanMembershipDto } from '../../../../../services/budget-plan-memberships';
import { cancelLastFailedAttempt, retryFailedMembershipStateTransition } from '../../../../../utils/queries';
import { ProgressAwareButton } from '../../../../../wmv-components';
import { HelperTextType } from '../../../../../wmv-components/HelperText';
import { REQUEST_STATUS } from '../../atoms/budget-plan-atoms';
import { useBudgetPlanMembership } from '../../atoms/hooks';

export type MembershipDetailsModalCancelClickReason = 'cancelRequest' | 'closeModal';

interface MembershipDetailsActionButtonsProps {
  membership: BudgetPlanMembershipDto;
  selectedPlan: BudgetPlanDto;
  onUnlinkMembershipModalClose: () => void;
}

export const MembershipDetailsActionButtons = ({
  membership,
  selectedPlan,
  onUnlinkMembershipModalClose,
}: MembershipDetailsActionButtonsProps) => {
  const { formatMessage: fm } = useIntl();

  const helperTextRef = useRef<string>('');
  const helperTextTypeRef = useRef<HelperTextType>('info');

  const {
    setBMUnlinkModalVisible,
    setBMSelectedMembership,
    retryStateTransitionApiStatus,
    setRetryStateTransitionApiStatus,
    discardFailedStateTransitionApiStatus,
    setDiscardFailedStateTransitionApiStatus,
  } = useBudgetPlanMembership();

  const enableTransitionRetry = membership?.isLinkingFailed() || membership?.isUnlinkingFailed() || membership?.isActive();
  return (
    <Box display="flex" mt={3} gap={4}>
      <ProgressAwareButton
        size="medium"
        variant="secondaryLight"
        onClick={onCancelOrCloseClick}
        loading={discardFailedStateTransitionApiStatus === REQUEST_STATUS.PENDING}
        loadingButtonProps={{ sx: { flex: 1 } }}
        disabled={
          discardFailedStateTransitionApiStatus === REQUEST_STATUS.PENDING || retryStateTransitionApiStatus === REQUEST_STATUS.PENDING
        }
      >
        <Typography variant="bodyMediumBold">
          {membership?.isLastAttemptedStateTransitionFailed() ? fm({ id: 'actions.cancel' }) : fm({ id: 'common.close' })}
        </Typography>
      </ProgressAwareButton>
      <ProgressAwareButton
        size="medium"
        variant={membership?.isLinked() ? 'alert' : 'contained'}
        onClick={handleFailedTransitionRetry}
        disabled={!enableTransitionRetry}
        loading={retryStateTransitionApiStatus === REQUEST_STATUS.PENDING}
        loadingButtonProps={{ sx: { flex: 1 } }}
      >
        <Typography variant="bodyMediumBold">
          {membership?.isLinked() ? fm({ id: 'budgetPlanMembership.unlinkUser' }) : fm({ id: 'common.retryToLink' })}
        </Typography>
      </ProgressAwareButton>
    </Box>
  );

  async function onCancelOrCloseClick() {
    await closeModalOrDiscardFailedTransitionRequest(membership?.isLastAttemptedStateTransitionFailed() ? 'cancelRequest' : 'closeModal');
  }

  async function closeModalOrDiscardFailedTransitionRequest(cancelClickReason: MembershipDetailsModalCancelClickReason) {
    if (cancelClickReason === 'closeModal') {
      helperTextRef.current = '';
      helperTextTypeRef.current = 'info';
      onUnlinkMembershipModalClose();
    }
    if (cancelClickReason === 'cancelRequest') {
      setDiscardFailedStateTransitionApiStatus(REQUEST_STATUS.PENDING);
      try {
        await cancelLastFailedAttempt(selectedPlan.id, membership!.id);
        onUnlinkMembershipModalClose();
        setDiscardFailedStateTransitionApiStatus(REQUEST_STATUS.SUCCESS);
      } catch (e) {
        setDiscardFailedStateTransitionApiStatus(REQUEST_STATUS.FAILURE);
      }
    }
  }

  async function handleFailedTransitionRetry() {
    if (membership!.isUnlinkingFailed() || membership!.isLinkingFailed()) {
      setRetryStateTransitionApiStatus(REQUEST_STATUS.PENDING);
      try {
        const newMembership = await retryFailedMembershipStateTransition(selectedPlan.id, membership!.id);
        const newMembershipDto = new BudgetPlanMembershipDto(newMembership);
        setBMSelectedMembership(newMembershipDto);
        onUnlinkMembershipModalClose();
        setRetryStateTransitionApiStatus(REQUEST_STATUS.SUCCESS);
      } catch (e) {
        setRetryStateTransitionApiStatus(REQUEST_STATUS.FAILURE);
      }
    } else {
      setBMUnlinkModalVisible(true);
    }
  }
};
