import { Button, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import isEmpty from 'lodash/isEmpty';
import { useIntl } from 'react-intl';

import { ReactComponent as PlusIcon } from '../../../../../assets/icons/fill/plus.svg';
import { TranslatedText } from '../../../../../wmv-components';
import { useBudgetPlanMembership } from '../../atoms/hooks';
import { SearchInput } from '../common/SearchInput';

export const MembershipSearchAndLinkBar = () => {
  const { palette } = useTheme();
  const { formatMessage } = useIntl();

  const { setBMLinkModalVisible, bmModalSearchString, setBMModalSearchString } = useBudgetPlanMembership();

  return (
    <Box display="flex" justifyContent="flex-end" alignItems="center" mt={1} mb={3}>
      <SearchInput
        value={bmModalSearchString}
        onChange={handleSearchStringChange}
        variant="outlined"
        label={formatMessage({ id: 'common.searchProfiles' })}
        placeholder={formatMessage({ id: 'common.searchUser' })}
        sx={{ flex: 1 }}
        showSearchIcon={isEmpty(bmModalSearchString)}
        showClearIcon={!isEmpty(bmModalSearchString)}
      />
      <TranslatedText translationKey="common.orSeprator" variant="bodyMedium" color={palette.baseLight.base76} sx={{ mx: 3 }} />
      <Button variant="contained" color="primary" size="medium" onClick={handleLinkNewMembershipClick}>
        <PlusIcon fill={palette.white.main} />
        <TranslatedText translationKey="common.linkNewUser" variant="bodyMediumBold" />
      </Button>
    </Box>
  );

  function handleLinkNewMembershipClick() {
    setBMLinkModalVisible(true);
  }

  function handleSearchStringChange(searchStr: string) {
    setBMModalSearchString(searchStr);
  }
};
