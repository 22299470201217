import { useMemo, useState } from 'react';

import { useAtom } from 'jotai';

import { isTripBudgetRefundSectionVisibleAtom } from '../../atoms/feature-flags-atoms';
import { TripInfoTabType, TripInfoTabTypeHelper, TripDetailsResponse } from '../../models';
import { WmvTabPanel, WmvTabs } from '../../wmv-components';

import { TripAuditLog } from './TripAuditLog';
import { TripBudgedRefundForm } from './TripBudgedRefundForm';
import TripTimePricingAndPaymentDetails from './TripTimePricingAndPaymentDetails';

interface TripInfoTabsProps {
  tripDetails: TripDetailsResponse;
}

export function TripInfoTabs({ tripDetails }: TripInfoTabsProps) {
  const [activeTab, setActiveTab] = useState<TripInfoTabType>(TripInfoTabType.TripDetails);
  const [isTripBudgetRefundSectionVisible] = useAtom(isTripBudgetRefundSectionVisibleAtom);

  function handleTabChange(tab: TripInfoTabType) {
    setActiveTab(tab);
  }

  const tripInfoTabs = useMemo(() => {
    return TripInfoTabTypeHelper.allLabelAndValues().filter(({ value }) => {
      if (value === TripInfoTabType.BudgetRefund) return isTripBudgetRefundSectionVisible;
      return true;
    });
  }, [isTripBudgetRefundSectionVisible]);

  return (
    <WmvTabs<TripInfoTabType> options={tripInfoTabs} activeTab={activeTab} onChange={handleTabChange}>
      <WmvTabPanel<TripInfoTabType> activeTab={activeTab} currentTab={TripInfoTabType.TripDetails}>
        <TripTimePricingAndPaymentDetails tripDetails={tripDetails} />
      </WmvTabPanel>
      <WmvTabPanel<TripInfoTabType> activeTab={activeTab} currentTab={TripInfoTabType.BudgetRefund} sx={{ my: 4 }}>
        <TripBudgedRefundForm />
      </WmvTabPanel>
      <WmvTabPanel<TripInfoTabType> activeTab={activeTab} currentTab={TripInfoTabType.AuditLog} sx={{ my: 4, mr: 4 }}>
        <TripAuditLog tripDetails={tripDetails} />
      </WmvTabPanel>
    </WmvTabs>
  );
}
