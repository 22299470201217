import Box from '@mui/material/Box';

import { DateTimeRangeSearchForm, StartAndEndDateTimeRangeValues } from '../../../wmv-components';

interface CustomDateFilterFormProps {
  initialValues: { startDate: string; endDate: string };
  onSubmit: (startAndEndDateTime: StartAndEndDateTimeRangeValues) => void;
}

const CustomTripDateFilter: React.FC<CustomDateFilterFormProps> = ({ initialValues, onSubmit }) => {
  return (
    <Box ml={1} pb={2}>
      <DateTimeRangeSearchForm onSearch={onSubmit} />
    </Box>
  );
};

export default CustomTripDateFilter;
