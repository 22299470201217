import { Box, Typography } from '@mui/material';

import { LinkedMembersList } from './LinkedMembersList';
import { linkedProfilesContainerStyles } from './styles';

export function LinkedProfilesTabContent() {
  const isOwner = false;

  return (
    <Box sx={linkedProfilesContainerStyles}>
      {isOwner ? (
        <Typography variant="bodyLargeBold">{'common.linkedOwner'.i18nText()}</Typography>
      ) : (
        <Typography variant="bodyLargeBold">{'common.sharedMember'.i18nText()}</Typography>
      )}
      <Box mt={3}>
        <LinkedMembersList memberships={isOwner ? ownerMember : sharedMembers} />
      </Box>
    </Box>
  );
}

const sharedMembers = [
  {
    name: 'Sharing Member1',
    activationDate: '01.01.2024',
  },
  {
    name: 'Sharing Member2',
    activationDate: '01.01.2024',
  },
];

const ownerMember = [
  {
    name: 'Owner The Maalik',
    activationDate: '01.01.2024',
  },
];
