import type { Theme } from '@mui/material/styles';

export const budgetTabPanelStyles = (theme: Theme) => ({
  flex: 1,
});

// NoContentMessage
export const noContentContainerStyles = (theme: Theme) => ({
  flex: 1,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

export const noContentContentStyles = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '460px',
});
