import {
  BudgetPlanDetailsResponse,
  BudgetPlanStatus,
  BudgetPlanStatusHelper,
  LastChangeAttemptStatus,
  LastChangeAttemptStatusHelper,
} from '../../models';
import { BudgetPlanDetailsFormValues } from '../../pages/Operator/BudgetPlan/BudgetPlanDetails';

import { BudgetPlanActiveStateDto } from './BudgetPlanActiveStateDto';
import { BudgetPlanLastChangeAttemptDto } from './LastChangeAttemptDto';

export class BudgetPlanDto {
  id: number;
  version: number;
  activeState: BudgetPlanActiveStateDto;
  lastChangeAttempt: BudgetPlanLastChangeAttemptDto;

  constructor(budgetPlan: BudgetPlanDetailsResponse) {
    this.version = budgetPlan.version;
    this.id = budgetPlan.id;
    this.activeState = new BudgetPlanActiveStateDto(budgetPlan.activeState);
    this.lastChangeAttempt = new BudgetPlanLastChangeAttemptDto(budgetPlan.lastStateChangeAttempt);
  }

  statusChangeAttemptToDisplayTextAndColor(): ColorAndDisplayText {
    const { status: lastChangeAttemptStatus } = this.lastChangeAttempt;
    if (lastChangeAttemptStatus === LastChangeAttemptStatus.Failed || lastChangeAttemptStatus === LastChangeAttemptStatus.InProgress) {
      return LastChangeAttemptStatusHelper.metadata(lastChangeAttemptStatus) as ColorAndDisplayText;
    }
    return BudgetPlanStatusHelper.metadata(BudgetPlanStatus.Active) as ColorAndDisplayText;
  }

  toFormInput(activeState: BudgetPlanActiveStateDto): BudgetPlanDetailsFormValues {
    return {
      name: activeState.name,
      depositFrequency: activeState.frequency,
      depositAmount: activeState.depositAmount,
      amountPerTripSpendConstraintExists: !!activeState.amountPerTripConstraint,
      amountPerTripSpendConstraint: activeState?.amountPerTripConstraint,
      depositDayOfMonth: activeState.depositDayOfMonth,
      depositTime: activeState.depositTime.asDayjsTime(),
      vehicleTypesSpendConstraintExists: !!activeState.vehicleTypesConstraint.length,
      vehicleTypesSpendConstraint: activeState.vehicleTypesConstraint,
    };
  }
}

interface ColorAndDisplayText {
  color: string;
  displayText: string;
}
