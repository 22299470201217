import React, { useEffect, useRef } from 'react';

import L from 'leaflet';
import { Marker, Popup } from 'react-leaflet';

import { GraphqlVehicleUsageStatusHelper } from '../../models';
import { FormattedLiveVehicle } from '../../state/common/vehicle.model';

import VehicleIcons from './VehicleIcons';
import VehicleInfoModal from './VehicleInfoModal';
import { generateUniqueID } from './WikimoveMap';

export function VehicleMarkerWithInfoPopover({
  vehicle,
  openMarkerId,
  selected = false,
  handleOpenDetailedVehicleModal,
}: MarkerComponentProps) {
  const markerRef = useRef<L.Marker>(null);

  useEffect(() => {
    if (markerRef && openMarkerId === generateUniqueID(vehicle)) {
      markerRef.current?.openPopup();
    }
  }, [openMarkerId, vehicle]);

  return (
    <Marker
      key={generateUniqueID(vehicle)}
      icon={VehicleIcons(GraphqlVehicleUsageStatusHelper.metadata(vehicle.usageStatus).iconClassName, vehicle.formFactor, selected)}
      position={[vehicle.lat, vehicle.lon]}
      ref={markerRef}
    >
      <Popup autoClose>
        <VehicleInfoModal status={vehicle.usageStatus} vehicle={vehicle} handleOpenModal={handleOpenDetailedVehicleModal} />
      </Popup>
    </Marker>
  );
}

interface MarkerComponentProps {
  vehicle: FormattedLiveVehicle;
  openMarkerId: string | null | undefined;
  selected?: boolean;
  handleOpenDetailedVehicleModal: (vehicle: FormattedLiveVehicle) => void;
}
