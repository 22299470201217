import { useSelector } from 'react-redux';

import { useAppDispatch } from '../../state';
import { alertStateSelector, hideAlertAction } from '../../state/app';
import { SnackbarAlert } from '../SnackbarAlert';

export const AppSnackbarAlert = () => {
  const dispatch = useAppDispatch();

  const alertState = useSelector(alertStateSelector);

  const handleSnackBarClose = () => {
    dispatch(hideAlertAction());
  };

  return <SnackbarAlert onClose={handleSnackBarClose} message={alertState.message} open={alertState.visible} type={alertState.type} />;
};
