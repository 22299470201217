// BudgetUsageLinearProgressbar
import { linearProgressClasses } from '@mui/material/LinearProgress';
import type { Theme } from '@mui/material/styles';

export const headingStyles = (theme: Theme) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '8px',
});

export const budgetBarStyles = (theme: Theme) => ({
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset',
  height: '28px',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.baseLight.base8,
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: theme.palette.blue.main,
    borderRadius: `${theme.spacing(0.5)}px 0px 0px ${theme.spacing(0.5)}px`,
    borderRight: `0.5px solid ${theme.palette.white.main}`,
  },
});

export const remainingBarHelperStyles = (theme: Theme) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: theme.spacing(1),
  gap: '4px',
});

export const dotStyles = (theme: Theme) => ({
  height: '16px',
  width: '16px',
  borderRadius: '50%',
  backgroundColor: theme.palette.blue.main,
});

// MembershipInfo
export const membershipInfoRowStyles = (theme: Theme) => ({
  display: 'flex',
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
});

export const membershipInfoLabelStyles = (theme: Theme) => ({
  minWidth: '200px',
  marginRight: theme.spacing(3),
  color: theme.palette.baseLight.base76,
});
