import type { ChangeEvent } from 'react';

import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import type { SelectChangeEvent } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';

import { DayOfWeekHelper, ZoneType, ZoneTypeHelper } from '../../../../models';
import { SelectionMenu, Checkbox, InfoIconWithTooltip } from '../../../../wmv-components';

import { ZoneRuleFormFields, ZoneRuleFormServerError, ZoneRulesFormValues } from './ZoneRulesForm';

const zoneTypesMenuConfig = ZoneTypeHelper.allTypes().map((zoneType) => ({
  value: zoneType,
  label: ZoneTypeHelper.metadata(zoneType).translationKey.i18nText(),
  startIcon: <SquareRoundedIcon style={{ color: ZoneTypeHelper.metadata(zoneType).color }} />,
}));

interface ZoneTypeAndNameFormGroupProps {
  type: string;
  representsMobilityStation: boolean;
  name: string;
  readOnlyZoneRuleForm: boolean;
  serverError?: ZoneRuleFormServerError;
}

export const ZoneTypeAndNameFormGroup = ({
  type,
  representsMobilityStation,
  name,
  readOnlyZoneRuleForm,
  serverError,
}: ZoneTypeAndNameFormGroupProps) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const { errors, touched, setFieldValue, handleBlur } = useFormikContext<ZoneRulesFormValues>();
  const updateZoneServerViolationsFieldToError = serverError?.updateError;
  const createZoneServerViolationsFieldToError = serverError?.createError;

  const zoneServerViolationsFieldToError = updateZoneServerViolationsFieldToError || createZoneServerViolationsFieldToError;
  const isStopZone = type === ZoneType.STOP;
  return (
    <Stack spacing={3}>
      <Typography variant="bodyMediumBold" component="h4">
        {formatMessage({ id: 'map.forms.section.zoneInformation' })}
      </Typography>
      <Box display="flex" flexDirection="column">
        <SelectionMenu
          options={zoneTypesMenuConfig}
          onChange={handleTypeChange}
          value={type}
          label={formatMessage({ id: 'map.forms.zoneType.label' })}
          disabled={readOnlyZoneRuleForm}
          name={ZoneRuleFormFields.type}
        />
        {isStopZone && (
          <Box display="flex" flexDirection="row" alignItems="center">
            <Checkbox<boolean>
              onChange={handleMobilityHubToggle}
              checked={representsMobilityStation}
              label={'zone.mobilityHubSetup'.i18nText()}
              value={representsMobilityStation}
              name={ZoneRuleFormFields.representsMobilityStation}
              disabled={readOnlyZoneRuleForm}
              sx={{ mr: theme.spacing(0.25) }}
            />
            <InfoIconWithTooltip placement="bottom-end" title={'zone.mobilityStationFacility'.i18nText()} disabled={readOnlyZoneRuleForm} />
          </Box>
        )}
      </Box>
      <TextField
        label={formatMessage({ id: 'map.forms.zoneName.label' })}
        onChange={handleNameChange}
        onBlur={handleBlur}
        value={name}
        error={(!!errors.name && touched.name) || !!zoneServerViolationsFieldToError?.['name']}
        name={ZoneRuleFormFields.name}
        placeholder={formatMessage({ id: 'map.forms.zoneName.placeholder' })}
        helperText={(!!errors.name && touched.name && errors.name) || zoneServerViolationsFieldToError?.['name']?.message}
        disabled={readOnlyZoneRuleForm}
      />
    </Stack>
  );

  async function handleNameChange(event: ChangeEvent<HTMLInputElement>) {
    await setFieldValue(ZoneRuleFormFields.name, event.target.value);
  }

  async function handleTypeChange(event: SelectChangeEvent<string | number>) {
    await setFieldValue(ZoneRuleFormFields.type, event.target.value);
    if (event.target.value !== ZoneType.STOP) {
      await setFieldValue(ZoneRuleFormFields.representsMobilityStation, false);
    }
  }

  async function handleMobilityHubToggle(event: ChangeEvent<HTMLInputElement>) {
    await setFieldValue(ZoneRuleFormFields.representsMobilityStation, event.target.checked);
    if (event.target.checked) {
      await setFieldValue(ZoneRuleFormFields.indefiniteZone, true);
      await setFieldValue(ZoneRuleFormFields.endDate, null);

      await setFieldValue(ZoneRuleFormFields.representsFullDay, true);
      await setFieldValue(ZoneRuleFormFields.startTime, dayjs().toBeginningOfDay());
      await setFieldValue(ZoneRuleFormFields.endTime, dayjs().toEndOfDay());

      await setFieldValue(ZoneRuleFormFields.daysOfWeek, DayOfWeekHelper.allDaysOfWeek());
    }
  }
};
