import { themeOptions } from '../../init-setup/ThemeOptions';
import { EnumWithStringValue } from '../../utils/EnumUtils';
import { intl } from '../../utils/intl';

export enum BudgetPlanMembershipStatus {
  Subscribed = 'SUBSCRIBED',
  Unsubscribed = 'UNSUBSCRIBED',
}

interface BudgetPlanMembershipStatusUnderivedMetadata {
  displayText: string;
  color: string;
}

export namespace BudgetPlanMembershipStatusHelper {
  const { formatMessage } = intl;
  const statusToUnderivedMetadata = new Map<BudgetPlanMembershipStatus, BudgetPlanMembershipStatusUnderivedMetadata>([
    [
      BudgetPlanMembershipStatus.Subscribed,
      { displayText: formatMessage({ id: 'common.status.linked' }), color: themeOptions.palette.success.main },
    ],
    [
      BudgetPlanMembershipStatus.Unsubscribed,
      { displayText: formatMessage({ id: 'common.status.unlinked' }), color: themeOptions.palette.baseDark.base32 },
    ],
  ]);

  export function metadata(status: BudgetPlanMembershipStatus): BudgetPlanMembershipStatusUnderivedMetadata {
    const underivedMetadata = statusToUnderivedMetadata.get(status);
    if (!underivedMetadata) {
      throw Error(`
              Specified BudgetPlanMembershipStatus: ${toString(status)} doesnt have corresponding underived/explicitly defined metadata.
              This usually (not always) means a bug or incomplete implementation.
          `);
    }

    return { ...underivedMetadata };
  }

  export function toString(status: BudgetPlanMembershipStatus): string {
    return EnumWithStringValue.getEnumKeyByEnumValue(BudgetPlanMembershipStatus, status)!;
  }
}
