import { StrictMode } from 'react';

import ReactDOM from 'react-dom/client';

import './assets/styles/index.scss';
import App from './App';
import UserService from './services/UserService';

const root = ReactDOM.createRoot(document.getElementById('root')!);
const renderApp = () =>
  root.render(
    <StrictMode>
      <App />
    </StrictMode>,
  );

UserService.initKeycloak(renderApp);
