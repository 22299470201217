import MuiDrawer from '@mui/material/Drawer';
// eslint-disable-next-line import/named
import { styled, Theme } from '@mui/material/styles';

import { HEADER_HEIGHT, HEADER_TABLE_GAP_HEIGHT, TABLE_HEIGHT } from '../utils/constants';

const openedMixin = (theme: Theme) => ({
  height: TABLE_HEIGHT,
  marginTop: `calc(${HEADER_HEIGHT} + ${HEADER_TABLE_GAP_HEIGHT})`,
  width: '480px',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflow: 'hidden',
});

const closedMixin = (theme: Theme) => ({
  height: TABLE_HEIGHT,
  marginTop: `calc(${HEADER_HEIGHT} + ${HEADER_TABLE_GAP_HEIGHT})`,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflow: 'hidden',
  width: '100px',
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  maxWidth: '480px',
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export interface SideDrawerProps {
  open: boolean;
  anchor?: 'top' | 'right' | 'bottom' | 'left';
  children?: React.ReactNode;
}

const SideDrawer: React.FC<SideDrawerProps> = ({ open, anchor = 'right', children }) => {
  return (
    <Drawer variant="permanent" open={open} anchor={anchor}>
      {children}
    </Drawer>
  );
};

export default SideDrawer;
