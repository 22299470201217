import { Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useIntl } from 'react-intl';

import { LoadingAnimation } from '../../../../wmv-components';

export enum StatusModalChangeButtonUsage {
  Cancel = 'Cancel',
  Change = 'Change',
  Retry = 'Retry',
  CancelRetry = 'CancelRetry',
}

const ModalChangeButton = ({
  usage,
  message,
  onClickFunction,
  loading,
  disabled,
}: {
  usage: StatusModalChangeButtonUsage;
  message: string;
  onClickFunction: () => void;
  loading?: boolean;
  disabled?: boolean;
}) => {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  return (
    <Button
      sx={{
        borderRadius: theme.spacing(1),
        height: theme.spacing(4),
        width: '140px',
        '&:hover': {
          backgroundColor:
            usage === StatusModalChangeButtonUsage.Change
              ? theme.palette.alert.dark
              : usage === StatusModalChangeButtonUsage.Cancel
              ? theme.palette.dark.shade24
              : usage === StatusModalChangeButtonUsage.Retry
              ? theme.palette.dark.main
              : theme.palette.white.main,
        },
        backgroundColor:
          usage === StatusModalChangeButtonUsage.Change
            ? theme.palette.alert.main
            : usage === StatusModalChangeButtonUsage.Cancel
            ? theme.palette.dark.shade4
            : usage === StatusModalChangeButtonUsage.Retry
            ? theme.palette.dark.shade76
            : theme.palette.white.main,
      }}
      onClick={onClickFunction}
      variant="contained"
      disabled={disabled}
    >
      {loading ? (
        <LoadingAnimation sizeMultiplier={0.5} />
      ) : (
        <Typography
          variant="bodySmallBold"
          color={
            usage === StatusModalChangeButtonUsage.CancelRetry || usage === StatusModalChangeButtonUsage.Cancel
              ? theme.palette.dark.main
              : theme.palette.white.main
          }
          sx={{ whiteSpace: 'nowrap' }}
        >
          {formatMessage({ id: message })}
        </Typography>
      )}
    </Button>
  );
};

export default ModalChangeButton;
