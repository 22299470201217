import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Polygon } from 'leaflet';
import { useSelector, useDispatch } from 'react-redux';

import { ReactComponent as OverlayFilledIcon } from '../../assets/icons/fill/overlayFilled.svg';
import { ReactComponent as OverlayOutlinedIcon } from '../../assets/icons/outline/overlay.svg';
import { GroupMenuControl } from '../../components/Map/controls/GroupMenuControl';
import GeoJsonZones from '../../components/Map/GeoJsonZones';
import SetView from '../../components/Map/MapModes/PlanningMode/LayersView/SetView';
import VehicleStateLegendAndResetFilterBottomBar from '../../components/Map/VehicleStateLegendAndResetFilterBottomBar';
import WikimoveMap from '../../components/Map/WikimoveMap';
import MapFiltersAndZoneTypeInfoControls from '../../components/Map/ZoneControls';
import { MapFilters } from '../../components/Map/ZoneControls/MapFilters';
import { zoneRuleInitialValues } from '../../components/Map/ZoneManagement/ZoneRuleSettingsPopover/helpers';
import {
  ZoneManagementActionState,
  ZoneRuleSettingsPopover,
} from '../../components/Map/ZoneManagement/ZoneRuleSettingsPopover/ZoneRuleSettingsPopover';
import { MapVehiclesTableColumnDefs } from '../../components/MapTableView/MapVehiclesTableColumnDefs';
import VehiclesPopupDataTable from '../../components/MapTableView/ZonesPopupDataTable';
import {
  useFilterZonesByPredicates,
  useApplicableVehiclesForFilters,
  useEvaluateZonesVisibilityWithTheTime,
  useModalIsOpen,
} from '../../hooks';
import { AppDispatch, loadingVehiclesSelector, loadingVehiclesErrorSelector, formattedAllVehiclesSelector } from '../../state';
import { FormattedLiveVehicle } from '../../state/common/vehicle.model';
import { publishedZonesSelector, getPublishedZonesAsyncThunkAction } from '../../state/zones-management';
import { TABLE_FOOTER_HEIGHT, VIEW_PANNING_MODE_VIEW_HEIGHT, VIEW_PLANNING_MODE_TABLE_HEIGHT } from '../../utils/constants';
import { FloatingBox } from '../../wmv-components';

import { ZoneGeoJsonFeature } from './ZoneManagement';

const ViewModeLayout = ({ filters, onFilterChange, anyFilterApplied, resetFilters }: ViewModeLayoutProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();

  const [selectedVehicleId, setSelectedVehicleId] = useState<string | null | undefined>();
  const [showVehicleTable, setShowVehicleTable] = useState(false);
  const [selectedByShiftAndDragVehicles, setSelectedByShiftAndDragVehicles] = useState<FormattedLiveVehicle[]>([]);

  const loadingVehiclesError = useSelector(loadingVehiclesErrorSelector);
  const loadingVehicles: boolean = useSelector(loadingVehiclesSelector);
  const allVehicles = useSelector(formattedAllVehiclesSelector);
  const filteredVehicles = useApplicableVehiclesForFilters(allVehicles, filters);

  const publishedZones = useSelector(publishedZonesSelector);
  const zonesApplicableAtCurrentTime = useEvaluateZonesVisibilityWithTheTime(publishedZones);
  const filteredZones = useFilterZonesByPredicates(zonesApplicableAtCurrentTime, {
    zoneTypes: filters.zoneTypes,
    vehicleTypes: filters.vehicleTypes,
    infraTypes: filters.infrastructureTypes,
  });

  const [selectedZoneGeoJsonFeature, setSelectedZoneGeoJsonFeature] = useState<ZoneGeoJsonFeature | null>(null);
  const [showZoneRuleConfig, openZoneRuleConfigPopover, closeZoneRuleConfigPopover] = useModalIsOpen(false);

  useEffect(() => {
    dispatch(getPublishedZonesAsyncThunkAction());
  }, [dispatch]);

  const vehicleTableHeight = useMemo(() => {
    let availableHeight = showVehicleTable
      ? VIEW_PANNING_MODE_VIEW_HEIGHT
      : `calc(${VIEW_PLANNING_MODE_TABLE_HEIGHT} - ${TABLE_FOOTER_HEIGHT})`;

    return `calc(100vh - ${availableHeight})`;
  }, [showVehicleTable]);

  useEffect(() => {
    if (!showVehicleTable && selectedByShiftAndDragVehicles.length > 0) {
      setShowVehicleTable(true);
    }
    if (showVehicleTable && selectedByShiftAndDragVehicles.length < 1) {
      setShowVehicleTable(false);
    }
  }, [selectedByShiftAndDragVehicles, showVehicleTable]);

  const MemoizedMapVehiclesTableColumnDefs = MapVehiclesTableColumnDefs();

  const handleZoneClick = useCallback(
    (selectedLayer: Polygon) => {
      const selectedZoneGeoJsonFeature = selectedLayer.toGeoJSON();
      setSelectedZoneGeoJsonFeature(selectedZoneGeoJsonFeature);
      openZoneRuleConfigPopover();
    },
    [openZoneRuleConfigPopover],
  );

  return (
    <Box height={vehicleTableHeight}>
      <WikimoveMap openMarkerId={selectedVehicleId} setPopupTableData={setSelectedByShiftAndDragVehicles} vehicles={filteredVehicles}>
        <FloatingBox boxStyling={{ top: theme.spacing(9) }}>
          <MapFiltersAndZoneTypeInfoControls filters={filters} isAnyFilterApplied={anyFilterApplied} onFilterChange={onFilterChange} />
        </FloatingBox>
        <ZoneRuleSettingsPopover
          readOnly
          isOpen={showZoneRuleConfig}
          zoneSettings={selectedZoneGeoJsonFeature?.properties || zoneRuleInitialValues}
          onZoneRuleFormClose={closeZoneRuleConfigPopover}
          zoneManagementActionState={ZoneManagementActionState.ViewZoneInformationScreen}
        />
        <GroupMenuControl
          buttons={[
            {
              icon: <OverlayOutlinedIcon />,
              activeIcon: <OverlayFilledIcon />,
              content: <SetView />,
            },
          ]}
        />
        <GeoJsonZones zones={filteredZones} onLayerClick={handleZoneClick} />
      </WikimoveMap>
      <Box>
        {showVehicleTable && (
          <VehiclesPopupDataTable
            loading={loadingVehicles}
            data={selectedByShiftAndDragVehicles}
            columns={MemoizedMapVehiclesTableColumnDefs}
            onClose={() => {
              setShowVehicleTable(false);
              setSelectedVehicleId(null);
              setSelectedByShiftAndDragVehicles([]);
            }}
            onRowClickHandler={setSelectedVehicleId}
          />
        )}
        <VehicleStateLegendAndResetFilterBottomBar
          filters={filters}
          isAnyFilterApplied={anyFilterApplied}
          onFiltersReset={resetFilters}
          loadingVehiclesError={loadingVehiclesError}
          loadingVehicles={loadingVehicles}
        />
      </Box>
    </Box>
  );
};

interface ViewModeLayoutProps {
  onFilterChange: (values: MapFilters) => void;
  filters: MapFilters;
  anyFilterApplied: boolean;
  resetFilters: () => void;
}

export default ViewModeLayout;
