import type { PluginFunc, Dayjs } from 'dayjs';

declare module 'dayjs' {
  interface Dayjs {
    dayDiff(date: Dayjs): number;
    weekDiff(date: Dayjs): number;
    monthDiff(date: Dayjs): number;
    hourDiff(date: Dayjs): number;
    minuteDiff(date: Dayjs): number;
    secondDiff(date: Dayjs): number;
    milliSecondDiff(date: Dayjs): number;
  }
}

export const diffPlugin: PluginFunc = (option, dayjsClass, dayjsFactory) => {
  dayjsClass.prototype.dayDiff = function (date: Dayjs): number {
    return this.diff(date, 'day');
  };

  dayjsClass.prototype.weekDiff = function (date: Dayjs): number {
    return this.diff(date, 'week');
  };

  dayjsClass.prototype.monthDiff = function (date: Dayjs): number {
    return this.diff(date, 'month');
  };

  dayjsClass.prototype.hourDiff = function (date: Dayjs): number {
    return this.diff(date, 'hour');
  };

  dayjsClass.prototype.minuteDiff = function (date: Dayjs): number {
    return this.diff(date, 'minute');
  };

  dayjsClass.prototype.secondDiff = function (date: Dayjs): number {
    return this.diff(date, 'second');
  };

  dayjsClass.prototype.milliSecondDiff = function (date: Dayjs): number {
    return this.diff(date, 'millisecond');
  };
};

export default diffPlugin;
