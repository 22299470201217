import type { Theme } from '@mui/material/styles';

export const floatingBoxStyles = (theme: Theme) => ({
  width: '100%',
  bottom: '20px',
  top: 'unset',
  left: 'unset',
});

export const drawActionButtonContainerStyles = (theme: Theme) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
  backgroundColor: theme.palette.baseLight.main,
  border: `1px solid ${theme.palette.baseDark.base8}`,
  margin: `0 auto`,
  borderRadius: '8px',
});

export const trashButtonStyles = (theme: Theme) => ({
  minWidth: 'unset',
  '&:hover': {
    svg: {
      fill: theme.palette.alert.main,
    },
  },
});

export const cancelButtonStyles = (theme: Theme, disabled: boolean) => ({
  textTransform: 'capitalize',
  '&': {
    span: {
      color: disabled ? theme.palette.baseLight.base32 : theme.palette.alert.main,
    },
  },
  '&:hover': {
    backgroundColor: theme.palette.black.shade10,
  },
});

export const continueButtonStyles = (theme: Theme, disabled: boolean) => ({
  textTransform: 'capitalize',
  '&': {
    span: {
      color: disabled ? theme.palette.baseLight.base32 : theme.palette.secondary.dark,
    },
  },
  '&:hover': {
    backgroundColor: theme.palette.black.shade10,
    span: {
      color: theme.palette.success.main,
    },
  },
  '& > svg': {
    fill: disabled ? theme.palette.baseLight.base32 : theme.palette.secondary.dark,
  },
});
