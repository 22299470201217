import { ReactNode, useEffect } from 'react';

import { useSubscription } from '@apollo/client';
import { useDispatch } from 'react-redux';

import {
  ALL_CLOUDBOX_VEHICLES_SUBSCRIPTION,
  ALL_COMODULE_VEHICLES_SUBSCRIPTION,
  FREE_DONKEY_VEHICLES_SUBSCRIPTION,
  FREE_MEIAUDO_VEHICLES_SUBSCRIPTION,
  FREE_VEHICLES_SUBSCRIPTION,
} from '../api/Graphql';
import { GraphqlVehicleUsageStatus } from '../models';
import { loadingVehiclesDataAction, loadingVehiclesErrorAction, updateAllVehiclesAction } from '../state';
import { LiveVehicle } from '../state/common/vehicle.model';

let cloudBoxxLiveVehicles: LiveVehicle[] = [];

const DataSubscription: React.FC<{ children: ReactNode }> = ({ children }) => {
  const center = [49.89873, 10.90067];
  const reduxDispatch = useDispatch();

  const graphQlQueryVariables = {
    radius: 7500,
    lat: center[0],
    lon: center[1],
  };

  const {
    data: zeusVehiclesData,
    loading: zeusVehiclesLoading,
    error: zeusVehiclesError,
  } = useSubscription(FREE_VEHICLES_SUBSCRIPTION, {
    variables: graphQlQueryVariables,
  });

  const {
    data: donkeyData,
    loading: donkeyLoading,
    error: donkeyError,
  } = useSubscription(FREE_DONKEY_VEHICLES_SUBSCRIPTION, {
    variables: graphQlQueryVariables,
  });

  const {
    data: meiAudoData,
    loading: meiAudoLoading,
    error: meiAudoError,
  } = useSubscription(FREE_MEIAUDO_VEHICLES_SUBSCRIPTION, {
    variables: graphQlQueryVariables,
  });

  const { loading: cloudBoxxLoading, error: cloudBoxxError } = useSubscription(ALL_CLOUDBOX_VEHICLES_SUBSCRIPTION, {
    variables: graphQlQueryVariables,
    onSubscriptionData: (data) => {
      if (data.subscriptionData.data && data.subscriptionData.data.allCloudBoxxVehiclesByLocation) {
        updateCloudBoxxData(data.subscriptionData.data.allCloudBoxxVehiclesByLocation);
      }
    },
  });

  const {
    data: coModuleData,
    loading: coModuleLoading,
    error: coModuleError,
  } = useSubscription(ALL_COMODULE_VEHICLES_SUBSCRIPTION, {
    variables: { ...graphQlQueryVariables, formFactor: 'eBike' },
  });

  function updateCloudBoxxData(cloudBoxxVehicle: LiveVehicle) {
    const i = cloudBoxxLiveVehicles.findIndex((obj) => obj?.qrCodeId === cloudBoxxVehicle.qrCodeId);
    if (i > -1) cloudBoxxLiveVehicles[i] = cloudBoxxVehicle;
    else cloudBoxxLiveVehicles.push(cloudBoxxVehicle);
  }

  useEffect(() => {
    reduxDispatch(loadingVehiclesDataAction(zeusVehiclesLoading || donkeyLoading || coModuleLoading || cloudBoxxLoading || meiAudoLoading));
    // eslint-disable-next-line
  }, [zeusVehiclesLoading, donkeyLoading, coModuleLoading, cloudBoxxLoading, meiAudoLoading]);

  useEffect(() => {
    if (zeusVehiclesError || donkeyError || coModuleError || cloudBoxxError || meiAudoError) {
      console.log(zeusVehiclesError, coModuleError, meiAudoError);
      reduxDispatch(loadingVehiclesErrorAction('Error! loading vehicles data.'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zeusVehiclesError, donkeyError, coModuleError, cloudBoxxError, meiAudoError]);

  useEffect(() => {
    let vehicles: LiveVehicle[] = [];
    if (zeusVehiclesData) {
      vehicles = [...vehicles, ...zeusVehiclesData.freeVehiclesByLocation];
    } //e-scooter
    if (donkeyData) {
      vehicles = [...vehicles, ...donkeyData.freeDonkeyVehiclesByLocation];
    } //cargo-bike
    if (meiAudoData) {
      vehicles = [...vehicles, ...meiAudoData.freeMeiAudoVehiclesByLocation];
    } //car
    if (cloudBoxxLiveVehicles.length > 1) {
      vehicles = [...vehicles, ...cloudBoxxLiveVehicles];
    } //e-moped
    if (coModuleData) {
      vehicles = [...vehicles, ...coModuleData.allCoModuleVehiclesByLocation];
    } //e-bike
    vehicles = vehicles.map((vehicle: LiveVehicle) => {
      if (!vehicle.usageStatus) {
        vehicle.usageStatus = GraphqlVehicleUsageStatus.NotBookable;
      }
      return vehicle;
    });
    reduxDispatch(updateAllVehiclesAction(vehicles));
    // eslint-disable-next-line
  }, [zeusVehiclesData, donkeyData, meiAudoData, cloudBoxxLiveVehicles, coModuleData]);

  return <>{children}</>;
};

export default DataSubscription;
