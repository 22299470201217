import { isArray } from 'lodash';
import capitalize from 'lodash/capitalize';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';

import { VehicleType } from '../../models';
import {
  DepositFrequency,
  DepositSchedule,
  DepositUnit,
  DepositUnitHelper,
  DepositFrequencyHelper,
  VehicleTypeSpendConstraints,
  VehicleSpendConstraintCategory,
  BudgetPlanActiveState,
  BudgetPlanMembershipCount,
} from '../../models/budget-plan';
import { intl } from '../../utils/intl';

const { formatMessage } = intl;

interface DepositFrequencyShortAndLongFormDisplayText {
  longForm: string;
  shortForm: string;
  shortFormNoAmount: string;
}

export class BudgetPlanActiveStateDto {
  name: string;
  depositAmount: number;
  frequency: DepositFrequency;
  depositAmountUnit: DepositUnit;
  depositSchedule: DepositSchedule;
  spendConstraintsExist: boolean;
  amountPerTripConstraint?: number;
  vehicleTypesConstraint: VehicleType[] = [];
  depositDayOfMonth: number;
  depositTime: string;
  membershipCount: BudgetPlanMembershipCount;

  constructor(activeState: BudgetPlanActiveState) {
    const { details: activeStateBudgetPlanDetails, membershipCount } = activeState;
    this.name = activeStateBudgetPlanDetails.name;
    this.depositAmount = activeStateBudgetPlanDetails.fragments[0].amount;
    this.depositAmountUnit = activeStateBudgetPlanDetails.fragments[0].unit;
    this.depositDayOfMonth = activeStateBudgetPlanDetails.depositSchedule.metadata.dayOfMonth;
    this.depositTime = activeStateBudgetPlanDetails.depositSchedule.metadata.timeOfDay;
    this.frequency = activeStateBudgetPlanDetails.depositSchedule.frequency;
    this.depositSchedule = activeStateBudgetPlanDetails.depositSchedule;
    this.membershipCount = membershipCount;
    this.spendConstraintsExist = !isEmpty(activeStateBudgetPlanDetails.fragments[0].spendConstraints);

    if (this.spendConstraintsExist) {
      const spendConstraints = activeStateBudgetPlanDetails.fragments[0].spendConstraints![0];
      if (isArray(spendConstraints.budget) && spendConstraints.budget.length) {
        this.amountPerTripConstraint = spendConstraints.budget[0].value;
      }

      const vehicleTypesConstraint = find(spendConstraints.vehicle, { type: VehicleSpendConstraintCategory.VehicleType });
      if (spendConstraints.vehicle && !!vehicleTypesConstraint) {
        this.vehicleTypesConstraint = (vehicleTypesConstraint as VehicleTypeSpendConstraints).values;
      }
    }
  }

  depositFrequencyShortAndLongFormDisplayText(): DepositFrequencyShortAndLongFormDisplayText {
    const shortForm = formatMessage(
      { id: 'budgetPlan.depositFrequency.displayText.shortForm' },
      {
        amount: this.depositAmount,
        unit: DepositUnitHelper.metadata(this.depositAmountUnit).displayText,
        frequency: capitalize(DepositFrequencyHelper.metadata(this.frequency).displayText),
        dayOfMonth: this.depositSchedule.metadata.dayOfMonth,
        depositTime: this.depositTimeOfDayText(),
      },
    );
    const shortFormNoAmount = formatMessage(
      { id: 'budgetPlan.depositFrequency.displayText.shortForm.noAmount' },
      {
        frequency: capitalize(DepositFrequencyHelper.metadata(this.frequency).displayText),
        dayOfMonth: this.depositSchedule.metadata.dayOfMonth,
        depositTime: this.depositTimeOfDayText(),
      },
    );
    const longForm = formatMessage(
      { id: 'budgetPlan.depositFrequency.displayText.longForm' },
      {
        amount: this.depositAmount,
        unit: capitalize(DepositUnitHelper.metadata(this.depositAmountUnit).displayText),
        dayOfMonth: this.depositSchedule.metadata.dayOfMonth,
        frequency: DepositFrequencyHelper.metadata(this.frequency).displayText,
        depositTime: this.depositTimeOfDayText(),
      },
    );
    return { longForm, shortForm, shortFormNoAmount };
  }

  private depositTimeOfDayText(): string {
    const timeOfDay = this.depositSchedule.metadata.timeOfDay;
    if (timeOfDay.asDayjsTime().isBeginningOfDay()) {
      return formatMessage({ id: 'budgetPlan.depositFrequency.atBeginningOfDay' });
    }
    if (timeOfDay.asDayjsTime().isEndOfDaySeconds()) {
      return formatMessage({ id: 'budgetPlan.depositFrequency.atEndOfDay' });
    }

    return timeOfDay;
  }
}
