/* eslint-disable no-extend-native */
import type { PluginFunc, Dayjs } from 'dayjs';
import dayjs from 'dayjs';

declare global {
  interface Number {
    parseEpochMillis(): Dayjs;
  }
}

const numberToDayjsConverter: PluginFunc = (option, dayjsClass, dayjsFactory) => {
  Number.prototype.parseEpochMillis = function (this: number): Dayjs {
    return dayjs(this);
  };
};

export default numberToDayjsConverter;
