import { Dayjs } from 'dayjs';

import { VehicleType } from '../../models';
import { DayOfWeek, ZoneType } from '../../models';

export enum SliderGranularity {
  AGGREGATED = 'AGGREGATED',
  DAY = 'DAY',
  HOUR = 'HOUR',
}

export interface SliderPointsWithGranularity {
  pointsAsEpochMillis: number[];
  granularity: SliderGranularity;
}

export interface ZoneLayerProperties extends ZoneLayerMetadata {
  name: string;
  representsMobilityStation: boolean;
  type: ZoneType;
  startTime: Dayjs;
  endTime: Dayjs;
  representsFullDay: boolean;
  startDate: Dayjs;
  endDate: Dayjs | null;
  indefiniteZone: boolean;
  daysOfWeek: Set<DayOfWeek>;
  applicableVehicleTypes: VehicleType[];
}

export interface ZoneLayerMetadata {
  id: number;
  strokeColor: string;
  fillColor: string;
}

export type ZoneGeoJsonFeature = GeoJSON.Feature<GeoJSON.Geometry, ZoneLayerProperties>;
