import { useEffect } from 'react';

import L from 'leaflet';
import { useMap } from 'react-leaflet';

import mapboxLogo from '../../../assets/images/mapbox-logo.svg';

/**
 * MapBoxLogoControl is a React component that adds a custom MapBox logo control to a Leaflet map.
 * The logo is placed in the bottom-left corner of the map and links to the MapBox website when clicked.
 *
 * This component is intended to be used with `react-leaflet` and Leaflet.
 */
const MapBoxLogoControl = () => {
  const map = useMap();

  // Remove default Leaflet logo - not mandatory
  map.attributionControl.setPrefix('');

  useEffect(() => {
    const mapBoxLogoControl = new L.Control({ position: 'bottomleft' });

    mapBoxLogoControl.onAdd = () => {
      const logoDiv = L.DomUtil.create('div', 'mapbox-logo');
      const logoImage = L.DomUtil.create('img', '', logoDiv);
      logoImage.src = mapboxLogo;
      logoImage.style.width = '100px';
      logoImage.style.height = 'auto';
      logoImage.style.cursor = 'pointer';

      // Add a link to MapBox
      logoImage.onclick = () => {
        window.open('https://www.mapbox.com/', '_blank');
      };

      return logoDiv;
    };

    mapBoxLogoControl.addTo(map);

    return () => {
      map.removeControl(mapBoxLogoControl);
    };
  }, [map]);

  return null;
};

export default MapBoxLogoControl;
