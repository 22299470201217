import { useRef, useState } from 'react';

import { TripDetailsResponse } from '../../../models';
import { getTripDetails } from '../../../utils/queries';

export function useFetchTripDetails(tripId: number) {
  const [tripDetailsData, setTripDetailsData] = useState<TripDetailsResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const prevTripIdRef = useRef<number | null>();

  const fetchTripDetails = async () => {
    setLoading(true);
    setError(false);
    setTripDetailsData(null);
    try {
      const data = await getTripDetails(tripId);
      if (tripId && data.id) {
        setTripDetailsData(data);
      } else {
        setTripDetailsData(null);
      }
    } catch (err) {
      setError(true);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  if (tripId && prevTripIdRef.current !== tripId) {
    prevTripIdRef.current = tripId;
    fetchTripDetails();
  }

  return { tripDetailsData, loading, error, fetchTripDetails };
}
