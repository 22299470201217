import { useEffect, useRef } from 'react';
import type { MouseEvent, RefObject } from 'react';

import { ButtonGroup } from '@mui/material';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';
import { useLeafletContext } from '@react-leaflet/core';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import { useIntl } from 'react-intl';

import { ReactComponent as AddIcon } from '../../../assets/icons/outline/add.svg';
import { ReactComponent as CutIcon } from '../../../assets/icons/outline/cut.svg';
import { ReactComponent as MoveIcon } from '../../../assets/icons/outline/move.svg';
import { ReactComponent as AdjustIcon } from '../../../assets/illustrations/adjust.svg';
import { Layer, Map } from '../../../services/layer-management';

import { useDisableActiveLayerManagementTool } from './hooks';
import { toolbarDividerStyles } from './LayerManagementToolbar.styles';
import { MapOverlayActionButton } from './MapOverlayActionButton';

export function LayerManagementToolbar({
  onDrawClick,
  onDragClick,
  onCutClick,
  onAdjustClick,
  activeTool,
  addButtonRef,
  workingLayer,
  setActiveLayerManagementTool,
  enableEditControls,
  enableAddControl,
}: LayerManagementToolbarProps) {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const context = useLeafletContext();
  const map = context.map;

  const mapRef = useRef<Map | null>(null);

  const disableActiveLayerManagementTool = useDisableActiveLayerManagementTool(map, workingLayer);

  useEffect(() => {
    mapRef.current = new Map(map);
  }, [map]);

  const handleDragClick = (event: MouseEvent<HTMLButtonElement>) => {
    disableActiveLayerManagementTool();
    if (activeTool === LayerManagementTool.Drag) {
      setActiveLayerManagementTool(null);
    } else {
      workingLayer?.enableDrag();
      setActiveLayerManagementTool(LayerManagementTool.Drag);
    }
    onDragClick && onDragClick(event);
  };

  const handleCutClick = (event: MouseEvent<HTMLButtonElement>) => {
    disableActiveLayerManagementTool();
    if (activeTool === LayerManagementTool.Cut) {
      setActiveLayerManagementTool(null);
    } else {
      mapRef.current!.enableGlobalCut();
      setActiveLayerManagementTool(LayerManagementTool.Cut);
    }
    onCutClick && onCutClick(event);
  };

  const handleAdjustClick = (event: MouseEvent<HTMLButtonElement>) => {
    disableActiveLayerManagementTool();
    if (activeTool === LayerManagementTool.Adjust) {
      setActiveLayerManagementTool(null);
    } else {
      workingLayer?.enableEdit();
      setActiveLayerManagementTool(LayerManagementTool.Adjust);
    }
    onAdjustClick && onAdjustClick(event);
  };

  const handleDrawClick = (event: MouseEvent<HTMLButtonElement>) => {
    disableActiveLayerManagementTool();
    if (activeTool === LayerManagementTool.Add) {
      mapRef.current!.removeLayer(workingLayer?.layer!);
      setActiveLayerManagementTool(null);
    } else {
      mapRef.current!.enablePolygonDraw();
      setActiveLayerManagementTool(LayerManagementTool.Add);
    }
    onDrawClick && onDrawClick(event);
  };

  return (
    <ButtonGroup orientation="vertical" variant="outlined" sx={{ boxShadow: theme.shadowOptions.belowMedium, zIndex: '1' }}>
      <MapOverlayActionButton
        label={formatMessage({ id: 'common.add' })}
        selected={activeTool === LayerManagementTool.Add}
        onClick={handleDrawClick}
        icon={<AddIcon />}
        disabled={!enableAddControl}
        tooltipTitle={formatMessage({ id: 'zone.controls.draw' })}
        ref={addButtonRef}
      />
      <Divider sx={toolbarDividerStyles} flexItem />

      <MapOverlayActionButton
        label={formatMessage({ id: 'common.adjust' })}
        selected={activeTool === LayerManagementTool.Adjust}
        onClick={handleAdjustClick}
        disabled={!enableEditControls}
        icon={<AdjustIcon />}
        tooltipTitle={formatMessage({ id: 'zone.controls.adjust' })}
      />
      <Divider sx={toolbarDividerStyles} flexItem />

      <MapOverlayActionButton
        label={formatMessage({ id: 'common.move' })}
        selected={activeTool === LayerManagementTool.Drag}
        onClick={handleDragClick}
        disabled={!enableEditControls}
        icon={<MoveIcon />}
        tooltipTitle={formatMessage({ id: 'zone.controls.move' })}
      />
      <Divider sx={toolbarDividerStyles} flexItem />

      <MapOverlayActionButton
        label={formatMessage({ id: 'common.cut' })}
        selected={activeTool === LayerManagementTool.Cut}
        onClick={handleCutClick}
        icon={<CutIcon />}
        disabled={!enableEditControls}
        tooltipTitle={formatMessage({ id: 'zone.controls.cut' })}
      />
    </ButtonGroup>
  );
}

export enum LayerManagementTool {
  Add = 'ADD',
  Adjust = 'ADJUST',
  Drag = 'DRAG',
  Cut = 'CUT',
}

interface LayerManagementToolbarProps {
  onDrawClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  onDragClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  onCutClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  onAdjustClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  activeTool: LayerManagementTool | null;
  addButtonRef: RefObject<HTMLButtonElement>;
  workingLayer: Layer | null;
  setActiveLayerManagementTool: (tool: LayerManagementTool | null) => void;
  enableEditControls: boolean;
  enableAddControl: boolean;
}
