import { useState } from 'react';

import Box from '@mui/material/Box';
// eslint-disable-next-line import/named
import { SelectChangeEvent } from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import { useIntl } from 'react-intl';

import { HEADER_HEIGHT } from '../../utils/constants';
import { intl } from '../../utils/intl';
import { SelectionMenu, SelectionMenuOptionMetadata } from '../../wmv-components';

import { CustomerDetailsView } from './CustomerDetails/CustomerDetails';
import { LawEnforcementTripSearchView } from './LawEnforcementTripSearch/LawEnforcementTripSearchView';

// is also used as key in the translation files
const customerDatabaseViews = ['customerDetails', 'lawEnforcementTripSearch'];

const options: SelectionMenuOptionMetadata[] = [
  {
    value: 'customerDetails',
    label: intl.formatMessage({ id: 'maas.customerDatabase.customerDetails.headline' }),
  },
  {
    value: 'lawEnforcementTripSearch',
    label: intl.formatMessage({ id: 'maas.customerDatabase.lawEnforcementTripSearch.headline' }),
  },
];

const getSelectedViewComponent = (view: string) => {
  switch (view) {
    case 'customerDetails':
      return <CustomerDetailsView></CustomerDetailsView>;
    case 'lawEnforcementTripSearch':
      return <LawEnforcementTripSearchView></LawEnforcementTripSearchView>;
    default:
      return <></>;
  }
};

const CustomerDatabase = () => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const [selectedView, setSelectedView] = useState(customerDatabaseViews[0]);

  const handleChange = (event: SelectChangeEvent<string | number>) => {
    setSelectedView(event.target.value as string);
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: `calc(100vh - ${HEADER_HEIGHT})`,
        backgroundColor: theme.palette.white.main,
      }}
    >
      <Box style={{ float: 'left' }} sx={{ p: '32px 24px 24px', bgcolor: 'white.main' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', borderBottom: 'red' }}>
          <SelectionMenu
            sx={{ minWidth: '192px' }}
            name="inputType"
            options={options}
            value={selectedView}
            onChange={handleChange}
            label={formatMessage({ id: 'maas.customerDatabase.inputType' })}
            required
          />
        </Box>
      </Box>
      {getSelectedViewComponent(selectedView)}
    </Box>
  );
};

export default CustomerDatabase;
