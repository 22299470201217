import { useMemo } from 'react';

import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import type { ColumnDef, Row } from '@tanstack/react-table';

import { FormattedTripPaymentDetails } from '../../../services/trip/TripPricingBreakdownService';

export const PaymentTransactionTableColumnDefs = () => {
  const { palette } = useTheme();
  return useMemo(
    (): ColumnDef<FormattedTripPaymentDetails>[] => [
      {
        header: 'common.paymentMethod'.i18nText(),
        accessorKey: 'methodName',
      },
      {
        header: 'tripHistory.table.date'.i18nText(),
        accessorKey: 'date',
      },
      {
        header: 'tripHistory.durationValue'.i18nText(),
        accessorKey: 'amount',
      },
      {
        header: 'common.lastStatusUpdate'.i18nText(),
        accessorKey: 'lastStatusUpdate',
        cell: ({ row }: { row: Row<FormattedTripPaymentDetails> }) => (
          <Typography variant="bodyMedium" color={row.original.isPaid ? 'initial' : palette.alert.main}>
            {row.original.lastStatusUpdate}
          </Typography>
        ),
      },
    ],
    [palette],
  );
};
