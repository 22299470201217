import type { CSSProperties } from 'react';

import { Box, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import { useIntl } from 'react-intl';

import CustomIcon from '../../utils/CustomIcon';
import { intl } from '../../utils/intl';
import { LoadingAnimation } from '../../wmv-components';

import {
  errorSubTextOnHoverStyles,
  containerStyles,
  contentWrapperStyles,
  retryButtonStyles,
} from './LoadingAndErrorWithRetryAndNoResults.styles';

export const LoadingAndErrorWithRetryAndNoResults = (props: LoadingAndErrorWithRetryAndNoResultsProps) => {
  const { style = {}, error, loading, baseTranslationKey, noResults = false } = props;
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const renderLoadingMessage = () => {
    return (
      <>
        <LoadingAnimation />
        <Typography variant="bodyMediumBold" color={theme.palette.dark.shade60}>
          {formatMessage({ id: `systemStatus.loading.${baseTranslationKey}` })}
        </Typography>
      </>
    );
  };

  const renderErrorMessage = () => {
    const titleExists = intl.messages[`systemStatus.error.${baseTranslationKey}`];
    const subTitleExists = intl.messages[`systemStatus.error.${baseTranslationKey}.subtext`];
    let mainText = formatMessage({ id: `systemStatus.error.caption` });
    let subText = formatMessage({ id: `systemStatus.error.subtext` });
    if (titleExists) {
      mainText = formatMessage({ id: `systemStatus.error.${baseTranslationKey}` });
    }
    if (subTitleExists) {
      subText = formatMessage({ id: `systemStatus.error.${baseTranslationKey}.subtext` });
    }
    return (
      <>
        <Button onClick={props.onRetry} sx={retryButtonStyles}>
          {CustomIcon('RefreshIcon', theme.palette.dark.shade76, '32px')}
          <Typography variant="bodyLargeBold" color={theme.palette.dark.shade76} sx={errorSubTextOnHoverStyles}>
            {mainText}
          </Typography>
          <Typography variant="bodyMedium" color={theme.palette.dark.shade60}>
            {subText}
          </Typography>
        </Button>
      </>
    );
  };

  const renderNoResultsMessage = () => {
    return (
      <>
        <Typography variant="bodyLargeBold" color={theme.palette.dark.shade76} className="hover-underline">
          {formatMessage({ id: `systemStatus.noResult.${baseTranslationKey}` })}
        </Typography>
        <Typography variant="bodyMedium" color={theme.palette.dark.shade60}>
          {formatMessage({ id: `systemStatus.noResult.${baseTranslationKey}.subtext` })}
        </Typography>
      </>
    );
  };

  const renderView = () => {
    if (loading) return renderLoadingMessage();
    if (error) return renderErrorMessage();
    if (noResults) return renderNoResultsMessage();

    return (
      <Typography variant="bodyMedium" color={theme.palette.dark.shade60}>
        {formatMessage({ id: `systemStatus.default.${baseTranslationKey}` })}
      </Typography>
    );
  };

  return (
    <Box sx={containerStyles} style={style}>
      <Box sx={contentWrapperStyles}>{renderView()}</Box>
    </Box>
  );
};

export interface LoadingAndErrorWithRetryAndNoResultsProps {
  style?: CSSProperties;
  baseTranslationKey?: string;
  onRetry?: () => void;
  error: boolean;
  loading: boolean;
  noResults?: boolean;
}
