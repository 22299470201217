import React from 'react';

import { Grid, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useIntl } from 'react-intl';

import { GraphqlVehicleUsageStatus, GraphqlVehicleUsageStatusHelper } from '../../../../models';
import { FormattedLiveVehicle } from '../../../../state/common/vehicle.model';
import CustomIcon from '../../../../utils/CustomIcon';

import VehicleIllustrations from './VehicleInfoIllustrations';

const GeneralInformation = ({ vehicle }: { vehicle: FormattedLiveVehicle }) => {
  const theme = useTheme();
  const { formatMessage, formatNumber } = useIntl();

  let range: number | string;
  if (vehicle.currentRangeMeters) {
    range = formatNumber(vehicle.currentRangeMeters! / 1000, { style: 'unit', unit: 'kilometer' });
  } else if (vehicle.maxRangeMeters) {
    range = formatNumber(vehicle.maxRangeMeters / 1000, { style: 'unit', unit: 'kilometer' });
  } else {
    range = formatNumber(0, { style: 'unit', unit: 'meter' });
  }

  const isMopedOrEbike = (formFactor: string) => ['moped', 'ebike'].includes(formFactor.toLowerCase());
  let batteryDisplay = formatMessage({ id: 'common.dash' });
  if (isMopedOrEbike(vehicle.formFactor) && vehicle.currentFuelPercent) {
    batteryDisplay = `${formatNumber(vehicle.currentFuelPercent / 100 || 0, { style: 'percent' })}`;
  }
  if (vehicle.currentRangeMeters !== null && vehicle.maxRangeMeters !== null) {
    batteryDisplay = `${formatNumber(vehicle.currentRangeMeters / vehicle.maxRangeMeters, { style: 'percent' })}`;
  }

  const StyledInfoRow = styled(Grid)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px',
    borderRadius: '8px',
  }));

  const vehicleDetails = [
    { label: 'provider', value: vehicle.mspName },
    { label: 'status', value: vehicle.usageStatus },
    { label: 'lastStatus', value: vehicle.usageStatus },
    { label: 'qr', value: vehicle.qrCodeId ? vehicle.qrCodeId : '-' },
    { label: 'range', value: range },
    {
      label: 'battery',
      value: batteryDisplay,
      icon: <>{CustomIcon('BatteryHalfIcon', theme.palette.dark.main, '32px')}</>,
    },
  ];

  const displayVehicleValue = (detail: { label: string; value: any }) => {
    return detail.label === 'status' || detail.label === 'lastStatus'
      ? GraphqlVehicleUsageStatusHelper.metadata(detail.value as GraphqlVehicleUsageStatus).displayText
      : detail.value;
  };
  return (
    <Grid container sx={{ gap: '32px' }}>
      <VehicleIllustrations formFactor={vehicle.formFactor} />
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        sx={{ backgroundColor: theme.palette.dark.shade3, borderRadius: '8px', padding: '4px 8px' }}
      >
        {vehicleDetails.map((detail, index) => (
          <StyledInfoRow key={index}>
            <Typography color={theme.palette.dark.shade76} variant="bodyMedium" sx={{ whiteSpace: 'nowrap' }}>
              {formatMessage({ id: `map.vehicleInfo.${detail.label}` })}
            </Typography>
            {detail.label === 'status' ? (
              <Typography
                color={GraphqlVehicleUsageStatusHelper.metadata(detail.value as GraphqlVehicleUsageStatus).color}
                variant="bodyMediumBold"
              >
                {displayVehicleValue(detail)}
              </Typography>
            ) : (
              <>
                {detail.icon ? (
                  <Grid container direction="row" alignItems="center" justifyContent="flex-end" gap="8px">
                    {detail.icon}
                    <Typography color={theme.palette.dark.main} variant="bodyMedium">
                      {displayVehicleValue(detail)}
                    </Typography>
                  </Grid>
                ) : (
                  <Typography color={theme.palette.dark.main} variant="bodyMedium">
                    {displayVehicleValue(detail)}
                  </Typography>
                )}
              </>
            )}
          </StyledInfoRow>
        ))}
      </Grid>
    </Grid>
  );
};
export default GeneralInformation;
