import { useEffect } from 'react';

import { Map } from 'leaflet';

export const useDrawDisabledEvent = (map: Map, onDrawDisabled: (shape: string) => void) => {
  useEffect(() => {
    // @ts-ignore
    const handleDrawDisabled = ({ shape }) => {
      onDrawDisabled(shape);
    };

    map.on('pm:drawend', handleDrawDisabled);

    return () => {
      map.off('pm:drawend', handleDrawDisabled);
    };
  }, [map, onDrawDisabled]);
};
