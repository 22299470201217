import type { PluginFunc } from 'dayjs';
import { Dayjs } from 'dayjs';
import isString from 'lodash/isString';

declare module 'dayjs' {
  interface Dayjs {
    // move time to known points within same day
    toBeginningOfDay(): Dayjs;
    toBeginningOfHour(): Dayjs;
    toBeginningOfMinute(): Dayjs;
    toBeginningOfSecond(): Dayjs;
    toEndOfDay(): Dayjs;
    toEndOfHour(): Dayjs;
    toEndOfMinute(): Dayjs;
    toEndOfSecond(): Dayjs;

    // plus
    plusYears(years: number): Dayjs;
    plusWeeks(weeks: number): Dayjs;
    plusMonths(months: number): Dayjs;
    plusDays(dayjs: number): Dayjs;
    plusHours(hours: number): Dayjs;
    plusMinutes(hours: number): Dayjs;
    plusSeconds(hours: number): Dayjs;
    plusMilliSeconds(hours: number): Dayjs;

    // minus
    minusYears(years: number): Dayjs;
    minusWeeks(weeks: number): Dayjs;
    minusMonths(months: number): Dayjs;
    minusDays(dayjs: number): Dayjs;
    minusHours(hours: number): Dayjs;
    minusMinutes(hours: number): Dayjs;
    minusSeconds(hours: number): Dayjs;
    minusMilliSeconds(hours: number): Dayjs;

    // set
    setDate(date: number): Dayjs;
    setYear(date: number): Dayjs;
    setMonth(day: number): Dayjs;
    setDay(day: number): Dayjs;
    setHour(hour: number): Dayjs;
    setMinute(minute: number): Dayjs;
    setSecond(second: number): Dayjs;
    setMilliSecond(second: number): Dayjs;
    setTime(days: Dayjs | string): Dayjs;
  }
}

const timeUnitAdjustmentPlugin: PluginFunc = (option, dayjsClass, dayjsFactory) => {
  // move time to known points within same day
  dayjsClass.prototype.toBeginningOfDay = function (): Dayjs {
    return this.startOf('day');
  };

  dayjsClass.prototype.toBeginningOfHour = function (): Dayjs {
    return this.startOf('hour');
  };

  dayjsClass.prototype.toBeginningOfMinute = function (): Dayjs {
    return this.startOf('minute');
  };

  dayjsClass.prototype.toBeginningOfSecond = function (): Dayjs {
    return this.startOf('second');
  };

  dayjsClass.prototype.toEndOfDay = function (): Dayjs {
    return this.endOf('day');
  };

  dayjsClass.prototype.toEndOfHour = function (): Dayjs {
    return this.endOf('hour');
  };

  dayjsClass.prototype.toEndOfMinute = function (): Dayjs {
    return this.endOf('minute');
  };

  dayjsClass.prototype.toEndOfSecond = function (): Dayjs {
    return this.endOf('second');
  };

  // plus

  dayjsClass.prototype.plusYears = function (years: number): Dayjs {
    return this.add(years, 'year');
  };

  dayjsClass.prototype.plusWeeks = function (weeks: number): Dayjs {
    return this.add(weeks, 'week');
  };

  dayjsClass.prototype.plusMonths = function (months: number): Dayjs {
    return this.add(months, 'month');
  };

  dayjsClass.prototype.plusDays = function (days: number): Dayjs {
    return this.add(days, 'day');
  };

  dayjsClass.prototype.plusHours = function (hours: number): Dayjs {
    return this.add(hours, 'hour');
  };

  dayjsClass.prototype.plusMinutes = function (minutes: number): Dayjs {
    return this.add(minutes, 'minute');
  };

  dayjsClass.prototype.plusSeconds = function (seconds: number): Dayjs {
    return this.add(seconds, 'second');
  };

  dayjsClass.prototype.plusMilliSeconds = function (milliSeconds: number): Dayjs {
    return this.add(milliSeconds, 'millisecond');
  };

  // minus

  dayjsClass.prototype.minusYears = function (years: number): Dayjs {
    return this.subtract(years, 'year');
  };

  dayjsClass.prototype.minusWeeks = function (weeks: number): Dayjs {
    return this.subtract(weeks, 'week');
  };

  dayjsClass.prototype.minusMonths = function (months: number): Dayjs {
    return this.subtract(months, 'month');
  };

  dayjsClass.prototype.minusDays = function (days: number): Dayjs {
    return this.subtract(days, 'day');
  };

  dayjsClass.prototype.minusHours = function (hours: number): Dayjs {
    return this.subtract(hours, 'hour');
  };

  dayjsClass.prototype.minusMinutes = function (minutes: number): Dayjs {
    return this.subtract(minutes, 'minute');
  };

  dayjsClass.prototype.minusSeconds = function (seconds: number): Dayjs {
    return this.subtract(seconds, 'second');
  };

  dayjsClass.prototype.minusMilliSeconds = function (milliSeconds: number): Dayjs {
    return this.subtract(milliSeconds, 'millisecond');
  };

  // Set time
  dayjsClass.prototype.setYear = function (year: number): Dayjs {
    return this.set('year', year);
  };

  dayjsClass.prototype.setDate = function (date: number): Dayjs {
    return this.set('date', date);
  };

  dayjsClass.prototype.setDay = function (day: number): Dayjs {
    return this.set('day', day);
  };

  dayjsClass.prototype.setMonth = function (month: number): Dayjs {
    return this.set('month', month);
  };

  dayjsClass.prototype.setHour = function (hour: number): Dayjs {
    return this.set('hour', hour);
  };

  dayjsClass.prototype.setMinute = function (minute: number): Dayjs {
    return this.set('minute', minute);
  };

  dayjsClass.prototype.setSecond = function (second: number): Dayjs {
    return this.set('second', second);
  };

  dayjsClass.prototype.setMilliSecond = function (ms: number): Dayjs {
    return this.set('millisecond', ms);
  };

  // time as string should be of format hh:mm HH:mm HH:mm:ss hh:mm:ss
  dayjsClass.prototype.setTime = function (time: Dayjs | string): Dayjs {
    if (isString(time)) {
      time = time.asDayjsTime();
    }
    const hour = time.getHour();
    const minute = time.getMinute();
    const second = time.getSecond();
    const ms = time.getMilliSecond();

    return this.setHour(hour).setMinute(minute).setSecond(second).setMilliSecond(ms);
  };
};

export default timeUnitAdjustmentPlugin;
